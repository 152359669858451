import endOfUtteranceSound from '../assets/sounds/end_of_utterance.mp3'
import errorSound from '../assets/sounds/error.mp3'
import exitDictationSound from '../assets/sounds/exit_dictation.mp3'
import urineCulture from '../assets/images/urineCulture.png'
import veinMapping from '../assets/images/veinMapping.png'
import chestXray from '../assets/images/chestXray.png'

export const Z_INDEX = {
  MODAL: 9999,
  HAMBURGER: 9000,
  VEIL_ACTIVE: 8000,
  AGENT: 7000,
  SIDEBAR: 4000,
  LIST: 3000,
  VEIL: 0,
  BACKGROUND: -1
}

export const AGENT = {
  SIZE: {
    LARGE: 'LARGE',
    THREE_QUARTERS: 'THREE_QUARTERS',
    SMALL: 'SMALL'
  },
  POSITION: {
    TOP_LEFT: 'TOP_LEFT',
    CENTER: 'CENTER',
    MIDDLE_LEFT: 'MIDDLE_LEFT',
    LEFT_MIDDLE: 'LEFT_MIDDLE',
    LEFT: 'LEFT'
  },
  STATE: {
    MUTED: 'MUTED',
    READY: 'READY',
    AWAKE: 'AWAKE',
    PROCESSING: 'PROCESSING',
    LISTENING: 'LISTENING'
  }
}

export const SOUNDS = {
  END_OF_UTTERANCE: endOfUtteranceSound,
  ERROR: errorSound,
  EXIT_DICTATION: exitDictationSound
}

export const LISTS = {
  SCHEDULE: 'SCHEDULE',
  PATIENT_NOTES: 'PATIENT_NOTES',
  UNFINISHED_NOTES: 'UNFINISHED_NOTES',
  PATIENTS_LIST: 'PATIENTS_LIST'
}

export const NOTE_STATUS = {
  INCOMPLETE: 'INCOMPLETE',
  USER_IS_DONE: 'USER_IS_DONE',
  SUBMITTED_TO_EMR: 'SUBMITTED_TO_EMR',
  IMPORTED_FROM_EMR: 'IMPORTED_FROM_EMR',
  NEED_DOCTOR_SIGN_OFF: 'NEED_DOCTOR_SIGN_OFF'
}

export const SECTION_STATUS = {
  CREATED: 'CREATED',
  ASR: 'ASR',
  USER_EDITS: 'USER_EDITS',
  READY_FOR_PROCESSING: 'READY_FOR_PROCESSING',
  PROCESSED_BY_AI: 'PROCESSED_BY_AI',
  SCRIBE_EDITS: 'SCRIBE_EDITS',
  REVIEWED: 'REVIEWED',
  EMR: 'EMR',
  DEFAULT: 'DEFAULT'
}

export const MODALS = {
  IM_DONE: 'IM_DONE',
  IM_DONE_BYPASS_QA: 'IM_DONE_BYPASS_QA',
  START_NOTE: 'START_NOTE',
  RADIO: 'RADIO',
  SELECT: 'SELECT',
  DELETE_NOTE: 'DELETE_NOTE',
  DELETE_SECTION: 'DELETE_SECTION',
  READ_NOTE: 'READ_NOTE',
  ERROR: 'ERROR',
  IMAGE: 'IMAGE',
  EMPTY_NOTE_IM_DONE: 'EMPTY_NOTE_IM_DONE',
  UNKNOWN_PATIENT_SAVE: 'UNKNOWN_PATIENT_SAVE',
  PDF_SUBMIT: 'PDF_SUBMIT',
  NO_ENCOUNTER: 'NO_ENCOUNTER',
  PRINT_NOTES: 'PRINT_NOTES',
  IM_DONE_PDF: 'IM_DONE_PDF',
  SCRIPT_DETAILS: 'SCRIPT_DETAILS',
  LIST: 'LIST',
  SIGN_OFF: 'SIGN_OFF',
  SUBMIT_OPTIONS: 'SUBMIT_OPTIONS',
  ICD10_SEARCH: 'ICD10_SEARCH',
  ICD10_PREVIEW: 'ICD10_PREVIEW',
  CONFIRM_CODES: 'CONFIRM_CODES',
  INACTIVITY_TIMEOUT: 'INACTIVITY_TIMEOUT',
  CONTENT_ERROR: 'CONTENT_ERROR',
  NEW_SCRIPT: 'NEW_SCRIPT',
  EMR_NOTE_PULL: 'EMR_NOTE_PULL',
  NEW_NOTETYPE: 'NEW_NOTETYPE',
  NOTETYPE_DETAIL: 'NOTETYPE_DETAIL',
  SETTINGS: 'SETTINGS',
  LOADING: 'LOADING',
  NOTE_SUBMISSION_WITHOUT_EMR_CONNECTION: 'NOTE_SUBMISSION_WITHOUT_EMR_CONNECTION',
  AMWELL_SCRIPTS: 'AMWELL_SCRIPTS'
}

export const DIMENSIONS = {
  LIST_HEADER: 90,
  LIST_CELL_ONE: 90,
  LIST_CELL_TWO: 120,
  LIST_DELIMITER: 50,
  NOTE_TOP: 84
}

export const NOTES = {
  READ_ONLY: 'READ_ONLY',
  EDITABLE: 'EDITABLE'
}

export const STRINGS = {
  TOAST_SUBMIT_NOTE: 'Great. Starting to process it now.',
  TOAST_DELETE_NOTE: 'Note deleted.'
}

export const IMAGES = {
  URINE_CULTURE: {
    title: 'Urine Culture',
    src: urineCulture
  },
  VEIN_MAPPING: {
    title: 'Vein Mapping',
    src: veinMapping
  },
  XRAY: {
    title: 'Chest X-Ray',
    src: chestXray
  }
}

export const ALERTS = {
  RECONNECTING: {
    message: 'Poor connection. Reconnecting...'
  }
}

export const SECTION_STATE = {
  FOCUSED: 'FOCUSED',
  TYPING: 'TYPING',
  DICTATION: 'DICTATION',
  INSERTING: 'INSERTING'
}

export const TOAST = {
  TYPE: {
    PRIMARY: 'primary',
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning'
  },
  DURATION: {
    SHORT: 2000,
    MEDIUM: 4000,
    LONG: 6000,
    INDEFINITE: 1
  }
}

export const ICON_SIZE = {
  XSMALL: '20',
  SMALL: '30',
  MED: '48',
  LARGE: '60',
  XLARGE: '75'
}

export const EMR_DESTINATION = {
  SUKI_ONLY: 'SUKI_ONLY',
  ENCOUNTER: 'ENCOUNTER',
  DOCUMENT: 'DOCUMENT'
}

export const HAMBURGER_MENU = {
  HOME: 'Home',
  SCHEDULE: 'Schedule',
  PATIENTS: 'Patients',
  UNFINISHED_NOTES: 'Unfinished Notes',
  PRINT_NOTE: 'Print Note',
  EMAIL_NOTE: 'Email Note',
  PRINT_NOTES: 'Print Notes',
  DELETE_NOTE: 'Delete Note',
  PRIOR_NOTES: 'Prior Notes',
  SCRIPTS: 'Scripts',
  REFRESH_NOTE_FROM_EMR: 'Refresh Note From EMR',
  HELP: 'Help',
  SETTINGS: 'Settings',
  LOG_OUT: 'Log Out'
}

export const TIMEZONES = [
  { name: 'ET (New York)', value: 'America/New_York' },
  { name: 'CT (Chicago)', value: 'America/Chicago' },
  { name: 'MT (Denver)', value: 'America/Denver' },
  { name: 'PT (Los Angeles)', value: 'America/Los_Angeles' },
  { name: 'AKT (Alaska)', value: 'America/Juneau' },
  { name: 'HST (Hawaii)', value: 'Pacific/Honolulu' }
]

export const KEYCODES = {
  ENTER: 'Enter'
}

export const RESPONSIVE = {
  MODAL_FULL_WIDTH: 'calc(100vw - 20px)',
  MODAL_LARGE_WIDTH: '800px'
}
