import React, { Component } from 'react'

const styles = {
  body: {
    paddingLeft: 15,
    marginTop: 10
  }
}

export default class ScriptChangeRawTranscript extends Component {
  render () {
    const rawArray = this.props.audioTranscripts
    const transcriptData = rawArray && rawArray.map((outer, oidx) => (
      outer.transcripts.map((inner, iidx, transcripts) => (
        <div key={`transcript-${oidx}-${iidx}`}>
          <div style={{ fontWeight: 600 }}>
            Transcript {oidx + 1}{transcripts.length > 1 && `.${iidx + 1}`}
          </div>
          <div>
            <ol>{inner.textContent.map((t, tidx) => <li key={tidx}>{t}</li>)}</ol>
          </div>
          <details>
            <summary style={{ outline: 'none' }}>Meta</summary>
            <ol>{inner.rawContent.map((r, ridx) => <li key={ridx}>{r}</li>)}</ol>
          </details>
        </div>
      ))
    ))

    return (
      <div>
        <h3>Transcript data for change</h3>
        <div style={styles.body}>
          {(this.props.audioTranscripts && this.props.audioTranscripts.length > 0) ? transcriptData : <i>No data to display</i>}
        </div>
      </div>
    )
  }
}
