import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Query, Mutation } from 'react-apollo'
import {
  GetEMRSectionsForOrg,
  CreateSection,
  GetSections,
  UpdateSection,
  DeleteSection,
  CreateSectionMapping,
  UpdateSectionMapping,
  DeleteSectionMapping
} from '@sukiai/gql/admin'
import get from 'lodash.get'
import { LO_STO } from '../../lib/constants'
import { whiteSmoke, charcoal } from '../../styles/colors'
import loSto from '../../config/loSto'
import {
  SECTION_HINTS_PLACEHOLDER,
  SECTION_NAME_PLACEHOLDER,
  FIELD,
  UNMAPPED,
  ASSESSMENT
} from './constants'
import { setError, notifySuccess } from '../../lib/util'
import TopNavBar from '../TopNavBar'
import { navBarHeight } from '../../styles/dimensions'

const styles = {
  pageContainer: {
    position: 'fixed',
    top: navBarHeight,
    left: 0,
    height: `calc(100vh - ${navBarHeight})`,
    overflow: 'scroll',
    width: '100vw',
    boxSizing: 'border-box',
    padding: 30
  },
  container: {
    boxSizing: 'border-box',
    width: '100%',
    minWidth: 500,
    maxWidth: 1000,
    padding: 20,
    borderRadius: 4,
    backgroundColor: whiteSmoke,
    color: charcoal,
    boxShadow: '0px 4px 10px rgba(0,0,0,0.5)'
  },
  header: {
    fontSize: 20,
    marginBottom: 20,
    fontWeight: 600
  },
  row: {
    marginBottom: 18,
    fontSize: 16,
    display: 'flex'
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  name: {
    boxSizing: 'border-box',
    fontSize: 'inherit',
    padding: 4,
    width: '320px'
  },
  hints: {
    display: 'block',
    boxSizing: 'border-box',
    fontSize: 'inherit',
    padding: 4,
    marginTop: 4,
    marginLeft: 17,
    width: 'calc(100% - 17px)',
    maxWidth: '320px'
  },
  middle: {
    margin: '0px 8px',
    height: 30,
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    display: 'block',
    boxSizing: 'border-box',
    fontSize: 'inherit',
    padding: 4,
    height: 30,
    width: '320px'
  },
  pbnSectionIndicator: {
    display: 'block',
    margin: '0px 20px',
    fontWeight: '600',
    color: 'green'
  },
  isPbn: {
    display: 'flex',
    alignContent: 'center',
    margin: '4px 0px 0px 16px'
  },
  isPbnCheckbox: {
    color: 'blue',
    width: '24px',
    height: '24px',
    marginRight: '20px'
  },
  isPbnLabel: {
    fontSize: '13pt',
    transform: 'translateY(18%)'
  },
  addSectionRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 17,
    width: 'calc(100% - 17px)'
  },
  addButton: {
    padding: '8px 12px',
    color: whiteSmoke,
    backgroundColor: charcoal,
    fontSize: '16px',
    borderRadius: '4px',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.5)',
    marginLeft: '10px',
    border: 'none',
    transition: 'all 0.3s',
    cursor: 'pointer'
  },
  saveButton: {
    fontSize: '14',
    marginLeft: '5px',
    borderRadius: '4px',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.5)',
    color: whiteSmoke,
    backgroundColor: charcoal,
    border: 'none',
    transition: 'all 0.3s',
    cursor: 'pointer',
    height: '32px'
  },
  deleteButton: {
    display: 'none',
    backgroundColor: '#de6262',
    boxShadow: '',
    marginTop: '-2px'
  }
}

class MappingRow extends Component {
  state = {
    isPbnSection: this.props.section.pbcSectionFlag,
    isDirty: false, // if inputs has been altered
    newEmrSectionId: null
  }

  handleUpdateSection = () => {
    const {
      section: { __typename, emrMapping, ...originalSection },
      organizationId,
      client
    } = this.props

    const { name, hints, isPbnSection, newEmrSectionId } = this.state

    // if value is set to state then override
    // else use values for cache
    const section = {
      ...originalSection,
      name: name || originalSection.name,
      hints: hints ? hints.split(',').map(v => v.trim()) : originalSection.name,
      pbcSectionFlag: isPbnSection
    }

    client.mutate({
      mutation: UpdateSection,
      variables: { organizationId, section },
      refetchQueries: [{
        query: GetSections,
        variables: { organizationId, withMapping: true }
      }]
    })
      .then(() => notifySuccess(`Successfully updated ${section.name}`))
      .catch(e => setError(`Could not update section: ${e}`))

    // handle emr mapping
    newEmrSectionId && this.handleMappingChange(newEmrSectionId)

    this.setState({ isDirty: false })
  }

  addSectionMapping = emrSectionId => {
    const { section, client, emrSections = [], organizationId } = this.props
    const sukiSectionId = get(section, 'id')

    const emrSection = emrSections.find(es => es.id === emrSectionId)
    if (!emrSection) return
    delete emrSection.__typename

    const mapping = { sukiSectionId, emrSection }

    client.mutate({
      mutation: CreateSectionMapping,
      variables: { organizationId, mapping },
      refetchQueries: [{
        query: GetSections,
        variables: { organizationId, withMapping: true }
      }]
    })
      .then(() => notifySuccess(`Successfully mapped ${section.name}`))
      .catch(e => setError(`Could not create section mapping: ${e}`))
  }

  updateSectionMapping = emrSectionId => {
    const { section, client, emrSections = [], organizationId } = this.props
    const sukiSectionId = get(section, 'id')

    const emrSection = emrSections.find(es => es.id === emrSectionId)
    if (!emrSection) return
    delete emrSection.__typename

    const mapping = { sukiSectionId, emrSection }

    client.mutate({
      mutation: UpdateSectionMapping,
      variables: { organizationId, mapping },
      refetchQueries: [{
        query: GetSections,
        variables: { organizationId, withMapping: true }
      }]
    })
      .then(() => notifySuccess(`Successfully updated mapping for ${section.name}`))
      .catch(e => setError(`Could not update section mapping: ${e}`))
  }

  deleteSectionMapping = () => {
    const { section, client, organizationId } = this.props
    const sukiSectionId = get(section, 'id')
    client.mutate({
      mutation: DeleteSectionMapping,
      variables: { organizationId, sukiSectionId },
      refetchQueries: [{
        query: GetSections,
        variables: { organizationId, withMapping: true }
      }]
    })
      .then(() => notifySuccess(`Successfully deleted mapping for ${section.name}`))
      .catch(e => setError(`Could not delete section mapping: ${e}`))
  }

  handleMappingChange = emrSectionId => {
    const { section } = this.props
    const { isPbnSection } = this.state
    const mapping = get(section, 'emrMapping')

    if (!mapping) {
      this.addSectionMapping(emrSectionId)
    } else if (emrSectionId === UNMAPPED) {
      this.deleteSectionMapping()
    } else {
      this.updateSectionMapping(emrSectionId)
    }

    if (emrSectionId.toUpperCase() === ASSESSMENT && !isPbnSection) {
      this.handleUpdateSection(FIELD.IS_PBN_SECTION, true)
      this.setState({ isPbnSection: true })
    }
  }

  handleMappingTouch = newEmrSectionId => {
    this.setState({ newEmrSectionId, isDirty: true })
  }

  handleTogglePbn = e => {
    this.setState({ isPbnSection: !this.state.isPbnSection, isDirty: true })
  }

  handleInputChange = e => {
    // set values to state
    this.setState({ [e.target.name]: e.target.value, isDirty: true })
  }

  handleDeleteSection = () => {
    const {
      section,
      organizationId,
      client
    } = this.props

    const confirm = window.confirm(`Are you sure you want to delete this section? ${section.name}`)

    if (confirm) {
      // delete section
      client.mutate({
        mutation: DeleteSection,
        variables: {
          organizationId,
          id: section.id
        },
        refetchQueries: [{
          query: GetSections,
          variables: { organizationId, withMapping: true }
        }],
        awaitRefetchQueries: true
      }).then(() => notifySuccess(`Successfully deleted section ${section.name}`))
    }

    // delete mapping
    !!section.emrMapping && this.deleteSectionMapping()
  }

  render () {
    const { section, emrSections = [] } = this.props
    const { isDirty, isPbnSection } = this.state
    return (
      <div style={styles.row}>
        <details>
          <summary>
            <input
              type='text'
              name={FIELD.NAME}
              placeholder={SECTION_NAME_PLACEHOLDER}
              style={styles.name}
              defaultValue={section.name}
              onChange={this.handleInputChange}
              autoComplete='off'
            />
          </summary>
          <input
            type='text'
            name={FIELD.HINTS}
            placeholder={SECTION_HINTS_PLACEHOLDER}
            style={styles.hints}
            defaultValue={get(section, 'hints', []).join(', ')}
            onChange={this.handleInputChange}
            autoComplete='off'
          />
          <span style={styles.isPbn}>
            <input
              type='checkbox'
              name={FIELD.IS_PBN_SECTION}
              style={styles.isPbnCheckbox}
              checked={isPbnSection}
              onChange={this.handleTogglePbn}
            />
            <label style={styles.isPbnLabel}>Is PBN Section?</label>
          </span>

        </details>
        {emrSections.length > 0 && (
          <>
            <span style={styles.middle}>maps to</span>
            <select
              defaultValue={get(section, 'emrMapping.id')}
              style={styles.select}
              onChange={e => this.handleMappingTouch(e.target.value)}
            >
              <option value={UNMAPPED}>Unmapped</option>
              {emrSections.map(es => (
                <option key={es.id} value={es.id}>{es.name || es.id}</option>
              ))}
            </select>
          </>
        )}
        <button
          disabled={!isDirty} onClick={this.handleUpdateSection} style={isDirty ? styles.saveButton
            : { ...styles.saveButton, boxShadow: '', opacity: 0.5, cursor: 'not-allowed', marginTop: '-2px' }}
        >Save
        </button>
        <button onClick={this.handleDeleteSection} style={{ ...styles.saveButton, backgroundColor: '#de6262', boxShadow: '', marginTop: '-2px' }}>
          Delete
        </button>

        {
          isPbnSection &&
            <div style={styles.pbnSectionIndicator}>PBN SECTION</div>
        }
      </div>
    )
  }
}

class AddSectionRow extends Component {
  state = { name: '' }

  render () {
    const { organizationId, userId } = this.props
    const { name } = this.state

    return (
      <div style={styles.addSectionRow}>
        <input
          type='text'
          name={FIELD.NAME}
          placeholder={SECTION_NAME_PLACEHOLDER}
          style={{ ...styles.name, flex: '', width: '45%' }}
          value={name}
          onChange={e => this.setState({ name: e.target.value })}
          autoComplete='off'
        />
        <Mutation
          mutation={CreateSection}
          variables={{
            organizationId,
            createdBy: userId,
            name
          }}
          refetchQueries={[{
            query: GetSections,
            variables: { organizationId, withMapping: true }
          }]}
          onSuccess={() => this.setState({ name: '' })}
          onError={e => setError(`Could not create section: ${e}`)}
        >
          {createSection => (
            <button
              onClick={createSection}
              disabled={!name}
              style={name
                ? styles.addButton
                : { ...styles.addButton, boxShadow: '', opacity: 0.7, cursor: 'not-allowed' }}
            >
              Add Section
            </button>
          )}
        </Mutation>
      </div>
    )
  }
}

class SectionMapper extends Component {
  render () {
    const { match } = this.props
    const organizationId = get(match, 'params.orgId')
    const userId = loSto.get(LO_STO.USER_ID)

    return (
      <>
        <TopNavBar />
        <div style={styles.pageContainer}>
          <div style={styles.container}>
            <Query
              query={GetEMRSectionsForOrg}
              variables={{ organizationId }}
              skip={!organizationId}
            >
              {({
                loading: orgsLoading,
                error: orgsError,
                data: orgsData
              }) => (
                <Query
                  query={GetSections}
                  variables={{ organizationId, withMapping: true }}
                  skip={!organizationId}
                >
                  {({
                    loading: sectionsLoading,
                    error: sectionsError,
                    data: sectionsData,
                    client
                  }) => {
                    if (orgsLoading || sectionsLoading) return 'Loading...'
                    if (orgsError || sectionsError) return 'Uh oh, something went wrong!'

                    const org = get(orgsData, 'organizations.results[0]', {})
                    const emrSections = get(org, 'emr.capabilities.sections', [])
                    const sections = get(sectionsData, 'sections.results', [])
                    const emrType = get(org, 'emr.type')

                    return (
                      <>
                        <div style={styles.header}>
                          {org.name} Sections
                          {emrType && ` (${emrType})`}
                        </div>
                        {sections.map((s, idx) => (
                          <MappingRow
                            key={s.id}
                            index={idx}
                            section={s}
                            emrSections={emrSections}
                            organizationId={organizationId}
                            client={client}
                          />
                        ))}
                        <AddSectionRow
                          organizationId={organizationId}
                          userId={userId}
                        />
                      </>
                    )
                  }}
                </Query>
              )}
            </Query>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(SectionMapper)
