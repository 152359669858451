import React, { Component } from 'react'
import {
  GetAccessRule,
  UpdateAccessRule,
  DeleteAccessRule,
  CreateAccessRule
} from '@sukiai/gql/admin'
import Loading from '../components/Loading'
import { FilterBar } from '@sukiai/components'
import { compose, graphql } from 'react-apollo'
import { CheckableListItem } from '../components/util'
import { whiteSmoke, charcoal, scribeGreen, lightGreyTrim } from '../styles/colors'
import { setError, notifySuccess } from '../lib/util'
import get from 'lodash.get'
import { FETCH_POLICY } from '../lib/constants'

const styles = {
  cardContainer: {
    position: 'relative',
    minWidth: 320,
    width: '35%',
    backgroundColor: whiteSmoke,
    padding: '10px 0px',
    boxShadow: '0 0 10px rgba(0,0,0,0.5)',
    marginTop: 30
  },
  accessControlContainer: {
    position: 'relative',
    height: 350,
    overflow: 'scroll'
  },
  accessControlHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 20px',
    cursor: 'pointer'
  },
  filterBoxStyle: {
    width: '100%',
    padding: '5px 5px 5px 10px',
    backgroundColor: 'transparent',
    border: `solid 1px ${lightGreyTrim}`,
    ':focus': {
      outline: 'none'
    },
    marginLeft: 20
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 30px 5px 10px',
    marginTop: 10,
    width: '100%'
  },
  actionBar: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 30px 10px 30px',
    borderTop: `1px solid ${lightGreyTrim}`
  },
  actionButton: {
    fontFamily: 'Open Sans',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 18,
    cursor: 'pointer',
    color: scribeGreen
  },
  saveButton: {
    position: 'absolute',
    right: 20
  },
  subtext: {
    padding: '0 20px',
    color: charcoal,
    fontSize: 14
  },
  headerText: {
    fontSize: 18
  },
  checkbox: {
    marginLeft: 12,
    height: 16,
    width: 16
  },
  filterBar: {
    width: '100%'
  }
}

const FILTER_PLACEHOLDER = 'Filter by org name or id'
const SUBTEXT = 'Select orgs that all ops in this org can access'

class OpsAccessControl extends Component {
  state = {
    open: false,
    isAllChecked: false, // change to depend on incoming data
    filterVal: '',
    changedRules: [],
    allowed: []
  }

  handleClick = e => {
    this.setState({ open: !this.state.open })
  }

  checkAll = e => {
    if (this.state.isAllChecked) {
      this.setState({ allowed: [] })
    } else {
      this.setState({ allowed: this.props.orgs.map(o => o.id) })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    // when the query for accessRules is done loading, initialize the allowed state var.
    if (!this.props.rulesLoading && prevProps.rulesLoading) {
      this.setState({ allowed: this.props.accessRules ? this.props.accessRules.allow : [] })
    }

    if (prevState.allowed !== this.state.allowed) {
      // We are checking to see whether all orgs have been checked or not.
      // This logic depends on onMount putting all of the org ids into the
      // orgKeys object.
      if (this.props.orgs.length === this.state.allowed.length) {
        !this.state.isAllChecked && this.setState({ isAllChecked: true })
      } else {
        this.state.isAllChecked && this.setState({ isAllChecked: false })
      }
    }
  }

  checkItem = id => {
    const { allowed } = this.state
    if (allowed.includes(id)) {
      this.setState({ allowed: allowed.filter(i => i !== id) })
    } else {
      this.setState({ allowed: [...allowed, id] })
    }
  }

  handleUpdateRules = () => {
    const { updateAccessRule, deleteAccessRule, createAccessRule, currentOrg, accessRules } = this.props
    const { allowed } = this.state
    const initialAllowed = accessRules ? accessRules.allow : []
    if (allowed.toString() === initialAllowed.toString()) return

    if (allowed.length === 0 && accessRules) {
      deleteAccessRule({
        variables: { organizationId: currentOrg.id },
        refetchQueries: [{
          query: GetAccessRule,
          variables: { organizationId: currentOrg.id }
        }]
      }).then(({ data }) => {
        console.info('Deleted Access Rule for org:', currentOrg.id)
        notifySuccess('Updated access rules')
      }).catch(err => {
        console.error('Error deleting access rule', err)
        setError('Error deleting access rule')
      })
    } else if (allowed.length > 0 && !accessRules) {
      createAccessRule({
        variables: { organizationId: currentOrg.id, allow: allowed },
        refetchQueries: [{
          query: GetAccessRule,
          variables: { organizationId: currentOrg.id }
        }]
      }).then(({ data }) => {
        console.info('Created Access Rule for org:', currentOrg.id)
        notifySuccess('Updated access rules')
      }).catch(err => {
        console.error('Error creating access rule', err)
        setError('Error creating access rule')
      })
    } else if (allowed.length > 0 && accessRules) {
      updateAccessRule({
        variables: { organizationId: currentOrg.id, allow: allowed },
        refetchQueries: [{
          query: GetAccessRule,
          variables: { organizationId: currentOrg.id }
        }]
      }).then(({ data }) => {
        console.info('Updated Access Rule for org:', currentOrg.id)
        notifySuccess('Updated access rules')
      }).catch(err => {
        console.error('Error updating access rule', err)
        setError('Error updating access rule')
      })
    }
  }

  handleRevertChanges = () => {
    const { accessRules } = this.props
    this.setState({ allowed: accessRules ? accessRules.allow : [] })
  }

  handleSetFilterString = val => {
    this.setState({ filterVal: val })
  }

  filterOrgs = orgs => (
    orgs.filter(org => (
      org.id.includes(this.state.filterVal) ||
        org.name.toUpperCase().includes(this.state.filterVal.toUpperCase())
    ))
  )

  renderActionBar = () => {
    return (
      <div style={styles.actionBar}>
        <div style={styles.actionButton} onClick={this.handleRevertChanges}>Revert</div>
        <div style={styles.actionButton} onClick={this.handleUpdateRules}>Save Changes</div>
      </div>
    )
  }

  render () {
    const { orgs } = this.props
    const { allowed, isAllChecked, open } = this.state
    if (!orgs) return null
    return (
      <div>
        <div style={styles.cardContainer}>
          <div
            onClick={this.handleClick}
            style={styles.accessControlHeader}
            data-cy='ops-access-control-header'
          >
            <span style={styles.headerText}>Ops Access Control</span>
          </div>

          {open && (
            <div>
              <div style={styles.subtext}>
                {SUBTEXT}
              </div>

              <div style={styles.filterContainer}>
                <span style={styles.checkAll}>
                  <input
                    type='checkbox'
                    onChange={e => this.checkAll(e)}
                    style={styles.checkbox}
                    checked={isAllChecked}
                    data-cy='ops-access-control-check-all'
                  />
                </span>

                <span style={styles.filterBar}>
                  <FilterBar
                    onChange={this.handleSetFilterString}
                    placeholder={FILTER_PLACEHOLDER}
                    data-cy='ops-access-control-filter-bar'
                  />
                </span>

              </div>

              <div style={styles.accessControlContainer}>
                {allowed
                  ? this.filterOrgs(orgs).map((o, i) => (
                    <CheckableListItem
                      org={o}
                      key={i}
                      checkAction={id => this.checkItem(id)}
                      checked={allowed.some(id => id === o.id)}
                    />
                  ))
                  : <Loading />}
              </div>
              {this.renderActionBar()}
            </div>
          )}

        </div>
      </div>
    )
  }
}

const mapAccessRulesToProps = ({ data }) => ({
  rulesError: data.error,
  rulesLoading: data.loading,
  accessRules: get(data, 'accessRules.results[0]', null)
})

export default compose(
  graphql(GetAccessRule, {
    skip: props => !props.currentOrg,
    options: props => ({
      variables: { organizationId: props.currentOrg.id },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY
    }),
    props: mapAccessRulesToProps
  }),
  graphql(UpdateAccessRule, { name: 'updateAccessRule' }),
  graphql(DeleteAccessRule, { name: 'deleteAccessRule' }),
  graphql(CreateAccessRule, { name: 'createAccessRule' })
)(OpsAccessControl)
