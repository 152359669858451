import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { setUserId } from '../actions/admin'
import { withRouter } from 'react-router-dom'
import {
  GetUsers,
  CreateUser,
  UpdateUser,
  DeleteUsers,
  GetOrganizationById,
  GetNotificationHistory,
  ResetPassword,
  LinkEMRUser,
  GetUserNvoqInformation,
  UnlinkEMRUser,
  ClearWebUserSessions
} from '@sukiai/gql/admin'
import { MassiveBtnContainer, MassiveBtn } from '../components/Buttons/styled'
import { getFullName } from '@sukiai/utils'
import Frame from './Frame'
import adminBackground from '../images/adminBackground.jpg'
import {
  EditorSidebar,
  ListPanel,
  ActionPanel
} from '../components/util'
import {
  UserLinks,
  UserForm,
  ActivatePanel,
  LinkEMRPanel,
  NotificationEventItem
} from '../components/Users'
import { notifySuccess, setError, copyText, disableSharedUserInput } from '../lib/util'
import { LO_STO, GENDERS, FETCH_POLICY, QUERY_PARAMS } from '../lib/constants'
import { whiteSmoke, charcoal } from '../styles/colors'
import editPencil from '../images/edit-pencil.svg'
import Radium from 'radium'
import omitDeep from 'omit-deep-lodash'
import loSto from '../config/loSto'
import get from 'lodash.get'
import LinkEMRForm from '../components/Users/LinkEMRForm'
import AppointmentsRangePicker from '../components/Orgs/AppointmentsRangePicker'
import queryString from 'query-string'
import EMRStatusCheck from '../components/HealthCheck/EMRStatusCheck'
import { UserNotes } from '../components'
import { EnhancedReviewBox } from './'

const TIMEZONES = [
  { name: 'EST', value: 'America/New_York' },
  { name: 'CST', value: 'America/Chicago' },
  { name: 'MST', value: 'America/Denver' },
  { name: 'PST', value: 'America/Los_Angeles' },
  { name: 'AKST', value: 'America/Juneau' },
  { name: 'AZ', value: 'America/Phoenix' },
  { name: 'IST', value: 'Asia/Calcutta' },
  { name: 'BST', value: 'Europe/London' },
  { name: 'HST', value: 'Pacific/Honolulu' }
]

const styles = {
  main: {
    padding: '40px 20px 0px'
  },
  userNameContainer: {
    width: '100%',
    fontSize: 26,
    color: whiteSmoke,
    fontWeight: 600,
    minHeight: 83,
    marginBottom: 16
  },
  userName: {
    display: 'flex',
    alignItems: 'center'
  },
  editButton: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    backgroundColor: 'rgba(245, 247, 250, 0.30)',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12,
    cursor: 'pointer',
    opacity: 1,
    transition: '0.2s',
    ':hover': {
      opacity: 0.85
    },
    ':active': {
      transform: 'scale(0.95)'
    }
  },
  columns: {
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 99px)'
  },
  left: {
    width: '25%',
    marginRight: 20,
    height: '100%'
  },
  middle: {
    width: '35%'
  },
  right: {
    marginLeft: 20,
    width: '35%'
  },
  subheader: {
    width: 'fit-content',
    color: whiteSmoke,
    cursor: 'pointer',
    fontSize: 20,
    marginTop: 16,
    marginRight: 15
  },
  panelSelector: {
    background: 'none',
    border: 'none',
    fontSize: '16pt',
    color: charcoal
  },

  subHeaderRefresh: {
    width: 'fit-content',
    color: whiteSmoke,
    cursor: 'pointer',
    fontSize: 20,
    margin: '5px 15px 5px 0'
  }
}

const RESET_PASSWORD_BUTTON_TEXT = 'Reset Password'
const CLEAR_WEB_USER_SESSIONS = 'Clear Web Sessions'

const FORMS = {
  ADD: 'Add',
  UPDATE: 'Update'
}

class Users extends Component {
  state = {
    formOpen: null,
    linkFormOpen: null,
    listItems: this.props.notes || [],
    isLoadingMore: false
  }

  componentDidMount () {
    const { users } = this.props

    const params = queryString.parse(this.props.location.search)
    const loStoCurrentUserId = loSto.get(LO_STO.CURRENT_USER_ID)

    if (!users) return

    const isLoStoInUsers = this.props.users.some(user => user.id === loStoCurrentUserId)
    // set default logic id 1.query, 2.ls, 3.first in list
    const userId = params[QUERY_PARAMS.USER_ID] || (isLoStoInUsers && loStoCurrentUserId) || (this.props.users[0] && this.props.users[0].id)

    this.selectUserHandler(userId)
    document.getElementById(loStoCurrentUserId) &&
      document
        .getElementById(loStoCurrentUserId)
        .scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  componentDidUpdate (prevProps) {
    const params = queryString.parse(this.props.location.search)
    const paramUserId = params[QUERY_PARAMS.USER_ID]
    const loStoCurrentUserId = loSto.get(LO_STO.CURRENT_USER_ID)

    // set default userID
    const isParamAbsent = (!paramUserId || paramUserId === 'undefined') &&
      !prevProps.currentUserId && !this.props.currentUserId
    if (isParamAbsent) {
      const isLoStoInUsers = this.props.users.some(user => user.id === loStoCurrentUserId)
      // set default logic id 1.query, 2.ls, 3.first in list
      const defaultUserId = (isLoStoInUsers && loStoCurrentUserId) || (this.props.users[0] && this.props.users[0].id)
      defaultUserId && this.selectUserHandler(defaultUserId)
      return
    }

    if (loStoCurrentUserId !== prevProps.currentUserId) {
      document.getElementById(loStoCurrentUserId) &&
        document
          .getElementById(loStoCurrentUserId)
          .scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    if (
      this.props.currentUserId !== prevProps.currentUserId &&
      prevProps.currentUserId && this.props.refetchNotes
    ) {
      this.setState({ listItems: [], panelLoading: true })
      this.props
        .refetchNotes({
          organizationId: this.props?.match?.params?.orgId,
          userId: this.props.currentUserId,
          limit: 20,
          offset: 0
        })
        .then(() => {
          this.setState({ panelLoading: false })
        })
    }

    if (
      this.props.notes !== prevProps.notes &&
      !this.props.loading &&
      !this.state.isLoadingMore
    ) {
      this.setState({ listItems: this.props.notes })
    }
  }

  unlinkEmrUsers = () => {
    const { unlinkEMRUser, currentUserId, match } = this.props
    const organizationId = match?.params?.orgId

    unlinkEMRUser({
      variables: {
        organizationId,
        sukiUserId: currentUserId
      },
      refetchQueries: [
        {
          query: GetUsers,
          variables: { organizationId }
        }
      ]
    }).then(({ data }) => {
      notifySuccess('Successfully unlinked account(s) to EMR.')
    }).catch(error => {
      setError('Error unlinking to EMR:', error)
    })
  }

  handleUnlink = () => {
    const confirmed = window.confirm('Are you sure you want to unlink ALL EMR USER IDS')
    if (confirmed) {
      this.unlinkEmrUsers()
    }
  }

  toggleForm = form => {
    this.setState({ formOpen: form })
  }

  toggleLinkForm = form => {
    this.setState({ linkFormOpen: form })
  }

  createUser = (values, actions) => {
    const {
      createUser,
      match,
      currentOrgData
    } = this.props

    const organizationId = match?.params?.orgId
    const cleanValues = omitDeep(values, '__typename')

    const emr = currentOrgData?.emr?.secondaryType

    createUser({
      variables: {
        organizationId,
        ...cleanValues,
        emr,
        enhancedReview: currentOrgData.enhancedReview === 'DEFAULT_ON' ? 'ON' : 'OFF'
      },
      refetchQueries: [
        {
          query: GetUsers,
          variables: { organizationId }
        }
      ]
    })
      .then(
        ({
          data: {
            createUser: { user }
          }
        }) => {
          actions.setSubmitting(false)
          this.toggleForm(null)
          console.info('Successfully created user with ID:', user.id)
          notifySuccess('Successfully created user')
          setTimeout(() => {
            this.selectUserHandler(user.id)
            loSto.set(LO_STO.CURRENT_USER_ID, user.id)
            document.getElementById(user.id) &&
              document
                .getElementById(user.id)
                .scrollIntoView({ behavior: 'smooth', block: 'center' })
          }, 500)
        }
      )
      .catch(error => {
        actions.setSubmitting(false)
        console.error('Error creating user:', error)
        setError(error)
      })
  }

  updateUser = (values, actions) => {
    const {
      updateUser,
      match,
      currentUser
    } = this.props

    const organizationId = match?.params?.orgId
    const cleanValues = omitDeep(values, '__typename')

    updateUser({
      variables: {
        organizationId,
        ...currentUser,
        ...cleanValues
      },
      refetchQueries: [
        {
          query: GetUsers,
          variables: { organizationId }
        }
      ]
    })
      .then(
        ({
          data: {
            updateUser: { user }
          }
        }) => {
          actions.setSubmitting(false)
          this.toggleForm(null)
          console.info('Successfully updated user with ID:', user.id)
          notifySuccess('Successfully updated user')
          this.selectUserHandler(user.id)
          setTimeout(() => loSto.set(LO_STO.CURRENT_USER_ID, user.id), 500)
        }
      )
      .catch(error => {
        actions.setSubmitting(false)
        console.error('Error updating user:', error)
        setError(error)
      })
  }

  deleteUser = () => {
    const { deleteUsers, match, currentUserId } = this.props
    const organizationId = match?.params?.orgId

    const userId = loSto.get(LO_STO.USER_ID)
    if (userId === currentUserId) {
      window.alert("You can't delete yourself. Please contact suport.")
    } else {
      deleteUsers({
        variables: {
          organizationId,
          ids: [currentUserId]
        },
        refetchQueries: [
          {
            query: GetUsers,
            variables: { organizationId }
          }
        ]
      })
        .then(
          ({
            data: {
              deleteUsers: { ids }
            }
          }) => {
            this.toggleForm(null)
            console.info('Successfully deleted user with ID:', ids[0])
            notifySuccess('Successfully deleted user')
            this.selectUserHandler(null)
            loSto.remove(LO_STO.CURRENT_USER_ID)
          }
        )
        .catch(error => {
          console.error('Error deleting user:', error)
          setError(error)
        })
    }
  }

  resetPassword = () => {
    const { resetPassword, match, currentUserId } = this.props
    const organizationId = match?.params?.orgId
    resetPassword({
      variables: {
        organizationId,
        userId: currentUserId
      },
      refetchQueries: [
        {
          query: GetUsers,
          variables: { organizationId }
        }
      ]
    })
      .then(({ data: { userId } }) => {
        this.setState({ loading: false })
        console.info('Reset password email sent for ID:', userId)
        notifySuccess('Reset password email sent')
      })
      .catch(error => {
        this.setState({ loading: false })
        console.error('Error sending reset password email:', error)
        setError(error)
      })
  }

  clearSessions = () => {
    this.props.clearWebUserSessions({
      variables: {
        userId: this.props.currentUserId
      }
    }).then(res => {
      notifySuccess('Cleared user\'s sessions from redis')
    }).catch(err => {
      setError(`Unable to clear user's session: ${err}`)
    })
  }

  selectUserHandler = userId => {
    const { setUserId } = this.props
    setUserId(userId)

    userId &&
      this.props.history.replace({
        search: `?${[QUERY_PARAMS.USER_ID]}=${userId}`
      })
  }

  // formik onSubmit to link EMR user
  linkEMRUser = (values, actions) => {
    const { linkEMRUser, currentUserId, match } = this.props
    const organizationId = match?.params?.orgId
    const timezone = values.timezone
    const emrUserId = values.emrIds.split(':')[0]
    const emrDepartmentId = values.emrIds.split(':')[1] || '0'
    const emrUsername = values.emrIds.split(':')[2] ? values.emrIds.split(':')[2] : null
    const emrUserIdList = values.emrIds.split(':')[3] ? values.emrIds.split(':')[3].split(',') : [emrUserId]
    const fhirUserId = values.fhirUserId

    const emrUserVariables = {
      organizationId,
      sukiUserId: currentUserId,
      emrUserId,
      emrDepartmentId,
      fhirUserId,
      timezone,
      emrUserIdList
    }

    if (emrUsername) {
      emrUserVariables.emrUsername = emrUsername
    }

    if (emrUserId.length > 0) {
      linkEMRUser({
        variables: emrUserVariables,
        refetchQueries: [
          {
            query: GetUsers,
            variables: { organizationId }
          }
        ]
      }).then(({ data }) => {
        this.toggleLinkForm(null)
        notifySuccess('Successfully linked Account to EMR.')
      }).catch(error => {
        setError(error)
      })
    } else {
      setError('Can\'t link EMR because User ID is missing in EMR.')
    }
  }

  render () {
    const {
      currentUserId,
      users,
      currentUser,
      loadingUsers,
      notificationHistory,
      loadingNotifHistory,
      orgData,
      match,
      nvoqInformation
    } = this.props

    const { formOpen, linkFormOpen } = this.state
    const organizationId = match?.params?.orgId

    const notifItems =
      notificationHistory &&
      notificationHistory.map(e => (
        <NotificationEventItem key={e.id || e.sentAt} notifEvent={e} />
      ))

    const hasEmr =
      currentUser && (!!currentUser.emrUserId || !!currentUser.emrDepartmentId)
    const emrOrgId = orgData?.emr?.id // undefined if its not an EMR org
    const actionPanelItems = [
      {
        name: RESET_PASSWORD_BUTTON_TEXT,
        onClick: this.resetPassword
      }
    ]

    const emrType = orgData?.emr?.type

    return (
      <Frame
        background={adminBackground}
        mainStyle={styles.main}
        orgName={orgData?.name}
      >
        <EditorSidebar
          items={users}
          currentId={currentUserId}
          loading={loadingUsers && !users}
          setUserId={this.selectUserHandler}
          toggleForm={this.toggleForm.bind(this, FORMS.ADD)}
        />
        <div
          style={Object.assign(
            {},
            styles.userNameContainer,
            hasEmr && { minHeight: 122 }
          )}
        >
          {currentUser && (
            <div style={styles.userName}>
              {getFullName(currentUser.person)}
              <div
                style={styles.editButton}
                onClick={() => this.toggleForm(FORMS.UPDATE, currentUser)}
              >
                <img alt='edit' src={editPencil} height='50%' width='50%' />
              </div>
            </div>
          )}
          {currentUser && (
            <div>
              <div
                style={styles.subheader}
                onClick={() => copyText(currentUser.id)}
              >
                Suki User ID: {currentUser.id}
              </div>
              {hasEmr && (
                <div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {currentUser.emrUserId && (
                      <div
                        style={styles.subheader}
                        onClick={() => copyText(currentUser.emrUserIdList)}
                      >
                        EMR User IDs: {currentUser.emrUserIdList.toString()}
                      </div>
                    )}
                    {currentUser.emrDepartmentId && (
                      <div
                        style={styles.subheader}
                        onClick={() => copyText(currentUser.emrDepartmentId)}
                      >
                        EMR Dept ID: {currentUser.emrDepartmentId}
                      </div>
                    )}
                  </div>
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
                      <h3 style={styles.subHeaderRefresh}>Refresh Appointments and Notes from EMR</h3>
                      {currentUser.emrUserIdList.length > 0 &&
                        <EMRStatusCheck
                          key={currentUser.id}
                          organizationId={organizationId}
                          emrType={emrType}
                          currentUser={currentUser}
                          orgName={orgData?.name}
                          timestamp={currentUser.scheduleHealthcheckTimestamp}
                        />}
                    </div>
                    <AppointmentsRangePicker
                      currentOrg={orgData}
                      currentUser={currentUser}
                    />

                  </div>

                </div>
              )}
            </div>
          )}
        </div>
        <div
          style={
            hasEmr
              ? { ...styles.columns, height: 'calc(100% - 138px)' }
              : styles.columns
          }
        >
          <div style={styles.left}>
            <UserLinks
              userId={currentUserId}
              orgId={organizationId}
              nvoqInformation={nvoqInformation}
            />
            {currentUser && !currentUser.activationSent ? (
              <ActivatePanel userId={currentUserId} orgId={organizationId} />
            ) : (
              <ActionPanel items={actionPanelItems} />
            )}

            <ActionPanel items={[{
              name: CLEAR_WEB_USER_SESSIONS,
              onClick: this.clearSessions
            }]}
            />
            {emrOrgId && (
              <LinkEMRPanel onClick={() => this.toggleLinkForm('Link')} />
            )}
            {hasEmr && currentUser.emrUserIdList.length >= 1 && (
              <MassiveBtnContainer>
                <MassiveBtn onClick={this.handleUnlink}>Unlink EMR</MassiveBtn>
              </MassiveBtnContainer>
            )}
            <EnhancedReviewBox
              reviewLevel='user'
              currentUser={this.props.currentUser || null}
              currentOrgData={this.props.currentOrgData || null}
            />
          </div>
          <div style={styles.middle}>
            {currentUserId && organizationId && <UserNotes userId={currentUserId} organizationId={organizationId} />}
          </div>
          <div style={styles.right}>
            <ListPanel
              title='Notification History'
              type='NOTIFICATIONS'
              collapsible
              withDropdownFilter
              listItems={notifItems}
              loading={loadingNotifHistory}
              currentId={currentUserId}
            />
          </div>
        </div>
        {formOpen && (
          <UserForm
            FORMS={FORMS}
            TIMEZONES={TIMEZONES}
            type={formOpen}
            toggleForm={() => this.toggleForm(null)}
            initialValues={
              formOpen === FORMS.UPDATE
                ? currentUser
                : { person: { gender: GENDERS.UNKNOWN }, timezone: TIMEZONES.find(tz => tz.name === 'PST').value }
            }
            handleSubmit={
              formOpen === FORMS.UPDATE ? this.updateUser : this.createUser
            }
            disableSharedUserInput={disableSharedUserInput({ users, selectedUserId: currentUser?.id })}
            handleDelete={this.deleteUser}
          />
        )}
        {linkFormOpen && (
          <LinkEMRForm
            TIMEZONES={TIMEZONES}
            currentOrgId={organizationId}
            emrOrgId={emrOrgId}
            initialValues={{ emrIds: '', fhirUserId: '', timezone: 'America/New_York' }}
            toggleForm={() => this.toggleLinkForm(null)}
            handleSubmit={this.linkEMRUser}
          />
        )}
      </Frame>
    )
  }
}

const mapStateToProps = ({ admin }) => ({
  currentUserId: admin.userId,
  userNotesPanelFilter: admin.userNotesPanelFilter
})

const mapDispatchToProps = dispatch => ({
  setUserId: id => dispatch(setUserId(id))
})

const mapUsersToProps = ({ ownProps, data }) => ({
  errorUsers: data.error,
  loadingUsers: data.loading,
  users: data?.users?.results || [],
  currentUser: (data?.users?.results || []).find(
    d => d.id === ownProps.currentUserId
  )
})

const mapOrgToProps = ({ data }) => ({
  orgError: data.error,
  orgLoading: data.loading,
  orgData: data?.organizations?.results?.[0]
})

const mapNotificationHistoryToProps = ({ data }) => ({
  errorNotifHistory: data.error,
  loadingNotifHistory: data.loading,
  notificationHistory: data?.notificationHistory?.events || []
})

const mapNvoqInformation = ({ data }, path) => ({
  nvoqInformation: {
    ...data
  }
})

const mapCurrentOrgToProps = ({ data }) => ({
  currentOrgError: data.error,
  currentOrgLoading: data.loading,
  currentOrgData: get(data, 'organizations.results[0]')
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  graphql(GetNotificationHistory, {
    skip: ({ match, currentUserId }) =>
      !(match?.params?.orgId) || !currentUserId,
    options: ({ match, currentUserId }) => ({
      variables: {
        organizationId: match?.params?.orgId,
        userId: currentUserId,
        startsAtRangeBeginning: '2017-06-01T00:00:00-07:00', // Jun 1, 2017
        startsAtRangeEnd: '2117-01-01T00:00:00-07:00' // Jan 1, 2117
      }
    }),
    props: mapNotificationHistoryToProps
  }),
  graphql(GetUsers, {
    skip: ({ match }) => !(match?.params?.orgId),
    options: ({ match }) => ({
      variables: { organizationId: match?.params?.orgId },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK
    }),
    props: mapUsersToProps
  }),
  graphql(GetOrganizationById, {
    skip: ({ match }) => !(match?.params?.orgId),
    options: ({ match }) => ({
      variables: {
        id: match?.params?.orgId,
        withEmrInfo: true
      }
    }),
    props: mapOrgToProps
  }),
  graphql(GetUserNvoqInformation, {
    skip: props => !props.orgData || !props.currentUserId,
    options: props => ({
      variables: {
        organizationId: props.orgData.id,
        sukiUserId: props.currentUserId
      },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY
    }),
    props: mapNvoqInformation
  }),
  graphql(GetOrganizationById, {
    options: ({ match }) => ({
      variables: {
        id: match?.params?.orgId,
        withEmrInfo: true
      },
      fetchPolicy: FETCH_POLICY.CACHE_FIRST
    }),
    props: mapCurrentOrgToProps
  }),
  graphql(CreateUser, { name: 'createUser' }),
  graphql(UpdateUser, { name: 'updateUser' }),
  graphql(DeleteUsers, { name: 'deleteUsers' }),
  graphql(ResetPassword, { name: 'resetPassword' }),
  graphql(LinkEMRUser, { name: 'linkEMRUser' }),
  graphql(UnlinkEMRUser, { name: 'unlinkEMRUser' }),
  graphql(ClearWebUserSessions, { name: 'clearWebUserSessions' }),
  Radium
)(Users)
