import styled from 'styled-components/native'
import reactStyled, { css } from 'styled-components'
import { Animated } from 'react-native'
import { snowDrift } from '../../lib/colors'
import { NOTES, DIMENSIONS } from '../../lib/constants'
import dictationBackground from '../../assets/images/dictationBackground.jpg'

export const PopOver = reactStyled.div`
  border-radius: 3px;
  margin-top: 8px;
  background: #fff;
  color: #1b1f23;
  padding: 8px 16px;
  position: absolute;
  right: 0px;
  min-width: 279px;
`
export const PageWrapper = reactStyled.div`
  position: fixed;
  height: ${props => props.vh}px;
  width: ${props => props.vw}px;
  cursor: default;
`
export const HelpWrapper = reactStyled.div`
  position: absolute;
  right: 24px;
  top: 24px;
`
export const DarkModeButton = reactStyled.div`
  background: transparent;
  color: #46aff2;
  border: 1px solid #46aff2;
  border-radius: 14px;
  min-width: 105px;
  min-height: 28px;
  cursor: pointer;
  div {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 3px;
    align-items: center;
  }
  img {
    max-width: 15px;
  }
  &:hover {
    background-color: rgba(70, 175, 242, 0.12);
  }
`
export const DictationBackground = styled(Animated.Image).attrs(() => ({
  source: ({
    uri: dictationBackground,
    height: '100vh',
    width: '100vw'
  })
}))`
  position: fixed;
`

export const PageContainer = reactStyled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
`

export const NoteWrapper = reactStyled.div`
  cursor: default;
  ${props => props.isAmwell && css`
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #1a1f24;
    position: relative;

    &::-webkit-scrollbar {
      background-color: #1a1f24;
      width: 16px;
    }
    
    &::-webkit-scrollbar-track {
      background-color: #1a1f24;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #494c50;
      border-radius: 16px;
      border: 4px solid #1a1f24;
    }

    &::-webkit-scrollbar-button {
      display:none;
    }

    &::after {
      content: "";
      position: fixed;
      right: 16px;
      bottom: 0px;
      left: 0px;
      height: 60px;
      box-shadow: #1a1f24 0px -60px 36px -28px inset;
    }
  `}
`

const MIN_WIDTH_600PX = css`
  min-width: 600px;
`

const MIN_WIDTH_80VW = css`
  min-width: 80vw;
`

const LEFT_VW_30 = css`
  left: 30vw;
`

const LEFT_INHERIT = css`
  left: inherit;
`

const SMALL_READ_ONLY_MODAL = css`
  position: absolute;
  min-width: inherit;
  margin-left: 5vw;
  width: 91vw;
  padding: 80px 40px;
  left: 0;
`

export const NoteContainerDark = reactStyled.div`
  position: ${props => props.isSmallWindow ? 'static' : 'absolute'};

  ${props => !props.isSmallWindow ? LEFT_VW_30 : LEFT_INHERIT};
  ${props => !props.isSmallWindow ? MIN_WIDTH_600PX : MIN_WIDTH_80VW};
  padding: ${props => props.isSmallWindow ? '0px 8px' : props.isWrapUpWindow ? '0 5px' : '0 45px'};

  height: fit-content;
  background-color: #1a1f24;
  color: white;
  padding-top: 80px;
  padding-bottom: 86px;
`

export const NoteContainer = styled(Animated.View)`
  position: ${props => props.isSmallWindow ? 'static' : 'absolute'};
  ${props => !props.isSmallWindow ? LEFT_VW_30 : LEFT_INHERIT}
  width: ${props => props.isSmallWindow ? '89vw' : '60vw'};
  max-width: 950px;
  ${props => !props.isSmallWindow ? MIN_WIDTH_600PX : MIN_WIDTH_80VW}
  min-height: ${props => props.vh * 1.05};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50;
  margin-top: ${props => props.marginTop ? props.marginTop : DIMENSIONS.NOTE_TOP};
  background-color: ${props => props.type === NOTES.READ_ONLY
    ? snowDrift
    : 'white'
  };
  padding: ${props => props.isSmallWindow ? '40px' : '80px'};
  padding-top: 80px;
  box-shadow: ${props => props.type === NOTES.READ_ONLY
    ? '0px 0px 10px rgba(255,255,255,0.4)'
    : '0px 0px 10px rgba(0,0,0,0.2)'
  };

  // isSmallWindow && readonlymodal
  ${props => props.vw < 900 && props.type === NOTES.READ_ONLY && SMALL_READ_ONLY_MODAL}
`
