import React from 'react'
import { Query, Mutation } from 'react-apollo'
import styled from 'styled-components'
import get from 'lodash.get'
import { GetUsers, CopyNoteType, GetNoteTypes } from '@sukiai/gql/admin'
import { getFullName } from '@sukiai/utils'
import { Form } from '../../components'
import { whiteSmoke, charcoal } from '../../styles/colors'
import { sortItemsByKey, notifySuccess, setError } from '../../lib/util'

const Veil = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  width: 600px;
  padding: 20px;
  background: ${whiteSmoke};
  color: ${charcoal};
  border-radius: 4px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.5);
`

const Heading = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 14px;
`

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Button = styled.button.attrs({
  type: 'button'
})`
  border: none;
  outline: none;
  padding: 0px;
  background: ${charcoal};
  color: ${whiteSmoke};
  ${p => p.disabled && 'opacity: 0.5;'}
  transition: all 0.3s;
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 14px;
  margin-left: 14px;
`

export default class CopyToUser extends React.Component {
  state = {
    copyToId: null
  }

  setCopyToId = copyToId => {
    this.setState({ copyToId })
  }

  handleClose = () => {
    const { closeDialog } = this.props
    closeDialog()
    this.setState({ copyToId: null })
  }

  onCopy = async (cache, { data: { copyNoteType } }) => {
    const { copyToId } = this.state
    const { organizationId } = this.props

    const copy = get(copyNoteType, 'noteType')

    try {
      // Get all current note types from the cache for copied to user
      const { noteTypes: allNoteTypesRes } = cache.readQuery({
        query: GetNoteTypes,
        variables: { organizationId, userId: copyToId }
      })

      const allNoteTypes = get(allNoteTypesRes, 'results', [])

      // Check to see if the org has a note type with the same name
      // If so, replace it with the copied one since it will take precendence

      // Finds the index of the org note type that the copy was made from
      // If this equals -1, it means the user copied one of their own note types
      // so we just push it to the end of the note types array
      const copiedOrgIdx = allNoteTypes.findIndex(nt => nt.name.toLowerCase() === copy.name.toLowerCase() && !nt.userId)

      if (copiedOrgIdx > -1) {
        allNoteTypes.splice(copiedOrgIdx, 1, copy)
      } else {
        allNoteTypes.push(copy)
      }

      // Re-sort the note types by name
      sortItemsByKey(allNoteTypes, 'name')

      // Update the cache with the new list of note types
      await cache.writeQuery({
        query: GetNoteTypes,
        variables: { organizationId, userId: copyToId },
        data: {
          noteTypes: {
            __typename: 'QueryNoteTypesPayload',
            count: get(allNoteTypes, 'length', 0),
            results: allNoteTypes
          }
        }
      })
    } catch (err) {
      // Note types for copied to user are not in cache yet
      console.info(`Cannot update cache with copied note type for user ${copyToId}`)
    }

    this.handleClose()
  }

  render () {
    const { copyToId } = this.state
    const { open, organizationId, userId, noteTypeId } = this.props

    const usersVariables = { organizationId }
    const copyVariables = { organizationId, id: noteTypeId, userId: copyToId }
    const buttonDisabled = !organizationId || !copyToId || !noteTypeId

    return (
      open && (
        <Veil onClick={this.handleClose}>
          <Container data-cy='nt-copy-to-user-container' onClick={e => e.stopPropagation()}>
            <Heading>Copy note type to user</Heading>
            <Body>
              <Query query={GetUsers} variables={usersVariables} skip={!organizationId}>
                {({ loading: usersLoading, data: usersData }) => {
                  const users = get(usersData, 'users.results', [])
                  const options = [<option value='' key='copy-to-user'>Select user to copy to</option>]
                  usersLoading
                    ? options.push(<option disabled>Loading users...</option>)
                    : users
                      .filter(u => u.id !== userId)
                      .forEach(u =>
                        options.push(
                          <option key={u.id} value={u.id}>
                            {getFullName(u.person)}
                          </option>
                        )
                      )

                  return (
                    <Form.Select
                      data-cy='nt-copy-to-user-select'
                      value={copyToId}
                      onChange={e => this.setCopyToId(e.target.value)}
                      options={options}
                    />
                  )
                }}
              </Query>
              <Mutation
                mutation={CopyNoteType}
                variables={copyVariables}
                onError={setError}
                onCompleted={() => notifySuccess('Copied note type')}
                update={this.onCopy}
              >
                {(copyToUser, { loading }) => (
                  <Button data-cy='nt-copy-to-user-button' disabled={buttonDisabled || loading} onClick={copyToUser}>
                    {loading ? 'Copying' : 'Copy'}
                  </Button>
                )}
              </Mutation>
            </Body>
          </Container>
        </Veil>
      )
    )
  }
}
