import styled from 'styled-components/native'
import reactStyled from 'styled-components'
import { Animated } from 'react-native'
import loginLogo from '../../assets/icons/suki-vertical.svg'
import { black, burntSierra, casal, white, fulvous, lightGray } from '../../lib/colors'

export const LoginContainer = styled.View`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-left: 10vw;
  padding-right: 10vw;
`

export const LoginLogo = styled.Image.attrs({
  source: loginLogo
})`
  height: 320;
  width: 275;
`

export const FormContainer = styled.View`
  height: 270;
  width: 360;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

export const Input = reactStyled.input.attrs({
  autoCapitalize: 'none',
  autoFocus: true,
  autoCorrect: 'false',
  autoComplete: 'username',
  placeholder: 'Email',
  placeholderTextColor: '#C0C0C0',
  type: 'email'
})`
  width: 100%;
  font-family: 'Open Sans';
  font-size: 26px;
  color: ${black};
  padding: 6px;
  border: none;
  border-bottom: 1px solid ${black};
  outline: none;
  margin-bottom: 1.2vw;
  background-color: transparent;
`

export const Password = reactStyled(Input).attrs({
  autoFocus: false,
  secureTextEntry: true,
  placeholder: 'Password',
  autoComplete: 'current-password',
  type: 'password'
})`
  -webkit-text-security: disc;
  text-security: disc;
`

export const LoginButtonContainer = reactStyled.div`
  cursor: pointer;
  width: 100%;
`

export const LoginButtonText = styled(Animated.Text)`
  font-family: 'Open Sans';
  height: 58;
  width: 250;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${casal};
  border-radius: 50px;
  font-size: 18;
  color: ${white};
  margin: 30px auto 0 auto;
`

export const LoginError = reactStyled.div`
  color: ${burntSierra};
  width: 100%;
`

export const Loading = styled.ActivityIndicator.attrs({
  size: 'small',
  color: fulvous
})`
`

export const ResetPasswordButtonContainer = styled.TouchableWithoutFeedback`
  cursor: pointer;
`

export const ResetPasswordButtonText = styled.Text`
  width: 100%;
  top: 50px;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align:center;
  justify-content: center;
  color: ${lightGray};
  margin-top: 20px;
`
