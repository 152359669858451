import gql from 'graphql-tag'

// USED IN: USERS
export const ResetPassword = gql`
  mutation ResetPassword($organizationId: ID!, $userId: ID!) {
    resetPassword(input: {
      organizationId: $organizationId,
      userId: $userId
    }) {
      userId
    }
  }
`
