import React, { Component } from 'react'
import { Animated, Easing } from 'react-native'
import {
  MenuContainer,
  IconWrapper,
  IconContainer,
  HamburgerIcon,
  HamburgerVeil,
  NoteCount,
  PanelContainer,
  Username,
  MenuItemWrapper,
  MenuItem,
  Divider
} from './styled'
import { HAMBURGER_MENU } from '../../lib/constants'
import { getFullName, isAmwellTheme } from '../../lib/util'

class HamburgerMenu extends Component {
  state = {
    open: false,
    menuAnim: new Animated.Value(0),
    showCount: false,
    countAnim: new Animated.Value(0)
  }

  componentDidMount () {
    if (this.props.noteCount > 0) {
      this.setState({ showCount: true })
      Animated.sequence([
        Animated.delay(600),
        Animated.timing(this.state.countAnim, {
          toValue: 1,
          duration: 200,
          easing: Easing.linear
        })
      ]).start()
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.noteCount === 0 && nextProps.noteCount > 0) {
      this.setState({ showCount: true })
      Animated.sequence([
        Animated.delay(600),
        Animated.timing(this.state.countAnim, {
          toValue: 1,
          duration: 200,
          easing: Easing.linear
        })
      ]).start()
    }

    if (this.props.noteCount > 0 && nextProps.noteCount === 0) {
      Animated.timing(this.state.countAnim, {
        toValue: 0,
        duration: 200,
        easing: Easing.linear
      }).start(() => this.setState({ showCount: false }))
    }
  }

  handleToggle = () => {
    const { recordToggle } = this.props

    if (this.state.open) {
      recordToggle(false)
      Animated.timing(this.state.menuAnim, {
        toValue: 0,
        duration: 100,
        easing: Easing.linear
      }).start(() => this.setState({ open: false }))
    } else {
      recordToggle(true)
      this.setState({ open: true })
      Animated.timing(this.state.menuAnim, {
        toValue: 1,
        duration: 100,
        easing: Easing.linear
      }).start()
    }
  }

  execFunc = func => {
    this.handleToggle()
    func()
  }

  render () {
    const {
      noteCount,
      currentUser,
      toHelp,
      toSettings,
      toHome,
      toSchedule,
      toUnfinishedNotes,
      printNoteEnabled,
      showBulkPrint,
      emailNote,
      printNote,
      printNotesEnabled,
      printNotesModal,
      deleteNoteEnabled,
      deleteCurrentNote,
      patientNotesEnabled,
      toPatientNotes,
      logout,
      marginTop
    } = this.props

    const {
      open,
      menuAnim,
      showCount,
      countAnim
    } = this.state

    const {
      HOME,
      PATIENTS,
      UNFINISHED_NOTES,
      DELETE_NOTE,
      PRINT_NOTE,
      EMAIL_NOTE,
      PRINT_NOTES,
      PRIOR_NOTES,
      HELP,
      SETTINGS,
      LOG_OUT
    } = HAMBURGER_MENU

    const styles = {
      menu: {
        opacity: menuAnim.interpolate({
          inputRange: [0, 1],
          outputRange: [0.5, 1]
        })
      },
      box: {
        backgroundColor: menuAnim.interpolate({
          inputRange: [0, 1],
          outputRange: ['rgba(217,226,236,0)', 'rgba(217,226,236,0.3)']
        })
      },
      count: {
        opacity: countAnim.interpolate({
          inputRange: [0, 0.7, 1],
          outputRange: [0, 1, 1]
        }),
        transform: [
          {
            scale: countAnim.interpolate({
              inputRange: [0, 0.7, 1],
              outputRange: [0, 1.2, 1]
            })
          }
        ]
      }
    }

    const isAmwellDark = isAmwellTheme()

    if (isAmwellDark) {
      return null
    }

    return (
      <> {/* Mark: this will not work in mobile, but a View will not work here. Find a better solution */}
        <MenuContainer marginTop={marginTop}>
          <IconWrapper style={styles.box}>
            <IconContainer onClick={this.handleToggle} data-cy='top-nav-menu'>
              {showCount && !open &&
                <NoteCount style={styles.count}>
                  {noteCount}
                </NoteCount>}
              <HamburgerIcon />
            </IconContainer>
          </IconWrapper>
          {open &&
            <PanelContainer style={styles.menu}>
              <Username data-cy='menu-user-name'>
                {currentUser ? getFullName(currentUser.person, currentUser.userType) : null}
              </Username>
              <Divider />
              <MenuItemWrapper onPress={() => this.execFunc(toHome)}>
                <MenuItem data-cy='menu-item-home'>
                  {HOME}
                </MenuItem>
              </MenuItemWrapper>
              <MenuItemWrapper onPress={() => this.execFunc(toSchedule)}>
                <MenuItem data-cy='menu-item-schedule'>
                  {PATIENTS}
                </MenuItem>
              </MenuItemWrapper>
              <MenuItemWrapper onPress={() => this.execFunc(toUnfinishedNotes)}>
                <MenuItem data-cy='menu-item-unfinished-notes'>
                  {UNFINISHED_NOTES}
                  {showCount &&
                    <NoteCount inside='true'>
                      {noteCount}
                    </NoteCount>}
                </MenuItem>
              </MenuItemWrapper>
              <Divider />

              <MenuItemWrapper
                onPress={() => showBulkPrint ? this.execFunc(printNotesModal) : this.execFunc(printNote)}

              >
                <MenuItem
                  disabled={showBulkPrint ? (!printNotesEnabled).toString() : (!printNoteEnabled).toString()}
                  data-cy='menu-item-print-note'
                >
                  {showBulkPrint ? PRINT_NOTES : PRINT_NOTE}
                </MenuItem>
              </MenuItemWrapper>
              <MenuItemWrapper
                onPress={() => this.execFunc(emailNote)}
                disabled={showBulkPrint ? !printNotesEnabled : !printNoteEnabled}
              >
                <MenuItem
                  disabled={showBulkPrint ? (!printNotesEnabled).toString() : (!printNoteEnabled).toString()}
                  data-cy='menu-item-email-note'
                >
                  {EMAIL_NOTE}
                </MenuItem>
              </MenuItemWrapper>
              <MenuItemWrapper onPress={() => this.execFunc(deleteCurrentNote)} disabled={!deleteNoteEnabled}>
                <MenuItem disabled={(!deleteNoteEnabled).toString()} data-cy='menu-item-delete-note'>
                  {DELETE_NOTE}
                </MenuItem>
              </MenuItemWrapper>
              <MenuItemWrapper onPress={() => this.execFunc(toPatientNotes)} disabled={!patientNotesEnabled}>
                <MenuItem disabled={(!patientNotesEnabled).toString()} data-cy='menu-item-prior-notes'>
                  {PRIOR_NOTES}
                </MenuItem>
              </MenuItemWrapper>
              <Divider />
              <MenuItemWrapper onPress={() => this.execFunc(toHelp)}>
                <MenuItem data-cy='menu-item-help'>
                  {HELP}
                </MenuItem>
              </MenuItemWrapper>
              <MenuItemWrapper onPress={() => this.execFunc(toSettings)}>
                <MenuItem data-cy='menu-item-settings'>
                  {SETTINGS}
                </MenuItem>
              </MenuItemWrapper>
              <MenuItemWrapper onPress={() => this.execFunc(logout)}>
                <MenuItem data-cy='menu-item-log-out'>
                  {LOG_OUT}
                </MenuItem>
              </MenuItemWrapper>
            </PanelContainer>}
        </MenuContainer>
        {open && <HamburgerVeil onPress={this.handleToggle} />}
      </>
    )
  }
}

export default HamburgerMenu
