import React, { Component } from 'react'

const styles = {
  body: {
    paddingLeft: 15,
    marginTop: 10
  }
}

export default class RawTranscript extends Component {
  render () {
    const rawArray = this.props.audioAndTranscripts
    const transcriptData = rawArray && rawArray.map((outer, oidx) => (
      outer.transcripts.map((inner, iidx, transcripts) => (
        <div key={`transcript-${oidx}-${iidx}`}>
          <div style={{ fontWeight: 600 }}>
            Transcript {oidx + 1}{transcripts.length > 1 && `.${iidx + 1}`}
          </div>
          <div>
            <ol>{inner.textContent.map((t, tidx) => <li key={tidx}>{t}</li>)}</ol>
          </div>
          <details>
            <summary style={{ outline: 'none' }}>Meta</summary>
            <ol>{inner.rawContent.map((r, ridx) => <li key={ridx}>{r}</li>)}</ol>
          </details>
        </div>
      ))
    ))

    return (
      <div style={this.props.style}>
        <details>
          <summary style={this.props.headingStyle}>
            Transcript Data
          </summary>
          <div style={styles.body}>
            {this.props.loadingAudioAndTranscripts ? <i>Loading transcripts...</i> : (this.props.audioAndTranscripts && this.props.audioAndTranscripts.length > 0) ? transcriptData : <i>No data to display</i>}
          </div>
        </details>
      </div>
    )
  }
}
