import styled from 'styled-components/native'
import { Animated } from 'react-native'
import { Z_INDEX } from '../../lib/constants'

export const StyledVeil = styled(Animated.View)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${props => props.active === 'true'
    ? Z_INDEX.VEIL_ACTIVE
    : Z_INDEX.VEIL
  };
  display: flex;
  align-items: center;
  justify-content: center;
`
