import _map from 'lodash/map'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { Skeleton, Button, Modal } from 'antd'

import './featureFlag.css'
import FeatureFlagCard from '../featureFlagCard'
import { isWriteAccessEnabled } from '../../helpers'
import FeatureFlagDetails from '../featureFlagDetails'
import CreateFeatureFlagModal from '../createFeatureFlagModal'
import { FEATURE_FLAG_WRITE } from '../../constants'

function FeatureFlagList ({
  featureFlags,
  isLoading,
  onSave,
  onCreate,
  featureFlagCreationInProgress = false
}) {
  const [selectedFeatureFlag, setSelectedFeatureFlag] = useState(null)
  const [isCreateFeatureFlagModalVisible, setCreateFeatureFlagModalVisibility] =
    useState(false)

  const handleSetSelectedFeatureFlag = (featureFlag) => () => {
    setSelectedFeatureFlag(featureFlag)
  }

  useEffect(() => {
    if (!featureFlagCreationInProgress) {
      setCreateFeatureFlagModalVisibility(false)
    }
  }, [featureFlagCreationInProgress])

  const writeAccessEnabled = isWriteAccessEnabled(featureFlags)

  const handleOpenCreateFeatureFlagModal = () => {
    setCreateFeatureFlagModalVisibility(true)
  }

  const handleCloseCreatureFeatureFlagModal = () => {
    setCreateFeatureFlagModalVisibility(false)
  }

  const handleCreateFeatureFlag = ({ key, description }) => {
    onCreate({
      key,
      description
    })
  }

  const renderFeatureFlag = ({ key, title, description }) => {
    if (key === FEATURE_FLAG_WRITE) {
      return null
    }

    return (
      <FeatureFlagCard
        key={key}
        title={title || key}
        description={description}
        onClick={handleSetSelectedFeatureFlag(key)}
      />
    )
  }

  if (isLoading && _isEmpty(featureFlags)) {
    return (
      <>
        <h2>
          <strong>Feature flags</strong>
        </h2>
        <div className='content'>
          <Skeleton active />
        </div>
      </>
    )
  }

  if (!_isEmpty(selectedFeatureFlag)) {
    const selectedFeatureflagDetails = featureFlags[selectedFeatureFlag]
    return (
      <FeatureFlagDetails
        featureFlagDetails={selectedFeatureflagDetails}
        onGoBack={() => setSelectedFeatureFlag(null)}
        isLoading={isLoading}
        writeAccessEnabled={writeAccessEnabled}
        onSave={onSave}
      />
    )
  }

  return (
    <>
      <div className='ff-header'>
        <h2>
          <strong>Feature flags</strong>
        </h2>
        <Button
          type='primary'
          disabled={!writeAccessEnabled}
          onClick={handleOpenCreateFeatureFlagModal}
        >
          Add New Feature Flag
        </Button>
      </div>
      <div className='content'>{_map(featureFlags, renderFeatureFlag)}</div>
      {isCreateFeatureFlagModalVisible && (
        <CreateFeatureFlagModal
          isOpen={isCreateFeatureFlagModalVisible}
          onOk={handleCreateFeatureFlag}
          onCancel={handleCloseCreatureFeatureFlagModal}
          isLoading={featureFlagCreationInProgress}
        />
      )}
    </>
  )
}

export default FeatureFlagList
