import React from 'react'
import { Field } from 'formik'

// This component wraps Formik's <Field /> to also add
// information about whether this field is currently active
export default class ActiveField extends React.Component {
  state = { active: false }

  render () {
    const { name, children } = this.props
    const { active } = this.state

    return (
      <Field name={name}>
        {fieldProps =>
          children({
            ...fieldProps,
            field: {
              ...fieldProps.field,
              onFocus: () => {
                this.setState({ active: true })
              },
              onBlur: event => {
                this.setState({ active: false })
                fieldProps.field.onBlur(event)
              }
            },
            active
          })}
      </Field>
    )
  }
}
