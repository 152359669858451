import React, { Component } from 'react'
import Radium from 'radium'
import { navBarHeight } from '../../styles/dimensions'
import { UserNoteItem } from '../Users'
import Loading from '../Loading'
import { getPanelTitle } from '../../lib/util'
import { listHeaderBrown } from '../../styles/colors'
import { GRAPHQL_TYPE } from '../../lib/constants'
import { withRouter } from 'react-router-dom'
import get from 'lodash.get'

const styles = {
  button: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: 'white',
    padding: '10px 30px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: 'color 0.2s',
    ':hover': {
      color: 'rgba(255,255,255,0.75)'
    }
  },
  modalContainer: {
    position: 'fixed',
    top: `calc(${navBarHeight} + 42)`,
    right: 100,
    zIndex: 10,
    width: 500,
    maxHeight: 600,
    boxShadow: '0px 0px 6px rgba(0,0,0,0.5)',
    overflow: 'scroll',
    backgroundColor: listHeaderBrown
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: listHeaderBrown,
    fontSize: 22,
    height: 75
  }
}

class PatientNotesPanel extends Component {
  state = {
    open: false,
    modalText: '',
    currentHintId: null
  }

  handleToggleModal = () => {
    this.setState({ open: !this.state.open })
  }

  isSelected = note => {
    const { noteId } = get(this.props, 'match.params')
    return note.__typename === GRAPHQL_TYPE.PATIENT_NOTE
      ? note.noteId === noteId
      : note.id === noteId
  }

  render () {
    const {
      notes,
      patientName,
      loadingNotes
    } = this.props

    const { open } = this.state

    let dropdownContent
    if (loadingNotes) {
      dropdownContent = (
        <div style={styles.modalContainer}>
          <Loading />
        </div>
      )
    } else if (!loadingNotes && notes) {
      dropdownContent = notes.map((n, i) => (
        <UserNoteItem
          key={i}
          note={n}
          selected={this.isSelected(n)}
          openInNewTab
        />
      ))
    }

    const buttonStyle = open ? { ...styles.button, backgroundColor: listHeaderBrown } : styles.button

    return (
      <div>
        <div
          onClick={this.handleToggleModal}
          style={buttonStyle}
          data-cy='patient-notes-panel-header'
        >
          Patient Notes
        </div>
        {open && (
          <div style={styles.modalContainer}>
            <div style={styles.modalHeader}>
              {getPanelTitle(patientName)}
            </div>
            {dropdownContent}
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(Radium(PatientNotesPanel))
