import styled from 'styled-components/native'
import reactStyled, { css } from 'styled-components'
import { Animated } from 'react-native'
import suki from '../../assets/icons/suki.png'
import sukiMuted from '../../assets/icons/suki-muted.png'
import { mediumTurquoise } from '../../lib/colors'
import agentProcessing from '../../assets/icons/agent-processing.svg'
import { Z_INDEX } from '../../lib/constants'

export const Container = styled(Animated.View)`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: ${Z_INDEX.AGENT};
`

export const BadgeWrapper = reactStyled.div`
  height: ${props => props.size + 'px'};
  width: ${props => props.size + 'px'};
  border-radius: 50%;
  z-index: ${Z_INDEX.AGENT};
  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  ${props => props.isAmwellTheme && css`
    height: 70px;
    width: 70px;
    box-shadow: #1A1F24 0px 0px 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

export const Badge = styled(Animated.View)`
  position: fixed;
  background-image: url(${suki});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  border-radius: 50%;
  z-index: ${Z_INDEX.AGENT};
  opacity: ${props => props.muted
    ? 0
    : 1
  };
  transition-duration: 0.5s;
  transition-property: opacity;
`

export const BadgeDark = styled(Animated.View)`
  position: fixed;
  background-image: url(${suki});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  border-radius: 50%;
  z-index: ${Z_INDEX.AGENT};
  opacity: ${props => props.muted
    ? 0
    : 1
  };
  transition-duration: 0.5s;
  transition-property: opacity;
`

export const BadgeMuted = styled(Badge)`
  background-image: url(${sukiMuted});
  opacity: ${props => props.muted
    ? 1
    : 0
  };
`

export const BadgeMutedDark = styled(Badge)`
  background-image: url(${sukiMuted});
  filter: brightness(0.4);
  z-index: ${Z_INDEX.AGENT - 1};
  ${props => props.muted && css`
    z-index: ${Z_INDEX.AGENT};
    opacity: 1;
  `}
`

export const AwakeRing = styled(Animated.View)`
  position: fixed;
  border: ${props => props.size * 0.035}px solid ${mediumTurquoise};
  border-radius: 50%;
  box-sizing: content-box;
  z-index: ${Z_INDEX.AGENT - 1};
`

export const ListeningRing = styled(AwakeRing)`
  border: ${props =>
    (props.thicker && props.size * 0.02) ||
    props.size * 0.01}px solid ${mediumTurquoise};
`

export const Processing = styled(Animated.Image).attrs({
  source: agentProcessing
})`
  position: absolute;
  height: ${props => props.size};
  width: ${props => props.size};
  z-index: ${Z_INDEX.AGENT + 1};
`
