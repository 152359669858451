import gql from 'graphql-tag'

// USED IN: DOC
export const GetAccessRule = gql`
  query GetAccessRule($organizationId: ID!) {
    accessRules(input: {
      organizationId: $organizationId
    }) {
      count,
      results {
        id,
        allow
      }
    }
  }
`

// USED IN: DOC
export const CreateAccessRule = gql`
  mutation CreateAccessRule($organizationId: ID!, $allow: [ID!]!) {
    createAccessRule(input: {
      organizationId: $organizationId,
      allow: $allow
    }) {
      rule {
        id,
        allow
      }
    }
  }
`

// USED IN: DOC
export const UpdateAccessRule = gql`
  mutation UpdateAccessRule($organizationId: ID!, $allow: [ID!]!) {
    updateAccessRule(input: {
      organizationId: $organizationId,
      allow: $allow
    }) {
      rule {
        id,
        allow
      }
    }
  }
`

// USED IN: DOC
export const DeleteAccessRule = gql`
  mutation DeleteAccessRule($organizationId: ID!) {
    deleteAccessRule(input: {
      organizationId: $organizationId
    }) {
      organizationId
    }
  }
`
