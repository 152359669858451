// ***Slate Grey Palette***
export const charcoal = '#29404F'
export const modalOverlay = 'rgba(0,0,0,0.5)'
export const slate = '#ACBCC3'
export const slateGrey = '#5E798E'
export const platinum = '#D9E2EC'

// ***Color Palette***
export const ginger = '#B76609'
export const fulvous = '#DF6B06'
export const pastelOrange = '#FAC242'
export const antiqueWhite = '#FBEBD4'
export const burntSierra = '#E87154'
export const mediumTurquoise = '#54C5C5'
export const whiteSmoke = '#F5F7FA'
export const white = '#FFFFFF'

// ***Application Palette***

// Accent color - check marks, enabled mic icon
export const accentColor = fulvous

// For disabled items
export const disabledItem = '#9B9B9B'

// Area that medical form/note sits over
export const formAreaBkg = platinum

export const sideBarText = charcoal

export const sideBarVeil = 'rgba(0,0,0,0.12)'

export const titleBarItems = charcoal

// Color for under modals
// Previously: 'rgba(41,64,79,0.8)'
export const underModal = modalOverlay

export const emrBadge = slateGrey

// Schedule
export const scheduleGrey = '#B8B8B8'

// Agent
export const agentRing = 'rgba(84,198,198,0.7)'

// Scribe
export const scribeGreen = '#417505'

// DEMO
export const demoGrey = '#DCDCDC'

// Editors
export const borderGrey = '#D0D0D0'

// Read-only Robin Editor
export const readOnlyGrey = '#707070'

// Used for header of admin note view
export const adminHeaderGrey = '#808080'

// Used when indicating a selected / focused list item
export const selectedGrey = '#C0C5C9'

// Used for admin list headers
export const listHeaderBrown = '#633E17'

export const transcriptHeader = '#F1F1F1'

export const lightGreyTrim = '#DAE2EB'

export const successLight = '#ECF8F5'
export const dangerLight = '#FFD7D8'
export const dangerText = '#DF0000'
export const pomegranate = '#E74C3C'
