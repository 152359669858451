import React, { Component } from 'react'
import styled from 'styled-components'

import { isScribe, getScribeNoteLink } from '../../lib/util'

const StyledOpsView = styled.div`
    position: relative;
    top: 10px;
    right: 10px;
    text-align: center;
    width: 8vw;
    color: black;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        color: rgba(0,0,0, 0.75)
    }
`

const OpsView = () => {
  const scribeLink = getScribeNoteLink()
  const handleClick = () => window.open(scribeLink, '_blank', 'noopener')

  return (
    <StyledOpsView>
      {isScribe() &&
        <div onClick={handleClick}>
                    Ops View
        </div>}
    </StyledOpsView>
  )
}

export default OpsView
