import React, { useState, useEffect } from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'
import { Button, Skeleton, Table, Input } from 'antd'
import { compose, graphql } from 'react-apollo'
import { GetAllOrganizations } from '@sukiai/gql/admin'

import {
  filterOrganizationsBySearchText,
  formatOrganizationsDataForTable,
  getRowSelection,
  sortOrganizationsByAccess
} from './helpers'
import { COLUMNS } from './constants/columns'
import { sortItemsByKey } from '../../../../lib/util'
import { FETCH_POLICY } from '../../../../lib/constants'

const { Search } = Input

function OrganizationTable ({
  orgsLoading,
  organizations,
  selectedOrganizationIds,
  isEditable,
  onSave,
  isLoading
}) {
  const [tableData, setTableData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [isEditView, setIsEditView] = useState(false)
  const [selectedOrganizations, setSelectedOrganizations] = useState([])

  useEffect(() => {
    !isLoading && setSelectedOrganizations(selectedOrganizationIds)
  }, [selectedOrganizationIds, isLoading])

  useEffect(() => {
    const organizationsSortedByAccess = sortOrganizationsByAccess(
      organizations,
      selectedOrganizationIds
    )
    const filteredOrganizations = filterOrganizationsBySearchText(
      organizationsSortedByAccess,
      searchText
    )
    const tableData = formatOrganizationsDataForTable(filteredOrganizations)
    setTableData(tableData)
  }, [organizations, searchText, selectedOrganizationIds])

  const handleSelect = (selectedRowKeys) => {
    const selectedOrganizationsNotRelatedToTable = selectedOrganizations.filter(id => !tableData.find(org => org.id === id))
    const newSelectedOrganizations = [...selectedOrganizationsNotRelatedToTable, ...selectedRowKeys]

    setSelectedOrganizations(newSelectedOrganizations)
  }

  const handleSearch = (searchText) => {
    setSearchText(searchText)
  }

  const handleSearchTextChange = (event) => {
    handleSearch(event.target.value)
  }

  const handleSetEditView = () => {
    setIsEditView(true)
  }

  const handleSave = () => {
    onSave(selectedOrganizations)
    setIsEditView(false)
  }

  const handleCancel = () => {
    setSelectedOrganizations(selectedOrganizationIds)
    setIsEditView(false)
  }

  const rowSelection = getRowSelection(
    selectedOrganizations,
    isEditView,
    handleSelect
  )

  if (orgsLoading) {
    return <Skeleton active />
  }

  const renderActionButtons = () => {
    if (!isEditView) {
      return (
        <Button
          type='primary'
          onClick={handleSetEditView}
          className='antd-button-override'
          loading={isLoading}
          disabled={!isEditable}
        >
          Edit
        </Button>
      )
    }
    return (
      <div>
        <Button
          type='primary'
          onClick={handleSave}
          className='antd-button-override antd-button-override-success'
          loading={isLoading}
        >
          Save
        </Button>
        <Button
          type='primary'
          onClick={handleCancel}
          danger
          className='antd-button-override'
          loading={isLoading}
        >
          Cancel
        </Button>
      </div>
    )
  }

  return (
    <>
      <div className='table-search'>
        {renderActionButtons()}
        <Search
          placeholder='search organization'
          allowClear
          onChange={handleSearchTextChange}
          onSearch={handleSearch}
          style={{
            width: 240
          }}
        />
      </div>
      <Table
        rowSelection={rowSelection}
        columns={COLUMNS}
        dataSource={tableData}
      />
    </>
  )
}

const mapOrgsToProps = ({ data }) => ({
  orgsError: data.error,
  orgsLoading: data.loading,
  organizations: sortItemsByKey(
    _get(data, 'organizations.results', []).slice(),
    'name'
  )
})

export default compose(
  graphql(GetAllOrganizations, {
    options: {
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK
    },
    props: mapOrgsToProps
  })
)(OrganizationTable)
