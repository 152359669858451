import React from 'react'

// I highly distrust touchableOpacity's - hence the react styling
const styles = {
  mainContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    margin: '32px 0px 10px 0px'
  },
  expandButton: {
    marginLeft: 'auto',
    marginRight: '20px'
  }
}

class ModalItem extends React.Component {
  state = {
    isExpanded: false
  }

  handleItemClick = () => {
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  render () {
    const { mainContent, expandButton, expandedContent } = this.props
    const { isExpanded } = this.state

    return (
      <div>
        <div
          style={styles.mainContent}
          onClick={this.handleItemClick}
        >
          {mainContent}
          {!isExpanded &&
            <div style={styles.expandButton}>
              {expandButton}
            </div>}
        </div>
        {isExpanded && expandedContent}
      </div>
    )
  }
}

export default ModalItem
