import _trim from 'lodash/trim'
import { Input, Modal } from 'antd'
import _isEmpty from 'lodash/isEmpty'
import _toUpper from 'lodash/toUpper'
import React, { useState } from 'react'

import './createFeatureFlag.css'

const { TextArea } = Input

function CreateFeatureFlagModal ({ onOk, onCancel, isOpen, isLoading }) {
  const [key, setKey] = useState('')
  const [description, setDescription] = useState('')

  const handleKeyChange = (event) => {
    setKey(_trim(_toUpper(event?.target?.value)))
  }

  const handleDescriptionChange = (event) => {
    setDescription(event?.target?.value)
  }

  const handleCreate = () => {
    onOk({
      key,
      description
    })
  }

  return (
    <Modal
      title='Create feature flag'
      open={isOpen}
      onOk={handleCreate}
      onCancel={onCancel}
      destroyOnClose
      okText='Create'
      okButtonProps={{
        disabled: _isEmpty(key) || _isEmpty(description),
        loading: isLoading
      }}
      cancelButtonProps={{
        loading: isLoading
      }}
    >
      <Input
        placeholder='Key'
        value={key}
        onChange={handleKeyChange}
        className='key-ff'
      />
      <TextArea
        rows={2}
        placeholder='Description...'
        maxLength={100}
        onChange={handleDescriptionChange}
        value={description}
      />
    </Modal>
  )
}

export default CreateFeatureFlagModal
