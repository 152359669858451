import React from 'react'
import store from 'store2'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import { Alert } from 'antd'

import './userManagement.css'
import UsersTable from '../usersTable'
import { isWriteAccessEnabled } from '../../helpers'
import { FEATURE_FLAG_WRITE } from '../../constants'

function UserManagement ({ featureFlags, onSave, isLoading }) {
  const featureFlagWriteParameter = featureFlags[FEATURE_FLAG_WRITE]

  const sukiUserIds = _get(
    featureFlagWriteParameter,
    'value.access.sukiUserIds',
    []
  )
  const sukiOrganizationIds = _get(
    featureFlagWriteParameter,
    'value.access.sukiOrganizationIds',
    []
  )

  const sukiAdminUserIds = _get(
    featureFlagWriteParameter,
    'value.access.sukiAdminUserIds',
    []
  )
  const featureFlagWriteAccessEnabled = isWriteAccessEnabled(featureFlags)

  const handleUpdateUserAccess = (selectedUsers) => {
    onSave({
      key: FEATURE_FLAG_WRITE,
      sukiUserIds: selectedUsers,
      sukiOrganizationIds: sukiOrganizationIds
    })
  }

  const renderAccessManagementGuidelines = () => {
    return (
      <ul>
        <li>Access management is only enabled for Suki Organization</li>
        <li>
          Users with Admin or Read/Write access will be able to modify the
          access
        </li>
        <li>
          Users with Admin or Read/Write access will be able to create/edit
          feature flags
        </li>
        <li>
          Only users with Admin access will be able to delete/deactivate feature
          flags
        </li>
      </ul>
    )
  }

  return (
    <>
      <h2>
        <strong>Access management</strong>
      </h2>
      <div className='user-management-content'>
        <Alert
          message='Access management guidelines'
          description={renderAccessManagementGuidelines()}
          type='info'
          showIcon
        />
        <UsersTable
          selectedUserIds={sukiUserIds}
          sukiAdminUserIds={sukiAdminUserIds}
          isEditable={featureFlagWriteAccessEnabled}
          onSave={handleUpdateUserAccess}
          isLoading={isLoading}
          showRole
          disableOrgSelection
        />
      </div>
    </>
  )
}

export default React.memo(UserManagement)
