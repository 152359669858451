import React, { Component } from 'react'
import styled from 'styled-components'
import TopNavBar from './TopNavBar'
import EditorSandboxContainer from '../components/EditorSandbox/EditorSandboxContainer'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div`
  margin-top: 75px;
  width: calc(100% - 50px);
`

class EditorSandbox extends Component {
  render () {
    return (
      <Container>
        <TopNavBar />
        <Content>
          <EditorSandboxContainer />
        </Content>
      </Container>
    )
  }
}

export default EditorSandbox
