export const SET_USER_ID = 'SET_USER_ID'
export const SET_USER_NOTES_PANEL_FILTER = 'SET_USER_NOTES_PANEL_FILTER'
export const SET_ORG_ID = 'SET_ORG_ID'
export const SET_COMMAND_TYPE = 'SET_COMMAND_TYPE'
export const SET_PREF_ID = 'SET_PREF_ID'

export const setUserId = userId => ({
  type: SET_USER_ID,
  userId
})

export const setUserNotesPanelFilter = filter => ({
  type: SET_USER_NOTES_PANEL_FILTER,
  filter
})

export const setOrgId = orgId => ({
  type: SET_ORG_ID,
  orgId
})

export const setCommandType = command => ({
  type: SET_COMMAND_TYPE,
  command
})

export const setPreferenceId = prefId => ({
  type: SET_PREF_ID,
  prefId
})
