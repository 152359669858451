import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { GetPreferences, UpdatePreferences } from '@sukiai/gql/admin'
import { withRouter } from 'react-router-dom'
import get from 'lodash.get'
import { Frame } from '.'
import { Preference } from '../components/Preferences'
import omitDeep from 'omit-deep-lodash'
import { notifySuccess, setError } from '../lib/util'
import { EditorSidebar } from '../components/util'
import { setPreferenceId } from '../actions/admin'
import { connect } from 'react-redux'
import { FETCH_POLICY } from '../lib/constants'

const styles = {
  container: {
    padding: 15,
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'scroll'
  }
}

class Preferences extends Component {
  updatePreference = (values, actions) => {
    const { match, UpdatePreferences } = this.props
    const organizationId = get(match, 'params.orgId')
    const userId = get(match, 'params.userId')
    const cleanValues = omitDeep(values, '__typename')

    UpdatePreferences({
      variables: {
        organizationId,
        userId,
        preferences: [cleanValues]
      },
      refetchQueries: [
        {
          query: GetPreferences,
          variables: { organizationId, userId }
        }
      ]
    })
      .then(({ data: { updatePreferences: { ids } } }) => {
        actions.setSubmitting(false)
        notifySuccess(`Updated preference: ${ids[0]}`)
      })
      .catch(err => {
        actions.setSubmitting(false)
        setError(`Could not update ${cleanValues.id} preference: ${err}`)
      })
  }

  render () {
    const { prefsData, currentPrefId, setPreferenceId } = this.props

    return (
      <Frame>
        <EditorSidebar
          noAddButton
          items={prefsData}
          currentId={currentPrefId}
          setPreferenceId={setPreferenceId}
        />
        <div style={styles.container}>
          {prefsData &&
            prefsData.map((p, i, pd) => (
              <Preference
                key={p.id}
                updatePreference={this.updatePreference}
                {...p}
                last={i === pd.length - 1}
              />
            ))}
        </div>
      </Frame>
    )
  }
}

const mapStateToProps = ({ admin }) => ({ currentPrefId: admin.prefId })

const mapDispatchToProps = dispatch => ({
  setPreferenceId: id => dispatch(setPreferenceId(id))
})

const mapPrefsToProps = ({ data }) => ({
  prefsError: data.error,
  prefsLoading: data.loading,
  prefsData: get(data, 'preferences.results', []).sort((a, b) => a.id - b.id)
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  graphql(GetPreferences, {
    skip: ({ match }) => !get(match, 'params.orgId'),
    options: ({ match }) => ({
      variables: {
        organizationId: get(match, 'params.orgId'),
        userId: get(match, 'params.userId')
      },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY
    }),
    props: mapPrefsToProps
  }),
  graphql(UpdatePreferences, { name: 'UpdatePreferences' })
)(Preferences)
