import React, { Component } from 'react'
import { Animated, Easing } from 'react-native'
import {
  Container,
  BadgeWrapper,
  Badge,
  BadgeMuted,
  BadgeMutedDark,
  AwakeRing,
  ListeningRing,
  Processing,
  BadgeDark
} from './styled'
import Alert from '../Alert'
import OutOfBoxToast from '../OutOfBoxToast'
import { AGENT } from '../../lib/constants'
import { getPagetype, isAmwellTheme } from '../../lib/util'

class Agent extends Component {
  constructor (props) {
    super(props)

    // This is used for the `hearingAlt` animation that we're exploring
    this.listeningArray = [5, 4, 3, 2, 1]
    const listeningAnims = this.listeningArray.map(e =>
      new Animated.Value(props.size * 0.95)
    )

    this.state = {
      badgeSize: new Animated.Value(props.size),
      badgePosition: new Animated.ValueXY(props.positionCoordinates),
      awakeRing: new Animated.Value(-1),
      listeningAnims,
      processingAnim: new Animated.Value(0),
      isAmwellDark: isAmwellTheme(),
      pagetype: getPagetype()
    }
  }

  componentWillReceiveProps (nextProps) {
    // Animation to adjust badge size
    if (nextProps.size !== this.props.size) {
      Animated.timing(this.state.badgeSize, {
        toValue: nextProps.size,
        duration: 200,
        easing: Easing.linear
      }).start()
    }

    // Animation to adjust badge position
    if (nextProps.positionCoordinates !== this.props.positionCoordinates) {
      Animated.timing(this.state.badgePosition, {
        toValue: nextProps.positionCoordinates,
        duration: 200,
        easing: Easing.linear
      }).start()
    }

    // One ring animation for wakeup on trigger
    if (nextProps.agentState === AGENT.STATE.AWAKE) {
      const getRandomValue = () => {
        return Animated.timing(this.state.awakeRing, {
          toValue: Math.random(),
          duration: 200,
          easing: Easing.linear
        }).start(({ finished }) => {
          finished && getRandomValue()
        })
      }

      Animated.sequence([
        Animated.timing(this.state.awakeRing, {
          toValue: 1,
          duration: 100,
          easing: Easing.linear
        }),
        Animated.delay(900)
      ]).start(({ finished }) => {
        finished && getRandomValue()
      })
    } else {
      this.setState({
        awakeRing: new Animated.Value(-1)
      })
    }

    // Pulsing rings animation for `listening` state
    if (nextProps.agentState === AGENT.STATE.LISTENING) {
      const listeningAnim = this.listeningArray.map((e, i) =>
        Animated.loop(
          Animated.sequence([
            Animated.timing(this.state.listeningAnims[i], {
              toValue: nextProps.size * 1.2,
              duration: 800,
              easing: Easing.linear
            }),
            Animated.timing(this.state.listeningAnims[i], {
              toValue: nextProps.size * 0.95,
              duration: 800,
              easing: Easing.linear
            }),
            Animated.delay(800)
          ])
        )
      )

      Animated.stagger(100, listeningAnim).start()
    } else {
      const listeningAnims = this.listeningArray.map(e =>
        new Animated.Value(nextProps.size * 0.95)
      )
      this.setState({ listeningAnims })
    }

    // Spinning light animation for `processing` state
    if (nextProps.agentState === AGENT.STATE.PROCESSING) {
      Animated.loop(
        Animated.timing(this.state.processingAnim, {
          toValue: 1,
          duration: 500,
          easing: Easing.linear
        })
      ).start()
    } else {
      this.setState({
        processingAnim: new Animated.Value(0)
      })
    }
  }

  render () {
    const {
      badgeSize,
      badgePosition,
      awakeRing,
      listeningAnims,
      processingAnim,
      isAmwellDark
    } = this.state

    const {
      size,
      agentState,
      toggleMute,
      alert,
      clearAlert,
      muteAgent
    } = this.props

    const { pathname } = new URL(window.location)

    const styles = {
      awake: {
        height: awakeRing.interpolate({
          inputRange: [-1, 0, 1],
          outputRange: [badgeSize._value * 0.9, badgeSize._value, badgeSize._value * 1.2]
        }),
        width: awakeRing.interpolate({
          inputRange: [-1, 0, 1],
          outputRange: [badgeSize._value * 0.9, badgeSize._value, badgeSize._value * 1.2]
        })
      },
      hearing: this.listeningArray.map((e, i) => ({
        height: listeningAnims[i],
        width: listeningAnims[i]
      })),
      badge: {
        height: badgeSize,
        width: badgeSize
      },
      badgeContainer: {
        height: badgeSize,
        width: badgeSize,
        marginLeft: '-1rem',
        marginTop: !pathname.includes('/home') && window.innerWidth <= 1050 ? '-70px' : '0'
      },
      processing: {
        transform: [
          {
            rotate: processingAnim.interpolate({
              inputRange: [0, 1],
              outputRange: ['0deg', '360deg']
            })
          }
        ]
      }
    }

    const stylesDark = {
      awake: { ...styles.awake },
      hearing: { ...styles.hearing },
      badgeContainer: {
        marginLeft: '-1rem',
        marginTop: !pathname.includes('/home') && window.innerWidth <= 1050 ? '-16px' : '0'
      },
      processing: { ...styles.processing },
      left: '50vw',
      right: '50vw',
      bottom: '5px'
    }

    return (
      <Container style={isAmwellDark ? { ...stylesDark } : [styles.badgeContainer, badgePosition.getLayout()]} data-cy='suki-agent-container'>
        {/* User alert */}
        {alert && alert.open && (
          <Alert
            size={size}
            message={alert.message}
            clearAlert={clearAlert}
            muteAgent={muteAgent}
            dataCy='suki-alert'
          />
        )}
        {isAmwellDark && <OutOfBoxToast agentState={agentState} toastType={this.state.pagetype} />}
        <BadgeWrapper onClick={toggleMute} size={size} data-cy='suki-agent-badgeWrapper' isAmwellTheme={isAmwellDark} muted={agentState === AGENT.STATE.MUTED}>
          {/* Badge */}
          {
            isAmwellDark ? (
              <BadgeDark style={styles.badge} muted={agentState === AGENT.STATE.MUTED} />
            ) : (
              <Badge style={styles.badge} muted={agentState === AGENT.STATE.MUTED} />
            )
          }
          {/* Muted badge */}
          {isAmwellDark ? (
            <BadgeMutedDark style={styles.badge} muted={agentState === AGENT.STATE.MUTED} data-cy='suki-agent-muted' />
          ) : (
            <BadgeMuted style={styles.badge} muted={agentState === AGENT.STATE.MUTED} data-cy='suki-agent-muted' />
          )}
        </BadgeWrapper>

        {/* Awake ring */}
        {agentState === AGENT.STATE.AWAKE &&
          <AwakeRing size={size} style={styles.awake} data-cy='suki-agent-awake-ring' />}

        {/* Listening rings */}
        {agentState === AGENT.STATE.LISTENING && this.listeningArray.map((e, i) =>
          <ListeningRing size={size} style={[styles.hearing[i], { opacity: e * 0.2 }]} thicker={(i === 0).toString()} key={i} data-cy='suki-agent-listening-ring' />
        )}

        {/* Processing spinner */}
        {agentState === AGENT.STATE.PROCESSING &&
          <Processing size={size} style={styles.processing} data-cy='suki-agent-processing-spinner' />}
      </Container>
    )
  }
}

export default Agent
