import {
  CLEAR_ACTION_REQUESTED,
  CLEAR_SERVER_ERROR,
  SET_ACTION_REQUESTED,
  SET_SERVER_ERROR,
  SET_STREAM_AUTHENTICATED,
  TOGGLE_DISCONNECT
} from '../actions'

/**
 * Basically, a collection of assorted state that wasn't large enough to have its own reducer, but didn't
 * really fit anywhere else either
 */
const initialState = {
  actionRequested: null, // Action user requested through voice
  disconnected: false, // Stream is disconnected
  streamInfo: { // Contains stream ID & isAuthenticated bool
    id: null,
    isAuthenticated: false
  },
  languageCode: 'en-US'
}

const general = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ACTION_REQUESTED:
      return { ...state, actionRequested: null }
    case CLEAR_SERVER_ERROR:
      return { ...state, error: '' }
    case SET_ACTION_REQUESTED:
      return { ...state, actionRequested: { name: action.name, data: action.data } }
    case SET_SERVER_ERROR:
      return { ...state, error: action.error }
    case SET_STREAM_AUTHENTICATED:
      return { ...state, streamInfo: action.streamInfo }
    case TOGGLE_DISCONNECT:
      return { ...state, disconnected: action.disconnected }
    default:
      return state
  }
}

export default general
