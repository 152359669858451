import styled from 'styled-components/native'
import reactStyled, { css } from 'styled-components'
import { Animated } from 'react-native'
import {
  snowDrift,
  scribeGreen,
  charcoal,
  platinum,
  fulvous,
  amwellPrimaryButtonColor
} from '../../lib/colors'
import { Z_INDEX, MODALS } from '../../lib/constants'
import AddIcon from '../../assets/icons/add-icon.svg'

export const ModalContainer = styled(Animated.View)`
  display: flex;
  flex-direction: column;
  min-width: ${props => props.type === MODALS.SELECT
    ? 390
    : props.type === MODALS.IMAGE
      ? props.minw || 780
      : props.isAmwellDark
        ? 'calc(520px - 5vw - 48px)'
        : 'calc(520px - 5vw)'
  };
  ${props => props.type === MODALS.SELECT || props.isAmwellDark
    ? 'max-width: 600px'
    : `width: ${props.type === MODALS.IMAGE
        ? props.w || props.vw * 0.6
        : props.vw * 0.4
      }`
  };
  background-color: ${props => props.isAmwellDark ? 'rgb(45,55,64)' : snowDrift};
  box-shadow: 0px 0px 10px rgba(1,1,1,0.2);
  border-radius: ${props => props.isAmwellDark ? '12px' : '1px'};
  padding: ${props => props.type === MODALS.SELECT
    ? '20px 30px'
    : props.type === MODALS.IMAGE
      ? '30px'
      : props.type === MODALS.SCRIPT_DETAILS
        ? '40px 30px 20px'
        : '30px 30px 20px'
  };
  z-index: ${Z_INDEX.MODAL};

  ${props => props.isAmwellDark && props.type === MODALS.ICD10_PREVIEW && css`
    marginHorizontal: 24px;
  `}
`

const HeadingStyles = {
  paddingBottom: {
    [MODALS.SELECT]: '40px',
    [MODALS.SCRIPT_DETAILS]: '40px',
    [MODALS.ICD10_PREVIEW]: '0px'
  }
}

export const Heading = styled.Text`
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  text-align: ${props => props.type === MODALS.IM_DONE_BYPASS_QA || props.type === MODALS.SCRIPT_DETAILS ? 'center' : 'left'};
  padding-bottom: ${props => HeadingStyles.paddingBottom[props.type] ? HeadingStyles.paddingBottom[props.type] : '30px'};
  font-family: 'Open Sans';
  color: ${charcoal};
`

export const HeadingDark = styled.Text`
  width: 100%;
  font-size: 28px;
  font-weight: 600;
  text-align: ${props => props.type === MODALS.IM_DONE_BYPASS_QA || props.type === MODALS.SCRIPT_DETAILS ? 'center' : 'left'};
  padding-bottom: 8px;
  font-family: 'Open Sans';
  color: #fff;
  line-height: 0.86em;
`
// packages/components/src/ModalDialog/styled.js

export const HeadingBtn = reactStyled.button`
  position: absolute;
  right: 20px;
  width: 30px;
  border: 0px;
  height: 30px;
  top: 25px;
  border-radius: 50%;
  cursor: pointer;
  background: url(${AddIcon});
  background-position: center;
  filter: invert(28%) sepia(33%) saturate(658%) hue-rotate(134deg) brightness(100%) contrast(84%);

  &:hover {
    background-color: rgba(118, 118, 128, 0.08);
  }
`

export const SecondaryHeading = styled.Text`
  width: 100%;
  font-size: 22px;
  padding-bottom: 10px;
  font-family: 'Open Sans';
`

export const Body = reactStyled.div`
  width: 100%;
  padding-bottom: ${props => props.type === MODALS.SELECT ||
    props.type === MODALS.IMAGE || props.type === MODALS.IM_DONE_BYPASS_QA
      ? '0px'
      : '30px'
  };
  max-height: ${props => props.type === MODALS.SELECT || props.type === MODALS.LIST
    ? props.vh * 0.6 + 'px'
    : 'none'
  };
  overflow-x: hidden;
  overflow-y: auto;
  color: #fff;
  
  ${props => props.isAmwellDark && css`
    &::-webkit-scrollbar {
      background-color: #1a1f24;
      width: 16px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: rgb(45,55,64);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #494c50;
      border-radius: 16px;
      border: 4px solid rgb(45,55,64);
    }

    &::-webkit-scrollbar-button {
      display:none;
    }
  `}
`

export const BodyText = styled.Text`
  font-size: ${props => props.fontSize ? props.fontSize : '18px'};
  font-family: 'Open Sans';
  line-height: 170%;
  color: ${props => props.color ? props.color : charcoal};
  font-weight: ${props => props.semibold === 'true' ? '600' : '400'}
`
export const BodyTextDark = styled.Text`
  font-size: ${props => props.fontSize ? props.fontSize : '16px'};
  font-family: 'Open Sans';
  line-height: 1.26em;
  color: #fff;
  font-weight: ${props => props.semibold === 'true' ? '600' : '400'}
`
export const BodyImage = styled.Image.attrs({
  draggable: false,
  resizeMode: 'contain'
})`
  height: ${props => props.h};
  min-height: ${props => props.minh};
  max-height: ${props => props.maxh};
  width: ${props => props.w || '100%'};
  min-width: ${props => props.minw || 720};
  max-width: ${props => props.maxw || 1140};
`

export const ItemWrapper = reactStyled.button`
  border: none;
  background-color: transparent;
  &:active {
    opacity: 0.6 !important;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  width: 100%;
`
export const AmwellItemWrapper = reactStyled.button`
  border: none;
  background-color: transparent;
  &:active {
    opacity: 0.6 !important;
  }
  &:hover {
    background-color: #3b424a;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  width: 100%;
`

export const RadioButton = styled.View`
  height: 20;
  width: 20;
  border-width: 2;
  border-color: ${props => props.active === 'true'
    ? scribeGreen
    : charcoal
  };
  border-radius: 50%;
  margin-right: 12;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SelectedRadio = styled.View`
  height: 10;
  width: 10;
  border-radius: 50%;
  background-color: ${scribeGreen};
`

export const Footer = styled.View`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.alignment || 'row'};
  justify-content: ${props => props.alignment === 'column' ? 'center' : 'flex-end'};
  align-items: center;
`

export const ActionButton = styled.TouchableOpacity`
  margin-right: ${props => props.alignleft === 'true'
    ? 'auto'
    : 'none'
  };
  padding: ${props => props.padding || '0px'};
`

export const ActionText = styled.Text`
  font-family: 'Open Sans';
  padding: ${props => props.type === MODALS.ICD10_PREVIEW ? '6px 18px 6px 0' : '6px 10px'};
  text-transform: ${props => props.allcaps === 'true' ? 'uppercase' : 'none'};
  border-radius: 4px;
  font-weight: 700;
  margin-left: ${props => props.type === MODALS.ICD10_PREVIEW ? '0' : '18px'};
  font-size: 16px;
  cursor: pointer;
  color: ${props => props.disabled === 'true'
    ? '#DCDCDC'
    : props.isAmwellDark ? amwellPrimaryButtonColor : scribeGreen
  };
  transition-duration: 0.5s;
`

export const LoadingButton = styled.ActivityIndicator.attrs({
  color: fulvous,
  size: 32
})`
  padding: 1px 20px;
`

export const Divider = styled.View`
  position: absolute;
  top: 70;
  left: 0;
  width: 100%;
  height: 1.2px;
  background-color: ${platinum};
`
