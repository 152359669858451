import styled from 'styled-components/native'
import reactStyled, { css } from 'styled-components'
import { Animated } from 'react-native'
import search from '../../assets/icons/search.svg'
import { snowDrift, platinum, charcoal } from '../../lib/colors'
import { Z_INDEX, ICON_SIZE } from '../../lib/constants'

const borderColor = '#eaeaea'

export const SearchVeil = styled.TouchableOpacity.attrs({
  activeOpacity: 1
})`
  position: fixed;
  z-index: ${Z_INDEX.HAMBURGER - 2};
  cursor: default;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

export const Container = styled.View`
  position: relative;
  display: flex;
  align-items: flex-end;
  z-index: ${Z_INDEX.HAMBURGER};
`

export const IconContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 0.7
})`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${Z_INDEX.HAMBURGER + 1};
`

export const SearchIcon = reactStyled.img.attrs({
  src: search
})`
  height: ${ICON_SIZE.SMALL}px;
  width: ${ICON_SIZE.SMALL}px;
  z-index: ${Z_INDEX.HAMBURGER};
`

export const SearchBar = styled(Animated.View)`
  position: absolute;
  height: 100%;
  background-color: ${snowDrift};
  z-index: ${Z_INDEX.HAMBURGER};
  box-shadow: 1px 1px 8px rgba(0,0,0,0.3);
  width: 100%;
`

export const NonAnimatedSearchBar = styled.Text`
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: ${Z_INDEX.HAMBURGER};
`

export const AnimatedContainer = styled.View`
  display: flex;
  align-items: flex-end;
  width: 400px;
  max-width: 400px;
  width: calc(100vw - 290px);
`

export const DropDownContainer = styled.View`
  overflow-y: auto;
  width: 100%;
  max-height: 300px;
  box-shadow: 2px 2px 8px rgba(0,0,0,0.3);
  border: 1px solid ${borderColor};
  ${props => props.isAmwellDark && css`
    border: 2px solid #313a42;
    border-radius: 2px;
  `}
`

export const Input = styled.TextInput.attrs({
  autoFocus: true,
  placeholder: 'Start typing a patient name...',
  placeholderTextColor: platinum
})`
  position: relative;
  height: 100%;
  width: calc(100% - ${ICON_SIZE.MED}px);
  padding: 0px;
  box-sizing: border-box;
  outline: none;
  font-size: 14px;
  color: ${charcoal};
`

export const Empty = styled.Text`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${charcoal};
  padding: 10px 14px;
  text-align: center;
  font-size: 8px;
  font-family: 'Open Sans';
  word-wrap: break-word;
`

export const NoResultTitle = styled.Text`
`
