import React, { Component } from 'react'
import moment from 'moment'
import Editor from '@sukiai/quill-editor'
import { NOTE_MODE, TIMELINE_MODE, ROUTES, USER_PERSONA, EDITOR_TYPE } from '../../lib/constants'
import { withRouter } from 'react-router-dom'
import { transcriptHeader } from '../../styles/colors'
import * as jsdiff from 'diff'
import Plain from 'slate-plain-serializer'
import get from 'lodash.get'
import { Value } from 'slate'
import { copyText, isHTMLString, sanitizeHTML } from '../../lib/util'

const styles = {
  authorFields: {
    marginLeft: '115px'
  },
  codeFieldSummary: {
    display: 'flex',
    alignItems: 'center'
  },
  codeFieldHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '48%'
  },
  codeMetaField: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '60%'
  },
  fieldValue: {
    marginLeft: '50px',
    width: 260
  },
  codeFieldValue: {
    marginLeft: '50px',
    width: 400
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  sectionVersion: {
    padding: 10,
    backgroundColor: transcriptHeader
  },
  sectionMeta: {
    height: '100%',
    width: '100%',
    postiion: 'relative'
  },
  sectionMetaField: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%'
  },
  slateContent: {
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap'
  },
  timelineModeSelector: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '260px'
  },
  transcriptHistory: {
    border: '1px solid lightgray'
  },
  underline: {
    textDecoration: 'underline'
  },
  versionedContent: {
    width: '90%',
    overflow: 'scroll',
    backgroundColor: 'white',
    padding: 5
  },
  versionHeading: {
    display: 'flex',
    flexDirection: 'row'
  },
  versionSelector: {
    marginLeft: 10
  },
  versionSlider: {
    width: '100%'
  }
}

class TranscriptHistory extends Component {
  constructor (props) {
    super(props)

    const versions = this.getVersions(TIMELINE_MODE.UNFILTERED)

    this.state = {
      timelineMode: TIMELINE_MODE.UNFILTERED,
      versions: versions,
      numVersions: versions && versions.length,
      currentVersion: this.getCurrentVersion(versions),
      difContent: ''
    }
  }

  getColor = part => {
    if (part.added) return 'green'
    if (part.removed) return 'red'
    return 'black'
  }

  getTextDecoration = part => part.removed ? 'line-through' : ''

  componentDidUpdate (nextProps, nextState) {
    if (nextState.timelineMode !== this.state.timelineMode) {
      const versions = this.getVersions(nextState.timelineMode)
      const currentVersion = this.getCurrentVersion(versions)
      this.setState({
        versions: versions,
        numVersions: versions && versions.length,
        currentVersion: currentVersion
      })
    }
  }

  getVersions = (timelineMode) => {
    const { versions = [] } = this.props
    if (timelineMode !== TIMELINE_MODE.FILTERED) return versions

    const filteredVersions = []
    versions.forEach((v, i) => {
      if (i === versions.length - 1) {
        filteredVersions.push(v)
      } else {
        const status = v.section.status
        const nextStatus = versions[i + 1].section.status
        if (nextStatus !== status) filteredVersions.push(v)
      }
    })

    return filteredVersions
  }

  getCurrentVersion = versions => versions.length ? versions.length : 1

  setVersion = (e, v) => {
    this.setState({ currentVersion: parseInt(e.target.value, 10) })
  }

  getContent = () => {
    const { currentVersion, versions } = this.state
    const version = versions[currentVersion - 1]
    if (version && version.section) {
      return version.section.content
    }
    return ''
  }

  handleSliderChange = e => {
    this.setState({ currentVersion: e.target.value })
  }

  renderSectionMetaField = (fieldName, fieldValue) => {
    const { sectionId } = this.props
    return (
      <div style={styles.sectionMetaField} data-cy={`section-meta-field-${fieldName}-${sectionId}`}>
        <span>{`${fieldName}: `}</span>
        <span style={styles.fieldValue}><b>{fieldValue}</b></span>
      </div>
    )
  }

  renderAuthorMetaField = (fieldName, author) => {
    const { sectionId, history } = this.props

    const userId = get(author, 'userId')
    const organizationId = get(author, 'organizationId')
    const type = get(author, 'type')
    const role = get(author, 'role')
    const organizationName = get(author, 'organizationName')
    const userName = get(author, 'userName')

    // Display role if author type is USER, otherwise display MACHINE or UNKNOWN
    let userType = type !== 'USER' ? type : get(role, 'type')
    if (userType === 'USER') userType = 'DOCTOR'
    const isUser = type === 'USER'

    return (
      <div>
        <div style={styles.sectionMetaField} data-cy={`section-meta-field-${fieldName}-${sectionId}`}>
          <div
            onClick={isUser ? () => history.push('/' + organizationId + '/' + ROUTES.USERS) : () => { }}
            style={isUser ? styles.link : {}}
          >
            {`${fieldName}:`}
          </div>
          <span style={styles.fieldValue}>{userType}</span>
        </div>
        {isUser &&
          <div style={styles.authorFields}>
            <div style={{ display: 'flex' }} onClick={() => copyText(organizationId)}>
              <div style={{ width: '110px' }}>Organization:</div> {organizationName}
            </div>
            <div style={{ display: 'flex' }} onClick={() => copyText(userId)}>
              <div style={{ width: '110px' }}>User:</div> {userName}
            </div>
          </div>}
      </div>
    )
  }

  renderVersionSelector = () => {
    const { sectionId } = this.props
    const { currentVersion, versions } = this.state

    return (
      <span style={styles.sectionMetaField}>
        <span>Version:</span>
        <span style={styles.fieldValue}>
          <select
            onChange={e => this.setVersion(e)}
            value={currentVersion}
            data-cy={`section-history-version-selector-${sectionId}`}
          >
            {versions.map((v, i) => (
              <option value={i + 1} key={`${v.section.name}-${i}`}>{i + 1}</option>
            ))}
          </select>
          &nbsp;of {versions.length}
        </span>
      </span>
    )
  }

  renderTimelineModeSelector = () => {
    const { sectionId } = this.props
    const { timelineMode } = this.state
    return (
      <div style={Object.assign({}, styles.sectionMetaField, { width: '50%' })}>
        <span>Timeline Mode: </span>
        <span onChange={(e) => this.setTimelineMode(e)} style={styles.timelineModeSelector}>
          <span>
            <input
              type='radio'
              value={TIMELINE_MODE.UNFILTERED}
              name={`mode-${sectionId}`}
              defaultChecked={timelineMode === TIMELINE_MODE.UNFILTERED}
              data-cy={`unfiltered-timeline-mode-${sectionId}`}
            />
            Unfiltered
          </span>
          <span>
            <input
              type='radio'
              value={TIMELINE_MODE.FILTERED}
              name={`mode-${sectionId}`}
              defaultChecked={timelineMode === TIMELINE_MODE.FILTERED}
              data-cy={`filtered-timeline-mode-${sectionId}`}
            />
            Filtered
          </span>
          <span>
            <input
              type='radio'
              value={TIMELINE_MODE.S2}
              name={`mode-${sectionId}`}
              defaultChecked={timelineMode === TIMELINE_MODE.S2}
              data-cy={`slate-timeline-mode-${sectionId}`}
            />
            S2
          </span>
          <span>
            <input
              type='radio'
              value={TIMELINE_MODE.DIF}
              name={`mode-${sectionId}`}
              defaultChecked={timelineMode === TIMELINE_MODE.DIF}
              data-cy={`dif-timeline-mode-${sectionId}`}
            />
            Diff
          </span>
        </span>
      </div>
    )
  }

  setTimelineMode = e => {
    this.setState({ timelineMode: e.target.value })
  }

  generateDifContent = strContent => {
    const { currentVersion, versions } = this.state
    const content = Plain.serialize(Value.create(JSON.parse(strContent)))
    if (currentVersion > 1) {
      let prevContent = versions[currentVersion - 2].section && versions[currentVersion - 2].section.content
      prevContent = Plain.serialize(Value.create(JSON.parse(prevContent)))
      const diffs = jsdiff.diffChars(prevContent, content)
      return diffs.map((part, index) => (
        <span
          style={{ textDecoration: this.getTextDecoration(part), color: this.getColor(part) }}
          key={index}
        >
          {part.value}
        </span>
      ))
    }
    return content
  }

  renderEditor (content, cursorPosition) {
    const { s2Mode } = this.props

    // [Parial dictation]: To render html section in non editable mode
    const text = content && JSON.parse(content).total_string
    const isHTML = isHTMLString(text)

    return isHTML ? (
      <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(text) }} style={{ 'overflow-x': 'hidden' }} />
    ) : (
      <Editor
        value={content}
        persona={USER_PERSONA.ADMIN}
        editorType={EDITOR_TYPE.DETAILED}
        readOnly
        s2Mode={s2Mode}
        cursorPosition={cursorPosition}
      />
    )
  }

  renderSectionContent = (content, cursorPosition) => {
    switch (this.state.timelineMode) {
      case TIMELINE_MODE.FILTERED:
      case TIMELINE_MODE.UNFILTERED:
        return this.renderEditor(content, cursorPosition)
      case TIMELINE_MODE.S2:
        return <pre style={styles.slateContent}>{content}</pre>
      case TIMELINE_MODE.DIF:
      default:
        return content
    }
  }

  renderCodeField = version => {
    const { sectionId } = this.props

    const code = get(version, 'section.diagnosisEntry.diagnosis.icdCode')
    const desc = get(version, 'section.diagnosisEntry.diagnosis.description')
    const confirmStatus = get(version, 'section.diagnosisEntry.confirmStatus')

    // If no code is set, no
    if (!code) {
      return this.renderSectionMetaField('Code', code || 'Not Set')
    }

    return (
      <details>
        <summary style={styles.codeFieldSummary}>
          <div style={styles.codeFieldHeader} data-cy={`section-meta-field-${code}-${sectionId}`}>
            <span>Code</span>
            <span style={styles.fieldValue}><b>{code || 'Not Set'}</b></span>
          </div>
        </summary>
        <div style={styles.codeMetaField} data-cy={`section-meta-field-code-desc-${sectionId}`}>
          <span>Code Description</span>
          <span style={styles.codeFieldValue}><b>{desc}</b></span>
        </div>
        <div style={styles.codeMetaField} data-cy={`section-meta-field-code-conf-${sectionId}`}>
          <span>Code Confirm Status</span>
          <span style={styles.codeFieldValue}><b>{confirmStatus}</b></span>
        </div>
      </details>
    )
  }

  renderTranscriptHistoryModal = () => {
    const { sectionId } = this.props
    const { currentVersion, versions, timelineMode } = this.state

    const version = versions[currentVersion - 1]
    if (!version) return null

    const status = get(version, 'section.status')
    const author = version.author
    const created = version.createdAt && moment(version.createdAt).format('MMM DD YYYY, hh:mm:ss a')
    const cursorPosition = version?.section?.cursorPosition

    const isProblemSection = !!get(version, 'section.diagnosisEntry')

    let content = get(version, 'section.content')
    switch (timelineMode) {
      case TIMELINE_MODE.UNFILTERED:
        content = this.getContent()
        break
      case TIMELINE_MODE.FILTERED:
        content = this.getContent()
        break
      case TIMELINE_MODE.DIF:
        try {
          content = this.generateDifContent(content)
        } catch (err) {
          content = <span>Error: Unable to parse slate.</span>
        }
        break
      case TIMELINE_MODE.S2:
        try {
          content = JSON.stringify(JSON.parse(content), null, 4)
        } catch (err) {
          content = <span>Error: Unable to parse slate.</span>
        }
        break
      default:
        content = this.getContent()
    }

    return (
      <div style={styles.transcriptHistory} data-cy={`transcript-history-${sectionId}`}>
        <div style={styles.sectionVersion}>
          <div style={styles.sectionMeta}>
            {this.renderSectionMetaField('Status', status)}
            {isProblemSection && this.renderCodeField(version)}
            {author && this.renderAuthorMetaField('Author', author)}
            {this.renderVersionSelector()}
            {this.renderSectionMetaField('Created', created)}
            {this.renderTimelineModeSelector()}
          </div>
          <div className='slidecontainer'>
            <input
              type='range'
              style={styles.versionSlider}
              min={1}
              max={this.state.numVersions}
              value={currentVersion}
              onChange={e => this.handleSliderChange(e)}
            />
          </div>
        </div>
        <div style={styles.versionedContent} data-cy={`section-current-content-${sectionId}`}>
          {this.renderSectionContent(content, cursorPosition)}
        </div>
      </div>
    )
  }

  render () {
    const { style, headingStyle, noteMode, sectionId } = this.props
    const { versions } = this.state
    if (!versions) return null

    return (
      <div style={style}>
        {noteMode === NOTE_MODE.QA
          ? this.renderTranscriptHistoryModal()
          : (
            <details style={this.versionHeading} data-cy={`transcript-history-dropdown-${sectionId}`}>
              <summary style={headingStyle}>Transcript History</summary>
              {this.renderTranscriptHistoryModal()}
            </details>
          )}
      </div>
    )
  }
}

export default withRouter(TranscriptHistory)
