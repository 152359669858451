export const s2Example = {
  number_of_strings: 20,
  total_string_length: 661,
  total_string: 'Managing Your Weight\n\nYour BMI is ***\n\nIf you want to lose weight, start with a goal of 5-10% of your current weight.\n\n* Eat smaller portions. Try using smaller plates for dinner.\n\n* Choose low-calorie snacks. Try foods such as fruits, vegetables, air-popped popcorn, fat-free yogurt, hummus, and almonds.\n\n* Watch your intake of sugary and high-fat desserts--they can add a lot of extra calories and fats.\n\n* Limit foods high in solid fats, such as butter/stick margarine, regular cheese, fatty meats, and French fries fried in oil.\n\n* Drink more water and fat-free or low-fat (1%) milk and less regular soda, sports drinks, energy drinks, and fruit drinks.\n',
  content: [
    {
      id: 0,
      start_offset: 0,
      end_offset: 20,
      string: 'Managing Your Weight\n',
      length_of_string: 21,
      is_bold: 1,
      is_italic: 1,
      source: 'ASR_NVOQ'
    },
    {
      id: 1,
      start_offset: 21,
      end_offset: 21,
      string: '\n',
      length_of_string: 1,
      is_bold: 0,
      is_italic: 0,
      source: 'ASR_NVOQ'
    },
    {
      id: 2,
      start_offset: 22,
      end_offset: 26,
      string: 'Your ',
      length_of_string: 5,
      is_bold: 0,
      is_italic: 0,
      source: 'TYPED_SCRIBE'
    },
    {
      id: 3,
      start_offset: 27,
      end_offset: 30,
      string: 'BMI ',
      length_of_string: 4,
      is_bold: 1,
      is_italic: 0,
      source: 'TYPED_SCRIBE'
    },
    {
      id: 4,
      start_offset: 31,
      end_offset: 37,
      string: 'is ***\n',
      length_of_string: 7,
      is_bold: 0,
      is_italic: 0,
      source: 'ASR_NVOQ'
    },
    {
      id: 5,
      start_offset: 38,
      end_offset: 38,
      string: '\n',
      length_of_string: 1,
      is_bold: 0,
      is_italic: 0,
      source: 'ASR_NVOQ'
    },
    {
      id: 6,
      start_offset: 39,
      end_offset: 103,
      string: 'If you want to lose weight, start with a goal of 5-10% of your ',
      length_of_string: 65,
      is_bold: 0,
      is_italic: 0,
      source: 'ASR_NVOQ'
    },
    {
      id: 7,
      start_offset: 104,
      end_offset: 111,
      string: 'current ',
      length_of_string: 8,
      is_bold: 1,
      is_italic: 0,
      source: 'ASR_NVOQ'
    },
    {
      id: 8,
      start_offset: 112,
      end_offset: 119,
      string: 'weight.\n',
      length_of_string: 8,
      is_bold: 0,
      is_italic: 0,
      source: 'ASR_NVOQ'
    },
    {
      id: 9,
      start_offset: 120,
      end_offset: 120,
      string: '\n',
      length_of_string: 1,
      is_bold: 0,
      is_italic: 0,
      source: 'ASR_NVOQ'
    },
    {
      id: 10,
      start_offset: 121,
      end_offset: 181,
      string: '* Eat smaller portions. Try using smaller plates for dinner.\n',
      length_of_string: 61,
      is_bold: 0,
      is_italic: 0,
      source: 'ASR_NVOQ'
    },
    {
      id: 11,
      start_offset: 182,
      end_offset: 182,
      string: '\n',
      length_of_string: 1,
      is_bold: 0,
      is_italic: 0,
      source: 'ASR_NVOQ'
    },
    {
      id: 12,
      start_offset: 183,
      end_offset: 307,
      string: '* Choose low-calorie snacks. Try foods such as fruits, vegetables, air-popped popcorn, fat-free yogurt, hummus, and almonds.\n',
      length_of_string: 125,
      is_bold: 0,
      is_italic: 0,
      source: 'ASR_NVOQ'
    },
    {
      id: 13,
      start_offset: 308,
      end_offset: 308,
      string: '\n',
      length_of_string: 1,
      is_bold: 0,
      is_italic: 0,
      source: 'ASR_NVOQ'
    },
    {
      id: 14,
      start_offset: 309,
      end_offset: 408,
      string: '* Watch your intake of sugary and high-fat desserts--they can add a lot of extra calories and fats.\n',
      length_of_string: 100,
      is_bold: 0,
      is_italic: 0,
      source: 'ASR_NVOQ'
    },
    {
      id: 15,
      start_offset: 409,
      end_offset: 409,
      string: '\n',
      length_of_string: 1,
      is_bold: 0,
      is_italic: 0,
      source: 'ASR_NVOQ'
    },
    {
      id: 16,
      start_offset: 410,
      end_offset: 417,
      string: '* Limit ',
      length_of_string: 8,
      is_bold: 0,
      is_italic: 1,
      source: 'ASR_NVOQ'
    },
    {
      id: 17,
      start_offset: 418,
      end_offset: 535,
      string: 'foods high in solid fats, such as butter/stick margarine, regular cheese, fatty meats, and French fries fried in oil.\n',
      length_of_string: 118,
      is_bold: 0,
      is_italic: 0,
      source: 'ASR_NVOQ'
    },
    {
      id: 18,
      start_offset: 536,
      end_offset: 536,
      string: '\n',
      length_of_string: 1,
      is_bold: 0,
      is_italic: 0,
      source: 'ASR_NVOQ'
    },
    {
      id: 19,
      start_offset: 537,
      end_offset: 660,
      string: '* Drink more water and fat-free or low-fat (1%) milk and less regular soda, sports drinks, energy drinks, and fruit drinks.\n',
      length_of_string: 124,
      is_bold: 0,
      is_italic: 0,
      source: 'ASR_NVOQ'
    }
  ]
}
