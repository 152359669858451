import gql from 'graphql-tag'

// USED IN: VEIL
export const GetNotePDF = gql`
  query GetNotePDF($organizationId: ID, $compositionId: ID, $noteId: ID) {
    notePDF(input: {
      organizationId: $organizationId,
      compositionId: $compositionId,
      noteId: $noteId
    }) {
      pdf {
        uri
      }
    }
  }
`
