import React, { Component } from 'react'
import logo from '../../assets/icons/suki-logo-navbar.svg'
import styled from 'styled-components'

const MainFrame = styled.div`
  width: 600px;
  margin: 14vh auto 0;
`

const Content = styled.div`
  margin: 45px 0;

  h2 {
    margin: 0;
    font-weight: 900;
    font-size: 20px;
  }

  p {
    margin: 5px 0;
    font-size:18px;
    color: #446375;
  }
`

class FourOhFour extends Component {
  render () {
    return (
      <MainFrame>
        <img src={logo} width={220} alt='Suki Logo' />
        <Content>
          <h2>Sorry</h2>
          <p> We couldn't find the page.</p>
        </Content>
      </MainFrame>
    )
  }
}

export default FourOhFour
