import { push, replace } from 'react-router-redux'
import Auth from '../../okta'
import store from '../../store'
import { stripIds, getIdFromPath } from './path'
import { success } from 'react-notification-system-redux'
import loSto from '../../config/loSto'
import {
  LO_STO,
  ROUTES,
  USER_PERSONA,
  NOTE_MODE,
  ROLES,
  SCRIPT_TAG,
  ORG_IDS,
  BACKDOOR_WHITE_LIST,
  NOTE_QUEUE_STATUS,
  MACRO_SOURCE
} from '../../lib/constants'
import moment from 'moment'
import { uuid } from 'uuidv4'
import jwtDecode from 'jwt-decode'
import client from '../../apollo'
import { RefreshEMRAppointments, ReleaseAllNotesBackdoor } from '@sukiai/gql/admin'
import { isCharUnsupported } from '@sukiai/utils'
import { setServerError } from '../../actions'
import history from '../../history'
import queryString from 'query-string'
import get from 'lodash.get'

// Use the route pathname to figure out org ID context
export const getOrgIdFromPath = () => window.location.pathname.split('/')[1]

export const getNoteModeFromUrl = () => {
  const mode = queryString.parse(history.location.search).mode
  if (!mode) return NOTE_MODE.DEFAULT
  return mode
}

// This happens if the socket is disconnected for any reason, but
// the client view has the context to re-send state as needed
export const handleReconnection = () => {
  // Currently handling only the `JOIN_NOTE` scenario, since this blocks transcript
  // data flow on disconnection. Eventually, we can add more contextual data reconnections
  // as needed.

  // Check if we are in the note or scribe note views
  const pathname = window.location.pathname

  // eg. /note/8d9205df-db0f-402d-ba6b-16bd3f4e7f44 => [0] = '', [1] = note. [2] = 8d9205df-db0f-402d-ba6b-16bd3f4e7f44
  const route = stripIds(pathname)
  if (route === ROUTES.NOTE || route === ROUTES.SCRIBE_NOTE) {
    const noteId = getIdFromPath(pathname)
    const role = USER_PERSONA.ADMIN
    console.info('Handling reconnection of socket at path: [%s]. Note ID: [%s] & Role [%s]', pathname, noteId, role)
    window.Logger.info(`Handling reconnection of socket at path: [${pathname}]. Note ID: [${noteId}] & Role [${role}]`)
  }
}

export const getPatientName = patientInfo => {
  return patientInfo.firstName + ' ' + patientInfo.lastName
}

export const getGender = patient => {
  if (!patient) return '--'
  return patient.gender && patient.gender[0] + patient.gender.substring(1).toLowerCase()
}

export const getAge = dateOfBirth => {
  if (!dateOfBirth || new Date(dateOfBirth).getTime() === 0) return '--'

  const ageYears = moment().diff(dateOfBirth, 'years')
  const ageMonths = moment().diff(dateOfBirth, 'months')
  return ageYears >= 2 ? ageYears : ageMonths >= 1 ? `${ageMonths} ${ageMonths === 1 ? 'month' : 'months'}` : '--'
}

// Route user to given path if not already on that path (to prevent infinite loops)
export const routeTo = path => {
  if (store.getState().router.location.pathname !== path) {
    switch (path) {
      case ROUTES.ROOT:
        store.dispatch(replace(ROUTES.ROOT))
        break
      default:
        store.dispatch(push(path))
    }
  }
}

// Generates a 64-bit identifier
export const generateSuki64bitID = () =>
  uuid()
    .replace(/-/g, '')
    .substring(16)

// This function gets the `ms-users` service's user ID
// Specific to Suki managed users (which will be all users involved with Suki)
// Even ones who are being authed using SAML from their own orgs
export const getSukiUserID = () => {
  const userId = loSto.get(LO_STO.USER_ID)
  return userId
}

export const getUserName = () => {
  const jwtToken = loSto.get(LO_STO.ID_TOKEN)
  const decoded = jwtDecode(jwtToken)
  return decoded.name
}

// Newest to Oldest
export const timeSortDesc = arr => {
  arr.sort((a, b) => {
    a = new Date(a.visitDate)
    b = new Date(b.visitDate)
    return b > a ? 1 : b < a ? -1 : 0
  })
  return arr
}

// Oldest to Newest
export const timeSortAsc = arr => {
  arr.sort((a, b) => {
    a = new Date(a.visitDate)
    b = new Date(b.visitDate)
    return a > b ? 1 : a < b ? -1 : 0
  })
  return arr
}

// Notifications
export const notifySuccess = (message, title = '') => {
  store.dispatch(
    success({
      title,
      message,
      position: 'bc',
      autoDismiss: 3,
      dismissible: false
    })
  )
}

export const setError = err => {
  const errorMessage = err.message || err
  store.dispatch(setServerError(errorMessage))
}

// Sort an array of objects by key
export const sortItemsByKey = (arr, key) => {
  arr.sort((a, b) => {
    return a[key].toLowerCase() < b[key].toLowerCase() ? -1 : a[key].toLowerCase() > b[key].toLowerCase() ? 1 : 0
  })
  return arr
}

export const getLastFirstName = person => {
  if (person && person.firstName && person.lastName) {
    if (person.middleName) {
      return `${person.lastName}, ${person.firstName} ${person.middleName[0]}.`
    } else {
      return `${person.lastName}, ${person.firstName}`
    }
  } else {
    return ''
  }
}

export const isValidPathname = pathname => {
  // [TODO Mark] - This doesn't work at all.
  // let prefix = '/' + pathname.split('/')[1]
  // return Object.values(ROUTES).indexOf(prefix) > -1
  return true
}

export const getPanelTitle = name => {
  const lastChar = name.substr(name.length - 1, 1)
  return lastChar === 's' ? `${name}' Notes` : `${name}'s Notes`
}

export const logout = () => {
  Auth.logout()
  loSto.clearAll()
  window.location.href = '/'
}

export const initializeUser = async auth => {
  const jwt = await auth.getIdToken()

  const jwtUser = jwtDecode(jwt)
  const userId = get(jwtUser, 'userID')
  const organizationId = get(jwtUser, 'organizationID')

  // Make sure the JWT contains a user ID, org ID, and roles
  if (!jwtUser || !userId || !organizationId || !jwtUser.roles || !jwtUser.email) {
    console.error('User is missing info in the JWT.')
    logout()
    return
  }

  // Make sure the user has the right role
  if (!jwtUser.roles.includes(ROLES.ADMIN)) {
    console.error('User is not authorized for this page.')
    logout()
    return
  }

  loSto.set(LO_STO.ID_TOKEN, jwt)
  loSto.set(LO_STO.USER_ID, userId)
  loSto.set(LO_STO.ORGANIZATION_ID, organizationId)
  loSto.set(LO_STO.CURRENT_USER_EMAIL, jwtUser.email)
  loSto.set(LO_STO.CURRENT_USER_ROLES, jwtUser.roles)
}

export const copyText = text => {
  const input = document.createElement('input')
  input.style.opacity = 0
  document.body.appendChild(input)
  input.value = text
  input.focus()
  input.select()
  document.execCommand('copy')
  notifySuccess('Copied to clipboard!')
  document.body.removeChild(input)
}

// Capitalizes first letter of a string and makes the rest lowercase
export const capitalize = str => str[0].toUpperCase() + str.slice(1).toLowerCase()

export const capitalizeSnakeCase = str => {
  const wordArr = str.split('_')

  const capitalized = []
  wordArr.forEach(function (word, index) {
    this[index] = capitalize(word)
  }, capitalized)

  return capitalized.join(' ')
}

// Sorts script objects by name
export const sortScriptsByName = scripts => {
  scripts.sort((a, b) => {
    const aTag = a.tags.find(t => t.type === SCRIPT_TAG.NAME)
    const bTag = b.tags.find(t => t.type === SCRIPT_TAG.NAME)

    if (!bTag) return -1
    if (!aTag) return 1

    const aName = get(aTag, 'name.value', '').toLowerCase()
    const bName = get(bTag, 'name.value', '').toLowerCase()

    return aName < bName ? -1 : aName > bName ? 1 : 0
  })
}

export const validateHasOnePBNSection = (sections) => {
  // filters through sections and returns a string of names of pbn sections
  // if multiple pbn sections exist
  const pbnSections = sections.filter(section => section.pbcSectionFlag)
  return pbnSections.length > 1 ? pbnSections.map(p => p.name) : null
}

export const isBackDoorWhiteListed = () => {
  const userEmail = loSto.get(LO_STO.CURRENT_USER_EMAIL)
  return BACKDOOR_WHITE_LIST.includes(userEmail)
}

export const showReleaseAllNotes = orgId => {
  return isBackDoorWhiteListed() && orgId === ORG_IDS.SUKI
}

export const releaseAllNotesBackdoor = () => {
  client.mutate({
    mutation: ReleaseAllNotesBackdoor
  }).then(({ data }) => {
    const status = get(data, 'releaseAllNotesBackdoor.status')
    const count = get(data, 'releaseAllNotesBackdoor.count')
    if (status === NOTE_QUEUE_STATUS.RELEASED_ALL_NOTES) {
      notifySuccess(`Successfully released ${count} notes from ops queue`)
    } else {
      setError(`Could not release locked notes: ${status}`)
    }
  }).catch((err) => {
    setError(`Err: Could not release locked notes: ${err}`)
  })
}

export const refreshEMRAppointments = async ({ organizationId, userID, emrType, fromDate, toDate, orgName }) => {
  const fromDateString = moment(fromDate).format('YYYY-MM-DD HH:mm Z')
  const toDateString = moment(toDate).format('YYYY-MM-DD HH:mm Z')
  // Refresh EMR appointments
  return client.mutate({
    mutation: RefreshEMRAppointments,
    variables: {
      organizationId,
      userID,
      emrType,
      fromDate,
      toDate
    }
  }).then(({ data }) => {
    window.Logger.info(
      `Refreshed EMR appointments for ${orgName} and user ${userID} from ${fromDateString} to ${toDateString}.`
    )
    notifySuccess(
      `Refreshed EMR appointments for ${orgName} and user ${userID} from ${fromDateString} to ${toDateString}.`
    )
  }).catch(error => {
    console.error(`Error refreshing appointments for ${orgName} and user ${userID} from ${fromDateString} to ${toDateString}. [Error: ${JSON.stringify(error)}]`)
    window.Logger.error(`Error refreshing appointments for ${orgName} and user ${userID} from ${fromDateString} to ${toDateString}. [Error: ${JSON.stringify(error)}]`)
    setError(error)
  })
}

export const isContentError = (delta) => {
  const plainText = convertSlateToPlainText(delta)
  return plainText.split('').some(char => {
    return isCharUnsupported(char)
  }
  )
}

export const isScribe = () => {
  const userRoles = loSto.get(LO_STO.CURRENT_USER_ROLES)
  return userRoles && userRoles.some(role => role === 'SCRIBE')
}

export const getScribeNoteLink = () => {
  const url = window.location.href
  const scribeNoteURL = url.includes('localhost') ? url.replace('localhost:3005', 'localhost:3004') : url.replace('manage.suki', 'ops.suki')
  return scribeNoteURL
}

export const convertSlateToPlainText = (slateString) => {
  let totalString = ''
  let slate = null
  try {
    slate = JSON.parse(slateString)
  } catch (err) {
    console.error('Unable to parse Slate string in JSON: ', slateString)
  }

  const document = get(slate, 'document')
  const blockNodes = get(document, 'nodes', [])

  blockNodes.forEach((bn, i) => {
    const subnodes = get(bn, 'nodes', [])
    subnodes.forEach(sn => {
      const path = sn.object === 'inline' ? 'nodes[0].leaves' : 'leaves'
      const leaves = get(sn, path, [])
      leaves.forEach(leaf => {
        if (leaf.text === '') return
        totalString += leaf.text
      })
    })
  })
  return totalString
}

export const sortScriptsBySource = scripts => {
  scripts.sort((a, b) => {
    // sort by source firt
    const aSource = a.source === MACRO_SOURCE.USER ? 0 : 1
    const bSource = b.source === MACRO_SOURCE.USER ? 0 : 1

    return aSource - bSource
  })
}

export const disableSharedUserInput = ({ users, selectedUserId }) => {
  // return true if there is a sharedUser in users list
  // AND not selectedUserId
  const sharedUser = users.find(user => user.sharedUser)
  if (!sharedUser) return false
  return selectedUserId && sharedUser?.id !== selectedUserId
}

export const filterNotes = (items, filterVal) => {
  if (!items) return null

  const filterText = filterVal.toUpperCase()

  const getNameToDisplay = item => {
    const lastName = get(item, 'metadata.patient.person.lastName')
    const filterVal = filterText
    let finalName = 'Unknown Patient'
    if (lastName) {
      finalName = getLastFirstName(get(item, 'metadata.patient.person'))
    }
    return finalName.toUpperCase().includes(filterVal)
  }

  return items.filter(i => (
    get(i, 'id', '').toUpperCase().includes(filterText) ||
    get(i, 'compositionId', '').toUpperCase().includes(filterText) ||
      get(i, 'noteId', '').toUpperCase().includes(filterText) ||
      getNameToDisplay(i)
  ))
}

export const isHTMLString = (str) => {
  const regex = /<[a-z][\s\S]*>/i
  return regex.test(str)
}

export const sanitizeHTML = (html) => {
  const regex = /\\n|\\/g
  return html.replace(regex, '')
}
