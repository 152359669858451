import styled from 'styled-components'
import { navBarHeight, sideBarWidth } from '../../styles/dimensions'
import {
  whiteSmoke,
  charcoal,
  platinum,
  scribeGreen
} from '../../styles/colors'

const HEADER_HEIGHT = '70px'

export const Container = styled.div`
  position: fixed;
  top: ${p => (p.withNav ? navBarHeight : 0)};
  left: 0;
  height: ${p => (p.withNav ? `calc(100vh - ${navBarHeight})` : '100vh')};
  width: ${sideBarWidth};
  background-color: ${whiteSmoke};
  color: ${charcoal};
  box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.12);
`

export const Header = styled.div`
  font-size: 20px;
  font-weight: 600;
  height: ${HEADER_HEIGHT};
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${p => p.withButton && 'margin-right: 10px;'}
`

export const AddButton = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  color: ${scribeGreen};
  background-color: ${platinum};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const List = styled.div`
  height: ${`calc(100% - ${HEADER_HEIGHT})`};
  overflow-y: scroll;
`

export const SectionTitle = styled.div`
  font-size: 14px;
  padding: 14px 20px 8px;
  font-weight: 600;
  text-transform: uppercase;
`

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  padding: 12px 20px;
  ${p => p.secondary && 'padding-left: 30px;'}
  cursor: pointer;
  transition: all 0.3s;
  ${p => p.selected && 'background-color: rgba(0,0,0,0.2);'}
  :hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`

export const Empty = styled(Item)`
  cursor: default;
  font-style: italic;
  :hover {
    background-color: transparent;
  }
`

export const Divider = styled.div`
  height: 10px;
`

export const DropDownList = styled.select`
  width: 300px;
  margin: auto;
  text-align: center;
  margin: 8px;
`

export const Source = styled.div`
  font-weight: 600;
`
