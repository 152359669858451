import client from './apollo'
import moment from 'moment'
/** * JS Logger ***/
import Logger from 'js-logger'
import { SendLogToServer } from '@sukiai/gql/common'
import loSto from './config/loSto'
import { LO_STO, USER_PERSONA } from './lib/constants/index'

export default () => {
  Logger.useDefaults()
  // TODO: Right now window.console functions are separate from Logger
  // But, we can wrap console commands with Logger, to make Logger the standard way to log in the future
  Logger.setHandler((messages, context) => {
    client.mutate({
      mutation: SendLogToServer,
      variables: {
        session: loSto && loSto.get(LO_STO.SESSION_TOKEN),
        role: USER_PERSONA.ADMIN,
        user: loSto && loSto.get(LO_STO.USER_ID),
        ua: window.navigator && window.navigator.userAgent,
        message: Object.values(messages),
        level: context.level.name,
        timestamp: moment().toISOString()
      }
    })
  })

  window.Logger = Logger
}
