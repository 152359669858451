import styled from 'styled-components/native'
import reactStyled from 'styled-components'

export const HeaderContainer = styled.Text`
  font-family: 'Open Sans';
`
export const DarkHeaderContainer = styled.Text`
  font-family: 'Open Sans';
  color: #fff;
`

export const Top = styled.View`
  width: 100%;
  position: absolute;
  top: 25;
  left: 0;
`

export const TemplateNameDark = reactStyled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  padding: 0 24px;
  color: #fff;
`

export const TemplateName = reactStyled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`

export const Bottom = styled.Text`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${props => props.isAmwell ? '24px' : '0px'}
`

export const Left = styled.View`
  flex: 3;
  display: flex;
  flex-direction: column;
  z-index: 1;
`

export const LeftWithPadding = styled.View`
  flex: 3;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-left: 16px;
`
export const RightWithPadding = styled.View`
  flex: 2;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-right: 16px;
`

export const Right = styled(Left)`
  flex: 2;
`

export const PatientName = styled.Text.attrs({
  numberOfLines: 1
})`
  font-size: 24;
  font-weight: 600;
`

export const AgeGender = styled.Text`
  font-size: ${props => props.isAmwell ? 12 : 18};
  margin-top: 0;
  display: ${props => props.ispatientnameunknown === 'true'
    ? 'block'
    : 'none'
  };
  padding-bottom: ${props => props.isAmwell ? '8px' : '0px'}
`

export const Container = styled.Text`
  display: inline-block;
  position: relative;
`

export const Meta = styled.Text`
  font-size: 12;
  text-align: right;
`

export const LoadingMeta = styled.Text`
  font-size: 12;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`
export const Label = styled.Text`
  margin-right: 24px;
`

export const LoadingContainer = styled.Text`
  position: absolute;
  right: 5px;
`

export const GenderLoadingContainer = styled.Text`
  margin-left: 24px;
`

export const NoteLockStatus = styled.View`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const NoteLockStatusName = styled.Text`
  font-size: 14px;
  font-weight: 600;
  color: #2D5F62;
  margin-right: 10px;
`

export const ProcessingIcon = styled.Image`
  height: ${props => props.height ? props.height : 30};
  width: ${props => props.width ? props.width : 30};
`
