import React, { Component } from 'react'
import { Animated } from 'react-native'
import { isEqual, get } from 'lodash'
import DropDownComponent from '../DropDown'
import { solidBlack, platinum } from '../../lib/colors'
import {
  SearchVeil,
  Container,
  IconContainer,
  SearchIcon,
  SearchBar,
  Input,
  Empty,
  NoResultTitle,
  NonAnimatedSearchBar,
  AnimatedContainer,
  DropDownContainer
} from './styled'
import { isAmwellTheme } from '../../lib/util'
import { NO_RESULTS_TEXT, PATIENT_INPUT_PLACEHOLDER } from './constants'

// This component holds the search bar and the search results dropdown container
class Search extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      searchBarAnim: new Animated.Value(0),
      isDropDownVisible: props.isDropDownVisible,
      inputVal: props.searchText
    }
    this.isSelectionClicked = false
    this.isAmwellDark = isAmwellTheme()
  }

  componentDidMount () {
    this.setState({ inputVal: this.props.searchText })
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.triggerToggle) {
      this.toggle()
    }

    if (get(nextProps, 'resultsData.length') && !isEqual(nextProps.resultsData, this.props.resultsData)) {
      this.setState({ isDropDownVisible: true })
    }

    if (this.isSelectionClicked) {
      this.setState({ inputVal: nextProps.searchText })
      this.isSelectionClicked = false
    }

    if (this.props.isDropDownVisible !== nextProps.isDropDownVisible) {
      this.setState({ isDropDownVisible: nextProps.isDropDownVisible })
    }

    if (nextProps.searchText && nextProps.searchText !== this.state.inputVal) {
      this.setState({ inputVal: nextProps.searchText })
    }
  }

  toggle = () => {
    const {
      open,
      searchBarAnim
    } = this.state

    if (this.props.isSearchAnimatedWithIcon) {
      if (open) {
        Animated.timing(searchBarAnim, {
          toValue: 0,
          duration: 200
        }).start(() => this.setState({ open: !open, inputVal: '' }))
        this.searchText('')
      } else {
        this.setState({ open: !open, currentHighlightedIndex: null })
        Animated.timing(searchBarAnim, {
          toValue: 1,
          duration: 200
        }).start()
      }
    } else {
      this.setState({ isDropDownVisible: false })
    }
  }

  handleSelectResult = id => {
    const { handleSelection } = this.props
    this.toggle()
    handleSelection(id)
    this.isSelectionClicked = true
  }

  searchText = text => {
    const { search, isSearchAnimatedWithIcon } = this.props
    let searchStr
    isSearchAnimatedWithIcon ? searchStr = text : searchStr = get(text, 'target.value', '')
    this.setState({ inputVal: searchStr })
    search(searchStr)
  }

  renderEmptyListView = () => {
    return (
      <Empty>
        <NoResultTitle data-cy='search-no-results-container'>{`${NO_RESULTS_TEXT}`}</NoResultTitle>
      </Empty>
    )
  }

  renderSearchBox = () => {
    const { isSearchAnimatedWithIcon, isFocused, isDisabled } = this.props
    const { searchBarAnim, open, inputVal } = this.state
    const styles = {
      searchBar: {
        width: searchBarAnim.interpolate({
          inputRange: [0, 1],
          outputRange: ['0%', '100%']
        }),
        opacity: searchBarAnim
      },
      // Need it to be inline styles coz the Styled.TextInput is messing with the scrollbar when the note is loading
      nonAnimatedSearchBar: { // Need it to be inline styles coz the Styled.TextInput is messing with the scrollbar when the note is loading
        autoFocus: this.props.isFocused !== 'true',
        placeholderTextColor: platinum,
        position: 'relative',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        outline: 'none',
        fontSize: !this.isAmwellDark && window.innerWidth > 900 ? 24 : 20,
        fontWeight: 600,
        color: this.isAmwellDark ? '#fff' : solidBlack,
        backgroundColor: 'transparent',
        fontFamily: 'Open Sans',
        border: 'none'
      }
    }

    // There can be 2 options to  be able to render the search box
    // 1. With the search magnifying glass when clicked opens the box
    // 2. Without the magnifying glass, when clicked on something else(from the application side)
    // then opens the search box
    if (isSearchAnimatedWithIcon) {
      return (
        <AnimatedContainer>
          <IconContainer data-cy='search-icon-container' onPress={this.handleToggle}>
            <SearchIcon />
          </IconContainer>

          {open &&
            <SearchBar style={styles.searchBar}>
              <Input
                data-cy='search-bar-input'
                onChangeText={t => this.searchText(t)}
                spellCheck={false}
                value={inputVal}
              />
            </SearchBar>}
        </AnimatedContainer>
      )
    }
    return (
      <NonAnimatedSearchBar>
        <input
          style={styles.nonAnimatedSearchBar}
          data-cy='search-bar-input'
          value={inputVal}
          onChange={t => this.searchText(t)}
          isfocused={isFocused.toString()}
          placeholder={PATIENT_INPUT_PLACEHOLDER}
          type='text'
          disabled={isDisabled}
        />
      </NonAnimatedSearchBar>
    )
  }

  handleToggle = () => {
    this.toggle()
  }

  render () {
    const {
      open,
      isDropDownVisible
    } = this.state

    const { resultsData } = this.props

    return (
      <div data-cy='search-component'>
        {open &&
          <SearchVeil onPress={this.handleToggle} data-cy='search-veil' />}
        <Container>
          {this.renderSearchBox()}
          {resultsData && isDropDownVisible &&
            <DropDownContainer isAmwellDark={this.isAmwellDark}>
              <DropDownComponent
                resultsData={resultsData}
                emptyListViewHandler={this.renderEmptyListView}
                onListItemClickHandler={this.handleSelectResult}
              />
            </DropDownContainer>}

        </Container>
      </div>
    )
  }
}

export default Search
