import React, { Component } from 'react'
import get from 'lodash.get'
import { charcoal, whiteSmoke } from '../../styles/colors'
import { getLastFirstName } from '../../lib/util'
import { List } from 'material-ui/List'
import FontIcon from 'material-ui/FontIcon'
import Loading from '../Loading'
import Radium from 'radium'

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: charcoal,
    boxShadow: '2px 2px 8px 0px rgba(0,0,0,0.5)'
  },
  dropdownHeader: {
    fontSize: '8pt',
    marginTop: '6px'
  },
  expand: {
    marginTop: '2px',
    fontSize: '20pt'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: whiteSmoke,
    padding: '20px 28px',
    boxSizing: 'border-box',
    width: '100%',
    boxShadow: '0px 2px 10px 0px rgba(0,0,0,0.3)',
    zIndex: 2,
    minHeight: 74
  },
  panelMain: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 22
  },
  search: {
    fontSize: 34
  },
  list: {
    backgroundColor: 'white',
    width: '100%',
    height: 'calc(100% - 74px)',
    minHeight: 'calc(100% - 74px)',
    padding: 0,
    overflow: 'auto'
  },
  filterDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  filterBox: {
    padding: '3px 10px',
    marginLeft: 10,
    ':focus': {
      outline: 'none'
    }
  }
}

class ListPanel extends Component {
  state = {
    filterVal: '',
    isExpanded: false
  }

  onFilterInput = e => this.setState({ filterVal: e.target.value })

  getNameToDisplay = item => {
    const lastName = get(item, 'props.note.metadata.patient.person.lastName')
    const filterVal = this.state.filterVal.toUpperCase()
    let finalName = 'Unknown Patient'
    if (lastName) {
      finalName = getLastFirstName(item.props.note.metadata.patient.person)
    }
    return finalName.toUpperCase().includes(filterVal)
  }

  handleHeaderClick = e => {
    if (!this.props.collapsible) return
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  filterNotes = items => {
    if (!items) return null

    const { filterVal } = this.state
    const filterText = filterVal.toUpperCase()

    return items.filter(i => {
      const note = get(i, 'props.note')
      if (note) {
        return (
          get(note, 'compositionId', '').includes(filterText) ||
          get(note, 'noteId', '').includes(filterText) ||
          this.getNameToDisplay(i)
        )
      }
      return false
    })
  }

  render () {
    const { isExpanded } = this.state
    const {
      collapsible,
      title,
      type,
      withSearch,
      listItems,
      loading,
      withDropdownFilter
    } = this.props

    let items = listItems
    if (type === 'NOTES') {
      items = this.filterNotes(listItems)
    }

    return (
      <div style={{ ...styles.container, boxShadow: isExpanded && 'none' }}>
        <div style={styles.header} onClick={this.handleHeaderClick}>
          <div style={styles.panelMain}>
            <div> {title} </div>

            {withSearch &&
              <div style={styles.filterDiv}>
                <FontIcon
                  className='material-icons'
                  color={charcoal}
                  style={styles.search}
                >
                  search
                </FontIcon>
                <input
                  type='text'
                  value={this.state.notesFilter}
                  onChange={e => this.onFilterInput(e)}
                  placeholder='Filter by name or id'
                  style={styles.filterBox}
                  data-cy='sidebar-search-filter'
                />
              </div>}

            {(collapsible && !isExpanded) &&
              <FontIcon
                className='material-icons'
                style={styles.expand}
              >
                expand_more
              </FontIcon>}

          </div>

          {withDropdownFilter && isExpanded &&
            <div style={styles.dropdownHeader}>
              Showing entire notification history
            </div>}
        </div>

        {(!collapsible || isExpanded) &&
          <List style={styles.list}>
            {loading
              ? <Loading />
              : items}
          </List>}

      </div>
    )
  }
}

export default Radium(ListPanel)
