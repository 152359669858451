export const TYPE = {
  PREPOPULATED: 'prepopulated',
  ASR_TRANSCRIPT: 'asr_transcript',
  DOCTOR_TYPED: 'doctor_typed',
  OPS_TYPED: 'ops_typed',
  OPS_TO_PROCESS: 'ops_to_process',
  SCRIPT_CONTENT: 'script_content',
  DEFAULT: 'default',

  // New inline types
  ASR_NVOQ: 'ASR_NVOQ',
  ASR_GOOGLE: 'ASR_GOOGLE',
  ASR_HOMEGROWN: 'ASR_HOMEGROWN',
  TYPED_DOCTOR: 'TYPED_DOCTOR',
  TYPED_SCRIBE: 'TYPED_SCRIBE',
  TYPED_ADMIN: 'TYPED_ADMIN',
  SCRIPT: 'SCRIPT',
  COPY_FORWARD: 'COPY_FORWARD',
  NOTETYPE_PREFILLED: 'NOTETYPE_PREFILLED',
  INTERMEDIATE_TEXT: 'INTERMEDIATE_TEXT',
  SCRIPT_CHANGE: 'SCRIPT_CHANGE',
  NOTE_PULL: 'NOTE_PULL',
  DYNAMIC_VITALS: 'DYNAMIC_VITALS',
  DYNAMIC_VITALS_TEMPLATE: 'DYNAMIC_VITALS_TEMPLATE',
  UNKNOWN: 'UNKNOWN'
}

export const EDITOR_TYPE = {
  SCRIPT: 'SCRIPT',
  NOTETYPE: 'NOTETYPE',
  NOTE: 'NOTE',
  OPS: 'OPS',
  OPS_PROCESSED: 'OPS_PROCESSED',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  VERBATIM: 'VERBATIM',
  DETAILED: 'DETAILED'
}

export const PERSONA = {
  PHYSICIAN: 'PHYSICIAN',
  SCRIBE: 'SCRIBE',
  ADMIN: 'ADMIN'
}

//TODO: commenting new chips as part of release 13.0
export const DYNAMIC_CHIP_MAPPING = {
  'Default': 'DEFAULT',
  'Blood Pressure': 'BLOOD_PRESSURE',
  'Heart Rate': 'HEART_RATE',
  'Temperature': 'TEMPERATURE',
  'Respiratory Rate': 'RESPIRATORY_RATE',
  'Pulse Oximetry': 'PULSE_OXIMETRY',
  'Height': 'HEIGHT',
  'Weight': 'WEIGHT',
  'BMI': 'BMI',
  'Past Medical History': 'PAST_MEDICAL_HISTORY',
  'Past Surgical History': 'PAST_SURGICAL_HISTORY',
  'Allergies': 'ALLERGIES',
  'Current Medications': 'CURRENT_MEDICATION',
  'WBC': 'LEUKOCYTES',
  'RBC': 'ERYTHROCYTES',
  'Hemoglobin': 'HEMOGLOBIN',
  'Hematocrit': 'HEMATOCRIT',
  'Platelets': 'PLATELETS',
  'Glucose': 'GLUCOSE',
  'BUN': 'UREA',
  'Creatinine': 'CREATININE',
  'Sodium': 'SODIUM',
  'Potassium': 'POTASSIUM',
  'Chloride': 'CHLORIDE',
  'CO2': 'CARBON_DIOXIDE',
  'Protein': 'PROTEIN',
  'Albumin': 'ALBUMIN',
  'TotalBilirubin': 'BILIRUBIN_TOTAL',
  'DirectBilirubin': 'BILIRUBIN_DIRECT',
  'ALP': 'ALKALINE_PHOSPHATASE',
  'AST': 'ASPARTATE_AMINOTRANSFERASE',
  'Thyroxine': 'THYROXINE',
  'TSH': 'THYROTROPIN',
  'TotalCholesterol': 'TOTAL_CHOLESTEROL',
  'HDLCholesterol': 'CHOLESTEROL_HDL',
  'LDLCholesterol': 'CHOLESTEROL_LDL',
  'Triglycerides': 'TRIGLYCERIDES',
  'PSA': 'PROSTATE_ANTIGEN',
  'A1C': 'HEMOGLOBIN_A1C',
  'INR': 'INR',
  'ALT': 'ALANINE_AMINOTRANSFERASE',
  'XRAY Narrative': 'XRAY_NARRATIVE',
  'MRI Narrative': 'MRI_NARRATIVE',
  'CT Narrative': 'CT_NARRATIVE',
  'Show Past Medical History': 'PAST_MEDICAL_HISTORY_SHOW_ME',
  'Show Past Surgical History': 'PAST_SURGICAL_HISTORY_SHOW_ME',
  'First Name': 'FIRST_NAME',
  'Last Name': 'LAST_NAME',
  'Gender': 'GENDER',
  'Age': 'AGE',
  'Chief Complaint': 'CHIEF_COMPLAINT'
}