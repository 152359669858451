import React from 'react'
import { Query, withApollo } from 'react-apollo'
import { Multiselect } from 'multiselect-react-dropdown'
import { GetNoteTypeById, GetSections, GetOrganizationById, UpdateNoteType, GetNoteTypes, GetEmrNotetypes } from '@sukiai/gql/admin'
import { Formik, Form as FormikForm, Field, FastField, FieldArray } from 'formik'
import omitDeep from 'omit-deep-lodash'
import get from 'lodash.get'
import { Paper, Form, ContentEditor, Loading, CircleButton, SpecialtiesList } from '../../components'
import NoteTypeOptions from './NoteTypeOptions'
import SectionHeader from './SectionHeader'
import { ContentContainer, AddButton, Empty, Label } from './styled'
import { setError, capitalizeSnakeCase, isContentError, notifySuccess, validateHasOnePBNSection } from '../../lib/util'
import { scribeGreen } from '../../styles/colors'
import CopyToUser from './CopyToUser'
import ActionBar from './ActionBar'
import { EDITOR_TYPE, ORG_IDS, LO_STO, SPECIALTY_TYPE, SPECIALTIES_LIST } from '../../lib/constants'
import { V2_DEFAULT_CONTENT } from '@sukiai/quill-editor'
import styled from 'styled-components'
import SpecialtiesDialog from '../../components/SpecialtiesDialog'
import ContentErrorsDialog from '../../components/ContentErrorsDialog'
import loSto from '../../config/loSto'
import { snakeCapsToEnglish } from '@sukiai/utils'

const SPECIALTIES_ARR = Object.keys(SPECIALTY_TYPE).map(s => ({
  name: snakeCapsToEnglish(s),
  value: s
}))

const SpecialtiesContainer = styled.div`
  display:flex;
  justify-content: flex-end;

  margin-top: -60px;
  margin-right: -20px;
  margin-bottom: 30px;
`

class NoteTypeEditor extends React.Component {
  state = {
    copyDialogOpen: false,
    specialtiesDialogOpen: false,
    contentErrors: false,
    noteTypeSpecialtyFilter: 'NA'
  }

  setSpecialtiesDialog = specialtiesDialogOpen => {
    this.setState({ specialtiesDialogOpen })
  }

  setCopyDialog = copyDialogOpen => {
    this.setState({ copyDialogOpen })
  }

  setContentErrors = contentErrors => {
    this.setState({ contentErrors })
  }

  selectSection = (selectedId, sections, setFieldValue, idx) => {
    const { id, name, hints, pbcSectionFlag } = sections.find(s => s.id === selectedId)
    setFieldValue(`sections.${idx}.id`, id)
    setFieldValue(`sections.${idx}.name`, name)
    setFieldValue(`sections.${idx}.navigationKeywords`, hints)
    setFieldValue(`sections.${idx}.pbcSectionFlag`, pbcSectionFlag)
  }

  validateErrorSections = (sections) => {
    const errorSections = sections.reduce((array, section) => {
      if (isContentError(section.content)) {
        array.push(section)
      }
      return array
    }, [])

    return errorSections.length > 0 ? errorSections : false
  }

  updateNoteType = (values, { setSubmitting, resetForm }) => {
    // errors out if more than 1 pbn section
    const pbnDuplicates = validateHasOnePBNSection(values.sections)
    if (pbnDuplicates) {
      setSubmitting(false)
      setError(`Error: Multiple PBN Sections: ${pbnDuplicates.toString()}`)
      return null
    }

    const { organizationId, userId, id, client } = this.props

    // Need to transform the keywords back into an array
    const keywords = get(values, 'keywords', '')
      .split(',')
      .map(i => i.trim())

    // Need to transform destinations back into a single array
    const destinations = []
    const primary = get(values, 'primaryDestination')
    const secondary = get(values, 'secondaryDestination')

    // make sure sections dont have unsupported chars
    const contentErrors = this.validateErrorSections(values.sections)
    if (contentErrors) {
      this.setState({ contentErrors })
      setSubmitting(false)
      return
    }

    if (!primary) {
      setSubmitting(false)
      resetForm()
      console.error('Error updating note type. Must specify primary destination.')
      setError('Error updating note type. Must specify primary destination.')
      return null
    }

    // make sure content has default content before updating note sections
    if (get(values.sections, 'length') > 0) {
      values.sections = values.sections.map(s => {
        if (!s.content) {
          s.content = V2_DEFAULT_CONTENT
        }
        return s
      })
    }

    destinations.push(primary)
    if (secondary) destinations.push(secondary)
    const noteType = { ...values, keywords, destinations, source: 'ADMIN' }
    delete noteType.primaryDestination
    delete noteType.secondaryDestination

    const { noteTypes: oldNoteTypeRes } = client.readQuery({
      query: GetNoteTypeById,
      variables: { organizationId, id }
    })

    const optimisticResponse = {
      __typename: 'Mutation',
      updateNoteType: {
        __typename: 'UpdateNoteTypePayload',
        noteType: {
          __typename: 'NoteType',
          ...noteType
        },
        isOptimistic: true
      }
    }

    const emrNoteTypeCopy = get(
      optimisticResponse,
      'updateNoteType.noteType.emrNoteType')

    if (emrNoteTypeCopy) {
      optimisticResponse.updateNoteType.noteType.emrNoteType = { ...emrNoteTypeCopy, __typename: 'EmrNoteType' }
    }

    optimisticResponse.updateNoteType.noteType.sections = get(
      optimisticResponse,
      'updateNoteType.noteType.sections',
      []
    ).map(({ id, ...s }) => ({ sectionId: id, ...s, __typename: 'CompositionSection' }))
    client
      .mutate({
        mutation: UpdateNoteType,
        variables: { organizationId, userId, noteType },
        optimisticResponse,
        update: (cache, data) => this.onUpdate(cache, data),
        refetchQueries: data => this.getRefetchQueries(data, oldNoteTypeRes)
      })
      .then(res => {
        setSubmitting(false)
        notifySuccess('Updated note type')
        console.info(`Successfully updated note type ${get(res, 'data.updateNoteType.noteType.id')}`)
      })
      .catch(err => {
        setSubmitting(false)
        // This is a temporary workaround for what appears to be an Apollo
        // <Query> bug. This particular error can be safely ignored, as the
        // actual mutation and update is working as expected despite it.
        if (!get(err, 'message', '').includes('Cannot read property \'length\' of undefined')) {
          resetForm()
          console.error(`Error updating note type: ${err}`)
          setError(`Could not update note type: ${err}`)
        }
      })
  }

  getRefetchQueries = (
    {
      data: {
        updateNoteType: { noteType: updatedNoteType }
      }
    },
    oldNoteTypeRes
  ) => {
    const { organizationId, userId } = this.props
    const oldNoteType = get(oldNoteTypeRes, 'results[0]')

    // We refetch if the name changed since it could
    // change which note types the user has access to
    if (oldNoteType.name !== updatedNoteType.name) {
      return [
        {
          query: GetNoteTypes,
          variables: { organizationId, userId }
        }
      ]
    }
  }

  onUpdate = (
    cache,
    {
      data: {
        updateNoteType: { noteType: updatedNoteType }
      }
    }
  ) => {
    const { organizationId, id } = this.props
    // const oldNoteType = get(oldNoteTypeRes, 'results[0]')

    // Update the cache with the updated version of this note type
    cache.writeQuery({
      query: GetNoteTypeById,
      variables: { organizationId, id },
      data: {
        noteTypes: {
          __typename: 'QueryNoteTypesPayload',
          count: 1,
          results: [updatedNoteType]
        }
      }
    })

    // TODO (zingerj): Commenting this out for now because it is
    // causing the UI to jump around, will need to look into it more
    // If the name changed, update the cache with the new name
    // This allows the sidebar to instantly update as well
    // We do this only on the optimistic response and ignore the server
    // Any discrepancies will be sorted out by refetchQueries
    // if (isOptimistic && oldNoteType.name !== updatedNoteType.name) {
    //   const oldResults = get(oldAllNoteTypesRes, 'results', [])
    //   const newResults = [...oldResults]

    //   // If there's an existing org note type with the same
    //   // name as the updated name, remove it from the cache
    //   const nameCollisionIdx = oldResults.findIndex(nt => !nt.userId && nt.name === updatedNoteType.name)
    //   if (nameCollisionIdx > -1) {
    //     newResults.splice(nameCollisionIdx, 1)
    //   }

    //   // Update name of note type in sidebar
    //   const idx = newResults.findIndex(nt => nt.id === updatedNoteType.id)
    //   if (idx > -1) newResults[idx].name = updatedNoteType.name

    //   // Re-sort the note types by name
    //   sortItemsByKey(newResults, 'name')

    //   // Update the cache with the new list of note types
    //   cache.writeQuery({
    //     query: GetNoteTypes,
    //     variables: { organizationId, userId },
    //     data: {
    //       noteTypes: {
    //         __typename: 'QueryNoteTypesPayload',
    //         count: get(newResults, 'length', 0),
    //         results: newResults,
    //       },
    //     },
    //   })
    // }
  }

  getInitialValues = noteType => {
    const initialValues = omitDeep(noteType, '__typename')

    // Need to transform the keywords array to a string
    initialValues.keywords = get(initialValues, 'keywords', []).join(', ')

    // Extract first and second destination into separate fields
    const destinations = get(noteType, 'destinations')
    if (destinations) {
      const [primaryDestination, secondaryDestination] = destinations
      initialValues.primaryDestination = primaryDestination
      initialValues.secondaryDestination = secondaryDestination
      delete initialValues.destinations
    }

    // Change `sectionId` on each section to `id`
    // We use `sectionId` when fetching the data to avoid
    // normalizing each section individually in the cache
    initialValues.sections =
      get(initialValues, 'sections', []).map(({ sectionId, ...s }) =>
        ({ id: sectionId, ...s }))

    return initialValues
  }

  renderEmrNotetypeSpecialityFilter = ({ setFieldValue }) => {
    return (
      <>
        <div style={{ marginBottom: '8px' }}>
          <Label width='150px'>Speciality Filter</Label>{' '}
          <select
            onChange={e => {
              setFieldValue('emrNoteType', null)
              this.setState({ noteTypeSpecialtyFilter: e.target.value })
            }}
          >
            {SPECIALTIES_ARR.map(s => (
              <option key={s.value} value={s.value}>
                {s.name}
              </option>
            ))}
          </select>
        </div>
      </>
    )
  }

  renderEmrNotetypesSelect = (
    organizationId,
    setFieldValue,
    values
  ) => {
    const { noteTypeSpecialtyFilter } = this.state

    if (!organizationId) return null
    const currentEmrValue = get(values, 'emrNoteType.id')
    return (
      <>
        {this.renderEmrNotetypeSpecialityFilter({ setFieldValue })}
        <Query
          query={GetEmrNotetypes}
          variables={{
            organizationId,
            specialties: noteTypeSpecialtyFilter ? [noteTypeSpecialtyFilter] : []
          }}
          skip={!organizationId}
        >
          {({
            loading: emrNoteTypeLoading,
            error: emrNoteTypeError,
            data: emrNoteTypeData
          }) => {
            const form = fieldRender => (
              <Form.Section flex>
                <Form.Section flex margin='8px'>
                  <Label width='200px'>Note Category</Label> {fieldRender}
                </Form.Section>
              </Form.Section>
            )

            let fieldRender = null

            if (emrNoteTypeLoading) {
              fieldRender = (
                <Field
                  name='emrNoteType'
                  component={Form.Select}
                  options={<option>Loading...</option>}
                />
              )
              return form(fieldRender)
            }

            if (emrNoteTypeError) {
              return null
            }

            // TODO: need to show the default notetype for this org as default mapping
            const emrNoteTypeOptions = get(
              emrNoteTypeData,
              'emrNoteTypes.emrNoteTypes',
              []
            )
            const defaultOption = (
              <option key='default' value=''>
                No Mapping
              </option>
            )
            const options = emrNoteTypeOptions
              .map(n => (
                <option key={n.id} value={n.id}>
                  {n.name}
                </option>
              ))
              .sort()

            const selectOptions = [defaultOption, ...options]

            fieldRender = (
              <Field
                name='emrNoteType'
                component={Form.Select}
                options={selectOptions}
                value={currentEmrValue}
                enableReinitialize
                onChange={e => {
                  const id = e.target.value
                  const emrNotetype = emrNoteTypeOptions.find(o => o.id === id)
                  const updatedEmrNoteType = omitDeep(emrNotetype, '__typename')
                  setFieldValue('emrNoteType', updatedEmrNoteType)
                }}
              />
            )

            return form(fieldRender)
          }}
        </Query>
      </>
    )
  }

  render () {
    const { contentErrors, copyDialogOpen, specialtiesDialogOpen } = this.state
    const { organizationId, userId, id, readOnly, setSelected, filteredSpecialty, isOnboardingDefault } = this.props
    const noteTypeVariables = { organizationId, id }
    const sectionsVariables = { organizationId, withMapping: true }
    const orgVariables = { id: organizationId, withEmrInfo: true }
    const showSpecialties = organizationId === ORG_IDS.SUKI_ONBOARDING_DEFAULTS && !userId
    return (
      <Paper>
        <Query query={GetNoteTypeById} variables={noteTypeVariables} skip={!id}>
          {({ loading: noteTypeLoading, error: noteTypeError, data: noteTypeData }) => (
            <Query query={GetSections} variables={sectionsVariables} skip={!organizationId}>
              {({ loading: sectionsLoading, error: sectionsError, data: sectionsData }) => (
                <Query query={GetOrganizationById} variables={orgVariables} skip={!organizationId}>
                  {({ loading: orgLoading, error: orgError, data: orgData }) => {
                    if (noteTypeLoading || sectionsLoading || orgLoading) {
                      return <Loading />
                    }

                    if (noteTypeError) {
                      return <Empty>Whoops, couldn't find that note type: {noteTypeError}</Empty>
                    }

                    if (sectionsError) {
                      return <Empty>Whoops, couldn't find available sections: {sectionsError}</Empty>
                    }

                    if (orgError) {
                      return <Empty>Whoops, couldn't find that organization: {orgError}</Empty>
                    }

                    const noteType = get(noteTypeData, 'noteTypes.results[0]')
                    if (!noteType) return null

                    const initialValues = this.getInitialValues(noteType)
                    const sections = get(sectionsData, 'sections.results', [])

                    // Default to first available section when adding a new one
                    const newSection = {
                      id: get(sections, '[0].id', ''),
                      name: get(sections, '[0].name', ''),
                      navigationKeywords: get(sections, '[0].hints', []),
                      pbcSectionFlag: get(sections, '[0].pbcSectionFlag', false)
                    }

                    const organization = get(orgData, 'organizations.results[0]')
                    const hasEMR = !!get(organization, 'emr.id')
                    // Lets let SUKI_ONLY always be a possible destination due to the fact that
                    // some users such as Dr.Kasow sometimes wants to not submit to EMR.
                    let primaryDestinationsList = []
                    if (hasEMR) {
                      primaryDestinationsList = get(organization, 'emr.capabilities.destinations', [])
                    } else {
                      primaryDestinationsList = ['SUKI_ONLY']
                    }
                    const secondaryDestinationsList = primaryDestinationsList.filter(destination =>
                      destination !== 'SUKI_ONLY' && destination !== 'ENCOUNTER_NEEDS_SIGNOFF' && destination !== 'DOCUMENT_NEEDS_SIGNOFF'
                    )
                    const possiblePrimaryDestinations = primaryDestinationsList.map(d => (
                      <option key={d} value={d}>
                        {capitalizeSnakeCase(d)}
                      </option>
                    ))
                    const possibleSecondaryDestinations = secondaryDestinationsList.map(d => (
                      <option key={d} value={d}>
                        {capitalizeSnakeCase(d)}
                      </option>
                    ))

                    const renderEmrNotetypes = organization?.emr?.secondaryType === 'CERNER_EMR' || organization?.emr?.secondaryType === 'EPIC_EMR' || organization?.emr?.secondaryType === 'ELATION_EMR'

                    // allow dynamic chips for e2e test org
                    const enableChips = loSto.get(LO_STO.CURRENT_ORG)?.emr?.id || organizationId === '22222222-2222-2222-2222-222222222222'

                    return (
                      <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={!readOnly ? this.updateNoteType : () => {}}
                      >
                        {({ values, setFieldValue, handleSubmit, dirty, isSubmitting }) => (
                          <>
                            <FormikForm data-cy='nt-editor' data-read-only={!!readOnly} key={id}>

                              {showSpecialties && (
                                <>
                                  <SpecialtiesContainer>
                                    <SpecialtiesList list={values.specialties} handleClick={() => this.setSpecialtiesDialog(true)} />
                                  </SpecialtiesContainer>

                                  <SpecialtiesDialog
                                    open={specialtiesDialogOpen}
                                    closeDialog={() => this.setSpecialtiesDialog(false)}
                                    setSpecialties={(list) => setFieldValue('specialties', list)}
                                    selected={values.specialties}
                                  />
                                </>
                              )}

                              {
                                <NoteTypeOptions
                                  primaryDestinations={possiblePrimaryDestinations}
                                  secondaryDestinations={possibleSecondaryDestinations}
                                  readOnly={readOnly}
                                  setFieldValue={setFieldValue}
                                  selectedPrimary={values.primaryDestination}
                                />
                              }

                              {renderEmrNotetypes &&
                               this.renderEmrNotetypesSelect(organization?.id, setFieldValue, values)}
                              <Form.Section flex direction='column'>
                                <FastField
                                  data-cy='nt-name'
                                  name='name'
                                  placeholder='Note Type Name'
                                  component={Form.Input}
                                  isTitle
                                  disabled={readOnly}
                                />
                                <FastField
                                  data-cy='nt-keywords'
                                  name='keywords'
                                  placeholder='Keywords'
                                  component={Form.Input}
                                  margin='0px'
                                  disabled={readOnly}
                                />
                              </Form.Section>
                              <Form.Section flex direction='column'>
                                <FieldArray
                                  name='specialties'
                                  render={({ form }) => (
                                    <Multiselect
                                      name='specialties'
                                      placeholder='Add Specialities'
                                      options={SPECIALTIES_LIST}
                                      isObject={false}
                                      selectedValues={values?.specialties}
                                      onSelect={(list) => form.setFieldValue('specialties', list)}
                                      onRemove={(list) => form.setFieldValue('specialties', list)}
                                    />
                                  )}
                                />
                              </Form.Section>
                              <FieldArray name='sections'>
                                {({ remove, swap, push }) => (
                                  <>
                                    {get(values, 'sections', []).map((s, idx, secs) => {
                                      const matchingSection = sections.find(sec => sec.id === s.id)
                                      const emrMapping = get(matchingSection, 'emrMapping')
                                      const mappingString = get(emrMapping, 'name') || get(emrMapping, 'id') || (
                                        <b>unmapped</b>
                                      )

                                      return (
                                        <Form.Section data-cy='nt-section' key={`${s.id}-${idx}`} id={s.id}>
                                          <Field
                                            data-cy='nt-section-header'
                                            id={s.id}
                                            name={`sections.${idx}.id`}
                                            component={SectionHeader}
                                            sections={sections}
                                            mapping={hasEMR && !renderEmrNotetypes && mappingString}
                                            removeSection={() => remove(idx)}
                                            upDisabled={idx === 0}
                                            downDisabled={idx === secs.length - 1}
                                            moveSection={dir => swap(idx, idx + dir)}
                                            onChange={e =>
                                              this.selectSection(e.target.value, sections, setFieldValue, idx)}
                                            readOnly={readOnly}
                                          />
                                          <Field
                                            data-cy='nt-section-content'
                                            id={s.id}
                                            name={`sections.${idx}.content`}
                                          >
                                            {field => (
                                              <ContentContainer>
                                                <ContentEditor
                                                  {...field}
                                                  readOnly={readOnly}
                                                  editorType={EDITOR_TYPE.NOTETYPE}
                                                  enableChips={enableChips}
                                                />
                                              </ContentContainer>
                                            )}
                                          </Field>
                                        </Form.Section>
                                      )
                                    })}
                                    {!readOnly && (
                                      <AddButton data-cy='nt-add-section' onClick={() => push(newSection)}>
                                        <CircleButton color={scribeGreen}>+</CircleButton>
                                        Add section
                                      </AddButton>
                                    )}
                                  </>
                                )}
                              </FieldArray>
                            </FormikForm>
                            <ActionBar
                              organizationId={organizationId}
                              userId={userId}
                              noteTypeId={id}
                              setSelected={setSelected}
                              openSpecialtiesDialog={() => this.setSpecialtiesDialog(true)}
                              openCopyDialog={() => this.setCopyDialog(true)}
                              readOnly={readOnly}
                              dirty={dirty}
                              saveNoteType={handleSubmit}
                              isSubmitting={isSubmitting}
                              showSpecialties={showSpecialties}
                              filteredSpecialty={filteredSpecialty}
                              isOnboardingDefault={isOnboardingDefault}
                            />
                            <CopyToUser
                              open={copyDialogOpen}
                              organizationId={organizationId}
                              userId={userId}
                              noteTypeId={id}
                              closeDialog={() => this.setCopyDialog(false)}
                            />

                            {!!contentErrors &&
                              <ContentErrorsDialog
                                open={!!contentErrors}
                                contentErrors={contentErrors}
                                closeDialog={() => this.setContentErrors(false)}
                              />}

                          </>
                        )}
                      </Formik>
                    )
                  }}
                </Query>
              )}
            </Query>
          )}
        </Query>
      </Paper>
    )
  }
}

export default withApollo(NoteTypeEditor)
