import styled from 'styled-components'

export const Container = styled.div`
  background-color: white;
  width: 100%;
  max-width: 900px;
  min-width: 600px;
  height: fit-content;
  min-height: 100vh;
  padding: 50px;
  box-sizing: border-box;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
`
