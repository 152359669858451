import reactStyled, { css } from 'styled-components'
import styled from 'styled-components/native'
import { Animated } from 'react-native'
import { charcoal, slateGrey } from '../../lib/colors'
import mic from '../../assets/images/mic.png'
import keyboard from '../../assets/icons/keyboard.svg'

export const DictateButton = reactStyled.img.attrs({
  src: mic
})`
  position: absolute;
  right: 0px;
  width: 15px;
  height: 20px;
  opacity: ${props => props.isDictationMode ? 1 : 0};

  &:hover {
    cursor: pointer;
  }
`

export const Wrapper = reactStyled.div`
  padding: 16px 0;
  border-radius: ${props => props.isFocused ? '4px' : 'inherit'};
  ${props => props.isAmwellTheme && css`
    background: ${props => props.isActive || props.isFocused ? '#232a30' : 'inherit'};
    margin-bottom: 8px;
    border-left: ${props => props.isActive ? props.isDictationMode ? '4px solid #46aff2' : '4px solid #4f5559' : 'none'};
    border-radius: ${props => props.isActive || props.isFocused ? '4px' : 'inherit'};
    padding: 8px 0px;
  `}
`

export const KeyboardIcon = reactStyled.img.attrs({
  src: keyboard
})`
position: absolute;
right: 70px;
width: 48px;
opacity: 0.5;

&:hover {
  cursor: pointer;
}
`

export const SectionContainer = reactStyled.div`
  margin: ${props => props.isAmwellDark ? 'none' : '0px -80px'};
  position: relative;
  padding: ${props => props.isAmwellDark ? props.isActive ? '0px 12px' : '0px 16px' : '0px 80px'};
  &:hover ${DictateButton} {
    opacity: 1;
  }
  ${props => props.isAmwellDark && css`
    caret-color: ${props => props.isActive ? props.isDictationMode ? '#46aff2' : '#bec0c1' : 'inherit'};
  `}
`

export const SectionName = reactStyled.div`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 8px;
`

export const SectionContent = reactStyled.div`
  font-family: 'Open Sans';
  font-size: 18px;
  min-height: 24px;
`

export const Blurred = reactStyled.div`
  filter: blur(4px);
`

export const Macro = reactStyled.div`
  color: #707070;
`

export const ScribeToProcess = reactStyled.div`
  font-weight: 600;
  font-style: italic;
`

export const Paragraph = reactStyled.div`
  display: block;
  margin-top: 26px;
`

export const SectionIndicator = reactStyled.div`
  position: absolute;
  left: 0;
  height: 100%;
  width: 12px;
  background-color: ${charcoal};
`

export const CapturingIndicator = styled(Animated.View)`
  position: absolute;
  right: ${props => props.isAmwellTheme ? '16' : '80'};
  height: 27px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CapturingIndicatorDot = styled(Animated.View)`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${slateGrey};
  margin: 0px 6px;
  ${props => props.isAmwellTheme && css`
    background-color: #46aff2;
    height: 6px;
    width: 6px;
    margin: 0px 2px;
  `}
`
