import React, { Component } from 'react'
import { whiteSmoke, platinum, charcoal } from '../../styles/colors'
import Radium from 'radium'
import { compose } from 'react-apollo'

const styles = {
  container: {
    width: '100%',
    marginBottom: 20,
    backgroundColor: platinum,
    boxShadow: '2px 2px 8px rgba(0,0,0,0.5)',
    padding: '20px 30px',
    boxSizing: 'border-box'
  },
  linkEMRButton: {
    width: '100%',
    height: 60,
    backgroundColor: charcoal,
    boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
    cursor: 'pointer',
    color: whiteSmoke,
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.2s',
    ':active': {
      transform: 'scale(0.98)'
    },
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    boxSizing: 'border-box'
  }
}

class LinkEMRPanel extends Component {
  state = {
    loading: false
  }

  link = () => {
    this.setState({ loading: true })
  }

  render () {
    const { onClick } = this.props
    return (
      <div style={styles.container}>
        <div key='linkToEmr' style={styles.linkEMRButton} onClick={onClick}>
          {this.state.loading ? <i>Linking...</i> : 'Link to EMR'}
        </div>
      </div>
    )
  }
}

export default compose(
  Radium
)(LinkEMRPanel)
