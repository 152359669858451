/*
 * Collection of configuration which would be impractical to retrieve from the server, and should not contain
 * any sensitive information.
 */
import { ENVIRONMENTS } from './lib/constants'
import history from './history'

const isEnvironmentDev = () => window.location.origin.match(/dev/i)
const isEnvironmentTest = () => window.location.origin.match(/test/i)
const isEnvironmentStage = () => window.location.origin.match(/stage/i)
const isEnvironmentProd = () => window.location.origin.match(/suki\.ai/i)

let environment
let oktaClientId
let launchDarklyClientId

const PROD_OKTA_URL = 'https://suki-api.okta.com'
const PROD_OKTA_OAUTH = 'https://suki-api.okta.com/oauth2/default'

const SANDBOX_OKTA_URL = 'https://suki-sandbox.oktapreview.com'
const SANDBOX_OKTA_OAUTH = 'https://suki-sandbox.oktapreview.com/oauth2/default'

if (isEnvironmentDev()) {
  environment = ENVIRONMENTS.DEV
} else if (isEnvironmentTest()) {
  environment = ENVIRONMENTS.TEST
} else if (isEnvironmentStage()) {
  environment = ENVIRONMENTS.STAGE
} else if (isEnvironmentProd()) {
  environment = ENVIRONMENTS.PROD
} else {
  environment = ENVIRONMENTS.LOCAL
}

switch (environment) {
  case ENVIRONMENTS.TEST:
    oktaClientId = '0oanr3o0oCl1Pt10N296'
    launchDarklyClientId = '5c894bb1b2fd181de341133c'
    break
  case ENVIRONMENTS.STAGE:
    oktaClientId = '0oao631r02pkumbg9296'
    launchDarklyClientId = '5c8b67597c606f07a079aa45'
    break
  case ENVIRONMENTS.PROD:
    oktaClientId = '0oao6vviukKraQDa1296'
    launchDarklyClientId = '5c894bb1b2fd181de341133d'
    break
  case ENVIRONMENTS.DEV:
  case ENVIRONMENTS.LOCAL:
  default:
    oktaClientId = '0oanh5ksoyJoJT03o0h7'
    launchDarklyClientId = '5c8b6780ea9a91079dbb40fc'
    break
}

// The launch darkly client id allows us to communicate with our LD admin account. There is
// a different ID for each environment
export const LDClientId = launchDarklyClientId

export const OktaConfig = {
  // the auth config data used to initialize the okta Auth object
  auth: {
    history: history,
    issuer:
      isEnvironmentProd() ||
      isEnvironmentStage() ||
      isEnvironmentTest() ? PROD_OKTA_OAUTH : SANDBOX_OKTA_OAUTH,
    redirect_uri: window.location.origin + '/implicit/callback',
    client_id: oktaClientId,
    pkce: false,
    onAuthRequired: () => {
      const path = history.location.pathname
      // if path not in public routes list
      if (path !== '/implicit/callback') {
        history.push('/')
      }
    }
  },
  baseURL:
    isEnvironmentProd() ||
    isEnvironmentStage() ||
    isEnvironmentTest() ? PROD_OKTA_URL : SANDBOX_OKTA_URL
}

export const BASE_URL = window.location.origin.includes('manage') ? window.location.origin.replace('manage', 'api') : window.location.origin

export const CONFIG = {
  auth: {
    oktaClientId
  },
  environment,
  graphql: {
    // TODO: web will mostly become api soon
    uri: `${BASE_URL}/graphql`
  },
  // TODO: Get a more permanent MSFT key. The one below is just a free trial key.
  msftKey: 'ec0eb324082643b6a35b5aa6568fbe39'
}
