import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as notifications } from 'react-notification-system-redux'
import { USER_LOGOUT } from '../actions/user'
import admin from './admin'
import general from './general'
import note from './note'
import user from './user'

const appReducer = combineReducers({
  router: routerReducer,
  admin,
  form: formReducer,
  general,
  note,
  notifications,
  user
})

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
