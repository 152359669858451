import React from 'react'
import { Field } from 'formik'
import { HalfContainer, Label } from './styled'
import { Form } from '../../components'

const onPrimaryChange = (selectedId, setFieldValue) => {
  setFieldValue('primaryDestination', selectedId)
  if (selectedId === 'SUKI_ONLY' || selectedId === 'ENCOUNTER_NEEDS_SIGNOFF' || selectedId === 'DOCUMENT_NEEDS_SIGNOFF') {
    setFieldValue('secondaryDestination', '')
  }
}

const onSecondaryChange = (selectedId, setFieldValue, selectedPrimary) => {
  if (selectedPrimary === 'SUKI_ONLY' || selectedPrimary === 'ENCOUNTER_NEEDS_SIGNOFF' || selectedPrimary === 'DOCUMENT_NEEDS_SIGNOFF') {
    setFieldValue('secondaryDestination', '')
  } else {
    setFieldValue('secondaryDestination', selectedId)
  }
}

const Selector = ({ name, label, options, ...props }) => (
  <Form.Section flex margin='8px'>
    <Label>{label}</Label>
    <Field name={name} component={Form.Select} options={options} {...props} />
  </Form.Section>
)

export default ({ primaryDestinations, secondaryDestinations, readOnly, setFieldValue, selectedPrimary }) => {
  return (
    <Form.Section flex>
      <HalfContainer>
        <Selector
          name='primaryDestination'
          label='Primary'
          options={primaryDestinations}
          disabled={readOnly}
          onChange={e =>
            onPrimaryChange(e.target.value, setFieldValue)}
        />
        {
          primaryDestinations.length === 4 && selectedPrimary !== 'SUKI_ONLY' && selectedPrimary !== 'ENCOUNTER_NEEDS_SIGNOFF' && selectedPrimary !== 'DOCUMENT_NEEDS_SIGNOFF' &&
            <Selector
              name='secondaryDestination'
              label='Secondary' optional
              options={secondaryDestinations}
              disabled={readOnly}
              onChange={e =>
                onSecondaryChange(e.target.value, setFieldValue, selectedPrimary)}
            />
        }
      </HalfContainer>
    </Form.Section>
  )
}
