import styled from 'styled-components/native'
import reactStyled, { css } from 'styled-components'

import { Animated } from 'react-native'
import { snowDrift, platinum, charcoal, solidWhite } from '../../lib/colors'
import { Z_INDEX } from '../../lib/constants'

export const ResultsContainer = styled(Animated.View)`
  border-radius: 2px;
  background-color: ${snowDrift};
  z-index: ${Z_INDEX.HAMBURGER};
  transition-duration: 0.2s;
  transition-property: height;
  width: 100%;
  height: 100%;
  top: ${props => props.isAmwellTheme ? '0px' : '1px'};
`

export const ResultOuterWrapper = styled.View`
  width: 100%;
`

export const ResultWrapper = reactStyled.div`
`

export const SearchResult = styled.Text`
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  color: ${charcoal};
  font-size: 14px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Open Sans';
  border: ${props => props.isselected === 'true'
    ? '1px solid rgb(94,121,142)'
    : '0px solid rgb(255, 255, 255)'
  };
  background-color: ${props => props.iscurrentlyhovered === 'true'
    ? 'rgba(94,121,142, 0.2)'
    : 'rgb(255, 255, 255)'
  };

  ${props => props.isAmwellTheme && css`
    border: none;
    padding: 0px;
    color: ${solidWhite};
    border-radius: 0px;
    background-color: #232a30;
  `}
`

export const Divider = styled.View`
  height: 1px;
  width: 100%;
  border: 1px solid ${platinum};
  ${props => props.isAmwellTheme && css`
    background: #313a42;
    height: 0px;
    border: none;
  `}
`
