import React from 'react'
import styled from 'styled-components'
import Editor from '@sukiai/quill-editor'
import { Powerbar } from './styled'
import { EDITOR_TYPE } from '../../lib/constants'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const EditorBox = styled.div`
  background: white;
  margin-bottom: 5px;

`

const EditorPreview = ({ content, persona }) => (
  <Container>
    <EditorBox>
      <Powerbar>
        <h2>Plain Quill View</h2>
      </Powerbar>
      <Editor
        value={content}
        readOnly
        persona={persona}
        editorType={EDITOR_TYPE.NOTE}
        s2Mode
      />
    </EditorBox>
  </Container>
)

export default EditorPreview
