import { Button, Radio } from 'antd'
import _includes from 'lodash/includes'
import React, { useState } from 'react'
import { LeftOutlined } from '@ant-design/icons'

import './featureFlagDetails.css'
import UsersTable from '../usersTable'
import { RADIO_OPTIONS } from './constants'
import OrganizationTable from '../organizationTable'

function FeatureFlagDetails ({
  featureFlagDetails,
  onGoBack,
  isLoading,
  onSave,
  writeAccessEnabled
}) {
  const { key, value, title, description } = featureFlagDetails
  const [selectedRadioOption, setSelectedRadioOption] =
    useState('Organizations')

  const { sukiOrganizationIds, sukiUserIds } = value?.access || {}

  const handleRadioChange = (e) => {
    const selectedTabKey = e?.target?.value
    setSelectedRadioOption(selectedTabKey)
  }

  const handleUpdateUserAccess = (selectedUsers) => {
    onSave({
      key: key,
      sukiUserIds: selectedUsers,
      sukiOrganizationIds: sukiOrganizationIds
    })
  }

  const handleUpdateOrganizationAccess = (selectedOrganizations) => {
    onSave({
      key: key,
      sukiUserIds: sukiUserIds,
      sukiOrganizationIds: selectedOrganizations
    })
  }

  return (
    <>
      <h2>
        <strong>Feature flags</strong>
      </h2>
      <div className='contentffd'>
        <div className='breadcrumb-header'>
          <Button
            type='link'
            icon={<LeftOutlined />}
            onClick={onGoBack}
            className='headerButton'
          >
            Go back
          </Button>
        </div>

        <div>
          <h2>{title || key}</h2>
          <p className='ff-description'>{description}</p>
        </div>
        <Radio.Group
          options={RADIO_OPTIONS}
          onChange={handleRadioChange}
          value={selectedRadioOption}
          optionType='button'
          buttonStyle='solid'
        />
        <div className='tablecontent'>
          {selectedRadioOption === 'Organizations' ? (
            <OrganizationTable
              selectedOrganizationIds={sukiOrganizationIds}
              isLoading={isLoading}
              isEditable={writeAccessEnabled}
              onSave={handleUpdateOrganizationAccess}
            />
          ) : (
            <UsersTable
              selectedUserIds={sukiUserIds}
              isEditable={writeAccessEnabled}
              onSave={handleUpdateUserAccess}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default FeatureFlagDetails
