import styled from 'styled-components'
import { scribeGreen, charcoal, westSide } from '../../lib/colors'
import { RESPONSIVE } from '../../lib/constants'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: ${RESPONSIVE.MODAL_FULL_WIDTH};
  max-width: ${RESPONSIVE.MODAL_LARGE_WIDTH};
  box-sizing: border-box;
  min-height: 400px;
  max-height: 800px;
  padding: 40px 30px 40px 30px;
  background-color: #ECECEC;
`

export const Title = styled.div`
  font-size: 18pt;
  font-weight: 600;
  font-family: 'Open Sans';
  color: ${charcoal};
`

export const Desc = styled.div`
  font-size: 16pt;
  font-family: 'Open Sans';
  line-height: 170%;
  color: ${charcoal};
  font-weight: 400;
  margin: 30px 0px;
`

export const Problem = styled.div`
  margin: 100%;
  margin: ${props => props.first ? '0px 20px 30px 20px' : '30px 20px'};
  padding: 20px 30px;
  background-color: white;
  border-radius: 9px;
  font-size: 14pt;

  border: ${props => props.isDupe ? `2px solid ${westSide}` : '0'};

  &:hover {
    cursor: pointer
  }
`

export const ProblemTitle = styled.span`
  font-weight: 600;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const DescWrapper = styled.div`
  margin: 5px 0px 0px 0px;
`

export const ProblemDesc = styled.span`

`

export const ProblemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const Code = styled.span`
  display: inline-flex;

  border-radius: 5px;
  border: 0;

  font-size: 0.8em;
  padding: 2px 5px;
  margin: 0px 5px 0px 10px;

  justify-content: center;
  align-items: center;
  
  background-color: rgba(0, 0, 0, .075);
  color: #406d70;
  font-weight: 600;

  &:hover {
    background-color: rgba(240, 244, 239, 0.75);
    border: none;
  }
`

export const AddCode = styled.span`
  color: #406d70;
  font-size: 12pt;
  &:hover {
    color: #7393ac;
  }
`

export const Footer = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const ActionText = styled.div`
  font-family: 'Open Sans';
  padding: 6px 10px;
  text-transform: ${props => props.allcaps === 'true' ? 'uppercase' : 'none'};
  border-radius: 4px;
  font-weight: 600;
  margin-left: 18px;
  font-size: 14pt;
  cursor: pointer;
  color: ${props => props.disabled === 'true'
    ? '#DCDCDC'
    : scribeGreen
  };
  transition-duration: 0.5s;
`

export const ActionTextRight = styled.div`
  font-family: 'Open Sans';
  padding: 6px 10px;
  text-transform: ${props => props.allcaps === 'true' ? 'uppercase' : 'none'};
  border-radius: 4px;
  font-weight: 600;
  margin-right: 18px;
  font-size: 14pt;
  cursor: pointer;
  color: ${props => props.disabled === 'true'
    ? '#DCDCDC'
    : scribeGreen
  };
  transition-duration: 0.5s;
`

export const ItemSection = styled.div`
  max-width: 800px;
  margin-bottom: 20px;
  overflow: auto;
  width: 80%;
`
