import styled from 'styled-components'
import { whiteSmoke, charcoal, platinum, fulvous } from '../../styles/colors'

export const CircleButton = styled.div`
  border-radius: 50%;
  height: 26px;
  width: 26px;
  background-color: ${platinum};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => p.color || fulvous};
  font-size: 20px;
  font-weight: 600;
  margin: 0px 6px;
`

export const MassiveBtn = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${charcoal};
  box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
  cursor: pointer;
  color: ${whiteSmoke};
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  
  &:active {
    transform: scale(0.98)
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box
`

export const MassiveBtnContainer = styled.div`
    width: 100%;
    margin-bottom: 20px;
    background-color: ${platinum};
    box-shadow: 2px 2px 8px rgba(0,0,0,0.5);
    padding: 20px 30px;
    box-sizing: border-box;
`
