import gql from 'graphql-tag'

// USED IN: USERS, ADMIN_NOTE, PATIENT_NOTE
export const GetAllNotes = gql`
  query GetAllNotes($organizationId: ID!, $userId: ID, $patientId: ID, $limit: Int, $offset: Int) {
    allNotes(input: {
      organizationId: $organizationId,
      userId: $userId,
      patientId: $patientId,
      limit: $limit,
      offset: $offset
    }) {
      count,
      results {
        # If it's a composition
        ... on Composition {
          id,
          metadata {
            status,
            user {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix
              },
              userType
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix
              },
              mrn,
              emrId,
              emrIdType
            },
            appointment {
              id,
              startsAt,
              emrId,
              emrEncounterId,
              emrDepartmentId
            }
          },
          createdAt
        },
        # If it's a patient note
        ... on PatientNote {
          noteId,
          compositionId,
          metadata {
            status,
            user {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix
              },
              userType
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix
              },
              mrn,
              emrId,
              emrIdType
            },
            appointment {
              id,
              startsAt,
              emrId,
              emrEncounterId,
              emrDepartmentId
            }
          },
          compositionCreatedAt
        }
      }
    }
  }
`

export const GetNotes = gql`
  query GetNotes($organizationId: ID!, $userId: ID, $limit: Int, $offset: Int) {
    patientNotes(input: {
      organizationId: $organizationId,
      userId: $userId,
      limit: $limit,
      offset: $offset
    }) {
      count,
      results {
        # If it's a patient note
        ... on PatientNote {
          noteId,
          compositionId,
          metadata {
            status,
            user {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix
              },
              userType
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix
              },
              mrn,
              emrId,
              emrIdType
            },
            appointment {
              id,
              startsAt,
              emrId,
              emrEncounterId,
              emrDepartmentId
            }
          },
          compositionCreatedAt
        }
      }
    }
  }
`

export const GetVersionedNote = gql`
  query GetVersionedNote($organizationId: ID, $id: ID!) {
    versionedNote(input: {
      organizationId: $organizationId,
      id: $id,
    }) {
      note {
        noteId,
        compositionId,
        metadata {
          name,
          status,
          noteTypeId,
          appointment {
            id
            startsAt
            emrId
            emrEncounterId
            emrDepartmentId
            emrEncounterStatus
            reason
          },
          patient {
            id,
            person {
              firstName,
              middleName,
              lastName,
              dateOfBirth,
              gender,
              prefix,
              suffix
            },
            mrn,
            emrId,
            emrIdType
          },
          user {
            id,
            person {
              firstName,
              lastName,
              middleName
            }
          },
          submissionInformation {
              destinations,
              tryAllDestinations
            },
          submissionStatus {
            warning {
              type,
              message
            },
            finalDestination
          },
          clientType
          createdWithS2
          pulledNoteFromEmr
        },
        metadataEntries {
          author {
            organizationId
            userId
            type
            role {
              type
            }
            userName
            organizationName
          }
          createdAt
          metadata {
            name,
            status,
            noteTypeId,
            appointment {
              id
              startsAt
              emrId
              emrEncounterId
              emrDepartmentId
              emrEncounterStatus
              reason
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                dateOfBirth,
                gender,
                prefix,
                suffix
              },
              mrn,
              emrId,
              emrIdType
            },
            user {
              id,
              person {
                firstName,
                lastName,
                middleName
              }
            },
            submissionInformation {
              destinations,
              tryAllDestinations
            },
            submissionStatus {
              warning {
                type,
                message
              },
              finalDestination
            },
            clientType
            createdWithS2
            pulledNoteFromEmr
          }
        },
        sections {
          id,
          sectionEntries {
            author {
              organizationId
              userId
              type
              role {
                type
              }
              userName
              organizationName
            }
            createdAt,
            section {
              name,
              content,
              navigationKeywords,
              status,
              cursors {
                actor,
                location
              }
              diagnosisEntry {
                diagnosis {
                  icdCode
                  billable
                  snomedCode
                  description
                  snomedDescription
                  hccCode
                  panelRanking
                  suggestionType                  
                }
                detailedDisplay {
                  tags {
                    text
                  }
                }
                confirmStatus
              }
            }
          }
        },
        compositionCreatedAt
      }
    }
  }
`

// USED IN: ORGS
export const TriggerRawNotesRefresh = gql`
  mutation TriggerRawNotesRefresh($organizationId: ID!, $startsAt: String, $endsAt: String, $patientId: ID, $encounterId: ID) {
    triggerRawNotesRefresh(input: {
      organizationId: $organizationId,
      startsAt: $startsAt,
      endsAt: $endsAt,
      patientId: $patientId,
      encounterId: $encounterId
    }) {
      patientId,
      encounterId
    }
  }
`
