import React, { Component } from 'react'
import adminBackground from '../images/adminBackground.jpg'
import { EditorSidebar } from '../components/util'
import { withRouter } from 'react-router-dom'
import { compose, graphql } from 'react-apollo'
import { connect } from 'react-redux'
import {
  GetAllOrganizations,
  GetOrganizationById,
  CreateOrganization,
  UpdateOrganization,
  DeleteOrganization,
  GetLinkedNvoqOrganizations,
  GetNvoqOrganizations
} from '@sukiai/gql/admin'
import { sortItemsByKey, notifySuccess, setError } from '../lib/util'
import { setOrgId } from '../actions/admin'
import { InviteUserForm, OrgForm, OrgMainContent } from '../components/Orgs'
import Loading from '../components/Loading'
import Frame from './Frame'
import loSto from '../config/loSto'
import { LO_STO, FETCH_POLICY, QUERY_PARAMS } from '../lib/constants'
import get from 'lodash.get'
import queryString from 'query-string'

const styles = {
  main: {
    padding: 40
  }
}

const FORMS = {
  ADD_ORG: 'ADD_ORG',
  UPDATE_ORG: 'UPDATE_ORG'
}

class Orgs extends Component {
  state = {
    formOpen: null,
    orgName: '',
    newInviteFormOpen: null
  }

  componentDidMount () {
    // get orgId from query else get from
    const params = queryString.parse(this.props.location.search)
    const orgId = params[QUERY_PARAMS.ORG_ID] || loSto.get(LO_STO.CURRENT_ORG_ID)
    this.props.setOrgId(orgId)
    document.getElementById(orgId) &&
      document
        .getElementById(orgId)
        .scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  componentDidUpdate (prevProps) {
    const params = queryString.parse(this.props.location.search)
    const paramOrgId = params[QUERY_PARAMS.ORG_ID]
    const currOrgId = this.props.currentOrgId

    // set default if no orgID set
    if (!paramOrgId && !currOrgId && !prevProps.currentOrgId) {
      const defaultOrg =
        loSto.get(LO_STO.CURRENT_ORG_ID) ||
        (this.props.orgsData[0] && this.props.orgsData[0].id)
      defaultOrg && this.props.setOrgId(defaultOrg)
      return
    }

    // set queryParams if curr org id is different from last
    if (currOrgId && paramOrgId !== currOrgId) {
      this.props.history.replace({
        search: `?${QUERY_PARAMS.ORG_ID}=${currOrgId}`
      })
    }

    if (prevProps.currentOrgData?.id !== this.props.currentOrgData?.id) {
      loSto.set(LO_STO.CURRENT_ORG, this.props.currentOrgData)
    }

    document.getElementById(currOrgId) &&
      document
        .getElementById(currOrgId)
        .scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  updateName = e => {
    this.setState({
      orgName: e.target.value
    })
  }

  toggleForm = (open, name) => {
    this.setState({
      formOpen: open,
      orgName: name || ''
    })
  }

  toggleNewInviteForm = (open) => {
    this.setState({
      newInviteFormOpen: open
    })
  }

  addOrg = () => {
    const { createOrganization, setOrgId } = this.props

    createOrganization({
      variables: { name: this.state.orgName },
      refetchQueries: [
        {
          query: GetAllOrganizations
        }
      ]
    })
      .then(
        ({
          data: {
            createOrganization: { organization }
          }
        }) => {
          console.info(
            'Successfully created organization with ID:',
            organization.id
          )
          notifySuccess('Successfully created organization')
          setTimeout(() => {
            setOrgId(organization.id)
            loSto.set(LO_STO.CURRENT_ORG_ID, organization.id)
            document.getElementById(organization.id) &&
              document
                .getElementById(organization.id)
                .scrollIntoView({ behavior: 'smooth', block: 'center' })
          }, 1000)
        }
      )
      .catch(error => {
        console.error('Error creating organization:', error)
        setError(error)
      })
  }

  updateOrg = () => {
    const { updateOrganization, currentOrgId, setOrgId, orgsData } = this.props

    updateOrganization({
      variables: {
        id: currentOrgId,
        name: this.state.orgName,
        enhancedReviwew: orgsData.enhancedReviwew
      },
      refetchQueries: [
        {
          query: GetAllOrganizations
        },
        {
          query: GetOrganizationById,
          variables: {
            id: currentOrgId,
            withEmrInfo: true
          }
        }
      ]
    })
      .then(
        ({
          data: {
            updateOrganization: { organization }
          }
        }) => {
          console.info(
            'Successfully updated organization with ID:',
            organization.id
          )
          notifySuccess('Successfully updated organization')
          setOrgId(organization.id)
          loSto.set(LO_STO.CURRENT_ORG_ID, organization.id)
        }
      )
      .catch(error => {
        console.error('Error updating organization:', error)
        setError(error)
      })
  }

  deleteOrg = () => {
    const { deleteOrganization, currentOrgId, setOrgId } = this.props

    deleteOrganization({
      variables: { id: currentOrgId },
      refetchQueries: [{ query: GetAllOrganizations }]
    })
      .then(
        ({
          data: {
            deleteOrganization: { id }
          }
        }) => {
          console.info('Successfully deleted organization with ID:', id)
          notifySuccess('Successfully deleted organization')
          setOrgId(null)
          loSto.remove(LO_STO.CURRENT_ORG_ID)
        }
      )
      .catch(error => {
        console.error('Error deleting organization:', error)
        setError(error)
      })
  }

  render () {
    const {
      location,
      orgsData,
      setOrgId,
      currentOrgId,
      currentOrgLoading,
      currentOrgData,
      linkedNvoqOrgsData,
      nvoqOrgsData,
      orgsLoading
    } = this.props

    return (
      <Frame background={adminBackground} mainStyle={styles.main}>
        <EditorSidebar
          route={location.pathname}
          items={orgsData}
          setOrgId={setOrgId}
          currentId={currentOrgId}
          loading={orgsLoading && !orgsData}
          toggleForm={this.toggleForm.bind(this, FORMS.ADD_ORG)}
        />
        {currentOrgLoading || currentOrgLoading === undefined ? (
          <Loading />
        ) : (
          <OrgMainContent
            FORMS={FORMS}
            currentOrg={currentOrgData}
            loading={orgsLoading && !orgsData}
            orgs={orgsData}
            linkedNvoqOrgs={linkedNvoqOrgsData}
            allNvoqOrgs={nvoqOrgsData}
            toggleForm={this.toggleForm}
            toggleNewInviteForm={this.toggleNewInviteForm}

          />
        )}
        {this.state.formOpen && (
          <OrgForm
            FORMS={FORMS}
            type={this.state.formOpen}
            orgName={this.state.orgName}
            updateName={this.updateName}
            toggleForm={this.toggleForm.bind(this, null)}
            addOrg={this.addOrg}
            updateOrg={this.updateOrg}
            deleteOrg={this.deleteOrg}
          />
        )}

        {this.state.newInviteFormOpen && (
          <InviteUserForm
            orgName={this.state.orgName}
            orgId={currentOrgId}
            toggleNewInviteForm={this.toggleNewInviteForm}
          />
        )}
      </Frame>
    )
  }
}

const mapStateToProps = ({ admin }) => ({
  currentOrgId: admin.orgId
})

const mapDispatchToProps = dispatch => ({
  setOrgId: id => dispatch(setOrgId(id))
})

const mapOrgsToProps = ({ data }) => ({
  orgsError: data.error,
  orgsLoading: data.loading,
  orgsData: sortItemsByKey(
    get(data, 'organizations.results', []).slice(),
    'name'
  )
})

const mapCurrentOrgToProps = ({ data }) => ({
  currentOrgError: data.error,
  currentOrgLoading: data.loading,
  currentOrgData: get(data, 'organizations.results[0]')
})

const mapLinkedNvoqOrgsToProps = ({ data }) => ({
  linkedNvoqOrgsError: data.error,
  linkedNvoqOrgsLoading: data.loading,
  linkedNvoqOrgsData: get(data, 'linkedNvoqOrganizations.organizations')
})

const mapNvoqOrgsToProps = ({ data }) => ({
  nvoqOrgsError: data.error,
  nvoqOrgsLoading: data.loading,
  nvoqOrgsData: get(data, 'nvoqOrganizations.organizations')
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GetAllOrganizations, {
    options: {
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK
    },
    props: mapOrgsToProps
  }),
  graphql(GetOrganizationById, {
    skip: props => !props.currentOrgId,
    options: props => ({
      variables: {
        id: props.currentOrgId,
        withEmrInfo: true
      },
      fetchPolicy: FETCH_POLICY.CACHE_FIRST
    }),
    props: mapCurrentOrgToProps
  }),
  graphql(GetLinkedNvoqOrganizations, {
    skip: props => !props.currentOrgId,
    options: props => ({
      variables: {
        organizationId: props.currentOrgId
      },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK
    }),
    props: mapLinkedNvoqOrgsToProps
  }),
  graphql(GetNvoqOrganizations, {
    skip: props => !props.currentOrgId,
    options: props => ({
      variables: {
        organizationId: props.currentOrgId
      },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY
    }),
    props: mapNvoqOrgsToProps
  }),
  graphql(CreateOrganization, { name: 'createOrganization' }),
  graphql(UpdateOrganization, { name: 'updateOrganization' }),
  graphql(DeleteOrganization, { name: 'deleteOrganization' }),
  withRouter
)(Orgs)
