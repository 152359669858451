import React, { Component } from 'react'
import {
  PatientNotesPanel,
  StatusSelector,
  NoteHeader,
  NoteSection,
  ScriptChangeSection,
  MetadataHistoryPanel
} from './index'
import Paper from 'material-ui/Paper'
import { withRouter } from 'react-router'
import Radium, { keyframes } from 'radium'
import { noteMarginTopAndBottom, navBarHeight } from '../../styles/dimensions'
import {
  adminHeaderGrey,
  charcoal,
  platinum
} from '../../styles/colors'
import {
  NOTE_STATUS,
  NOTE_MODE,
  GRAPHQL_TYPE
} from '../../lib/constants'
import { getFullName } from '@sukiai/utils'
import { copyText } from '../../lib/util'
import CircularProgress from 'material-ui/CircularProgress'
import get from 'lodash.get'
import NoteQueueStatusBar from '../NoteQueueStatusBar'
import OpsView from './OpsViewPanel'

const UNKNOWN_PATIENT = 'Unknown Patient'
const noteAnim = keyframes({
  '0%': {
    marginTop: '100vh'
  },
  '100%': {
    marginTop: 0
  }
}, 'noteAnim')

const styles = {
  note: {
    margin: `100vh 0 ${noteMarginTopAndBottom} 0`,
    animation: 'x 0.5s ease-out 0.5s forwards',
    animationName: noteAnim
  },
  adminHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 50px'
  },
  utilityPanel: {
    position: 'absolute',
    top: navBarHeight,
    right: 0,
    display: 'flex',
    zIndex: 10
  },
  link: {
    color: adminHeaderGrey
  },
  paper: {
    minHeight: `calc(100vh - ${navBarHeight} - ${noteMarginTopAndBottom})`,
    paddingBottom: '10px'
  },
  source: {
    padding: '20px 50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  resubmitButton: {
    height: 40,
    width: 100,
    boxSizing: 'border-box',
    backgroundColor: platinum,
    color: charcoal,
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.4)',
    borderRadius: 2,
    marginLeft: 16,
    cursor: 'pointer',
    transition: '0.2s',
    ':active': {
      transform: 'scale(0.98)',
      boxShadow: '0px 0px 4px rgba(0,0,0,0.55)'
    }
  }
}

class NotePaper extends Component {
  renderCompHeader = () => {
    const {
      note,
      noteMode,
      updateComposition,
      loading,
      orgId,
      refetchNote,
      resubmitComposition,
      resubmitting
    } = this.props

    return (
      <div style={styles.adminHeader}>
        {noteMode === NOTE_MODE.DEBUG &&
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StatusSelector
              style={{ color: adminHeaderGrey }}
              composition={note}
              updateComposition={updateComposition}
              loadingNote={loading}
              orgId={orgId}
              refetchNote={refetchNote}
            />
            {note.metadata.status === NOTE_STATUS.SUBMITTED_TO_EMR &&
              <div style={styles.resubmitButton} onClick={resubmitComposition}>
                {resubmitting
                  ? (
                    <CircularProgress
                      size={24}
                      thickness={3}
                      color={charcoal}
                    />
                  )
                  : 'Resubmit'}
              </div>}
          </div>}
      </div>
    )
  }

  renderPatientNoteHeader = () => {
    const { note, noteMode } = this.props
    return (
      <div style={styles.source}>
        <div>
          <b>Source:</b>
          {note.compositionId
            ? (
              <span style={{ cursor: 'pointer' }} onClick={() => copyText(note.compositionId)}>
                &nbsp;Suki {noteMode === NOTE_MODE.DEBUG && `(${note.compositionId})`}
              </span>
            )
            : <span>&nbsp;EMR</span>}
        </div>
      </div>
    )
  }

  showHeaderTools = () => {
    const { noteMode } = this.props
    return noteMode === NOTE_MODE.DEBUG || noteMode === NOTE_MODE.QA
  }

  showChangeSection = () => {
    const { type, noteMode } = this.props
    return type === GRAPHQL_TYPE.COMPOSITION && noteMode !== NOTE_MODE.PLAIN
  }

  render () {
    const {
      sections,
      note,
      patient,
      loadingPatientNotes,
      patientNotes,
      orgId,
      type,
      versionedSections,
      noteMode,
      currentSectionId
    } = this.props

    if (!note || !note.metadata) {
      // need to show some type of error for these cases in the container
      return null
    }

    const noteSections = sections && sections.map((s, i) => (
      <NoteSection
        type={type}
        note={note}
        section={s}
        versionedSection={versionedSections && versionedSections[i]} // need to verify there are same number of indices
        key={s.id}
        orgId={orgId}
        wasComp={!!note.compositionId}
        noteMode={noteMode}
        currentSectionId={currentSectionId}
      />
    ))

    return (
      <div style={styles.note}>
        <div style={styles.utilityPanel}>
          <OpsView />
          <MetadataHistoryPanel
            versions={note.metadataEntries}
          />
          {patientNotes && (
            <PatientNotesPanel
              loadingNotes={loadingPatientNotes}
              patientName={(patient && getFullName(patient.person)) || UNKNOWN_PATIENT}
              notes={patientNotes}
            />
          )}
        </div>
        <Paper style={styles.paper}>
          {this.showHeaderTools() && (type === GRAPHQL_TYPE.COMPOSITION
            ? this.renderCompHeader()
            : this.renderPatientNoteHeader())}
          <NoteHeader
            patient={patient}
            noteName={get(note, 'metadata.name')}
            createdAt={note.createdAt}
            appt={note.metadata.appointment}
            noteMode={noteMode}
          />
          {noteSections}
          {this.showChangeSection() &&
            <ScriptChangeSection noteId={note.id} />}
        </Paper>

        {noteMode === NOTE_MODE.DEBUG &&
          <NoteQueueStatusBar noteId={note.id || note.noteId} />}
      </div>
    )
  }
}

export default withRouter(Radium(NotePaper))
