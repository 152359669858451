import React from 'react'
import { Query, Mutation } from 'react-apollo'
import get from 'lodash.get'
import { withRouter } from 'react-router-dom'
import { GetScriptsByUserId, CreateScript, GetScriptById, GetUserById } from '@sukiai/gql/admin'
import { getFullName, snakeCapsToEnglish } from '@sukiai/utils'
import ScriptEditor from './ScriptEditor'
import { EditorContainer, Empty } from './styled'
import { NO_SCRIPTS } from './constants'
import TopNavBar from '../TopNavBar'
import { Sidebar, Loading } from '../../components'
import { SCRIPT_TAG, QUERY_PARAMS, ORG_IDS, SPECIALTY_TYPE, FETCH_POLICY } from '../../lib/constants'
import queryString from 'query-string'
import { setError, notifySuccess, sortScriptsByName, sortScriptsBySource } from '../../lib/util'
import client from '../../apollo'

const SPECIALTIES_ARR = Object.keys(SPECIALTY_TYPE).map(s => ({
  name: snakeCapsToEnglish(s),
  value: s
}))

class Scripts extends React.Component {
  state = {
    selectedId: null,
    filteredSpecialty: SPECIALTY_TYPE.NA // default filter (only applies to onboarding)
  }

  componentDidMount () {
    const params = queryString.parse(this.props.location.search)
    const scriptId = params[QUERY_PARAMS.SCRIPT_ID]

    scriptId && this.setState({ selectedId: scriptId })
  }

  componentDidUpdate (prevProps, prevState) {
    const selectedId = this.state.selectedId
    const prevSelectedId = prevState.selectedId

    if (selectedId && selectedId !== prevSelectedId) {
      this.props.history.replace({ search: `?${[QUERY_PARAMS.SCRIPT_ID]}=${selectedId}` })

      document.getElementById(selectedId) &&
        document
          .getElementById(selectedId)
          .scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  resetSpecialtyFilter = () => {
    // if onboarding default, reset back to default (N/A)
    const organizationId = get(this.props, 'match.params.organizationId')
    const isOnboardingDefault = organizationId === ORG_IDS.SUKI_ONBOARDING_DEFAULTS

    if (isOnboardingDefault) {
      this.setState({ filteredSpecialty: SPECIALTY_TYPE.NA })
    }
  }

  setSelected = id => {
    this.setState({ selectedId: id })
  }

  setFilteredSpecialty = e => {
    const organizationId = get(this.props.match, 'params.organizationId')
    const userId = get(this.props.match, 'params.userId')
    const specialty = e.target.value

    // need to select first from new filter list by default
    client.query({
      query: GetScriptsByUserId,
      variables: {
        organizationId,
        userId,
        specialties: [specialty]
      }
    }).then(({ data }) => {
      const selectedId = get(data, 'macros.results[0].id')
      this.setState({ selectedId, filteredSpecialty: specialty })
    })
  }

  mapSidebarItem = script => {
    const nameTag = get(script, 'tags', []).find(t => t.type === SCRIPT_TAG.NAME)
    const name = get(nameTag, 'name.value')
    const source = script?.source
    return { id: script.id, name, source }
  }

  addToCache = async (cache, { data: { createMacro } }) => {
    const { filteredSpecialty } = this.state
    const { match } = this.props
    const { organizationId, userId } = match.params
    const isOnboardingDefault = organizationId === ORG_IDS.SUKI_ONBOARDING_DEFAULTS

    const newScript = get(createMacro, 'macro')

    // if onboarding default then we need to
    // update the query variables
    const queryVariables = { organizationId, userId }
    if (isOnboardingDefault) {
      queryVariables.specialties = [filteredSpecialty]
    }

    const { macros: allScriptsRes } = cache.readQuery({
      query: GetScriptsByUserId,
      variables: queryVariables
    })

    // Add the new script to the list of scripts
    const allScripts = [...get(allScriptsRes, 'results', []), newScript]

    // Re-sort the scripts by name
    sortScriptsByName(allScripts)
    sortScriptsBySource(allScripts)

    // Update the cache with the new list of scripts
    await cache.writeQuery({
      query: GetScriptsByUserId,
      variables: queryVariables,
      data: {
        macros: {
          __typename: 'QueryMacrosPayload',
          count: get(allScripts, 'length', 0),
          results: allScripts
        }
      }
    })

    // Write the data of the new script to the cache
    await cache.writeQuery({
      query: GetScriptById,
      variables: { organizationId, id: newScript.id },
      data: {
        macros: {
          __typename: 'QueryMacrosPayload',
          count: 1,
          results: [newScript]
        }
      }
    })

    // Select the newly create script
    this.setSelected(newScript.id)

    // if onbording default org, reset filter to ALL
    this.resetSpecialtyFilter()
  }

  render () {
    const { filteredSpecialty } = this.state
    const { match } = this.props
    const { organizationId, userId } = match.params

    const isOnboardingDefault = organizationId === ORG_IDS.SUKI_ONBOARDING_DEFAULTS
    const isFilteringBySpecialty = isOnboardingDefault && !!filteredSpecialty

    const scriptVariables = { organizationId, userId }
    if (isFilteringBySpecialty) {
      scriptVariables.specialties = [filteredSpecialty]
    }

    const userVariables = { organizationId, id: userId }
    return (
      <>
        <TopNavBar />
        <Query query={GetScriptsByUserId} variables={scriptVariables} fetchPolicy={FETCH_POLICY.CACHE_AND_NETWORK}>
          {({ loading: scriptsLoading, data: scriptsData }) => (
            <Query query={GetUserById} variables={userVariables}>
              {({ loading: userLoading, data: userData }) => {
                const userPerson = get(userData, 'users.results[0].person')
                const fullName = getFullName(userPerson)
                const sidebarTitle = fullName && `${fullName}'s Scripts`
                const loading = scriptsLoading || userLoading
                const scriptsRes = get(scriptsData, 'macros.results', [])
                const scripts = scriptsRes.map(this.mapSidebarItem)
                sortScriptsBySource(scripts)
                const createVariables = { organizationId, userId }

                // if in suki onboarding,
                // all created notetypes will default to 'N/A' for specialties
                if (isOnboardingDefault) {
                  createVariables.specialties = [SPECIALTY_TYPE.NA]
                }
                createVariables.source = 'ADMIN'

                const queryVariables = { organizationId, userId }
                if (isOnboardingDefault) {
                  queryVariables.specialties = [SPECIALTY_TYPE.NA]
                }

                const selectedId = this.state.selectedId || get(scripts, '[0].id')
                if (selectedId && selectedId !== this.state.selectedId) {
                  this.setState({ selectedId: selectedId })
                }

                return (
                  <>
                    <Mutation
                      mutation={CreateScript}
                      variables={createVariables}
                      onError={setError}
                      onCompleted={() => notifySuccess('Created script')}
                      update={this.addToCache}
                    >
                      {createScript => (
                        <Sidebar
                          withNav
                          title={sidebarTitle}
                          dataType='script'
                          handleAdd={createScript}
                          loading={loading}
                          items={scripts}
                          selectedId={selectedId}
                          handleSelect={this.setSelected}
                          dropList={isOnboardingDefault && SPECIALTIES_ARR}
                          dropListSelected={filteredSpecialty}
                          handleDropListSelect={this.setFilteredSpecialty}
                        />
                      )}
                    </Mutation>
                    <EditorContainer>
                      {scriptsLoading ? (
                        <Loading />
                      ) : !scripts.length ? (
                        <Empty data-cy='scripts-empty'>{NO_SCRIPTS}</Empty>
                      ) : (
                        <ScriptEditor
                          organizationId={organizationId}
                          userId={userId}
                          id={selectedId}
                          setSelected={this.setSelected}
                          filteredSpecialty={filteredSpecialty}
                          isOnboardingDefault={isOnboardingDefault}
                        />
                      )}
                    </EditorContainer>
                  </>
                )
              }}
            </Query>
          )}
        </Query>
      </>
    )
  }
}

export default withRouter(Scripts)
