import styled from 'styled-components'
import { charcoal, whiteSmoke } from '../../styles/colors'

export const FormContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background: ${whiteSmoke};
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
  margin-bottom: ${props => !props.last && '15px'};
`

export const Item = styled.div`
  width: 12%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  padding-right: 4px;
`

export const InputItem = styled.div`
  flex: 1;
  margin: 0 10px;
  display: flex;
`

export const Input = styled.input`
  font: inherit;
  padding: 6px;
  outline: none;
  text-overflow: ellipsis;
  flex: 1;
`

export const Select = styled.select`
  font: inherit;
  padding: 6px;
  outline: none;
  text-overflow: ellipsis;
  flex: 1;
`

export const JSONContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 8px;
`

export const JSONField = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
`

export const JSONLabel = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
`

export const Button = styled.button.attrs({ type: 'submit' })`
  outline: none;
  border: none;
  border-radius: 4px;
  background: ${charcoal};
  color: ${whiteSmoke};
  font: inherit;
  margin-left: 10px;
  box-shadow: ${props => !props.disabled && '0px 2px 8px rgba(0, 0, 0, 0.4)'};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 45px;
  min-width: 80px;
  opacity: ${props => props.disabled && 0.6};
  transition: 0.3s;

  :hover {
    transform: ${props => !props.disabled && 'scale(1.02)'};
  }

  :active {
    transform: ${props => !props.disabled && 'scale(0.96)'};
  }
`
