import React, { Component } from 'react'
import {
  PageWrapper,
  DictationBackground,
  PageContainer,
  NoteWrapper,
  NoteContainer,
  NoteContainerDark,
  DarkModeButton,
  HelpWrapper,
  PopOver
} from './styled'
import { NoteHeader } from '../index'
import { Animated, Easing } from 'react-native'
import { AGENT, NOTES, SECTION_STATE } from '../../lib/constants'
import helpIcon from '../../assets/icons/help-icon.svg'
import { getPagetype, isAmwellTheme } from '../../lib/util'

const AmwellHelpButton = () => {
  const [show, setShow] = React.useState(false)
  const showPopover = () => setShow(true)
  const hidePopover = () => setShow(false)
  const textString = 'Get answers and troubleshooting tips from the Suki team.'
  const pageJump = page => window.open(page, '_blank')
  const helpUrl = 'https://help.suki.ai/en/'

  const isWrapUpWindow = getPagetype() === 'wrapup'

  return (
    <>
      <HelpWrapper isWrapUpWindow={isWrapUpWindow}>
        <DarkModeButton
          onClick={() => pageJump(helpUrl)}
          onMouseOver={showPopover}
          onMouseOut={hidePopover}
        >
          <div>
            <span>Help</span>
            <img src={helpIcon} />
          </div>
        </DarkModeButton>
        {show && <PopOver>{textString}</PopOver>}
      </HelpWrapper>
    </>
  )
}

class Note extends Component {
  isAmwell = isAmwellTheme()

  state = {
    noteModalAnim: new Animated.Value(0),
    noteBlurAnim: new Animated.Value(0),
    noteAnim: new Animated.Value(0),
    veilAnim: new Animated.Value(0)
  }

  componentDidMount () {
    this.props.type === NOTES.READ_ONLY
      ? Animated.timing(this.state.noteModalAnim, {
        toValue: 1,
        duration: 200,
        easing: Easing.linear
      }).start()
      : Animated.sequence([
        Animated.delay(400),
        Animated.timing(this.state.noteAnim, {
          toValue: 1,
          duration: 200,
          easing: Easing.linear
        })
      ]).start()
  }

  componentWillReceiveProps (nextProps) {
    nextProps.shouldDismiss &&
    Animated.timing(this.state.noteModalAnim, {
      toValue: 0,
      duration: 200,
      easing: Easing.linear
    }).start(() => nextProps.removeModal())

    this.props.type !== NOTES.READ_ONLY &&
    !this.props.veilActive &&
    nextProps.veilActive && !this.isAmwell &&
    Animated.timing(this.state.noteBlurAnim, {
      toValue: 5,
      duration: 200,
      easing: Easing.linear
    }).start(() => this.forceUpdate())

    this.props.type !== NOTES.READ_ONLY &&
    this.props.veilActive &&
    !nextProps.veilActive &&
    Animated.timing(this.state.noteBlurAnim, {
      toValue: 0,
      duration: 200,
      easing: Easing.linear
    }).start(() => this.forceUpdate())

    nextProps.dictation &&
    !this.props.dictation &&
    Animated.timing(this.state.veilAnim, {
      toValue: 1,
      duration: 200,
      easing: Easing.linear
    }).start()

    !nextProps.dictation &&
    this.props.dictation &&
    Animated.timing(this.state.veilAnim, {
      toValue: 0,
      duration: 200,
      easing: Easing.linear
    }).start()
  }

  handleDeleteEmptyProblem = () => {
    const {
      currentSection,
      deleteEmptyProblemSection,
      currentSectionState
    } = this.props

    // check if its an empty problem
    const isProblemSection = !!currentSection?.diagnosisEntry
    const isEmpty = !currentSection?.name
    if (isProblemSection && isEmpty && currentSectionState === SECTION_STATE.DICTATION) {
      deleteEmptyProblemSection(currentSection.id)
    }
  }

  getClickAction = (e, type) => {
    const {
      dictation,
      stopDictation,
      clearSection
    } = this.props
    if (type === NOTES.READ_ONLY) {
      return null
    } else if (dictation) {
      const cyTag = e.target.getAttribute('data-cy')

      const tagsToCancelDictation = [
        'note-type-name',
        'note-container',
        'note',
        'search-bar-input',
        'patient-dos',
        'patient-dob'
      ]

      if (tagsToCancelDictation.includes(cyTag)) {
        stopDictation()
      }
    } else {
      return clearSection
    }
  }

  onNoteWrapperClick = e => {
    const { onNoteWrapperClick } = this.props

    // If it is set, call it (only set in READ_ONLY state)
    onNoteWrapperClick && onNoteWrapperClick()

    // Need this for the normal editable note logic to work for typing
    e.stopPropagation()
  }

  render () {
    const {
      sections,
      metadata,
      type,
      dictation,
      search,
      schedulePicker,
      dobPicker,
      genderPicker,
      mrnInput,
      editableStatesForPickers,
      setEditableState,
      isProcessing,
      isDosEditable,
      isSmallWindow,
      hasEMR,
      isWrapUpWindow,
      isSukiOnly,
      marginTop,
      agentState
    } = this.props

    const vw = window.innerWidth
    const vh = window.innerHeight

    const styles = {
      background: {
        opacity: this.state.veilAnim
      },
      modal: {
        opacity: this.state.noteModalAnim,
        transform: [
          {
            scale: this.state.noteModalAnim.interpolate({
              inputRange: [0, 1],
              outputRange: [0.8, 1]
            })
          }
        ]
      },
      note: {
        filter: `blur(${this.state.noteBlurAnim._value}px)`,
        transform: [
          {
            translateY: this.state.noteAnim.interpolate({
              inputRange: [0, 1],
              outputRange: ['100%', '0%']
            })
          }
        ],
        ...this.props.veilActive ? {
          minHeight: 'unset',
          height: '100vh',
          overflow: 'hidden'
        } : {}
      }
    }

    if (this.isAmwell) {
      return (
        <NoteWrapper onClick={dictation ? null : e => this.onNoteWrapperClick(e)} isAmwell data-cy='note-container-scrollable' isAgentMuted={agentState === AGENT.STATE.MUTED}>
          <NoteContainerDark
            vw={vw}
            vh={vh}
            type={type}
            style={type === NOTES.READ_ONLY
              ? styles.modal
              : styles.note}
            data-cy='note-container'
            isSmallWindow={isSmallWindow}
            isWrapUpWindow={isWrapUpWindow}
          >
            <NoteHeader
              {...metadata}
              SearchComponent={search}
              isDosEditable={isDosEditable}
              SchedulePicker={schedulePicker}
              DobPicker={dobPicker}
              GenderPicker={genderPicker}
              MrnInput={mrnInput}
              editableStatesForPickers={editableStatesForPickers}
              setEditableState={setEditableState}
              isProcessing={isProcessing}
              hasEMR={hasEMR}
              isSukiOnly={isSukiOnly}
            />
            {sections}
          </NoteContainerDark>
          <AmwellHelpButton />
        </NoteWrapper>
      )
    }

    return (
      <PageWrapper
        vw={vw}
        vh={vh}
        onClick={e => this.getClickAction(e, type)}
      >
        <DictationBackground
          style={styles.background}
          vw={vw}
          vh={vh}
        />
        <PageContainer
          vw={vw}
          vh={vh}
          data-cy='note'
          isSmallWindow={isSmallWindow}
        >
          <NoteWrapper onClick={dictation ? null : e => this.onNoteWrapperClick(e)}>
            <NoteContainer
              vw={vw}
              vh={vh}
              type={type}
              style={type === NOTES.READ_ONLY
                ? styles.modal
                : styles.note}
              data-cy='note-container'
              isSmallWindow={isSmallWindow}
              marginTop={marginTop}
            >
              <NoteHeader
                {...metadata}
                SearchComponent={search}
                isDosEditable={isDosEditable}
                SchedulePicker={schedulePicker}
                DobPicker={dobPicker}
                GenderPicker={genderPicker}
                MrnInput={mrnInput}
                editableStatesForPickers={editableStatesForPickers}
                setEditableState={setEditableState}
                isProcessing={isProcessing}
                hasEMR={hasEMR}
                isSukiOnly={isSukiOnly}
              />
              {sections}
            </NoteContainer>
          </NoteWrapper>
        </PageContainer>
      </PageWrapper>
    )
  }
}

export default Note
