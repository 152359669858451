export const FONT = {
  XXS: '10px',
  XS: '12px',
  S: '14px',
  M: '16px',
  L: '20px',
  XL: '22px',
  XXL: '26px'
}

export const NO_NOTE_TYPES = 'No note types yet. To create one, click the + button on the sidebar.'
