import React, { Component } from 'react'
import Drawer from 'material-ui/Drawer'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { List, ListItem, makeSelectable } from 'material-ui/List'
import Chip from 'material-ui/Chip'
import PropTypes from 'prop-types'
import store from '../../store'
import { getFullName } from '@sukiai/utils'
import processSections from '../../lib/util/note/processSections'
import { setCurrentSection } from '../../actions/note'
import { sideBarWidth } from '../../styles/dimensions'
import { fulvous, sideBarVeil, sideBarText, slateGrey } from '../../styles/colors'
import docNotInNote from '../../images/doc-not-in-note.svg'
import docInNote from '../../images/doc-in-note.svg'

let SelectableList = makeSelectable(List)

function wrapState (ComposedComponent) {
  SelectableList.displayName = `SelectableList(${getDisplayName(ComposedComponent)})`

  function getDisplayName (ComposedComponent) {
    return ComposedComponent.displayName || ComposedComponent.name || 'Component'
  }

  return class SelectableList extends Component {
    static propTypes = {
      children: PropTypes.node.isRequired
    }

    state = {
      selectedIndex: 'defaultNotSet'
    }

    static getDerivedStateFromProps (nextProps, prevState) {
      if (nextProps.currentSectionId && nextProps.currentSectionId !== prevState.selectedIndex) {
        return { selectedIndex: nextProps.currentSectionId }
      }
      return null
    }

    handleRequestChange = (event, index) => {
      this.setState({
        selectedIndex: index
      })

      store.dispatch(setCurrentSection(index))

      // Scroll to section in note
      document.getElementById(index).scrollIntoView()
    }

    render () {
      return (
        <ComposedComponent
          value={this.state.selectedIndex}
          style={{ padding: 0 }}
          onChange={this.handleRequestChange}
        >
          {this.props.children}
        </ComposedComponent>
      )
    }
  }
}

SelectableList = wrapState(SelectableList)

const styles = {
  chip: {
    backgroundColor: slateGrey,
    margin: '10px 10px 0 0',
    justifyContent: 'center',
    width: 40,
    height: 20
  },
  chipText: {
    fontSize: 13,
    lineHeight: '20px',
    padding: 0,
    zIndex: 2
  },
  noAttentionNeeded: {
    color: sideBarText
  },
  attentionNeeded: {
    color: fulvous
  },
  itemDefault: {
    fontSize: 18,
    height: 48,
    display: 'flex',
    alignItems: 'center'
  },
  listItem: {
    paddingLeft: 48,
    width: '20vw',
    minWidth: 320,
    boxSizing: 'border-box'
  },
  userNameContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    width: sideBarWidth,
    height: 48
  },
  userIcon: {
    margin: 4,
    minHeight: 40,
    minWidth: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  userNameText: {
    padding: '4px 0 4px 4px',
    color: sideBarText,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  userName: {
    fontSize: 16,
    width: `calc(${sideBarWidth} - 48px)`,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingRight: 20,
    boxSizing: 'border-box'
  },
  noteOpen: {
    fontSize: 13
  }
}

class OpsSidePanel extends Component {
  render () {
    const {
      compSections,
      user,
      noteOpenByDoc,
      panelId,
      style
    } = this.props

    let items = []
    const userName = user && user.person && `Dr. ${getFullName(user.person)}`

    // Process sections using utility class
    const sections = processSections(compSections)

    if (sections && sections.length > 0) {
      items = sections.map(section => {
        const rightAvatar = section.source === 'EMR'
          ? (
            <Chip
              style={styles.chip}
              labelColor='white'
              labelStyle={styles.chipText}
            >
                EMR
            </Chip>
          )
          : null
        const textColor = section.isAttentionNeeded ? styles.attentionNeeded : styles.noAttentionNeeded

        return (
          <ListItem
            key={section.id}
            insetChildren
            leftIcon={section.statusIcon}
            primaryText={section.name}
            hoverColor={sideBarVeil}
            rightAvatar={rightAvatar}
            value={section.id}
            style={Object.assign({}, styles.itemDefault, textColor)}
            innerDivStyle={styles.listItem}
            data-cy={`sidepanel-item-section-${section.id}`}
          />
        )
      })
    }

    return (
      <div id={panelId}>
        <Drawer docked open zDepth={0} containerStyle={style}>
          <SelectableList children={items} sections={sections} />
        </Drawer>
        <div style={styles.userNameContainer}>
          <div style={styles.userIcon}><img src={compSections && noteOpenByDoc ? docInNote : docNotInNote} alt='user name icon' height={compSections && noteOpenByDoc ? 37 : 34} width={compSections && noteOpenByDoc ? 37 : 34} /></div>
          <div style={styles.userNameText}>
            <div style={styles.userName} data-cy='doctor-name'>{userName}</div>
            {compSections && noteOpenByDoc && <div style={styles.noteOpen}>has note open</div>}
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  withRouter
)(OpsSidePanel)
