import styled from 'styled-components'
import { platinum } from '../../lib/colors'

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px;
`

export const FilterIcon = styled.img.attrs(props => ({
  src: props.src
}))`
  width: 30px;
  height: 30px;
  padding: 0px 5px;
`

export const FilterInput = styled.input`
  width: 100%;
  padding: 5px 5px 5px 10px;
  background-color: ${props => props.backgroundColor};
  border: solid 1px ${platinum};
  &:focus {
    outline: none;
  };
`
