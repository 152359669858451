import React, { Component } from 'react'
import { Animated, Easing } from 'react-native'
import {
  Wrapper,
  SectionContainer,
  SectionName,
  SectionNameTextarea,
  SectionNameTextareaDark,
  ProblemSectionName,
  ProblemSectionNameDark,
  SectionContent,
  Blurred,
  Macro,
  ScribeToProcess,
  Paragraph,
  SectionIndicator,
  CapturingIndicator,
  CapturingIndicatorDot,
  SectionHeader,
  CodeSelectorButton,
  CodeSelectorButtonDark,
  DeleteSectionButton,
  DeleteSectionButtonDark,
  CodeLabel,
  KeyboardIcon,
  DiagnosisNumber,
  DiagnosisNumberDark
} from './styled'
import { isAmwellTheme } from '../../lib/util'
import { SECTION_STATE } from '../../lib/constants'

class ProblemSection extends Component {
  constructor (props) {
    super(props)
    this.sectionNameTextarea = React.createRef()
    this.sectionNameTextareaDark = React.createRef()
  }

  state = {
    textAnim: new Animated.Value(0),
    dotAnim1: new Animated.Value(0),
    dotAnim2: new Animated.Value(0),
    dotAnim3: new Animated.Value(0),
    animating: false,
    isFocused: false,
    isAmwell: isAmwellTheme(),
    windowInnerWidth: window.innerWidth
  }

  handleFocusOn () {
    if (this.state.isAmwell) this.setState({ isFocused: true })
  }

  handleFocusOff () {
    if (this.state.isAmwell) this.setState({ isFocused: false })
  }

  getProblemSectionId = () => {
    const { id } = this.props
    return `section-name-input-${id}`
  }

  getTextAreaRowsAndColsCount () {
    let componentWidth = 300
    if (this.state.isAmwell) componentWidth = this.sectionNameTextareaDark.current?.clientWidth
    else componentWidth = this.sectionNameTextarea.current?.clientWidth

    let divider = 10
    if (componentWidth <= 170) divider = 16
    if (componentWidth <= 185) divider = 15
    else if (componentWidth <= 210) divider = 13
    else if (componentWidth <= 280) divider = 12
    else if (componentWidth <= 305) divider = 11

    const colsCount = Math.ceil(componentWidth / divider)
    const rowsCount = Math.ceil(this.props.name.length / colsCount)

    return { rowsCount, colsCount }
  }

  handleWindowDimensionsChange = () => {
    this.setState({
      width: window.innerWidth
    })
  }

  componentDidMount () {
    const {
      isNewSection,
      scrollToSection,
      setSection
    } = this.props

    const inputElement = document.getElementById(this.getProblemSectionId())
    inputElement && inputElement.addEventListener('keyup', e => {
      if (e.keyCode === 13) {
        e.preventDefault()
        inputElement.blur()
      }
    })

    // TODO: this is obsolete rn :/
    // Ensures that focus is in the new section as soon as it's added
    if (isNewSection) {
      inputElement.focus()

      // Scrolls to the top of the new problem section and put focus into it
      scrollToSection('add-problem-section', true)
      setSection('add-problem-section', SECTION_STATE.FOCUSED)
    }
    window.addEventListener('resize', this.handleWindowDimensionsChange)
  }

  componentWillUnmount () {
    const inputElement = document.getElementById(this.getProblemSectionId())
    inputElement && inputElement.removeEventListener('keyup', e => {
      if (e.keyCode === 13) {
        e.preventDefault()
        inputElement.blur()
      }
    })
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.currentSectionId === this.props.id &&
      nextProps.currentSectionState === SECTION_STATE.DICTATION &&
      !this.state.animating) {
      this.startAnimation()
    } else if (nextProps.currentSectionId !== this.props.id && this.state.animating) {
      this.setState({
        animating: false,
        textAnim: new Animated.Value(0),
        dotAnim1: new Animated.Value(0),
        dotAnim2: new Animated.Value(0),
        dotAnim3: new Animated.Value(0)
      })
    }

    const wasSelected = this.props.id === this.props.currentSectionId
    const notSelectedNow = nextProps.currentSectionId !== this.props.id

    if (wasSelected && notSelectedNow) {
      const elementId = `section-name-input-${this.props.id}`
      const ele = document.getElementById(elementId)
      const updatedName = ele.value
      const isFocused = ele === document.activeElement
      if (updatedName === '' && !isFocused) {
        this.props.deleteEmptyProblemSection(this.props.id)
      }
    }
  }

  startAnimation = () => {
    this.setState({ animating: true })

    Animated.timing(this.state.textAnim, {
      toValue: 1,
      duration: 300,
      easing: Easing.linear
    }).start()

    Animated.loop(
      Animated.stagger(300,
        [
          Animated.sequence([
            Animated.timing(this.state.dotAnim1, {
              toValue: 1,
              duration: 500,
              easing: Easing.linear
            }),
            Animated.delay(100),
            Animated.timing(this.state.dotAnim1, {
              toValue: 0,
              duration: 500,
              easing: Easing.linear
            }),
            Animated.delay(500)
          ]),
          Animated.sequence([
            Animated.timing(this.state.dotAnim2, {
              toValue: 1,
              duration: 500,
              easing: Easing.linear
            }),
            Animated.delay(100),
            Animated.timing(this.state.dotAnim2, {
              toValue: 0,
              duration: 500,
              easing: Easing.linear
            }),
            Animated.delay(500)
          ]),
          Animated.sequence([
            Animated.timing(this.state.dotAnim3, {
              toValue: 1,
              duration: 500,
              easing: Easing.linear
            }),
            Animated.delay(100),
            Animated.timing(this.state.dotAnim3, {
              toValue: 0,
              duration: 500,
              easing: Easing.linear
            }),
            Animated.delay(500)
          ])
        ]
      )
    ).start()
  }

  handleWrapperClick = e => {
    const { id, setSection, handleSectionClick, dictation } = this.props

    handleSectionClick && handleSectionClick(e)
    setSection && setSection(id, dictation ? SECTION_STATE.DICTATION : SECTION_STATE.TYPING)
  }

  handleCodeClick = (e) => {
    e.stopPropagation()
    const { icdCode, promptICD10Lookup, promptCodePreview, isNewSection } = this.props
    if (isNewSection) return

    if (icdCode) {
      promptCodePreview()
    } else {
      promptICD10Lookup()
    }
  }

  handleNameSave = e => {
    const {
      saveSectionName,
      id,
      currentSectionState,
      deleteEmptyProblemSection
    } = this.props

    this.handleFocusOff()

    const elementId = `section-name-input-${id}`
    const updatedName = document.getElementById(elementId).value
    const suggestionsElement = document.getElementById('hcc-suggestions-panel')

    if (updatedName === '' && currentSectionState !== SECTION_STATE.DICTATION) {
      deleteEmptyProblemSection(id)
      return
    }

    // dont save if not in typing mode
    if (currentSectionState === SECTION_STATE.DICTATION) {
      return
    }

    // if clicking on suggestions panel
    // dont save or delete
    if (suggestionsElement && !suggestionsElement?.contains(e.target)) {
      return
    }

    saveSectionName(id, updatedName)
  }

  handleSectionNameChange = e => {
    this.props.handleSectionNameChange(e.target.value)
  }

  handleDeleteSection = e => {
    e.stopPropagation()
    const { handleDeleteSection, id } = this.props
    handleDeleteSection(id)
  }

  formatCode = code => {
    if (!code) return false
    if (code.length > 3) {
      return code.substring(0, 3) + '.' + code.substring(3)
    }
    return code
  }

  renderCodeButtonDark = () => {
    const { isReadOnly, icdCode, isNewSection, id } = this.props

    if (isReadOnly) {
      return (
        <CodeLabel icdCode={icdCode}>
          {this.formatCode(icdCode) || 'No Code'}
        </CodeLabel>
      )
    } else if (!isNewSection) {
      return (
        <CodeSelectorButtonDark
          icdCode={icdCode}
          onClick={this.handleCodeClick}
          disabled={isNewSection}
          data-cy={`code-button-${id}`}
        >
          {this.formatCode(icdCode) || 'Add Code'}
        </CodeSelectorButtonDark>
      )
    }
  }

  renderCodeButton = () => {
    const { isReadOnly, icdCode, isNewSection, id } = this.props

    if (isReadOnly) {
      return (
        <CodeLabel icdCode={icdCode}>
          {this.formatCode(icdCode) || 'No Code'}
        </CodeLabel>
      )
    } else if (!isNewSection) {
      return (
        <CodeSelectorButton
          icdCode={icdCode}
          onClick={this.handleCodeClick}
          disabled={isNewSection}
          data-cy={`code-button-${id}`}
        >
          {this.formatCode(icdCode) || 'Add Code'}
        </CodeSelectorButton>
      )
    }
  }

  renderSectionNameDark = () => {
    const {
      isReadOnly,
      isNewSection,
      name,
      handleNameInputClick,
      handleNameKeyDown,
      id,
      currentSectionId
    } = this.props
    const sectionId = this.getProblemSectionId()
    const isActive = id === currentSectionId
    if (isReadOnly) {
      return (
        <SectionName key={sectionId} isActive={isActive}>
          {name}
        </SectionName>
      )
    } else {
      const { rowsCount, colsCount } = this.getTextAreaRowsAndColsCount()
      return (
        <SectionNameTextareaDark
          ref={this.sectionNameTextareaDark}
          isFocused={this.state.isFocused}
          onFocus={(e) => this.handleFocusOn()}
          id={sectionId}
          key={sectionId}
          name={sectionId}
          cols={colsCount}
          rows={rowsCount}
          isnewsection={isNewSection}
          placeholder='section name here'
          value={name}
          onClick={handleNameInputClick}
          onChange={this.handleSectionNameChange}
          onKeyDown={handleNameKeyDown}
          onBlur={this.handleNameSave}
          autoComplete='off'
          isActive={isActive}
        />
      )
    }
  }

  renderSectionName = () => {
    const {
      isReadOnly,
      isNewSection,
      name,
      handleNameInputClick,
      handleNameKeyDown,
      id,
      currentSectionId
    } = this.props

    const sectionId = this.getProblemSectionId()
    const isActive = id === currentSectionId
    if (isReadOnly) {
      return (
        <SectionName key={sectionId} data-cy={`${sectionId}-ReadOnly`} isActive={isActive}>
          {name}
        </SectionName>
      )
    } else {
      const { rowsCount, colsCount } = this.getTextAreaRowsAndColsCount()
      return (
        <SectionNameTextarea
          data-cy={sectionId}
          ref={this.sectionNameTextarea}
          id={sectionId}
          key={sectionId}
          name={sectionId}
          isnewsection={isNewSection}
          placeholder='section name here'
          value={name}
          cols={colsCount}
          rows={rowsCount}
          onClick={handleNameInputClick}
          onChange={this.handleSectionNameChange}
          onKeyDown={handleNameKeyDown}
          onBlur={this.handleNameSave}
          autoComplete='off'
          isActive={isActive}
        />
      )
    }
  }

  render () {
    const {
      id,
      name,
      editor,
      currentSectionId,
      currentSectionState,
      isNewSection,
      diagnosisCount,
      isReadOnly,
      keyboardMode
    } = this.props

    const styles = {
      capturing: {
        opacity: this.state.textAnim
      },
      dot1: {
        transform: [
          {
            scale: this.state.dotAnim1.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 1.3]
            })
          }
        ]
      },
      dot2: {
        transform: [
          {
            scale: this.state.dotAnim2.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 1.3]
            })
          }
        ]
      },
      dot3: {
        transform: [
          {
            scale: this.state.dotAnim3.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 1.3]
            })
          }
        ]
      }
    }

    const diagnosisNumber = diagnosisCount + '.'
    const isActive = currentSectionId === id
    return (
      <Wrapper
        onClick={this.handleWrapperClick}
      >
        <SectionContainer
          id={id}
          isreadonly={isReadOnly}
          data-cy={`problem-section-container-${id}`}
          isAmwellTheme={this.state.isAmwell}
          isActive={isActive}
          isDictationMode={!keyboardMode}
          isFocused={this.state.isFocused}
        >
          {isActive && currentSectionState === SECTION_STATE.DICTATION && !keyboardMode &&
            <CapturingIndicator style={styles.capturing}>
              <CapturingIndicatorDot style={styles.dot1} isAmwellTheme={this.state.isAmwell} />
              <CapturingIndicatorDot style={styles.dot2} isAmwellTheme={this.state.isAmwell} />
              <CapturingIndicatorDot style={styles.dot3} isAmwellTheme={this.state.isAmwell} />
            </CapturingIndicator>}
          {isActive && !this.state.isAmwell && <SectionIndicator data-cy={`section-indicator-${name.replace(/\W/g, '-').toLowerCase()}`} />}
          <SectionHeader isAmwellTheme={this.state.isAmwell}>
            {this.state.isAmwell ? (
              <ProblemSectionNameDark data-cy={`problem-section-name-${id}`}>
                {isReadOnly ? this.renderSectionNameDark() : [<DiagnosisNumberDark>{diagnosisNumber}</DiagnosisNumberDark>, this.renderSectionNameDark()]}
              </ProblemSectionNameDark>
            ) : (
              <ProblemSectionName data-cy={`problem-section-name-${id}`}>
                {isReadOnly ? this.renderSectionName() : [<DiagnosisNumber>{diagnosisNumber}</DiagnosisNumber>, this.renderSectionName()]}
              </ProblemSectionName>
            )}
            {!this.state.isAmwell && currentSectionId === id && currentSectionState !== SECTION_STATE.DICTATION && keyboardMode && <KeyboardIcon data-cy={`problem-section-keyboardicon-${id}`} />}
            {this.state.isAmwell ? this.renderCodeButtonDark() : this.renderCodeButton()}
            {this.state.isAmwell && !isReadOnly && !isNewSection && (
              <DeleteSectionButtonDark onClick={this.handleDeleteSection}>
                <div id='mdiv'>
                  <div className='mdiv'>
                    <div className='md' />
                  </div>
                </div>
              </DeleteSectionButtonDark>
            )}
            {!this.state.isAmwell && !isReadOnly && !isNewSection && <DeleteSectionButton onClick={this.handleDeleteSection} />}
          </SectionHeader>
          {!isNewSection &&
            <SectionContent isAmwellDark={this.state.isAmwell} data-cy={`section-content-${name.replace(/\W/g, '-').toLowerCase()}`}>
              {editor}
            </SectionContent>}
        </SectionContainer>
      </Wrapper>
    )
  }
}

export default ProblemSection

export {
  SectionContent,
  Blurred,
  Macro,
  ScribeToProcess,
  Paragraph
}
