import React from 'react'
import { sectionNameSize } from '../../styles/dimensions'
import { GetScriptChangeAudio } from '@sukiai/gql/admin'
import { PlaybackList } from '../Audio'
import { ScriptChangeRawTranscript } from './index'
import { compose, graphql } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import get from 'lodash.get'
import { FETCH_POLICY } from '../../lib/constants'

const styles = {
  container: {
    minHeight: 60,
    marginBottom: 20,
    padding: '20px 50px 0 40px',
    boxSizing: 'border-box',
    wordWrap: 'break-word',
    borderTop: '1px solid #a8a8a8',
    borderLeft: '10px'
  },
  sectionName: {
    fontSize: sectionNameSize,
    color: '#a8a8a8',
    fontWeight: 800,
    marginBottom: 12,
    width: '100%'
  },
  sectionAudioControls: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '76%'
  }
}

const ScriptChangeSection = ({ audioTranscriptsLoading, audioTranscriptsData }) => (
  <div
    style={styles.container}
    id='change-script-section'
    data-cy='change-script-section'
  >
    <div style={styles.sectionName}>Change Command Audio</div>
    {get(audioTranscriptsData, 'length', 0) > 0 && (
      <div style={styles.sectionAudioControls}>
        <PlaybackList audioTranscripts={audioTranscriptsData} />
      </div>
    )}
    {!audioTranscriptsLoading && (
      <ScriptChangeRawTranscript audioTranscripts={audioTranscriptsData} />
    )}
  </div>
)

const mapAudioToProps = ({ data }) => ({
  audioTranscriptsError: data.error,
  audioTranscriptsLoading: data.loading,
  audioTranscriptsData: get(data, 'transcripts.results', [])
})

export default compose(
  withRouter,
  graphql(GetScriptChangeAudio, {
    skip: ({ noteId, match }) => !noteId || !get(match, 'params.orgId'),
    options: ({ noteId, match }) => ({
      variables: {
        organizationId: get(match, 'params.orgId'),
        compositionId: noteId
      },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK
    }),
    props: mapAudioToProps
  })
)(ScriptChangeSection)
