import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, graphql } from 'react-apollo'
import { setCurrentSection } from '../../actions/note'
import { withRouter } from 'react-router'
import { charcoal, adminHeaderGrey, fulvous } from '../../styles/colors'
import { sectionNameSize } from '../../styles/dimensions'
import { AudioAndTranscriptsBySection } from '@sukiai/gql/admin'
import { PlaybackList } from '../Audio'
import { dictateCursorUsed } from '@sukiai/utils'
import Editor from '@sukiai/quill-editor'
import { copyText, isHTMLString, sanitizeHTML } from '../../lib/util'
import {
  RawTranscript,
  EditedTranscript,
  TranscriptHistory
} from './index'
import { NOTE_MODE, GRAPHQL_TYPE, ROLES, FETCH_POLICY, EDITOR_TYPE } from '../../lib/constants'
import get from 'lodash.get'
import FontIcon from 'material-ui/FontIcon'
import styled from 'styled-components'

const DictateAtCursorFlag = styled.div`
  position: absolute;
  left: 15px;
`

const styles = {
  container: {
    padding: '0 50px 20px',
    boxSizing: 'border-box',
    wordWrap: 'break-word',
    position: 'relative'
  },
  sectionName: {
    fontSize: sectionNameSize,
    fontWeight: 800,
    marginBottom: 12,
    width: '100%'
  },
  sectionId: {
    fontWeight: 500,
    marginLeft: 8,
    color: adminHeaderGrey,
    fontSize: 14
  },
  link: {
    color: adminHeaderGrey,
    marginRight: 4,
    cursor: 'pointer'
  },
  subsection: {
    marginTop: 10,
    paddingLeft: 15
  },
  subsectionName: {
    fontWeight: 600,
    color: charcoal,
    fontSize: 18,
    outline: 'none'
  },
  pbnSectionLabel: {
    fontWeight: '600',
    color: 'green',
    fontSize: '12pt',
    margin: '0px 10px'
  }
}

class NoteSection extends Component {
  handleSetSection = () => {
    const {
      currentSectionId,
      section: { id },
      setCurrentSection
    } = this.props

    if (currentSectionId !== id) setCurrentSection(id)
  }

  showAudio = noteMode => noteMode !== NOTE_MODE.PLAIN

  showRawTranscript = noteMode => noteMode === NOTE_MODE.DEBUG

  showTranscriptHistory = (type, noteMode) => (
    noteMode === NOTE_MODE.QA || noteMode === NOTE_MODE.DEBUG
  )

  showEditedTranscript = (type, noteMode) => noteMode === NOTE_MODE.DEBUG || noteMode === NOTE_MODE.PLAIN

  renderEditor (section) {
    const { s2Mode, content } = section

    // [Parial dictation]: To render html section in non editable mode
    const text = content && JSON.parse(content).total_string
    const isHTML = isHTMLString(text)

    return isHTML ? (
      <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(text) }} style={{ 'overflow-x': 'hidden' }} />
    ) : (
      <Editor
        defaultValue={content}
        persona={ROLES.ADMIN}
        editorType={EDITOR_TYPE.NOTE}
        readOnly
        s2Mode={s2Mode}
      />
    )
  }

  render () {
    const {
      section,
      audioAndTranscripts,
      loadingAudioAndTranscripts,
      noteMode,
      wasComp,
      type,
      versionedSection
    } = this.props

    const currentSectionId = section.id
    const dictateAtCursorUsed = dictateCursorUsed(versionedSection, section)
    let enableAudioDownload = true
    const currentOrganization = this.props?.versionedSection[0]?.author?.organizationName
    if (currentOrganization === 'Privia') {
      enableAudioDownload = false
    }

    return (
      <div style={styles.container} onClick={this.handleSetSection} id={section.id}>
        {dictateAtCursorUsed && (
          <DictateAtCursorFlag>
            <FontIcon
              className='material-icons'
              color={fulvous}
              style={{ fontSize: 30 }}
            >
              priority_high
            </FontIcon>
          </DictateAtCursorFlag>)}
        <div style={styles.sectionName}>
          <span>{section.name}</span>
          {section.pbcSectionFlag && <span style={styles.pbnSectionLabel}>PBN Section Parent</span>}
          {!!section.diagnosisEntry && <span style={styles.pbnSectionLabel}>Problem Section</span>}
          <span style={styles.sectionId}>
            {noteMode === NOTE_MODE.DEBUG && (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => copyText(section.id)}
              >
                  &nbsp;(
                {section.id})
              </span>
            )}
          </span>
        </div>

        {type === GRAPHQL_TYPE.COMPOSITION || (type === GRAPHQL_TYPE.PATIENT_NOTE && wasComp) ? (
          <div>
            {this.showAudio(noteMode) && get(audioAndTranscripts, 'length', 0) > 0 && (
              <div style={{ paddingLeft: 15 }}>
                <PlaybackList
                  audioTranscripts={audioAndTranscripts}
                  sectionId={section.id}
                  isCurrentSection={currentSectionId === section.id}
                  enableAudioDownload={enableAudioDownload}
                />
              </div>
            )}
            {this.showRawTranscript(noteMode) && (
              <RawTranscript
                style={styles.subsection}
                headingStyle={{ ...styles.subsectionName, color: 'rgba(1, 1, 1, 0.5)' }}
                loadingAudioAndTranscripts={loadingAudioAndTranscripts}
                audioAndTranscripts={audioAndTranscripts}
              />
            )}
            {this.showTranscriptHistory(type, noteMode) && (
              <TranscriptHistory
                style={styles.subsection}
                versions={versionedSection}
                headingStyle={styles.subsectionName}
                noteMode={noteMode}
                sectionId={currentSectionId}
                s2Mode={section.s2Mode}
              />
            )}
            {this.showEditedTranscript(type, noteMode) && (
              <EditedTranscript
                style={styles.subsection}
                headingStyle={styles.subsectionName}
                section={section}
                s2Mode={section.s2Mode}
              />
            )}
          </div>
        ) : (
          <div style={styles.section}>
            {this.renderEditor(section)}
          </div>
        )}
      </div>
    )
  }
}

NoteSection.propTypes = {
  /**
   * This section
   */
  section: PropTypes.object.isRequired
}

const mapDispatchToProps = dispatch => ({
  setCurrentSection: id => dispatch(setCurrentSection(id))
})

const mapAudioAndTranscriptsToProps = ({ data }) => ({
  errorFetchingAudioAndTranscripts: data.error,
  loadingAudioAndTranscripts: data.loading,
  audioAndTranscripts: data.transcripts && data.transcripts.results,
  loadAudioAndTranscripts: data.refetch
})

export default compose(
  connect(null, mapDispatchToProps),
  graphql(AudioAndTranscriptsBySection, {
    skip: ({ type, orgId, note, section, wasComp }) => {
      if (type === GRAPHQL_TYPE.COMPOSITION) {
        return !orgId || !note.id || !section.id
      } else {
        return !wasComp || !orgId || !note.compositionId || !section.id
      }
    },
    options: ({ orgId, type, note, section }) => ({
      variables: {
        organizationId: orgId,
        compositionId: type === GRAPHQL_TYPE.COMPOSITION ? note.id : note.compositionId,
        sectionId: section.id
      },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK
    }),
    props: mapAudioAndTranscriptsToProps
  }),
  withRouter
)(NoteSection)
