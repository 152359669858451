import React, { Component, Fragment } from 'react'
import { ToastContainer, AmwellToastContainer, ToastText, ErrorIcon, AmwellText } from './styled'
import { Animated, Easing } from 'react-native'
import { TOAST } from '../../lib/constants'
import { OutOfBoxWrapper } from '../OutOfBoxToast/styled'
import { isAmwellTheme } from '../../lib/util'

class Toast extends Component {
  state = {
    toastAnim: new Animated.Value(0)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.shouldDismiss) {
      return Animated.sequence([
        Animated.delay(200),
        Animated.timing(this.state.toastAnim, {
          toValue: 0,
          duration: 200,
          easing: Easing.out(Easing.quad)
        })
      ]).start(this.props.onDismiss)
    }

    if (nextProps.message && nextProps.message !== this.props.message) {
      const duration = nextProps.duration || TOAST.DURATION.MEDIUM

      if (duration === TOAST.DURATION.INDEFINITE) {
        return Animated.sequence([
          Animated.delay(200),
          Animated.timing(this.state.toastAnim, {
            toValue: 1,
            duration: 200,
            easing: Easing.out(Easing.quad)
          })
        ]).start()
      } else {
        return Animated.sequence([
          Animated.delay(200),
          Animated.timing(this.state.toastAnim, {
            toValue: 1,
            duration: 200,
            easing: Easing.out(Easing.quad)
          }),
          Animated.delay(duration),
          Animated.timing(this.state.toastAnim, {
            toValue: 0,
            duration: 200,
            easing: Easing.in()
          })
        ]).start(this.props.onDismiss)
      }
    }
  }

  render () {
    const {
      type,
      message
    } = this.props

    const vw = window.innerWidth
    const vh = window.innerHeight

    const styles = {
      toast: {
        transform: [{
          translateY: this.state.toastAnim.interpolate({
            inputRange: [0, 1],
            outputRange: ['0%', '-165%']
          })
        }]
      }
    }
    const isAmwell = isAmwellTheme()

    return (
      <div data-cy='toast-container'>
        {isAmwell
          ? (
            <AmwellToastContainer
              message={message}
              style={styles.toast}
              vw={vw}
              vh={vh}
              type={type}
            >
              <AmwellText type={type}>
                {message}
              </AmwellText>
            </AmwellToastContainer>
          )
          : (
            <ToastContainer
              message={message}
              style={styles.toast}
              vw={vw}
              vh={vh}
              type={type}
            >
              {type === TOAST.TYPE.ERROR && <ErrorIcon />}
              <ToastText type={type}>
                {message}
              </ToastText>
            </ToastContainer>
          )}
      </div>
    )
  }
}

export default Toast
