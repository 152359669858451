import styled from 'styled-components'
import { navBarHeight, sideBarWidth } from '../../styles/dimensions'
import { whiteSmoke, charcoal } from '../../styles/colors'

const LIGHT_GREY = '#D3D3D3'
const MEDIUM_GREY = '#C0C0C0'
const ARROW_GREY = '#A0A0A0'
const DARK_GREY = '#808080'

export const EditorContainer = styled.div`
  position: fixed;
  top: ${navBarHeight};
  left: ${sideBarWidth};
  height: ${`calc(100vh - ${navBarHeight})`};
  width: ${`calc(100vw - ${sideBarWidth})`};
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  overflow: scroll;
`

export const Empty = styled.div`
  width: 50%;
  border-radius: 4px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.3);
  background: ${whiteSmoke};
  color: ${charcoal};
  font-size: 16px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  height: fit-content;
`

export const BlockControls = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
`

export const Arrow = styled.button.attrs({
  type: 'button'
})`
  border: none;
  outline: none;
  padding: 0px;
  color: ${ARROW_GREY};
  ${p => p.disabled && 'opacity: 0.4;'}
  transition: all 0.3s;
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  height: 26px;
  width: 26px;
  font-size: 26px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 6px;
`

export const ContentContainer = styled.div`
  margin-left: 24px;
  border: 1px solid ${LIGHT_GREY};
  padding: 10px;
`

export const AddBlockButton = styled.div`
  width: fit-content;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`

export const NameContainer = styled.div`
  position: relative;
  width: 50%;
  margin-bottom: 10px;
`

export const SearchContainer = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  z-index: 2;
  max-height: 50vh;
  overflow-y: scroll;
`

export const SearchResult = styled.div`
  width: 100%;
  padding: 14px 20px;
  box-sizing: border-box;
  background: white;
  color: black;
  font-size: 18px;
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    background: ${LIGHT_GREY};
  }

  &:active {
    background: ${MEDIUM_GREY};
  }
`

export const ResultHints = styled.span`
  color: ${DARK_GREY};
  font-weight: 400;
`

export const SearchEmpty = styled(SearchResult)`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: default;

  &:hover {
    background: white;
  }

  &:active {
    background: white;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${LIGHT_GREY};
`

export const ModalVeil = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContainer = styled.div`
  width: 600px;
  padding: 20px;
  background: ${whiteSmoke};
  color: ${charcoal};
  border-radius: 4px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.5);
`

export const ModalHeading = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 14px;
`

export const ModalBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ModalSelect = styled.select`
  font-size: 16px;
  flex: 1;
`

export const ModalButton = styled.button.attrs({
  type: 'button'
})`
  border: none;
  outline: none;
  padding: 0px;
  background: ${charcoal};
  color: ${whiteSmoke};
  ${p => p.disabled && 'opacity: 0.5;'}
  transition: all 0.3s;
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 14px;
  margin-left: 14px;
`
