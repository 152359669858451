import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import {
  Container,
  Header,
  Title,
  AddButton,
  List,
  Item,
  SectionTitle,
  Divider,
  Empty,
  DropDownList,
  Source
} from './styled'
import Loading from '../../components/Loading'

const mapItem = ({ id, name, source }, selected, handleSelect, secondary) => (
  <Item
    key={id}
    data-cy='sidebar-item'
    data-selected={selected}
    id={id}
    onClick={() => handleSelect(id)}
    selected={selected}
    secondary={secondary}
  >
    {name}
    {source === 'USER' && (
      <Source>
        {source}
      </Source>
    )}
  </Item>
)

const Sidebar = ({
  withNav,
  title,
  dataType,
  handleAdd,
  loading,
  items,
  sections,
  selectedId,
  handleSelect,
  dropList,
  dropListSelected,
  handleDropListSelect
}) => {
  const dropListElement = get(dropList, 'length') ? (
    <DropDownList value={dropListSelected} onChange={handleDropListSelect}>
      {dropList.map((option) => {
        return <option key={option.value} value={option.value}>{option.name}</option>
      })}
    </DropDownList>) : null

  const list = get(sections, 'length') ? (
    sections.map((s, idx, ss) => (
      <React.Fragment key={`${idx}-${s.title}`}>
        <SectionTitle data-cy={`sidebar-section-${idx}-title`}>
          {s.title}
        </SectionTitle>
        {get(s, 'items.length') ? (
          get(s, 'items', []).map(i =>
            mapItem(i, i.id === selectedId, handleSelect, true)
          )
        ) : (
          <Empty data-cy={`sidebar-section-${idx + 1}-empty`} secondary>
            No {dataType}s yet
          </Empty>
        )}
        {idx !== get(ss, 'length', 0) - 1 && <Divider />}
      </React.Fragment>
    ))
  ) : get(items, 'length') ? (
    items.map(i => mapItem(i, i.id === selectedId, handleSelect))
  ) : (
    <Empty data-cy='sidebar-items-empty'>No {dataType}s yet</Empty>
  )

  return (
    <Container withNav={withNav}>
      <Header>
        <Title data-cy='sidebar-title' withButton={!!handleAdd}>
          {title}
        </Title>
        {handleAdd && (
          <AddButton data-cy='sidebar-add' onClick={handleAdd}>
            +
          </AddButton>
        )}
      </Header>
      <List data-cy='sidebar-list'>
        {dropListElement}
        {loading ? <Loading data-cy='sidebar-loading' /> : list}
      </List>
    </Container>
  )
}

const SidebarItemType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  source: PropTypes.string
})

const SidebarSectionType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(SidebarItemType)
})

const DropListType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
})

Sidebar.propTypes = {
  withNav: PropTypes.bool,
  title: PropTypes.string.isRequired,
  dataType: PropTypes.string,
  handleAdd: PropTypes.func,
  loading: PropTypes.bool,
  items: PropTypes.arrayOf(SidebarItemType),
  sections: PropTypes.arrayOf(SidebarSectionType),
  selectedId: PropTypes.string,
  handleSelect: PropTypes.func,
  dropList: PropTypes.arrayOf(DropListType),
  dropListSelected: PropTypes.string,
  handleDropListSelect: PropTypes.func
}

Sidebar.defaultProps = {
  withNav: true,
  title: 'Items',
  dataType: 'item',
  handleAdd: () => {},
  loading: false,
  items: [],
  sections: [],
  selectedId: null,
  handleSelect: () => {}
}

export default Sidebar
