import gql from 'graphql-tag'

// USED IN: NOTE_TYPES
export const GetUserById = gql`
  query GetUserById($organizationId: ID!, $id: ID!) {
    users(input: { organizationId: $organizationId, ids: [$id] }) {
      results {
        id
        enhancedReview
        roles {
          type
        }
        person {
          firstName
          middleName
          lastName
          prefix
          suffix
        }
        userType,
        sharedUser
      }
    }
  }
`

export const GetUserOnLogin = gql`
  query GetUserById($id: ID!, $onLogin: Boolean) {
    users(input: {
      ids: [$id],
      onLogin: $onLogin
    }) {
      results {
        id
        roles {
          type
        }
        person {
          firstName
          middleName
          lastName
          prefix
          suffix
        }
        userType
      },
      launchDarklySdkId
    }
  }
`

// USED IN: USERS, APPT_MANAGER, USER_SELECTOR
export const GetUsers = gql`
  query GetUsers($organizationId: ID!, $ids: [ID!]) {
    users(
      input: { organizationId: $organizationId, ids: $ids, withEmrInfo: true }
    ) {
      results {
        id
        enhancedReview
        roles {
          type
        }
        person {
          firstName
          middleName
          lastName
          prefix
          suffix
          gender
          dateOfBirth
        }
        email
        phoneNumber
        specialty
        specialties
        timezone
        userType
        activationSent
        emrUserId
        emrUserIdType
        emrDepartmentId
        emrUserIdList
        fhirUserId
        scheduleHealthcheckTimestamp
        noteHealthcheckTimestamp,
        sharedUser
      }
    }
  }
`

export const SearchUsers = gql`
  query SearchUsers ($query: String!) {
    searchUsers(input: {query: $query}) {
      count,
      results {
        id
        enhancedReview
        organizationId
        roles {
            type
            __typename
        }
        person {
            firstName
            middleName
            lastName
            prefix
            suffix
            __typename
        }
        email
        specialties
        userType
      }
    }
  }
`

// USED IN: USERS
export const CreateUser = gql`
  mutation CreateUser(
    $organizationId: ID!
    $roles: [RoleInput!]!
    $person: PersonInput!
    $email: String!
    $phoneNumber: String
    $specialty: String
    $specialties: [SpecialtyType!]
    $timezone: String!
    $userType: String,
    $emr: EMRSecondaryType,
    $sharedUser: Boolean
    $enhancedReview: String
  ) {
    createUser(
      input: {
        organizationId: $organizationId
        roles: $roles
        person: $person
        email: $email
        phoneNumber: $phoneNumber
        specialty: $specialty
        specialties: $specialties
        timezone: $timezone
        userType: $userType,
        emr: $emr,
        sharedUser: $sharedUser
        enhancedReview: $enhancedReview
      }
    ) {
      user {
        id
      }
    }
  }
`

// E2E TEST ONLY
export const CreateUserWithPassword = gql`
  mutation CreateUserWithPassword(
    $organizationId: ID!
    $roles: [RoleInput!] = [{ type: USER }]
    $person: PersonInput!
    $email: String!
    $specialty: String
    $specialties: [SpecialtyType!]
    $timezone: String!
    $userType: String
    $password: String!,
    $emr: EMRSecondaryType
  ) {
    createUserWithPassword(
      input: {
        organizationId: $organizationId
        roles: $roles
        person: $person
        email: $email
        specialty: $specialty
        specialties: $specialties
        timezone: $timezone
        userType: $userType
        password: $password,
        emr: $emr
      }
    ) {
      user {
        id
      }
    }
  }
`

export const ActivateUser = gql`
  mutation ActivateUser($organizationId: ID!, $id: ID!) {
    activateUser(input: { organizationId: $organizationId, id: $id }) {
      id
    }
  }
`

// USED IN: USERS
export const UpdateUser = gql`
  mutation UpdateUser(
    $organizationId: ID!
    $id: ID!
    $roles: [RoleInput!]!
    $person: PersonInput
    $email: String
    $phoneNumber: String
    $specialty: String
    $specialties: [SpecialtyType!]
    $timezone: String
    $userType: String
    $activationSent: String,
    $sharedUser: Boolean,
    $enhancedReview: String
  ) {
    updateUser(
      input: {
        organizationId: $organizationId
        user: {
          id: $id
          roles: $roles
          person: $person
          email: $email
          phoneNumber: $phoneNumber
          specialty: $specialty
          specialties: $specialties
          timezone: $timezone
          userType: $userType
          activationSent: $activationSent,
          sharedUser: $sharedUser,
          enhancedReview: $enhancedReview
        }
      }
    ) {
      user {
        id
      }
    }
  }
`

// USED IN: USERS
export const DeleteUsers = gql`
  mutation DeleteUsers($organizationId: ID!, $ids: [ID!]!) {
    deleteUsers(input: { organizationId: $organizationId, ids: $ids }) {
      ids
    }
  }
`

export const GetPendingUsers = gql`
  query GetPendingUsers($organizationId: ID!, $ids: [ID!], $emails: [String!]) {
    pendingUsers(
      input: { organizationId: $organizationId, ids: $ids, emails: $emails }
    ) {
      results {
        user {
          id
          person {
            firstName
            middleName
            lastName
            prefix
            suffix
          },
          roles {
            type
          }
          specialties,
          timezone
          email
          phoneNumber
          organizationId
        }
        invitedAt,
        registerStage
      }
    }
  }
`

export const SendDemoEmail = gql`
  mutation SendDemoEmail($user: CreateUserInput!) {
    sendDemoEmail(
      input: { user: $user }
    ) {
      email
    }
  }
`

export const RetractDemoInvitation = gql`
  mutation RetractDemoInvitation($id: ID!) {
    deletePendingUser(
      input: { id: $id }
    ) {
      id
    }
  }
`
