// ***Slate Grey Palette***
export const charcoal = '#29404F'
export const modalOverlay = 'rgba(0,0,0,0.5)'
export const slate = '#ACBCC3'
export const slateGrey = '#5E798E'
export const platinum = '#D9E2EC'
export const listHeaderGrey = '#8493A1'

// ***Color Palette***
export const ginger = '#B76609'
export const fulvous = '#DF6B06'
export const pastelOrange = '#FAC242'
export const antiqueWhite = '#FBEBD4'
export const burntSierra = '#E87154'
export const burntSierraLight = '#FFD4CA'
export const mediumTurquoise = '#54C5C5'
export const whiteSmoke = '#F5F7FA'
export const solidBlack = '#000000'
export const solidWhite = '#FFFFFF'
export const dollarBill = '#8BBD4F'
export const greyShades = {
  grey1: '#D2D2D2',
  grey2: '#E6E6E6'
}

// ***New Suki Palette - 2019***
export const black = '#000000'
export const white = '#ffffff'
export const casal = '#2D5E60'
export const snowDrift = '#F7F9F7'
export const snowyDrift = '#F8FAF7'
export const westSide = '#ff9e14'
export const lightGray = '#787a78'

// ***Application Palette***

// Accent color - check marks, enabled mic icon
export const accentColor = fulvous

// For disabled items
export const disabledItem = '#9B9B9B'

// Area that medical form/note sits over
export const formAreaBkg = platinum

export const sideBarText = charcoal

export const sideBarVeil = 'rgba(0,0,0,0.12)'

export const titleBarItems = charcoal

// Color for under modals
// Previously: 'rgba(41,64,79,0.8)'
export const underModal = modalOverlay

export const emrBadge = slateGrey

// Schedule
export const scheduleGrey = '#B8B8B8'

// Agent
export const agentRing = 'rgba(84,198,198,0.7)'

// Scribe
export const scribeGreen = '#417505'

// DEMO
export const demoGrey = '#DCDCDC'

// Editors
export const borderGrey = '#D0D0D0'

// Read-only Robin Editor
export const readOnlyGrey = '#707070'

// Appointment border color
export const appointmentBorder = '#DCDBDC'

// Amwell primary button color
export const amwellPrimaryButtonColor = '#46AFF2'
export const amwellPrimaryButtonOutlineColor = '#46AFF2'
