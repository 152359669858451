import gql from 'graphql-tag'

// USED IN: NOTETYPE_MANAGER, SECTION_MAPPER
export const CreateSection = gql`
  mutation CreateSection($organizationId: ID!, $createdBy: ID, $name: String!, $hints: [String!]) {
    createSection(input: {
      organizationId: $organizationId,
      createdBy: $createdBy,
      name: $name,
      hints: $hints
    }) {
      section {
        id,
        createdBy,
        name,
        hints
      }
    }
  }
`

// USED IN: NOTETYPE_MANAGER, SCRIPT_MANAGER, SECTION_MAPPER
export const GetSections = gql`
  query GetSections($organizationId: ID!, $ids: [ID!], $prefix: String, $withMapping: Boolean!) {
    sections(input: {
      organizationId: $organizationId,
      ids: $ids,
      prefix: $prefix
    }) {
      count,
      results {
        id,
        createdBy,
        hints,
        name,
        emrMapping @include(if: $withMapping) {
          id,
          name
        },
        pbcSectionFlag
      }
    }
  }
`

// USED IN: NOTETYPE_MANAGER, SECTION_MAPPER
export const UpdateSection = gql`
  mutation UpdateSection($organizationId: ID!, $section: SectionInput!) {
    updateSection(input: {
      organizationId: $organizationId,
      section: $section
    }) {
      section {
        id,
        createdBy,
        name,
        hints
      }
    }
  }
`

export const DeleteSection = gql`
  mutation DeleteSection($organizationId: ID, $id: ID!) {
    deleteSections(input: { 
        organizationId: $organizationId,
        ids: [$id] 
    }) {
        success
      }
  }
`

// USED IN: SECTION_MAPPER
export const CreateSectionMapping = gql`
  mutation CreateSectionMapping($organizationId: ID!, $mapping: SectionMappingInput!) {
    createSectionMapping(input: {
      organizationId: $organizationId,
      mapping: $mapping
    }) {
      mapping {
        sukiSectionId,
        emrSection {
          id,
          name
        }
      }
    }
  }
`

// USED IN: SECTION_MAPPER
export const UpdateSectionMapping = gql`
  mutation UpdateSectionMapping($organizationId: ID!, $mapping: SectionMappingInput!) {
    updateSectionMapping(input: {
      organizationId: $organizationId,
      mapping: $mapping
    }) {
      mapping {
        sukiSectionId,
        emrSection {
          id,
          name
        }
      }
    }
  }
`

// USED IN: SECTION_MAPPER
export const DeleteSectionMapping = gql`
  mutation DeleteSectionMapping($organizationId: ID!, $sukiSectionId: ID!) {
    deleteSectionMapping(input: {
      organizationId: $organizationId,
      sukiSectionId: $sukiSectionId
    }) {
      sukiSectionId
    }
  }
`
