import gql from 'graphql-tag'

// USED IN: UTIL
export const ClearWebUserSessions = gql`
  mutation ClearWebUserSessions($userId: ID!) {
    clearWebUserSessions(input: {
      userId: $userId
    }) {
        success
    }
  }
`
