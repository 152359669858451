import { AuthService } from '@okta/okta-react'
import { OktaConfig } from './config'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import loSto from './config/loSto'
import { LO_STO } from './lib/constants'
import { logout } from './lib/util'

const auth = new AuthService(OktaConfig.auth)
const REFRESH_FREQUENCY_IN_MIN = 20
const EXPIRY_LIMIT_IN_HOURS = 2

export const startRefreshTimer = async () => {
  const idToken = await auth.getIdToken()
  if (!idToken) return

  const decoded = jwtDecode(idToken)
  const expiry = decoded.exp
  const m = moment.unix(expiry)
  console.info('[Admin:%s] Auth token expires %s at %s (exp %d)', decoded.userID, m.fromNow(), m.format(), expiry)
  window.Logger.info(`[Admin:${decoded.userID}] Auth token expires ${m.fromNow()} at ${m.format()} (exp ${expiry})}`)

  // If token has expired for more than 2 hours, log the user out/redirect them to the login page
  if (expiredForMoreThanTwoHours(decoded)) {
    logout()
    return
  }

  // If the token is close to expiring, refresh it immediately
  if (closeToExpiring(decoded)) {
    // Check if expiration is greater than a day, if so, log them out

    // Refresh immediately
    console.info('[Admin:%s] Auth token expiry already below threshold, refreshing token immediately', decoded.userID)
    window.Logger.info(`[Admin:${decoded.userID}] Auth token expiry already below threshold, refreshing token immediately`)
    refreshToken()
  }

  // Refresh the token automatically regularly
  console.info('[Admin:%s] Starting timer to trigger refresh token in %d min at around %s (exp %d)', decoded.userID, REFRESH_FREQUENCY_IN_MIN, moment().add(REFRESH_FREQUENCY_IN_MIN, 'minutes').format(), expiry)
  window.Logger.info(`[Admin:${decoded.userID}] Starting timer to trigger refresh token in ${REFRESH_FREQUENCY_IN_MIN} min at around ${moment().add(REFRESH_FREQUENCY_IN_MIN, 'minutes').format()} (exp ${expiry})`)
  return setInterval(refreshToken, REFRESH_FREQUENCY_IN_MIN * 60 * 1000)
}

const refreshToken = () => {
  auth._oktaAuth.tokenManager.renew('idToken')
    .then(newToken => {
      loSto.set(LO_STO.ID_TOKEN, newToken.idToken)
      const decoded = jwtDecode(newToken.idToken)
      const m = moment.unix(decoded.exp)
      console.info('[Admin:%s] Refreshed auth token, new token expires %s at %s (exp %d)', decoded.userID, m.fromNow(), m.format(), decoded.exp)
      window.Logger.info(`[Admin:${decoded.userID}] Refreshed auth token, new token expires ${m.fromNow()} at ${m.format()} (exp ${decoded.exp})`)
    })
}

const closeToExpiring = decoded => {
  return moment.unix(decoded.exp).diff(moment(), 'minutes') <= REFRESH_FREQUENCY_IN_MIN
}

const expiredForMoreThanTwoHours = decoded => {
  return moment().diff(moment.unix(decoded.exp), 'hours') >= EXPIRY_LIMIT_IN_HOURS
}

export default auth
