import gql from 'graphql-tag'

export const GetCompositions = gql`
  query GetCompositions($organizationId: ID!, $userId: ID!, $limit: Int, $offset: Int) {
    compositions(input: {
      organizationId: $organizationId,
      userId: $userId,
      limit: $limit,
      offset: $offset
    }) {
      count,
      results {
        ... on Composition {
          id,
          userId,
          metadata {
            name,
            status,
            submissionInformation {
              destinations,
              tryAllDestinations
            },
            submissionStatus {
              warning {
                type,
                message
              },
              finalDestination
            },
            clientType,
            createdWithS2
            pulledNoteFromEmr
            noteTypeId,
            appointment {
              id
              startsAt
              emrId
              emrEncounterId
              emrDepartmentId
              emrEncounterStatus
              reason
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                gender,
                dateOfBirth
              },
              mrn,
              emrId,
              emrIdType
            },
            user {
              id,
              person {
                firstName,
                middleName,
                lastName
              }
            }
          },
          sections {
            id,
            name,
            content,
            status,
            cursors {
              actor,
              location
            }
          },
          createdAt
        }
      }
    }
  }
`

// USED IN: ADMIN_NOTE
export const GetCompositionsById = gql`
  query GetCompositionsById($organizationId: ID!, $ids: [ID!]!) {
    compositions(input: {
      organizationId: $organizationId,
      ids: $ids,
      includeMetadata: true,
      includeSections: true
    }) {
      count,
      results {
        # If it's a composition
        ... on Composition {
          id,
          userId,
          metadata {
            name,
            status,
            submissionInformation {
              destinations,
              tryAllDestinations
            },
            submissionStatus {
              warning {
                type,
                message
              },
              finalDestination
            },
            clientType,
            createdWithS2
            pulledNoteFromEmr
            noteTypeId,
            appointment {
              id
              startsAt
              emrId
              emrEncounterId
              emrDepartmentId
              emrEncounterStatus
              reason
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                gender,
                dateOfBirth
              },
              mrn,
              emrId,
              emrIdType
            },
            user {
              id,
              person {
                firstName,
                middleName,
                lastName
              }
            }
          },
          sections {
            id,
            name,
            content,
            status,
            cursors {
              actor,
              location
            }
            diagnosisEntry {
              diagnosis {
                icdCode
                billable
                snomedCode
                description
                snomedDescription
                hccCode
                panelRanking
                suggestionType
              }
              detailedDisplay {
                tags {
                  text
                }
              }
              confirmStatus
            }
            pbcSectionFlag
          },
          createdAt
        },
        # If it's a patient note
        ... on PatientNote {
          noteId,
          compositionId,
          metadata {
            name,
            status,
            submissionInformation {
              destinations,
              tryAllDestinations
            },
            submissionStatus {
              warning {
                type,
                message
              },
              finalDestination
            },
            clientType,
            createdWithS2
            pulledNoteFromEmr
            noteTypeId,
            appointment {
              id
              startsAt
              emrId
              emrEncounterId
              emrDepartmentId
              emrEncounterStatus
              reason
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                gender,
                dateOfBirth
              },
              mrn,
              emrId,
              emrIdType
            },
            user {
              id,
              person {
                firstName,
                middleName,
                lastName
              }
            }
          },
          sections {
            id,
            name,
            content,
            status,
            cursors {
              actor,
              location
            }
            diagnosisEntry {
              diagnosis {
                icdCode
                billable
                snomedCode
                description
                snomedDescription
                hccCode
                panelRanking
                suggestionType
              }
              detailedDisplay {
                tags {
                  text
                }
              }
              confirmStatus
            }
            pbcSectionFlag
          }
        }
      }
    }
  }
`

export const GetVersionedComposition = gql`
  query GetVersionedComposition($organizationId: ID!, $id: ID!) {
    versionedComposition(input: {
      organizationId: $organizationId,
      id: $id
    }) {
      note {
        ... on VersionedComposition {
          id
          userId
          createdAt
          metadata {
            name
            status
            noteTypeId
            appointment {
              id
              startsAt
              emrId
              emrEncounterId
              emrDepartmentId
              emrEncounterStatus
              reason
            }
            patient {
              id
              person {
                firstName
                middleName
                lastName
                dateOfBirth
                gender
                prefix
                suffix
              }
              mrn
              emrId
              emrIdType
            }
            user {
              id
              person {
                firstName
                lastName
                middleName
              }
            }
            submissionInformation {
                destinations
                tryAllDestinations
              }
            submissionStatus {
              warning {
                type
                message
              }
              finalDestination
            }
            clientType
            createdWithS2
            pulledNoteFromEmr
          }
          metadataEntries {
            author {
              organizationId
              userId
              type
              role {
                type
              }
              userName
              organizationName
            }
            createdAt
            metadata {
              name
              status
              noteTypeId
              appointment {
                id
                startsAt
                emrId
                emrEncounterId
                emrDepartmentId
                emrEncounterStatus
                reason
              }
              patient {
                id
                person {
                  firstName
                  middleName
                  lastName
                  dateOfBirth
                  gender
                  prefix
                  suffix
                }
                mrn
                emrId
                emrIdType
              }
              user {
                id
                person {
                  firstName
                  lastName
                  middleName
                }
              }
              submissionInformation {
                destinations
                tryAllDestinations
              }
              submissionStatus {
                warning {
                  type
                  message
                }
                finalDestination
              }
              clientType
              createdWithS2
              pulledNoteFromEmr
            }
          }
          sections {
            id
            sectionEntries {
              author {
                organizationId
                userId
                type
                role {
                  type
                }
                userName
                organizationName
              }
              createdAt
              section {
                name
                content
                navigationKeywords
                status
                cursors {
                  actor
                  location
                }
                diagnosisEntry {
                  diagnosis {
                    icdCode
                    billable
                    snomedCode
                    description
                    snomedDescription
                    hccCode
                    panelRanking
                    suggestionType
                  }
                  detailedDisplay {
                    tags {
                      text
                    }
                  }
                  confirmStatus
                }
                pbcSectionFlag
              }
            }
          }
        }
        ... on VersionedNote {
          noteId
          compositionId
          metadata {
            name
            status
            noteTypeId
            appointment {
              id
              startsAt
              emrId
              emrEncounterId
              emrDepartmentId
              emrEncounterStatus
              reason
            }
            patient {
              id
              person {
                firstName
                middleName
                lastName
                dateOfBirth
                gender
                prefix
                suffix
              }
              mrn
              emrId
              emrIdType
            }
            user {
              id
              person {
                firstName
                lastName
                middleName
              }
            }
            submissionInformation {
                destinations
                tryAllDestinations
              }
            submissionStatus {
              warning {
                type
                message
              }
              finalDestination
            }
            clientType
            createdWithS2
            pulledNoteFromEmr
          }
          metadataEntries {
            author {
              organizationId
              userId
              type
              role {
                type
              }
              userName
              organizationName
            }
            createdAt
            metadata {
              name
              status
              noteTypeId
              appointment {
                id
                startsAt
                emrId
                emrEncounterId
                emrDepartmentId
                emrEncounterStatus
                reason
              }
              patient {
                id
                person {
                  firstName
                  middleName
                  lastName
                  dateOfBirth
                  gender
                  prefix
                  suffix
                }
                mrn
                emrId
                emrIdType
              }
              user {
                id
                person {
                  firstName
                  lastName
                  middleName
                }
              }
              submissionInformation {
                destinations
                tryAllDestinations
              }
              submissionStatus {
                warning {
                  type
                  message
                }
                finalDestination
              }
              clientType
              createdWithS2
              pulledNoteFromEmr
            }
          }
          sections {
            id
            sectionEntries {
              author {
                organizationId
                userId
                type
                role {
                  type
                }
                userName
                organizationName
              }
              createdAt
              section {
                name
                content
                navigationKeywords
                status
                cursors {
                  actor
                  location
                }
                diagnosisEntry {
                  diagnosis {
                    icdCode
                    billable
                    snomedCode
                    description
                    snomedDescription
                    hccCode
                    panelRanking
                    suggestionType
                  }
                  detailedDisplay {
                    tags {
                      text
                    }
                  }
                  confirmStatus
                }
                pbcSectionFlag
              }
            }
          }
        }
      }
    }
  }
`

// USED IN: ADMIN_NOTE
export const UpdateComposition = gql`
  mutation UpdateComposition($organizationId: ID!, $id: ID!, $metadata: MetadataInput!) {
    updateComposition(input: {
      organizationId: $organizationId,
      id: $id,
      metadata: $metadata
    }) {
      id
    }
  }
`

// USED IN: ADMIN_NOTE (STATUS SELECTOR)
export const GetCompositionMetadataById = gql`
  query GetCompositionMetadataById($organizationId: ID!, $id: ID!) {
    compositions(input: {
      organizationId: $organizationId,
      ids: [$id],
      includeMetadata: true,
      includeSections: false
    }) {
      count,
      results {
        # If it's a composition
        ... on Composition {
          id,
          metadata {
            name,
            noteTypeId,
            status,
            submissionInformation {
              destinations,
              tryAllDestinations
            },
            submissionStatus {
              warning {
                type,
                message
              },
              finalDestination
            },
            clientType
            createdWithS2
            pulledNoteFromEmr
            appointment {
              id,
              owner {
                id,
                roles {
                  type
                },
                person {
                  firstName,
                  middleName,
                  lastName,
                  prefix,
                  suffix,
                  gender,
                  dateOfBirth
                },
                authId,
                email,
                specialty,
                specialties,
                timezone,
                userType,
                activationSent
              },
              patient {
                id,
                person {
                  firstName,
                  middleName,
                  lastName,
                  dateOfBirth,
                  gender,
                  prefix,
                  suffix
                },
                mrn,
                emrId,
                emrIdType
              },
              startsAt,
              endsAt,
              type,
              medicalProcedure,
              reason,
              emrId,
              emrEncounterId,
              emrDepartmentId
            },
            encounterAddress {
              line1,
              line2,
              city,
              state,
              zip,
              country
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                dateOfBirth,
                gender,
                prefix,
                suffix
              },
              mrn,
              emrId,
              emrIdType
            },
            user {
              id,
              roles {
                type
              },
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                gender,
                dateOfBirth
              },
              authId,
              email,
              specialty,
              specialties,
              timezone,
              userType,
              activationSent
            }
          }
        },
        # If it's a patient note
        ... on PatientNote {
          noteId,
          compositionId,
          metadata {
            name,
            noteTypeId,
            status,
            submissionInformation {
              destinations,
              tryAllDestinations
            },
            submissionStatus {
              warning {
                type,
                message
              },
              finalDestination
            },
            clientType
            createdWithS2
            pulledNoteFromEmr
            appointment {
              id,
              owner {
                id,
                roles {
                  type
                },
                person {
                  firstName,
                  middleName,
                  lastName,
                  prefix,
                  suffix,
                  gender,
                  dateOfBirth
                },
                authId,
                email,
                specialty,
                specialties,
                timezone,
                userType,
                activationSent
              },
              patient {
                id,
                person {
                  firstName,
                  middleName,
                  lastName,
                  dateOfBirth,
                  gender,
                  prefix,
                  suffix
                },
                mrn,
                emrId,
                emrIdType
              },
              startsAt,
              endsAt,
              type,
              medicalProcedure,
              reason,
              emrId,
              emrEncounterId,
              emrDepartmentId
            },
            encounterAddress {
              line1,
              line2,
              city,
              state,
              zip,
              country
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                dateOfBirth,
                gender,
                prefix,
                suffix
              },
              mrn,
              emrId,
              emrIdType
            },
            user {
              id,
              roles {
                type
              },
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                gender,
                dateOfBirth
              },
              authId,
              email,
              specialty,
              specialties,
              timezone,
              userType,
              activationSent
            }
          }
        }
      }
    }
  }
`

// USED IN: ADMIN_NOTE
export const ResubmitCompositionAsScribe = gql`
  mutation ResubmitCompositionAsScribe($organizationId: ID!, $id: ID!) {
    submitComposition(input: {
      organizationId: $organizationId,
      id: $id,
      actor: SCRIBE
    }) {
      noteId
    }
  }
`

// USED IN: NOTE
export const DeleteCompositions = gql`
  mutation DeleteCompositions($organizationId: ID!, $ids: [ID!]) {
    deleteCompositions(input: {
      organizationId: $organizationId,
      ids: $ids
    }) {
      ids
    }
  }
`

export const ReleaseAllNotesBackdoor = gql`
  mutation releaseAllNotesBackdoor {
    releaseAllNotesBackdoor {
      count,
      status
    }
  }
`

export const NoteLockStatus = gql`
  query noteLockStatus($noteId: ID!){
    noteLockStatus(input: {
      noteId: $noteId
    }){
      scribe{
        id,
        firstName,
        middleName,
        lastName
      },
      status
    }
  }
`

export const ReleaseNoteBackdoor = gql`
  mutation releaseNoteBackdoor($noteId: ID!) {
    releaseNoteBackdoor(input: {
      noteId: $noteId
    }) {
      status
    }
  }
`

export const RemoveNoteFromQueue = gql`
  mutation RemoveNoteFromQueue($noteId: ID!) {
    removeNoteFromQueue(input: {
      noteId: $noteId
    }) {
      status
    }
  }
`
