import reactStyled from 'styled-components'

const CONSTANTS = {
  BOTTOM_POSITION: '150px',
  BOX_SHADOW_HUE: 'rgba(0, 0, 0, 0.15)',
  TOAST_BORDER_COLOR: '#313a42',
  TOAST_BACKGROUND_COLOR: '#232a30',
  AMWELL_FLAT_WHITE: '#fff',
  AMWELL_BLUE: 'rgb(68,172,237)',
  BTN_HOVER: 'rgb(47,55,61)',
  DOT_BTN: 'rgb(68,73,77)',
  DOT_BTN_ACTIVE: 'rgb(160,161,164)'
}

export const CloseModalButtonDark = reactStyled.div`
  margin-right: 16px;
  #mdiv {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .mdiv {
    height: 20px;
    width: 2px;
    margin-left: 12px;
    background-color: ${CONSTANTS.AMWELL_FLAT_WHITE};
    transform: rotate(45deg);
    z-index: 1;
  }
  .md {
    height: 20px;
    width: 2px;
    background-color: ${CONSTANTS.AMWELL_FLAT_WHITE};
    transform: rotate(90deg);
    z-index: 2;
  }
`
export const OobeGridContainer = reactStyled.div`
  border-radius: 8px;
  min-width: 435px;
  max-width: 435px;
  min-height: 73px;
  position: fixed;
  bottom: ${CONSTANTS.BOTTOM_POSITION};
  box-shadow: 0px 6px 10px 0 ${CONSTANTS.BOX_SHADOW_HUE};
  border: solid 1px ${CONSTANTS.TOAST_BORDER_COLOR};
  background-color: ${CONSTANTS.TOAST_BACKGROUND_COLOR};
  display: grid;
  grid-template-columns: 383px 52px;
  .article-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
    font-size: 16px;
    font-family: 'Open Sans';
  }
  .close-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  article {
    color: ${CONSTANTS.AMWELL_FLAT_WHITE};
  }
`
export const OutOfBoxWrapperInvisit = reactStyled.div`
  border-radius: 8px;
  min-width: 435px;
  max-width: 435px;
  min-height: 73px;
  position: fixed;
  bottom: ${CONSTANTS.BOTTOM_POSITION};
  box-shadow: 0px 6px 10px 0 ${CONSTANTS.BOX_SHADOW_HUE};
  border: solid 1px ${CONSTANTS.TOAST_BORDER_COLOR};
  background-color: ${CONSTANTS.TOAST_BACKGROUND_COLOR};
  article {
    color: ${CONSTANTS.AMWELL_FLAT_WHITE};
  }
  display: grid;
  grid-template-columns: 40px 355px 40px;
  .icon-item {
    display: flex;
    align-items: center;
    padding-left: 16px;
  }
  .article-item {
    padding: 0 16px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
  }
  .button-item {
    display: flex;
    align-items: center;
  }
`

export const OutOfBoxWrapper = reactStyled.div`
  border-radius: 8px;
  min-width: 445px;
  max-width: 445px;
  min-height: 73px;
  position: fixed;
  bottom: ${CONSTANTS.BOTTOM_POSITION};
  box-shadow: 0px 6px 10px 0 ${CONSTANTS.BOX_SHADOW_HUE};
  border: solid 1px ${CONSTANTS.TOAST_BORDER_COLOR};
  background-color: ${CONSTANTS.TOAST_BACKGROUND_COLOR};
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  font-family: "Open Sans";
  article {
    color: ${CONSTANTS.AMWELL_FLAT_WHITE};
  }
  .pad-left {
    padding-left: 17px;
  }

  .carousel-wrapper {
    width: 100%;
  }

  .carousel-footer {
    display: flex;
    justify-content: flex-end;
    margin: 1px 0px 5px 0px;
  }

  .footer-item {
    margin: 0px 0px 0px 2px;
  }

  .footer-item-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 8px;
  }

  .footer-item button {
    background: transparent;
    border: none;
    color: ${CONSTANTS.AMWELL_BLUE};
    font-weight: bold;
    cursor: pointer;
    padding: 12px 8px;
    min-width: 55px;
    min-height: 25px;
    margin-bottom: 4px;
  }

  .carousel-btn:hover {
    background: ${CONSTANTS.BTN_HOVER};
  }

  .dot-wrapper {
    position: absolute;
    bottom: -24px;
    min-width: 80px;
    max-height: 24px;
    display: flex;
  }

  .dot {
    min-width: 10px;
    min-height: 10px;
    background: ${CONSTANTS.DOT_BTN};
    margin: 8px;
    border-radius: 4px;
    cursor: pointer;
  }

  .dot.active {
    background: ${CONSTANTS.DOT_BTN_ACTIVE};
    pointer-events: none;
  }

  .slide-wrap {
    padding: 16px 16px 0px 16px;
  }

  .footer-item span {
    color: ${CONSTANTS.AMWELL_FLAT_WHITE};
  }

  .divider {
    width: 1px;
    height: 24px;
    margin: 13px 0 12px 5px;
    background-color: #5b6b7b;
  }
`
