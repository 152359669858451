export const ENTER_PATIENT_NAME = 'ENTER PATIENT NAME'
export const NOT_AVAILABLE = 'N/A'
export const MRN = 'MRN'
export const DATE_OF_SERVICE = 'Date of Service'
export const DATE_OF_BIRTH = 'Date of Birth'
export const LOADING = 'loading'
export const DATE_PICKER = 'datePicker'
export const APPOINT_PICKER = 'appointPicker'
export const GENDER_PICKER = 'genderPicker'
export const MRN_INPUT = 'mrnInput'
export const DATE_DEFAULT_VAL = 'MM/DD/YYYY'
export const SELECT_GENDER = 'Select Gender'
