import React, { Component } from 'react'
import { Animated, Easing } from 'react-native'
import moment from 'moment'
import { get } from 'lodash'
import {
  ListContainer,
  ListHeader,
  Title,
  ButtonWrapper,
  NewNoteButton,
  DownCaret,
  CellWrapper,
  ListCell,
  CellRow,
  RightText,
  NameText,
  StatusIcon,
  ExclamationPoint,
  IconSection,
  IconContainer,
  Divider,
  ListBody,
  EmptyText,
  Loading,
  PDFBadge,
  Delimiter,
  EmrPill,
  UnfinishedWrapper,
  PatientInfo
} from './styled'

import styled, { css } from 'styled-components'
import caretDown from '../../assets/icons/Triangle.svg'
import check from '../../assets/icons/check-green.svg'
import processingClock from '../../assets/icons/processing-clock.svg'
import signNote from '../../assets/icons/sign-note.svg'
import tipIcon from '../../assets/icons/tip-icon.png'

import {
  getFullName,
  getAge
} from '../../lib/util'
import { LISTS, NOTE_STATUS, DIMENSIONS, EMR_DESTINATION } from '../../lib/constants'
import { UNKNOWN_PATIENT, DEFAULT_MARGIN_TOP } from './constants'
import { DeleteSectionButton } from '../ProblemSection/styled'
const { LIST_DELIMITER } = DIMENSIONS

// [TODO: MARK] Bad style. Eventually get a debounce to only allow you to click buttons once.
let disable = false

export const TabContainer = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  align-items: flex-end;
  font-size: 18px;

  .tabs {
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.5);
    padding: 10px 30px;
    border-bottom: 3px solid #f8faf7;


    &:hover {
      cursor: pointer;
    }

    &.selected {
      font-weight: 600;
      color: black;
      border-bottom: 3px solid #2e5f62;

    }
  }

`

export const DateSwitcher = styled.div.attrs((props) => ({
  'data-cy': props.name
}))`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  .date {
    padding: 0px 30px;
    padding-right: 10px;
    font-weight: 600;
  }
`

export const AddPatientBtn = styled.button`
  cursor: pointer;
  position: absolute;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.25);
  padding: 5px 15px;
  font-size: 12px;
  background: transparent;
  top: 30px;
  right: 30px;
  font-family: 'Open Sans';
`

export const ResultsContainer = styled.div`
  position: relative;
  width: 100%;
`

const Result = styled.div.attrs((props) => ({
  'data-cy': props.name
}))`
  display: flex;
  padding: 0 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  position: relative;
  min-height: 90px;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &:hover {
    ${DeleteSectionButton} {
        opacity: 1;
    }
  }

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }

  p {
    margin: 0;
    color: rgba(0, 0, 0, 0.5);
  }

  div {
    span {
      color: rgba(0, 0, 0, 0.5);
    }
    span + span::before {
      content: ' • ';
    }
  }

  .appt-time {
    text-align: right;
    margin-left: 20px;
    font-size: 16px;
  }

  .status-icon {
    display: flex;
    flex-direction: column;
    width: 50px;
    align-items: center;
    margin-left: 20px;
  }
`

export const EmptyContainer = styled.div`
  width: 100%;
  height: 100%;

  ${props => props.isSchedulePatient === 'false' && css`
    display: flex;
    justify-content: center;
    align-items: center;
    `
  }

`

export const TipBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 5px;
  }

  p {
    font-size: 14px;
    width: 250px;
    font-weight: 400;
    text-align: left;
    margin-left: 5px;
  }
`

export const TipIcon = styled.img.attrs({
  src: tipIcon
})`
  width: 35px;
  height: 35px;
  cursor: pointer;
`
const EmptyContent = styled.h2`
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  width: 100%;
  margin-top: 26px;

  &:first-child {
    margin-top: 150px;
  }
`

const displaySecondaryPatient = (patient) => {
  const age = getAge(patient?.person)
  const mrn = patient?.mrn
  let gender
  if (patient?.person?.gender === 'MALE') {
    gender = 'M'
  } else if (patient?.person?.gender === 'FEMALE') {
    gender = 'F'
  }

  return (
    <div>
      {!!age && <span>{age}</span>}
      {!!gender && <span>{gender}</span>}
      {!!mrn && <span>MRN {mrn}</span>}
    </div>
  )
}

class ListPanel extends Component {
  constructor (props) {
    super(props)

    this.state = {
      listAnim: new Animated.Value(0)
    }

    this.topOffset = 0
  }

  componentDidMount () {
    Animated.timing(this.state.listAnim, {
      toValue: 1,
      duration: 200,
      easing: Easing.linear
    }).start()
  }

  componentDidUpdate () {
    const list = document.getElementById(`list-${this.props.type}`)
    if (!list) return
    list.scrollTop = this.topOffset
  }

  oneClick = func => {
    if (!disable) {
      disable = true
      setTimeout(() => { disable = false }, 2000)
      func()
    }
  }

  render () {
    const {
      type,
      title,
      loading,
      count,
      notes,
      appointments,
      handleClick,
      handleApptClick,
      openNoteTypeSelection,
      calcheight,
      togglePicker,
      children,
      renderChildElems,
      handleModeChange,
      handleAddPatientPrompt,
      patientsList,
      handleDeletePatientClick,
      showFullTabs,
      fullHeight,
      dateString,
      isSmallWindow,
      isCrampedWindow,
      marginTop = DEFAULT_MARGIN_TOP
    } = this.props

    const vw = window.innerWidth
    const vh = window.innerHeight

    const getStatusIcon = status => {
      switch (status) {
        case NOTE_STATUS.INCOMPLETE:
          return (
            <IconContainer>
              <ExclamationPoint>!</ExclamationPoint>
            </IconContainer>
          )
        case NOTE_STATUS.USER_IS_DONE:
          return (
            <IconContainer>
              <StatusIcon
                width='24px'
                height='24px'
                source={processingClock}
              />
            </IconContainer>
          )
        case NOTE_STATUS.SUBMITTED_TO_EMR:
          return (
            <IconContainer>
              <StatusIcon source={check} />
            </IconContainer>
          )
        case NOTE_STATUS.IMPORTED_FROM_EMR:
          return <EmrPill>EMR</EmrPill>
        case NOTE_STATUS.NEED_DOCTOR_SIGN_OFF:
          return (
            <IconContainer>
              <StatusIcon
                width='24px'
                height='24px'
                source={signNote}
              />
            </IconContainer>
          )
        default:
      }
    }

    let header, body, emptyText, cells, calcheightAlt, subHeader

    switch (type) {
      case LISTS.UNFINISHED_NOTES:
      {
        header = (
          <ListHeader type={type}>
            <Title vw={vw} type={type}>
              {title}
            </Title>
          </ListHeader>
        )

        emptyText = 'There are no unfinished notes in Suki'

        const getCell = (n, idx, notes) => {
          const nameObj = get(n, 'metadata.patient.person')
          const displayedName = nameObj ? getFullName(nameObj) : UNKNOWN_PATIENT
          return (
            n.metadata &&
              <CellWrapper key={idx} onPress={() => this.oneClick(() => handleClick(n.id, n.metadata.patient && n.metadata.patient.id))}>
                <ListCell type={type}>
                  <CellRow>
                    <NameText isnameunknown={nameObj ? 'false' : 'true'}>
                      {displayedName}
                    </NameText>
                    <RightText>
                      {
                        moment(
                        n?.metadata?.appointment?.startsAt ||
                        n?.createdAt
                        ).format('M/D/YYYY')
                      }
                    </RightText>
                    <IconSection>
                      {getStatusIcon(n.metadata.status)}
                    </IconSection>
                  </CellRow>
                  {/* TODO Add visit reason support */}
                  {/* <CellRow>
                    <CellText>
                      {n.metadata.encounterReason}
                    </CellText>
                  </CellRow> */}
                </ListCell>
                {idx !== notes.length - 1 && (
                  <Divider />
                )}
              </CellWrapper>
          )
        }

        const todayNotes = []
        const yesterdayNotes = []
        const olderNotes = []
        let todayCells = []
        let yesterdayCells = []
        let olderCells = []

        const todayDate = moment()
        const yesterdayDate = moment().subtract(1, 'days')

        // Notes here are compositions (unfinished notes)
        notes && notes.forEach(n => {
          if (moment(n.createdAt).isSame(todayDate, 'day')) {
            todayNotes.push(n)
          } else if (moment(n.createdAt).isSame(yesterdayDate, 'day')) {
            yesterdayNotes.push(n)
          } else {
            olderNotes.push(n)
          }
        })

        calcheightAlt = calcheight
        if (todayNotes.length > 0) calcheightAlt += LIST_DELIMITER
        if (yesterdayNotes.length > 0) calcheightAlt += LIST_DELIMITER
        if (olderNotes.length > 0) calcheightAlt += LIST_DELIMITER

        todayCells = todayNotes && todayNotes.map((n, idx, notes) => (
          getCell(n, idx, notes)
        ))

        yesterdayCells = yesterdayNotes && yesterdayNotes.map((n, idx, notes) => (
          getCell(n, idx, notes)
        ))

        olderCells = olderNotes && olderNotes.map((n, idx, notes) => (
          getCell(n, idx, notes)
        ))

        cells = (
          <UnfinishedWrapper>
            {todayCells.length > 0 && <Delimiter>Today</Delimiter>}
            {todayCells}
            {todayCells.length > 0 && <Divider />}
            {yesterdayCells.length > 0 && <Delimiter>Yesterday</Delimiter>}
            {yesterdayCells}
            {yesterdayCells.length > 0 && <Divider />}
            {olderCells.length > 0 && <Delimiter>Older</Delimiter>}
            {olderCells}
          </UnfinishedWrapper>
        )
        break
      }
      case LISTS.PATIENT_NOTES:
      {
        header = (
          <ListHeader type={type}>
            <Title vw={vw} type={type}>
              {title}
            </Title>
            <ButtonWrapper onPress={() => this.oneClick(openNoteTypeSelection)}>
              <NewNoteButton />
            </ButtonWrapper>
          </ListHeader>
        )

        emptyText = 'There are no prior notes\nin Suki for this patient.'

        cells = notes && notes.map((n, idx) => {
          // TODO Add specialty support
          // let specialty = n.doctor && n.doctor.specialty

          let dateOfService = 'Unknown Date'
          if (n.metadata.appointment && n.metadata.appointment.startsAt) {
            dateOfService = moment(n.metadata.appointment.startsAt).format('M/D/YYYY')
            // Composition created at date
          } else if (n.createdAt) {
            dateOfService = moment(n.createdAt).format('M/D/YYYY')
            // Patient notes composition created at date
          } else if (n.compositionCreatedAt) {
            dateOfService = moment(n.compositionCreatedAt).format('M/D/YYYY')
          }

          return (
            n.metadata
              ? (
                <CellWrapper key={idx} onPress={() => this.oneClick(() => handleClick(n.id || n.noteId, n.metadata.status, !!n.noteId))}>
                  <ListCell>
                    <CellRow>
                      <NameText>
                        {n.metadata.user && getFullName(n.metadata.user.person, n.metadata.user.userType)}
                      </NameText>
                      <RightText>
                        {dateOfService}
                      </RightText>
                      <IconSection>
                        {getStatusIcon(n.metadata.status)}
                      </IconSection>
                    </CellRow>
                    {get(n, 'metadata.submissionStatus.finalDestination', '') === EMR_DESTINATION.DOCUMENT && (
                      <PDFBadge bottom={6} right={5}>PDF</PDFBadge>
                    )}
                  </ListCell>
                  {(count === 1 || count === 2 || idx !== count - 1) && (
                    <Divider />
                  )}
                </CellWrapper>
              )
              : null
          )
        })
        break
      }
      case LISTS.PATIENTS_LIST:
      {
        header = (
          <ListHeader type={type}>
            <TabContainer>
              <div className='tabs' onClick={() => handleModeChange(LISTS.SCHEDULE)}>Schedule</div>
              <div className='tabs selected'>List</div>
            </TabContainer>
            <AddPatientBtn onClick={handleAddPatientPrompt}>Add Patient</AddPatientBtn>
          </ListHeader>
        )

        cells = (
          <ResultsContainer>
            {patientsList && patientsList.map(patient => {
              const name = getFullName(patient.person)

              return (
                <Result key={patient.id} onClick={(e) => openNoteTypeSelection(patient.id)} name={`listPanel-${type}-itemId-${patient.id}`}>
                  <PatientInfo>
                    <h2>{name}</h2>
                    {displaySecondaryPatient(patient)}
                  </PatientInfo>
                  <DeleteSectionButton onClick={(e) => handleDeletePatientClick(e, patient.id)} />
                </Result>
              )
            })}
          </ResultsContainer>
        )

        emptyText = (
          <>
            <EmptyContent>Add Patients to your list to create notes for them.</EmptyContent>
            <EmptyContent>Remove them when you no longer treat them.</EmptyContent>
          </>
        )
        break
      }

      case LISTS.SCHEDULE:
      {
        header = (
          <ListHeader type={type}>
            <TabContainer>
              <div className='tabs selected'>Schedule</div>
              {showFullTabs && <div className='tabs' onClick={() => handleModeChange(LISTS.PATIENTS_LIST)}>List</div>}
            </TabContainer>
          </ListHeader>
        )

        emptyText = (
          <EmptyContent>No appointments today.</EmptyContent>
        )

        const appointmentCells = (
          <ResultsContainer>

            {appointments && appointments.map((a, idx) => {
              const name = getFullName(a.patient?.person)

              return (
                <Result key={a.id} onClick={() => this.oneClick(() => handleApptClick(a))} name={`listPanel-${type}-itemId-${idx}`}>
                  <PatientInfo>  
                    <h2>{name}</h2>
                    {displaySecondaryPatient(a.patient)}
                  </PatientInfo>
                  <div className='appt-time'>
                    {moment(a.startsAt).format('h:mm A')}
                  </div>
                  <div className='status-icon' data-cy={`listPanel-${type}-itemId-${idx}-status-${a.noteStatus?.split('_').join('-')}`}>
                    {getStatusIcon(a.noteStatus)}
                    {get(a, 'noteSubmissionStatus.finalDestination', '') === EMR_DESTINATION.DOCUMENT && (
                        <PDFBadge className="pdf-badge">PDF</PDFBadge>
                    )}
                  </div>
                </Result>
              )
            })}
          </ResultsContainer>

        )

        subHeader = (
          <DateSwitcher onClick={togglePicker} name={`listPanel-${type}-date-switcher`}>
            <div className='date'>{dateString}</div>
            <DownCaret source={caretDown} />

          </DateSwitcher>
        )

        cells = (

          appointmentCells
        )
        break
      }
      default:

        header = (
          <ListHeader type={type}>
            <Title vw={vw} type={type}>
              {title}
            </Title>
          </ListHeader>
        )
        body = renderChildElems()
    }

    if (loading) {
      body = <Loading />
    } else if (count > 0) {
      body = (
        <>
          {subHeader}
          {cells}
        </>
      )
    } else if (count === 0) {
      const isSchedulePatient = type === LISTS.SCHEDULE || type === LISTS.PATIENTS_LIST
      body = (
        <EmptyContainer isSchedulePatient={isSchedulePatient.toString()}>
          {subHeader}
          <EmptyText>
            {emptyText}
          </EmptyText>
        </EmptyContainer>
      )
    }

    const isCentered = loading || count === 0

    const styles = {
      container: {
        transform: [
          {
            translateY: this.state.listAnim.interpolate({
              inputRange: [0, 1],
              outputRange: [vh, 0]
            })
          }
        ]
      }
    }

    return (
      <ListContainer
        vw={vw}
        vh={vh}
        calcheight={calcheightAlt || calcheight}
        style={styles.container}
        type={type}
        isSmallWindow={isSmallWindow}
        isCrampedWindow={isCrampedWindow}
        marginTop={marginTop}
      >
        {header}
        <div data-cy={`listPanel-${type}-body`}>
          <ListBody
            id={`list-${type}`}
            type={type}
            calcheight={calcheightAlt || calcheight}
            center={isCentered.toString()}
            fullHeight={fullHeight}
            vh={vh}
            data-cy={`list-${type.toLowerCase()}`}
            marginTop={marginTop}
          >
            {body}
          </ListBody>
        </div>
        {children}
      </ListContainer>
    )
  }
}

export default ListPanel
