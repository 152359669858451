import React from 'react'
import InVisitToast from './InVisitToast'
import WrapupToast from './WrapupToast'
import loSto from '../../lib/util/loSto'

const initialState = { visible: false }

const OutOfBoxToast = ({ agentState, toastType }) => {
  const [state, setState] = React.useState({ ...initialState })
  const isAmwellFirstTimeUser = loSto.get('IS_AMWELL_FIRST_TIME_USER')

  const toggleVisible = () => {
    setState(prevState => ({
      ...prevState,
      visible: !prevState.visible
    }))
  }

  React.useEffect(() => {
    if (agentState !== 'MUTED' && isAmwellFirstTimeUser) {
      setState((prevState) => ({
        ...prevState,
        visible: true
      }))
    }
  }, [agentState])

  const toastMap = {
    visit: <InVisitToast toggleVisible={toggleVisible} />,
    wrapup: <WrapupToast toggleVisible={toggleVisible} />
  }

  if (!state.visible) return null

  return (
    <>
      {toastMap[toastType]}
    </>
  )
}

export default OutOfBoxToast
