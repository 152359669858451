import React, { Component } from 'react'
import { charcoal, whiteSmoke } from '../../styles/colors'
import { List, ListItem } from 'material-ui/List'

const styles = {
  list: {
    backgroundColor: whiteSmoke,
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'auto',
    boxShadow: '2px 2px 8px 0px rgba(0,0,0,0.5)',
    marginBottom: 20
  },
  inner: {
    padding: '20px 30px',
    fontSize: 20,
    color: charcoal
  }
}

class ActionPanel extends Component {
  render () {
    const { items } = this.props

    return (
      <List style={styles.list}>
        {items.map(i => (
          <ListItem
            key={i.name}
            id='reset-password'
            innerDivStyle={styles.inner}
            onClick={() => i.onClick()}
          >
            {i.name}
          </ListItem>
        ))}
      </List>
    )
  }
}

export default ActionPanel
