import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  FilterWrapper,
  FilterIcon,
  FilterInput
} from './styled'
import magnifyingGlass from '../../assets/images/magnifying-glass.svg'
import noop from 'lodash/noop'

// REACT component

class FilterBar extends Component {
  render () {
    const {
      placeholder, onChange, hasIcon,
      icon, inputBackgroundColor, value
    } = this.props

    const filterIcon = (hasIcon || icon)
      ? <FilterIcon data-cy='filter-icon' src={icon || magnifyingGlass} />
      : null

    return (
      <FilterWrapper>
        {filterIcon}
        <FilterInput
          type='text'
          onChange={e => onChange(e.target.value)}
          placeholder={placeholder}
          backgroundColor={inputBackgroundColor}
          data-cy='filter-bar'
          value={value}
        />
      </FilterWrapper>
    )
  }
}

FilterBar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  hasIcon: PropTypes.bool,
  icon: PropTypes.string,
  inputBackgroundColor: PropTypes.string,
  value: PropTypes.string
}

FilterBar.defaultProps = {
  placeholder: '',
  onChange: noop,
  hasIcon: false,
  icon: null,
  inputBackgroundColor: 'transparent',
  value: null
}

export default FilterBar
