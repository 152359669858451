import React, { Component } from 'react'
import get from 'lodash.get'
import { charcoal, whiteSmoke } from '../../styles/colors'
import { getLastFirstName } from '../../lib/util'
import { List } from 'material-ui/List'
import FontIcon from 'material-ui/FontIcon'
import Loading from '../Loading'
import Radium from 'radium'
import InfiniteScroll from 'react-infinite-scroller'
import { UserNoteItem } from '../Users'
import { PAGE_LIMIT } from '../../lib/constants'

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: charcoal,
    boxShadow: '2px 2px 8px 0px rgba(0,0,0,0.5)'
  },
  dropdownHeader: {
    fontSize: '8pt',
    marginTop: '6px'
  },
  expand: {
    marginTop: '2px',
    fontSize: '20pt'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: whiteSmoke,
    padding: '20px 28px',
    boxSizing: 'border-box',
    width: '100%',
    boxShadow: '0px 2px 10px 0px rgba(0,0,0,0.3)',
    zIndex: 2,
    minHeight: 74
  },
  panelMain: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 22
  },
  search: {
    fontSize: 34
  },
  list: {
    backgroundColor: 'white',
    width: '100%',
    height: 'calc(100% - 74px)',
    minHeight: 'calc(100% - 74px)',
    padding: 0,
    overflow: 'auto'
  },
  filterDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  filterBox: {
    padding: '3px 10px',
    marginLeft: 10,
    ':focus': {
      outline: 'none'
    }
  }
}

class InfiniteScrollListPanel extends Component {
  state = {
    filterVal: '',
    isSearching: false,
    itemsList: [],
    offset: 0,
    hasMoreItems: true,
    noteItems: get(this.props, 'listItems', []),
    isPanelLoading: this.props.loading
  }

  componentDidUpdate (prevProps) {
    const nextProps = this.props

    if ((JSON.stringify(nextProps.listItems) !== JSON.stringify(prevProps.listItems)) || (!nextProps.loading && prevProps.loading)) {
      const hasMore = !!(nextProps.listItems.length !== 0 && (nextProps.listItems.length % PAGE_LIMIT.ALL_NOTES === 0))
      this.setState({ isSearching: false, isPanelLoading: false, hasMoreItems: hasMore })
    }
  }

  onFilterInput = e => {
    this.setState({ filterVal: e.target.value })
    if (e.target.value.length > 0) {
      this.setState({ isSearching: true, hasMoreItems: false })
    } else {
      this.setState({ isSearching: false, hasMoreItems: true })
    }
  }

  getNameToDisplay = item => {
    const lastName = get(item, 'metadata.patient.person.lastName')
    const filterVal = this.state.filterVal.toUpperCase()
    let finalName = 'Unknown Patient'
    if (lastName) {
      finalName = getLastFirstName(get(item, 'metadata.patient.person'))
    }
    return finalName.toUpperCase().includes(filterVal)
  }

  filterNotes = items => {
    if (!items) return null

    const { filterVal } = this.state
    const filterText = filterVal.toUpperCase()

    return items.filter(i => (
      get(i, 'id', '').toUpperCase().includes(filterText) ||
      get(i, 'compositionId', '').toUpperCase().includes(filterText) ||
        get(i, 'noteId', '').toUpperCase().includes(filterText) ||
        this.getNameToDisplay(i)
    ))
  }

  loadMore = page => {
    const { currentId, loadItems } = this.props
    const { isSearching } = this.state
    if (!isSearching && currentId) {
      loadItems(page)
      this.setState({ isSearching: true })
    }
  }

  render () {
    const {
      panelOptions,
      type,
      withSearch,
      panelLoading,
      listItems,
      currentId,
      organizationId,
      loading
    } = this.props

    const { hasMoreItems } = this.state

    let items = listItems || []
    if (type === 'NOTES') {
      items = this.filterNotes(items)
    }

    const noteItems = items.map(n => (
      <UserNoteItem
        key={n.id || n.noteId}
        note={n}
        userId={currentId}
        orgId={organizationId}
      />
    ))

    return (
      <div style={{ ...styles.container }}>
        <div style={styles.header} onClick={this.handleHeaderClick}>
          <div style={styles.panelMain}>
            {panelOptions}
            {withSearch &&
              <div style={styles.filterDiv}>
                <FontIcon
                  className='material-icons'
                  color={charcoal}
                  style={styles.search}
                >
                search
                </FontIcon>
                <input
                  type='text'
                  value={this.state.notesFilter}
                  onChange={e => this.onFilterInput(e)}
                  placeholder='Filter by name or id'
                  style={styles.filterBox}
                  data-cy='sidebar-search-filter'
                />
              </div>}
          </div>
        </div>

        <List style={styles.list}>
          {panelLoading || loading
            ? <Loading />
            : (
              <InfiniteScroll
                pageStart={0}
                loadMore={this.loadMore}
                hasMore={hasMoreItems}
                loader={<Loading key='loading' />}
                useWindow={false}
              >
                <div className='notesList'>
                  {noteItems}
                </div>
              </InfiniteScroll>
            )}
        </List>

      </div>
    )
  }
}

export default Radium(InfiniteScrollListPanel)
