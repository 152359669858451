import styled from 'styled-components/native'
import reactStyled from 'styled-components'
import { Animated } from 'react-native'
import hamburger from '../../assets/icons/white-burger.svg'
import { black, snowDrift, westSide } from '../../lib/colors'
import { Z_INDEX, ICON_SIZE } from '../../lib/constants'

export const HamburgerVeil = styled.TouchableOpacity.attrs({
  activeOpacity: 1
})`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${Z_INDEX.HAMBURGER - 1};
  cursor: default;
`

export const MenuContainer = styled.View`
  position: fixed;
  top: 14;
  right: 21;
  display: flex;
  align-items: flex-end;
  z-index: ${Z_INDEX.HAMBURGER};
  margin-top: ${props => props.marginTop ? props.marginTop : '0px'};
`

export const IconWrapper = styled(Animated.View)`

`

export const IconContainer = reactStyled.div`
  height: 54px;
  width: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  cursor: pointer;
  &:active {
    opacity: .7;
  }
`

export const HamburgerIcon = reactStyled.img.attrs({
  src: hamburger
})`
  height: 18px;
  width: ${ICON_SIZE.SMALL}px;
`

export const PanelContainer = styled(Animated.View)`
  background-color: ${snowDrift};
  box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
  padding-top: 16;
  padding-bottom: 20;
  max-width: 258;
`

export const Username = reactStyled.div`
font-family: 'Open Sans';
color: ${black};
opacity: 0.5;
padding-left: 20px;
padding-right: 20px;
text-align: center;
font-size: 18px;
`

export const MenuItemWrapper = styled.TouchableOpacity.attrs({
  activeOpacity: 0.7
})`

`

export const MenuItem = reactStyled.div`
  font-family: 'Open Sans';
  color: ${black};
  font-size: 22px;
  margin-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  opacity: ${props => props.disabled === 'true'
    ? 0.5
    : 1
  };
  cursor: ${props => props.disabled === 'true'
    ? 'not-allowed'
    : 'pointer'
  };
`

export const Divider = styled.View`
  height: 1;
  width: 100%;
  background-color: ${black};
  margin-top: 20;
  opacity: 0.4;
`

export const NoteCount = styled(Animated.Text)`
  background-color: ${westSide};
  height: 21;
  width: 21;
  position: absolute;
  right: ${props => props.inside === 'true'
    ? 6
    : 0
  };
  top: ${props => props.inside === 'true'
    ? 24
    : 0
  };
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${black};
  z-index: ${Z_INDEX.HAMBURGER + 1};
  font-size: 12;
`
