import React, { Component } from 'react'
import background from '../images/adminBackground.jpg'
import { OpsSidePanel, NotePaper } from '../components/Note'
import {
  GetCompositionsByIdSms,
  UpdateComposition,
  ResubmitCompositionAsScribe,
  GetAllNotes,
  GetVersionedNoteSms,
  GetVersionedCompositionSms,
  NoteLockStatus,
  ReleaseNoteBackdoor
} from '@sukiai/gql/admin'
import { withRouter } from 'react-router-dom'
import { whiteSmoke } from '../styles/colors'
import { sideBarWidth, navBarHeight } from '../styles/dimensions'
import { compose, graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { setNoteId } from '../actions/note'
import Frame from './Frame'
import {
  notifySuccess,
  setError
} from '../lib/util'
import get from 'lodash.get'
import {
  ROUTES,
  GRAPHQL_TYPE,
  FETCH_POLICY
} from '../lib/constants'

const styles = {
  sidepanel: {
    minWidth: sideBarWidth,
    width: '20vw',
    marginTop: navBarHeight,
    zIndex: 0,
    backgroundColor: whiteSmoke,
    display: 'flex',
    flexDirection: 'column'
  },
  wallpaper: {
    backgroundImage: `url(${background})`,
    position: 'absolute',
    width: '100%',
    left: 0,
    top: 0,
    marginTop: navBarHeight,
    height: `calc(100vh - ${navBarHeight})`,
    overflow: 'auto'
  },
  noteArea: {
    width: `calc(100% - ${sideBarWidth})`,
    marginLeft: sideBarWidth,
    display: 'flex',
    justifyContent: 'center'
  }
}

class Note extends Component {
  state = {
    joined: false,
    resubmitting: false
  }

  componentDidUpdate (prevProps, prevState) {
    const { setNoteId, noteId, noteType, history } = prevProps
    const { noteId: nextNoteId, noteType: nextNoteType } = this.props

    if (nextNoteId && nextNoteId !== noteId) setNoteId(this.props.nextNoteId)

    if (nextNoteType !== noteType && nextNoteType === GRAPHQL_TYPE.PATIENT_NOTE) {
      const noteId = get(this.props, 'compositionData.noteId')
      let modifiedPath = get(this.props, 'location.pathname')
      modifiedPath = modifiedPath.replace('note', 'patientnote')
      modifiedPath = modifiedPath.substr(0, modifiedPath.lastIndexOf('/') + 1) + noteId
      // TODO Eventually change back to history.replaceState({}, '', modifiedPath)
      // This was breaking in prod with error: i.replaceState is not a function
      history.push(modifiedPath)
    }
  }

  componentWillUnmount () {
    const { noteId } = this.props
    window.Logger.info(`Leaving note as admin: [id=${noteId}]`)
    console.info('Leaving note as admin: [id=%s]', noteId)
  }

  resubmitComposition = () => {
    const { resubmitCompositionAsScribe, orgId, noteId } = this.props
    this.setState({ resubmitting: true })

    resubmitCompositionAsScribe({
      variables: {
        organizationId: orgId,
        id: noteId
      },
      refetchQueries: [{
        query: GetCompositionsByIdSms,
        variables: {
          organizationId: orgId,
          ids: [noteId]
        }
      }]
    })
      .then(({ data: { submitComposition: { noteId: patientNoteId } } }) => {
        this.setState({ resubmitting: false })
        console.info(`Successfully re-submitted composition with ID: ${noteId}`)
        notifySuccess('Successfully re-submitted composition')
        // TODO Eventually add this (not being filled right now)
        // and potentially add re-routing to patient note
        // console.info(`New patient note ID: ${noteId}`)
      })
      .catch(error => {
        this.setState({ resubmitting: false })
        console.error('Error re-submitting composition:', error)
        setError(error)
      })
  }

  render () {
    const {
      compositionData,
      noteType,
      updateComposition,
      noteSections,
      loadingNote,
      isCurrentNoteOpenByDoctor,
      hints,
      currentSectionId,
      refetchNote,
      patientNotes,
      loadingPatientNotes,
      noteMode,
      s2Mode,
      versionedSections,
      orgId,
      userId
    } = this.props

    const { resubmitting } = this.state
    const patient = get(compositionData, 'metadata.patient')
    const user = get(compositionData, 'metadata.user')

    return (
      <div>
        {user && (
          <Frame withNote background={background}>
            <OpsSidePanel
              style={styles.sidepanel}
              compSections={noteSections}
              user={user}
              currentSectionId={currentSectionId}
              noteOpenByDoc={isCurrentNoteOpenByDoctor}
              panelId='adminSidePanel'
            />
            <NotePaper
              type={noteType}
              note={compositionData}
              patient={patient}
              loading={loadingNote}
              loadingPatientNotes={loadingPatientNotes} // eventually move this out of the NotePaper component
              orgId={orgId}
              userId={userId}
              patientNotes={patientNotes}
              refetchNote={refetchNote}
              hints={hints} // get rid of these four lines by moving this out of NotePaper component
              sections={noteSections}
              versionedSections={versionedSections}
              updateComposition={updateComposition}
              resubmitComposition={this.resubmitComposition}
              resubmitting={resubmitting}
              noteMode={noteMode}
              s2Mode={s2Mode}
              currentSectionId={currentSectionId}
            />
          </Frame>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ general, note }, { match }) => ({
  orgId: get(match, 'params.orgId'),
  userId: get(match, 'params.userId'),
  noteId: get(match, 'params.noteId'),
  isCurrentNoteOpenByDoctor: note.isCurrentNoteOpenByDoctor,
  currentSectionId: note.currentSectionId,
  noteMode: note.noteMode
})

const mapDispatchToProps = dispatch => ({
  setNoteId: id => dispatch(setNoteId(id))
})

const mapVersionedCompositionToProps = ({ data }) => {
  const errMessage = get(data, 'error.message')
  // In scribe view, if its a read-only note, we will redirect to patient note instead
  if (errMessage) {
    // If composition is not found, just set this error
    window.alert(`${errMessage}`)

    // setError(`${errMessage} --- Note may have been deleted or, most likely, submitted. Look for the note in the user view.`)
  }
  // TODO: Do this in node
  const sections = []
  const versionedSections = []
  let s2Mode = true
  const composition = data?.versionedCompositionSms?.note
  // Get s2 sections first, if not get slate sections
  let compositionSections = composition?.sectionsS2 || []
  if (compositionSections.length === 0) {
    s2Mode = false
    compositionSections = composition?.sections || []
  }
  if (composition) {
    compositionSections.forEach(s => {
      const sEntries = s.sectionEntries
      versionedSections.push(sEntries)
      if (sEntries) {
        const lastIdx = sEntries.length - 1
        if (lastIdx > -1) {
          const finalSection = { ...sEntries[lastIdx].section, id: s.id, s2Mode: s2Mode }
          sections.push(finalSection)
        }
      }
    })
  }
  const noteType = get(data, 'versionedCompositionSms.note.__typename') === 'VersionedComposition'
    ? GRAPHQL_TYPE.COMPOSITION
    : GRAPHQL_TYPE.PATIENT_NOTE

  return {
    errorNote: data.error,
    loadingNote: data.loading,
    compositionData: get(data, 'versionedCompositionSms.note'),
    noteSections: sections,
    noteType: noteType,
    s2Mode: s2Mode,
    refetchNote: data.refetch,
    versionedSections
  }
}

const mapVersionedNoteToProps = ({ data }) => {
  const sections = []
  const versionedSections = []
  const note = get(data, 'versionedNoteSms.note')
  let s2Mode = true
  // Get s2 sections first, if not get slate sections
  let noteSections = get(note, 'sectionsS2', [])
  if (noteSections.length === 0) {
    s2Mode = false
    noteSections = get(note, 'sections', [])
  }
  if (note) {
    noteSections.forEach(s => {
      const sEntries = s.sectionEntries
      versionedSections.push(sEntries)
      if (sEntries) {
        const lastIdx = sEntries.length - 1
        if (lastIdx > -1) {
          const finalSection = { ...sEntries[lastIdx].section, id: s.id, s2Mode: s2Mode }
          sections.push(finalSection)
        }
      }
    })
  }

  return {
    errorNote: data.error,
    loadingNote: data.loading,
    compositionData: note,
    noteSections: sections,
    s2Mode: s2Mode,
    noteType: GRAPHQL_TYPE.PATIENT_NOTE,
    refetchNote: data.refetch,
    versionedSections
  }
}

const mapPatientNotesToProps = ({ data }) => ({
  errorPatientNotes: data.error,
  loadingPatientNotes: data.loading,
  patientNotes: data.allNotes && data.allNotes.results
})

const mapLockStatusToProps = ({ data }) => {
  return {
    noteLockScribe: get(data, 'noteLockStatus.scribe'),
    noteLockStatus: get(data, 'noteLockStatus.status')
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GetVersionedCompositionSms, {
    skip: ({ match, orgId, noteId }) => (
      get(match, 'path', '').includes(ROUTES.PATIENT_NOTE) || !orgId || !noteId
    ),
    options: ({ orgId, noteId }) => ({
      variables: {
        organizationId: orgId,
        id: noteId
      },
      fetchPolicy: FETCH_POLICY.NO_CACHE
    }),
    props: mapVersionedCompositionToProps
  }),
  graphql(GetVersionedNoteSms, {
    skip: ({ match, orgId, noteId }) => !get(match, 'path', '').includes(ROUTES.PATIENT_NOTE) || !orgId || !noteId,
    options: ({ orgId, noteId }) => ({
      variables: {
        organizationId: orgId,
        id: noteId
      },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY
    }),
    props: mapVersionedNoteToProps
  }),
  graphql(GetAllNotes, {
    // compositionData being set is dependent on the order of the apollo hoc queries.
    // weird behavior that i've noticed before - hopefully fixed when we move to the new apollo queries.
    skip: ({ orgId, userId, compositionData }) => !orgId || !userId || !get(compositionData, 'metadata.patient.id'),
    options: ({ orgId, userId, compositionData }) => ({
      variables: {
        organizationId: orgId,
        userId,
        patientId: get(compositionData, 'metadata.patient.id')
      },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY
    }),
    props: mapPatientNotesToProps
  }),
  graphql(NoteLockStatus, {
    skip: ({ noteId }) => !noteId,
    options: ({ noteId }) => ({
      variables: {
        noteId
      },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK
    }),
    props: mapLockStatusToProps
  }),
  graphql(UpdateComposition, { name: 'updateComposition' }),
  graphql(ResubmitCompositionAsScribe, { name: 'resubmitCompositionAsScribe' }),
  graphql(ReleaseNoteBackdoor, { name: 'releaseNoteBackdoor' })
)(Note)
