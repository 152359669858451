import React from 'react'
import { Section as StyledSection, Input as StyledInput, Select as StyledSelect } from './styled'

const Section = ({ children, ...props }) => <StyledSection {...props}>{children}</StyledSection>

const Input = ({ field, ...props }) => <StyledInput {...field} {...props} autoComplete='off' />

const Select = ({ field, optional, options = [], ...props }) => (
  <StyledSelect {...field} {...props}>
    {optional && <option value=''>None</option>}
    {options}
  </StyledSelect>
)

export default { Section, Input, Select }
