import React from 'react'
import styled from 'styled-components'
import { snakeCapsToEnglish } from '@sukiai/utils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.div`
  text-transform: uppercase;
  color: #4a4a4a;
  font-size: 12.5px;
  font-weight: 600;
  margin: 24px 0px 5px;
  text-align: right;
`

const List = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
`

const Specialty = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px 0px 10px 10px;
  padding: 4px 8px;
  border-radius: 4px;
  background: #ebecf0;
  font-size: 12px;
  color: #172b4d;
`

const EmptyText = styled.div`
  color: #8993a4;
  font-size: 12px;
`
const SpecialtiesList = ({ list = [], handleClick }) => {
  const renderList = list.map(speciality => (
    <Specialty key={speciality}>{snakeCapsToEnglish(speciality)}</Specialty>
  ))

  const renderEmptyState = <EmptyText>None</EmptyText>
  return (
    <Container onClick={handleClick}>
      <Label>SPECIALTIES</Label>
      <List>
        {list.length === 0 && renderEmptyState}
        {list.length > 0 && renderList}
      </List>
    </Container>
  )
}

export default SpecialtiesList
