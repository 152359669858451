import React, { Component } from 'react'
import { compose, graphql, Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import get from 'lodash.get'
import styled from 'styled-components'
import {
  GetUserNvoqInformation,
  UpdateNvoqUserAccount,
  GetUserById,
  DeleteNvoqUserAccount,
  GetAllAvailableTopics
} from '@sukiai/gql/admin'
import { getFullName } from '@sukiai/utils'

import { Frame } from '.'
import { UserNvoq } from '../components/UserNvoq'
import { notifySuccess, setError } from '../lib/util'
import { Loading } from '../components'
import { FETCH_POLICY } from '../lib/constants'

const Container = styled.div`
  margin: 15px;
`
const ErrorMessage = styled.div`
  position: absolute;
  width: 90%;
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
  color: #d8000c;
  background-color: #ffbaba;
`

class Nvoq extends Component {
  state = {
    isProcessing: false
  }

  deleteNvoqUserAccount = () => {
    const { match, DeleteNvoqUserAccount } = this.props
    const id = get(match, 'params.userId')

    DeleteNvoqUserAccount({
      variables: {
        sukiUserIds: [id]
      }
    })
      .then(({ data }) => {
        this.setState({ isProcessing: false })
        notifySuccess('Successfully Deleted nVoqUser')
        this.toUserPage()
      })
      .catch(err => {
        this.setState({ isProcessing: false })
        setError(err)
      })
  }

  toUserPage = () => {
    const { match } = this.props
    const orgId = get(match, 'params.organizationId')
    const userId = get(match, 'params.userId')
    const { history } = this.props

    history.push(`/${orgId}/users?userId=${userId}`)
  }

  updateNvoqUserAccount = (information, wakewordPreference) => {
    const { match, UpdateNvoqUserAccount } = this.props
    const organizationId = get(match, 'params.organizationId')
    const sukiUserId = get(match, 'params.userId')
    this.setState({ isProcessing: true })

    UpdateNvoqUserAccount({
      variables: {
        organizationId,
        sukiUserId,
        information,
        wakewordPreference
      },
      refetchQueries: [
        {
          query: GetUserNvoqInformation,
          variables: { organizationId, sukiUserId },
          fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK
        }
      ]
    })
      .then(({ data }) => {
        this.setState({ isProcessing: false })
        notifySuccess('Successfully Modified')
      })
      .catch(err => {
        this.setState({ isProcessing: false })
        setError(err)
      })
  }

  render () {
    const { match, nvoqTopics } = this.props
    const organizationId = get(match, 'params.organizationId')
    const sukiUserId = get(match, 'params.userId')

    const nvoqVariables = { organizationId, sukiUserId }
    const userVariables = { organizationId, id: sukiUserId }
    const errorMessage = message => (
      <Container>
        <ErrorMessage>{message}</ErrorMessage>
      </Container>
    )
    return (
      <Frame>
        <div />
        <Query query={GetUserById} variables={userVariables}>
          {({ loading: userLoading, error: userError, data: userData }) => {
            if (userLoading) return <Loading />
            const userPerson = get(userData, 'users.results[0].person')
            const fullName = getFullName(userPerson)
            if (!fullName) return errorMessage('User does not exist')
            return (
              <Query
                query={GetUserNvoqInformation}
                variables={nvoqVariables}
                fetchPolicy={FETCH_POLICY.CACHE_AND_NETWORK}
              >
                {({
                  loading: nvoqLoading,
                  error: nvoqError,
                  data: nvoqData
                }) => {
                  if (nvoqError) {
                    return errorMessage(`${fullName} is not linked to nVoq`)
                  }
                  if (nvoqLoading) return <Loading />
                  const nvoqInformation = get(
                    nvoqData,
                    'nvoqInformation.information'
                  )
                  return (
                    <span>
                      {nvoqInformation && (
                        <UserNvoq
                          {...nvoqInformation}
                          nvoqTopics={nvoqTopics}
                          fullName={fullName}
                          deleteNvoqUserAccount={this.deleteNvoqUserAccount}
                          updateNvoqUserAccount={this.updateNvoqUserAccount}
                          isProcessing={this.state.isProcessing}
                          organizationId={organizationId}
                          sukiUserId={sukiUserId}
                        />
                      )}
                    </span>
                  )
                }}
              </Query>
            )
          }}
        </Query>
      </Frame>
    )
  }
}

const mapTopicsToProps = ({ data }) => ({
  nvoqTopics: data.availableTopics
})

export default compose(
  graphql(UpdateNvoqUserAccount, { name: 'UpdateNvoqUserAccount' }),
  graphql(DeleteNvoqUserAccount, { name: 'DeleteNvoqUserAccount' }),
  graphql(GetAllAvailableTopics, {
    options: () => ({
      fetchPolicy: FETCH_POLICY.CACHE_FIRST
    }),
    props: mapTopicsToProps
  }),
  withRouter
)(Nvoq)
