import get from 'lodash.get'
import { Query } from 'react-apollo'
import queryString from 'query-string'
import Drawer from 'material-ui/Drawer'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { List, ListItem } from 'material-ui/List'

import { GetScriptsByUserId, GetNoteTypes } from '@sukiai/gql/admin'

import Frame from './Frame'
import { sideBarWidth, navBarHeight } from '../styles/dimensions'
import { white, whiteSmoke, charcoal, scheduleGrey } from '../styles/colors'
import { ROUTES, SPECIALTIES_LIST, QUERY_PARAMS, FETCH_POLICY, SCRIPT_TAG } from '../lib/constants'

const styles = {
  sidebar: {
    minWidth: sideBarWidth,
    width: '20vw',
    marginTop: navBarHeight,
    zIndex: 0,
    backgroundColor: whiteSmoke,
    display: 'flex',
    flexDirection: 'column'
  },
  specialitiesList: {
    padding: 0,
    height: `calc(100vh - ${navBarHeight})`,
    overflow: 'auto'
  },
  speciality: {
    fontSize: 16,
    color: charcoal,
    paddingLeft: 35
  },
  main: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '60px'
  },
  list: {
    backgroundColor: white,
    width: '24vw',
    fontSize: '14px',
    height: '80vh',
    overflow: 'scroll'
  },
  listTitle: {
    padding: '10px',
    textAlign: 'center',
    backgroundColor: whiteSmoke
  },
  emptyList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

function formatScript (script) {
  const nameTag = get(script, 'tags', []).find(t => t.type === SCRIPT_TAG.NAME)
  const name = get(nameTag, 'name.value')
  const source = script?.source
  return { id: script.id, name, source }
}

function SpecialityMap (props) {
  const { orgId: organizationId, userId } = props.match.params
  const queryParams = queryString.parse(props.location?.search)

  const [selectedSpeciality, setSelectedSpeciality] = useState(queryParams[QUERY_PARAMS.SPECIALITY] || SPECIALTIES_LIST[0])

  const queryVariables = { organizationId, userId, specialties: [selectedSpeciality] }

  const handleSpecialitySelection = (speciality) => {
    const { history } = props
    setSelectedSpeciality(speciality)
    history.replace({ search: `?${[QUERY_PARAMS.SPECIALITY]}=${speciality}` })
  }

  const handleRedirect = (item, type) => {
    const { history } = props
    const navigateUrl = type === 'script'
      ? `/${organizationId}/${userId}/${ROUTES.SCRIPTS}?scriptId=${item.id}`
      : item.userId
        ? `/${ROUTES.NOTE_TYPES}/${organizationId}/${item.userId}?noteTypeId=${item.id}`
        : `/${ROUTES.NOTE_TYPES}/${organizationId}/?noteTypeId=${item.id}`
    history.push(navigateUrl)
  }

  const getEmptyStateElement = (type) => {
    const message = `No ${type} is associated with speciality ${selectedSpeciality}`
    return (
      <div style={{ ...styles.list, ...styles.emptyList }}>
        <span style={{ padding: '10px', color: charcoal, flex: '0 0 200px' }}>{message}</span>
      </div>
    )
  }

  return (
    <Frame>
      <Drawer docked open zDepth={0} containerStyle={styles.sidebar}>
        <List style={styles.specialitiesList}>
          {SPECIALTIES_LIST.map(item => (
            <ListItem
              key={item}
              primaryText={item}
              onClick={() => handleSpecialitySelection(item)}
              innerDivStyle={{ ...styles.speciality, backgroundColor: item === selectedSpeciality ? scheduleGrey : 'transparent' }}
            />
          ))}
        </List>
      </Drawer>
      <div style={styles.main}>
        <Query query={GetNoteTypes} variables={queryVariables} fetchPolicy={FETCH_POLICY.CACHE_AND_NETWORK}>
          {({ data: noteTypesData }) => {
            const notetypes = get(noteTypesData, 'noteTypes.results', [])
            const userNoteTypes = notetypes.filter(nt => !!nt.userId)
            const orgNoteTypes = notetypes.filter(nt => !nt.userId)

            return (
              <div>
                <div style={styles.listTitle}>Note Types</div>
                {notetypes.length ? (
                  <List style={styles.list}>
                    {userNoteTypes.map(item => (
                      <ListItem
                        key={item.id}
                        onClick={() => handleRedirect(item, 'notetype')}
                        innerDivStyle={{ fontSize: '14px', display: 'flex', justifyContent: 'space-between' }}
                      >
                        <span>{item.name}</span>
                        <span>USER</span>
                      </ListItem>
                    ))}
                    {orgNoteTypes.map(item => (
                      <ListItem
                        key={item.id}
                        primaryText={item.name}
                        onClick={() => handleRedirect(item, 'notetype')}
                        innerDivStyle={{ fontSize: '14px', display: 'flex', justifyContent: 'space-between' }}
                      />
                    ))}
                  </List>
                ) : getEmptyStateElement('NoteType')}
              </div>
            )
          }}
        </Query>
        <Query query={GetScriptsByUserId} variables={queryVariables} fetchPolicy={FETCH_POLICY.CACHE_AND_NETWORK}>
          {({ data: scriptsData }) => {
            let scripts = get(scriptsData, 'macros.results', [])
            scripts = scripts.map(formatScript)

            return (
              <div>
                <div style={styles.listTitle}>Scripts</div>
                {scripts.length ? (
                  <List style={styles.list}>
                    {scripts.map(item => (
                      <ListItem
                        key={item.id}
                        primaryText={item.name}
                        onClick={() => handleRedirect(item, 'script')}
                        innerDivStyle={{ fontSize: '14px' }}
                      />
                    ))}
                  </List>
                ) : getEmptyStateElement('Script')}
              </div>
            )
          }}
        </Query>
      </div>
    </Frame>
  )
}

export default withRouter(SpecialityMap)
