import gql from 'graphql-tag'

// USED IN: ORGS
export const GetAllOrganizations = gql`
  query GetAllOrganizations {
    organizations(input: {}) {
      count,
      results {
        id,
        name,
        enhancedReview
      }
    }
  }
`

// USED IN: ORGS, USERS
export const GetOrganizationById = gql`
  query GetOrganizationById($id: ID!, $withEmrInfo: Boolean!) {
    organizations(input: { ids: [$id] }) {
      count,
      results {
        id,
        name,
        enhancedReview,
        emr @include(if: $withEmrInfo) {
          id,
          type,
          secondaryType,
          capabilities {
            multipleNotesAllowed,
            destinations,
            predefinedSections,
            sections {
              id,
              name
            }
          }
        }
      }
    }
  }
`

// USED IN: SECTION_MAPPER
export const GetEMRSectionsForOrg = gql`
  query GetEMRSectionsForOrg($organizationId: ID!) {
    organizations(input: { ids: [$organizationId] }) {
      count,
      results {
        id,
        name,
        emr {
          id,
          type,
          secondaryType,
          capabilities {
            predefinedSections,
            sections {
              id,
              name
            }
          }
        }
      }
    }
  }
`

// USED IN: ORGS
export const createAndLinkAthenaOrganization = gql`
  mutation createAndLinkAthenaOrganization($emrOrganizationId: ID!, $sukiOrganizationId: ID!) {
    createAndLinkAthenaOrganization(input: {
     athenaOrganization:{
        id: $emrOrganizationId,
        sukiOrganizationId: $sukiOrganizationId,
      }
    }) {
      athenaOrganization {
        sukiOrganizationId,
        id,
        type
      }
    }
  }
`

// USED IN: ORGS
export const CreateOrganization = gql`
  mutation CreateOrganization($name: String!) {
    createOrganization(input: { name: $name }) {
      organization {
        id,
        name
      }
    }
  }
`

// USED IN: ORGS
export const UpdateOrganization = gql`
  mutation UpdateOrganization($id: ID!, $name: String!, $enhancedReview: String) {
    updateOrganization(input: {
      id: $id,
      name: $name,
      enhancedReview: $enhancedReview
    }) {
      organization {
        id,
        name,
        enhancedReview
      }
    }
  }
`

// USED IN: ORGS
export const DeleteOrganization = gql`
  mutation DeleteOrganization($id: ID!) {
    deleteOrganization(input: { id: $id }) {
      id
    }
  }
`
