import gql from 'graphql-tag'

// USED IN: ORGS
export const RefreshEMRAppointments = gql`
  mutation RefreshEMRAppointments($organizationId: ID!, $emrType: EMRType!, $fromDate: String, $toDate: String, $userID: String) {
    refreshEMRAppointments(input: {
      organizationId: $organizationId,
      emrType: $emrType,
      fromDate: $fromDate,
      toDate: $toDate,
      userID: $userID  
    }) {
      fromDate,
      toDate,
      userID  
    }
  }
`

// USED IN: USERS
export const SearchEMRUsers = gql`
  query SearchEMRUsers($organizationId: ID!, $query: String!, $emrOrgId: String, $sukiUserId: String) {
    searchEMRUsers(input: {
      organizationId: $organizationId,
      query: $query,
      emrOrgId: $emrOrgId,
      sukiUserId: $sukiUserId
    }) {
      users {
        sukiUserId,
        emrUserId,
        emrDepartmentId,
        emrDepartmentName,
        emrFirstName,
        emrLastName,
        emrUsername,
        emrUserIdList,
        fhirUserId
      }
    }
  }
`

// USED IN: USERS
export const LinkEMRUser = gql`
  mutation LinkEMRUser($organizationId: ID!, $sukiUserId: ID!, $emrUserId: ID!, $emrDepartmentId: ID!, $timezone: String!, $emrUsername: String, $emrUserIdList: [ID!], $fhirUserId: String) {
    linkEMRUser(input: {
      organizationId: $organizationId,
      user: {
        sukiUserId: $sukiUserId,
        emrUserId: $emrUserId,
        emrDepartmentId: $emrDepartmentId,
        timezone: $timezone,
        emrUsername: $emrUsername,
        emrUserIdList: $emrUserIdList,
        fhirUserId: $fhirUserId
      }
    }) {
      success
    }
  }
`

// USED IN: USERS
// Unlinks all EMR Users
export const UnlinkEMRUser = gql`
  mutation UnlinkEMRUser($organizationId: ID!, $sukiUserId: ID!) {
    unlinkEMRUser(input: {
      organizationId: $organizationId,
      sukiUserIds: [$sukiUserId]
    }) {
      success
    }
  }
`

export const CheckScheduleHealth = gql`
  mutation CheckScheduleHealth(
    $emrType: EMRType!,
    $organizationId: ID!,
    $users: [EMRUserInput!]!,
    $daysBeforeNow: Int,
    $daysAfterNow: Int) {
      checkScheduleHealth(input: {
        emrType: $emrType,
        organizationId: $organizationId,
        users: $users,
        daysBeforeNow: $daysBeforeNow,
        daysAfterNow: $daysAfterNow
      }) {
        hasAppointment
      }
    }
`

export const GetEmrNotetypes = gql`
  query GetEmrNotetypes($organizationId: ID!, $specialties: [SpecialtyType]) {
    emrNoteTypes(input: {
      organizationId: $organizationId,
      specialties: $specialties
    }) {
    emrNoteTypes {
      id
      code,
      name,
      codeSystem,
      allowedSections {
        id,
        name
      },
      systemGenerated,
      specialtyType
    }
  }
}`
