import React, { Component } from 'react'
import Drawer from 'material-ui/Drawer'
import Radium from 'radium'
import { sideBarWidth, navBarHeight } from '../../styles/dimensions'
import { whiteSmoke, charcoal, scribeGreen, platinum } from '../../styles/colors'
import { List, ListItem, makeSelectable } from 'material-ui/List'
import { getLastFirstName } from '../../lib/util'
import PropTypes from 'prop-types'
import loSto from '../../config/loSto'
import { ROUTES, LO_STO } from '../../lib/constants'
import Loading from '../Loading'
import { FilterBar } from '@sukiai/components'
import { withRouter } from 'react-router-dom'
import get from 'lodash.get'
import SupervisedUserCircleIcon from '../../images/shared_user.svg'
import history from '../../history'
import client from '../../apollo'
import debounce from 'lodash.debounce'
import { SearchUsers } from '@sukiai/gql/admin'

const styles = {
  container: {
    minWidth: sideBarWidth,
    width: '20vw',
    marginTop: navBarHeight,
    zIndex: 0,
    backgroundColor: whiteSmoke,
    display: 'flex',
    flexDirection: 'column'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    fontSize: 19
  },
  toggleBtn: {
    fontWeight: 600,
    border: '2px solid black',
    borderRadius: '20px',
    padding: '4px auto',
    margin: '4px 16px',
    width: '160px',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 20px'
  },
  filterBoxStyle: {
    width: '100%',
    padding: '5px 5px 5px 10px',
    backgroundColor: 'transparent',
    border: 'solid 1px #DAE2EB',
    ':focus': {
      outline: 'none'
    }
  },
  title: {
    fontWeight: 600,
    color: charcoal
  },
  button: {
    width: 26,
    height: 26,
    borderRadius: '50%',
    color: scribeGreen,
    backgroundColor: platinum,
    textAlign: 'center',
    cursor: 'pointer'
  },
  list: {
    padding: 0,
    height: `calc(100vh - ${navBarHeight} - 101px)`,
    overflow: 'auto'
  },
  item: {
    fontSize: 16,
    color: charcoal,
    paddingLeft: 35
  },
  oneLineItem: {
    fontSize: 16,
    color: charcoal,
    marginTop: 60,
    display: 'flex',
    justifyContent: 'center'
  },
  filterIcon: {
    padding: '0px 4px'
  },
  sharedUserIcon: {
    position: 'absolute',
    top: '-2px',
    right: '-8px',
    width: '20px',
    color: charcoal,
    opacity: 0.8
  }
}

let SelectableList = makeSelectable(List)

function wrapState (ComposedComponent) {
  return class SelectableList extends Component {
    constructor (props) {
      super(props)

      this.state = {
        selectedValue: props.defaultValue
      }
    }

    static getDerivedStateFromProps (nextProps, prevState) {
      if (nextProps.defaultValue !== prevState.selectedValue) {
        return { selectedValue: nextProps.defaultValue }
      }
      return null
    }

    handleRequestChange = (event, id) => {
      this.setState({ selectedValue: id })
    }

    render () {
      const { style, children } = this.props
      return (
        <ComposedComponent
          id='selectableList'
          value={this.state.selectedValue}
          onChange={this.handleRequestChange}
          style={style}
          data-cy='sidebar-selectable-list'
        >
          {children}
        </ComposedComponent>
      )
    }
  }
}

SelectableList = wrapState(SelectableList)

class EditorSidebar extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    setUserId: PropTypes.func.isRequired
  }

  static defaultProps = {
    items: [],
    currentId: null,
    setUserId: () => {},
    setCommand: () => {},
    setPreferenceId: () => {}
  }

  state = {
    filterVal: '',
    searchBy: 'org'
  }

  componentDidMount (nextProps) {
    if (!this.props.currentId || !this.props.items.find(item => item.id === this.props.currentId)) {
      get(this.props, 'items.length', 0) > 0 && this.setId(this.props.items[0].id)
    }
  }

  setId = id => {
    const {
      match: { path },
      setUserId,
      setPreferenceId,
      setCommand,
      setOrgId
    } = this.props

    if (path.includes(ROUTES.USERS)) {
      setUserId(id)
      loSto.set(LO_STO.CURRENT_USER_ID, id)
    } else if (path.includes(ROUTES.PREFERENCES)) {
      setPreferenceId(id)
      this.scroll(`pref-${id}`)
    } else if (path.includes(ROUTES.COMMAND_CENTRAL)) {
      // sets selected intent type for the command
      id && setCommand(id)
    } else {
      if (setOrgId) {
        // if this prop is set, we need organization context
        setOrgId(id)
        loSto.set(LO_STO.CURRENT_ORG_ID, id)
      }
    }
  }

  getSidebarTitle = () => {
    const {
      match: { path }
    } = this.props

    if (path.includes(ROUTES.USERS)) {
      return 'Users'
    } else if (path.includes(ROUTES.PREFERENCES)) {
      return 'Preferences'
    } else if (path.includes(ROUTES.COMMAND_CENTRAL)) {
      return 'Commands'
    } else if (path.includes(ROUTES.NVOQ)) {
      return 'nVoq User Configuration'
    } else {
      switch (this.state.searchBy) {
        case 'name': return 'Name (First Last)'
        case 'email': return 'Email'
        default: return 'Organizations'
      }
    }
  }

  handleAddClick = () => {
    const {
      match: { path },
      toggleForm
    } = this.props

    if (path.includes(ROUTES.ORGS)) {
      toggleForm()
    } else if (path.includes(ROUTES.USERS)) {
      toggleForm()
    }
  }

  getIsExtraFunctionality = () => ['name', 'email'].includes(this.state.searchBy)

  scroll = item => {
    document.getElementById(item) &&
      document.getElementById(item).scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  fetchUsers = (query) => {
    if (!query) return

    const body = {
      query: SearchUsers,
      variables: { query }
    }

    client.query(body).then((res) => {
      const users = res.data.searchUsers?.results || []
      this.setState({ users })
    })
  }

  debouncedFetchUsers = debounce(this.fetchUsers, 500)

  handleSearchByName = () => {
    this.setState({ searchBy: 'name' })
    const { filterVal, users } = this.state
    if (filterVal && !users) this.debouncedFetchUsers(filterVal)
  }

  handleSearchByEmail = () => {
    this.setState({ searchBy: 'email' })
    const { filterVal, users } = this.state
    if (filterVal && !users) this.debouncedFetchUsers(filterVal)
  }

  handleSetFilterString = filterString => {
    const isExtraFunctionality = this.getIsExtraFunctionality()
    if (!isExtraFunctionality) {
      this.setState({ filterVal: filterString })
    } else {
      this.debouncedFetchUsers(filterString)
    }
  }

  filterByNameOrId = items =>
    items.filter(
      item =>
        item.id.includes(this.state.filterVal) || item.name.toUpperCase().includes(this.state.filterVal.toUpperCase())
    )

  filterDocs = items =>
    items.filter(
      item =>
        item.id.includes(this.state.filterVal) ||
        getLastFirstName(item.person)
          .toUpperCase()
          .includes(this.state.filterVal.toUpperCase())
    )

  filterScripts = items =>
    // why in the world do macros have this complex structure?
    items.filter(
      item =>
        item.id.includes(this.state.filterVal) ||
        get(item, 'tags[0].name.value', '')
          .toUpperCase()
          .includes(this.state.filterVal.toUpperCase())
    )

  renderListItems = items => {
    if (get(items, 'length', 0) < 1) return []
    const isExtraFunctionality = this.getIsExtraFunctionality()

    const {
      match: { path }
    } = this.props

    if (path.includes(ROUTES.COMMAND_CENTRAL)) {
      // All possible voice commands that our agent can currently do
      return items.map((item, i) => (
        <ListItem
          key={i}
          value={i + 1}
          primaryText={item.type}
          onClick={() => this.setId(item.type)}
          innerDivStyle={styles.item}
          data-cy={`sidebar-item-cmd-${i}`}
        />
      ))
    } else if (path.includes(ROUTES.PREFERENCES)) {
      // User preferences configuration page
      return this.filterByNameOrId(items).map(item => (
        <ListItem
          id={item.id}
          key={item.id}
          value={item.id}
          primaryText={item.id}
          onClick={() => this.setId(item.id)}
          innerDivStyle={styles.item}
          data-cy={`sidebar-item-pref-${item.id}`}
        />
      ))
    } else if (path.includes(ROUTES.ORGS) && !isExtraFunctionality) {
      return this.filterByNameOrId(items).map(item => (
        <ListItem
          id={item.id}
          key={item.id}
          value={item.id}
          primaryText={item.name}
          onClick={() => this.setId(item.id)}
          innerDivStyle={styles.item}
          data-cy={`sidebar-item-org-${item.id}`}
        />
      ))
    } else {
      // ORGS with selected searchBy name or email (Users)
      if (isExtraFunctionality) {
        const { searchBy, users } = this.state
        if (!users) return <div style={styles.oneLineItem}>Please enter search value</div>
        if (users?.length === 0) return <div style={styles.oneLineItem}>Users not found</div>

        const isSearchByEmail = searchBy === 'email'

        return users.map(item => {
          const { id } = item
          return (
            <ListItem
              id={id}
              key={id}
              value={id}
              primaryText={isSearchByEmail ? item.email : getLastFirstName(item.person)}
              onClick={() => history.push(`/${item.organizationId}/users?userId=${id}`)}
              innerDivStyle={styles.item}
              data-cy={`sidebar-item-user-${id}`}
            />
          )
        })
      }

      // USERS
      return this.filterDocs(items).map((item, i) => {
        return (
          <ListItem
            id={item.id}
            key={item.id}
            value={item.id}
            primaryText={getLastFirstName(item.person)}
            onClick={() => this.setId(item.id)}
            innerDivStyle={styles.item}
            data-cy={`sidebar-item-user-${item.id}`}
          >

            <div
              style={{ position: 'relative' }} key={item.id} id={item.id} value={item.id}
            >
              {item?.sharedUser && (
                <img
                  src={SupervisedUserCircleIcon}
                  style={styles.sharedUserIcon}
                  alt='Shared User'
                  title='Shared User'
                />
              )}
            </div>
          </ListItem>
        )
      })
    }
  }

  render () {
    const {
      items,
      noAddButton,
      currentId,
      loading,
      match: { path },
      title
    } = this.props

    const isOrganisationPath = path.includes(ROUTES.ORGS)
    const { searchBy } = this.state
    // gets the placeholder text for the filter
    let placeholder = ''
    switch (searchBy) {
      case 'org': placeholder = 'Filter by Name or ID'; break
      case 'name': placeholder = 'Filter by name (First and/or Last)'; break
      case 'email': placeholder = 'Filter by email adress'; break
    }

    const isExtraFunctionality = this.getIsExtraFunctionality()
    styles.list.height = `calc(100vh - ${navBarHeight} - ${isExtraFunctionality ? 182 : 101}px)`

    return (
      <Drawer docked open zDepth={0} containerStyle={styles.container}>
        <div style={styles.titleContainer} data-cy='sidebar-title'>
          <div style={styles.title}>{title || this.getSidebarTitle()}</div>
          {!noAddButton && (
            <div
              id='sidebarCreateButton'
              style={styles.button}
              onClick={this.handleAddClick}
              data-cy='sidebar-btn-create'
            >
              +
            </div>
          )}
        </div>
        {searchBy !== 'org' && isOrganisationPath &&
          <div onClick={() => this.setState({ searchBy: 'org' })} style={styles.toggleBtn}>
            Search by Org
          </div>}
        {searchBy !== 'name' && isOrganisationPath &&
          <div onClick={this.handleSearchByName} style={styles.toggleBtn}>
            Search by name or id
          </div>}
        {searchBy !== 'email' && isOrganisationPath &&
          <div onClick={this.handleSearchByEmail} style={styles.toggleBtn}>
            Search by email or id
          </div>}
        <FilterBar onChange={this.handleSetFilterString} placeholder={placeholder} hasIcon />
        {loading
          ? <Loading />
          : (items && items.length > 0) && (
            <SelectableList style={styles.list} defaultValue={currentId || items[0].id}>
              {this.renderListItems(items)}
            </SelectableList>
          )}
      </Drawer>
    )
  }
}

export default withRouter(Radium(EditorSidebar))
