import React, { Component } from 'react'
import WaveSurfer from 'wavesurfer.js'
import playButton from '../../images/play-button.svg'
import pauseButton from '../../images/pause-button.svg'
import downloadButton from '../../images/download-icon.svg'
import moment from 'moment'

const styles = {
  playPauseButton: {
    background: 'none',
    border: 'none',
    width: 40
  },
  playImgStyle: {
    width: 28,
    height: 28
  },
  audioAndControl: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  wavesurferContainer: {
    width: 500
  }
}

const SPACE_KEY = 32
const RIGHT_KEY = 39
const LEFT_KEY = 37
const UP_KEY = 38
const DOWN_KEY = 40

class AudioControl extends Component {
  state = {
    paused: null,
    timeElapsed: 0,
    playbackRate: 1
  }

  componentDidMount () {
    var wavesurfer = WaveSurfer.create({
      container: '#' + this.props.wavesurferId,
      waveColor: '#497B3F',
      progressColor: '#93ED82'
    })

    this.wavesurfer = wavesurfer

    wavesurfer.load(this.props.src)

    window.addEventListener('keydown', this.keyDownListenerEvent)

    wavesurfer.on('ready', () => {
      this.setState({ paused: false })
      wavesurfer.play()
    })

    wavesurfer.on('audioprocess', timeElapsed => {
      this.setState({ timeElapsed })
    })

    wavesurfer.on('seek', progress => {
      this.setState({ timeElapsed: progress * wavesurfer.getDuration() })
    })

    wavesurfer.on('finish', () => {
      this.setState({ paused: true })
    })
  }

  componentWillUnmount () {
    this.wavesurfer.destroy()
  }

  keyDownListenerEvent = e => {
    if (!this.props.isCurrentSection || !this.wavesurfer) return
    const key = e.key
    const keyCode = e.keyCode

    if (e.shiftKey && (key === ' ' || keyCode === SPACE_KEY)) {
      // keyCode is deprecated on some browsers so use both key and keyCode method
      e.preventDefault()
      this.handlePlayPause()
    } else if (e.shiftKey && (key === 'ArrowRight' || keyCode === RIGHT_KEY)) {
      e.preventDefault()
      const duration = this.wavesurfer.getDuration()
      const threeAhead = this.wavesurfer.getCurrentTime() + 3
      if (threeAhead > duration) {
        this.setState({ paused: true })
        this.pause()
        this.wavesurfer.seekTo(1)
        return
      }
      this.wavesurfer.seekTo(threeAhead / duration)
    } else if (e.shiftKey && (key === 'ArrowLeft' || keyCode === LEFT_KEY)) {
      e.preventDefault()
      const audioDuration = this.wavesurfer.getDuration()
      const threeBehind = this.wavesurfer.getCurrentTime() - 3
      if (threeBehind < 0) {
        this.wavesurfer.seekTo(0)
        return
      }
      this.wavesurfer.seekTo(threeBehind / audioDuration)
    } else if (e.shiftKey && (key === 'ArrowUp' || keyCode === UP_KEY)) {
      e.preventDefault()
      const playbackRate = this.wavesurfer.getPlaybackRate()
      if (playbackRate >= 2) return
      this.setState({ playbackRate: playbackRate + 0.05 })
      this.wavesurfer.setPlaybackRate(playbackRate + 0.05)
    } else if (e.shiftKey && (key === 'ArrowDown' || keyCode === DOWN_KEY)) {
      e.preventDefault()
      const pbRate = this.wavesurfer.getPlaybackRate()
      if (pbRate <= 0.15) return
      this.setState({ playbackRate: pbRate - 0.05 })
      this.wavesurfer.setPlaybackRate(pbRate - 0.05)
    }
  }

  pause = () => {
    this.wavesurfer.pause()
    this.setState({ paused: true })
  }

  handlePlayPause = () => {
    this.wavesurfer.playPause()
    this.setState({ paused: !this.state.paused })
  }

  getTimeFormat = seconds => moment().startOf('day').seconds(seconds).format('mm:ss')

  getDuration = () => this.getTimeFormat(this.wavesurfer.getDuration())

  getTimeElapsed = () => this.getTimeFormat(this.state.timeElapsed)

  render () {
    const { enableAudioDownload } = this.props
    return (
      <div>
        {enableAudioDownload ? (
          <div style={styles.audioAndControl}>
            <img alt='play-pause-button' src={this.state.paused ? playButton : pauseButton} style={styles.playImgStyle} onClick={this.handlePlayPause} />
            <div>{this.wavesurfer && this.getTimeElapsed()}</div>
            <div style={styles.wavesurferContainer} id={this.props.wavesurferId} />
            <div>{this.wavesurfer && this.getDuration()}</div>
            <a href={this.props.src} target='_blank' download rel='noopener noreferrer'>
              <img alt='download-audio' src={downloadButton} style={{ width: 30, height: 20 }} />
            </a>
          </div>)
          : (
            <div style={styles.audioAndControl}>
              <img alt='play-pause-button' src={this.state.paused ? playButton : pauseButton} style={styles.playImgStyle} onClick={this.handlePlayPause} />
              <div>{this.wavesurfer && this.getTimeElapsed()}</div>
              <div style={styles.wavesurferContainer} id={this.props.wavesurferId} />
              <div>{this.wavesurfer && this.getDuration()}</div>
              <a href={this.props.src} target='_blank' download rel='noopener noreferrer' />
            </div>
          )}
      </div>
    )
  }
}

export default AudioControl
