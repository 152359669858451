import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import TopNavBar from './TopNavBar'
import { navBarHeight } from '../styles/dimensions'
import { GetOrganizationById } from '@sukiai/gql/admin'
import { FETCH_POLICY } from '../lib/constants'
import get from 'lodash.get'
import { whiteSmoke } from '../styles/colors'

const styles = {
  container: {
    position: 'fixed',
    top: navBarHeight,
    height: `calc(100vh - ${navBarHeight})`,
    overflow: 'scroll',
    width: '100%',
    padding: 30,
    color: whiteSmoke,
    boxSizing: 'border-box'
  }
}

class EMR extends Component {
  render () {
    const { match } = this.props
    const orgId = get(match, 'params.orgId')
    const variables = {
      id: orgId,
      withEmrInfo: true
    }

    return (
      <div>
        <TopNavBar />
        <div style={styles.container}>
          <Query
            query={GetOrganizationById}
            variables={variables}
            skip={!orgId}
            fetchPolicy={FETCH_POLICY.CACHE_AND_NETWORK}
          >
            {({ loading, error, data }) => {
              if (loading) return 'Loading...'
              if (error) return 'Uh oh, something went wrong!'
              const orgData = get(data, 'organizations.results[0]')

              return (
                <pre>
                  {JSON.stringify(orgData, null, 2)}
                </pre>
              )
            }}
          </Query>
        </div>
      </div>
    )
  }
}

export default withRouter(EMR)
