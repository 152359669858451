import reactStyled, { css } from 'styled-components'
import styled from 'styled-components/native'
import { Animated } from 'react-native'
import { charcoal, slateGrey, amwellPrimaryButtonOutlineColor } from '../../lib/colors'
import deleteSectionX from '../../assets/images/deleteSectionX.png'
import mic from '../../assets/images/mic.png'
import keyboard from '../../assets/icons/keyboard.svg'

const icd10BtnWidth = '120px'
// buffer + icd10BtnWidth + rest of right side
const problemSectionWidth = `calc(100% - 20px - ${icd10BtnWidth} - 140px)`

export const Wrapper = styled.View``

export const DeleteSectionButtonDark = reactStyled.div`
  width: 16px;
  height: 16px;
  opacity: 0.5;
  position: relative;
  left: 12px;
  &:hover {
    border: 1px solid white;
    border-radius: 50%;
    opacity: 1;
  }
  #mdiv {
    height: 16px;
    cursor: pointer;
    margin-top: 1px;
    margin-left: 0px;
  }
  .mdiv {
    height: 12px;
    width: 2px;
    margin-left: 6px;
    background-color: #fff;
    transform: rotate(45deg);
    Z-index: 1;
  }
  .md {
    height: 12px;
    width: 2px;
    background-color: #fff;
    transform: rotate(90deg);
    Z-index: 2;
  }
`

export const DeleteSectionButton = reactStyled.img.attrs({
  src: deleteSectionX
})`
  position: absolute;
  right: 10px;
  opacity: 0;
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 50%;
  transition: 0.2s;

  &:hover {
    cursor: pointer;
    background: rgba(118, 118, 128, 0.08);
  }
`

export const DictateButton = reactStyled.img.attrs({
  src: mic
})`
  position: absolute;
  right: 180px;
  width: 15px;
  height: 20px;
  opacity: ${props => props.isDictationMode ? 1 : 0};
  padding: 8px
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
`

export const KeyboardIcon = reactStyled.img.attrs({
  src: keyboard
})`
position: absolute;
right: 180px;
width: 48px;
height: 24px;
opacity: 0.5;

&:hover {
  cursor: pointer;
}

@media (max-width: 600px){
  opacity: 0;
}
`

export const SectionContainer = reactStyled.div`
  margin: ${props => props.isAmwellTheme ? '0px 0px 8px 0px' : '10px -5px 0px -17px'};
  padding: ${props => props.isAmwellTheme ? props.isActive ? '10px 16px 10px 12px' : '10px 16px 10px 16px' : '10px 0px 10px 45px'};
  align-items: flex-start;
  &:hover {
    ${DeleteSectionButton}, ${DictateButton} {
      opacity: 1;
    }
    outline: ${props => props.isreadonly ? 'none' : '1px solid rgba(2, 16, 17, 0.1)'};
  };
  ${props => props.isAmwellTheme && css`
    background: ${props => props.isActive ? '#232a30' : 'inherit'};
    border-left: ${props => props.isActive ? props.isDictationMode ? '4px solid #46aff2' : '4px solid #4f5559' : 'none'};
    border-radius: ${props => props.isActive ? '4px' : 'inherit'};
    caret-color: ${props => props.isActive ? props.isDictationMode ? '#46aff2' : '#bec0c1' : 'inherit'};
  `}
  &:hover {
    ${props => props.isAmwellTheme && css`
      border-radius: 4px;
      background-color: #232a30;    
   `}
  }
`

export const DiagnosisNumber = styled.Text`
  margin-top: 15;
`
export const DiagnosisNumberDark = styled.Text`
  margin-top: 12;
  color: #fff;
`

export const SectionName = styled.Text`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 20;
  margin-bottom: 8;
  margin-top: 10;
  margin-left: 10;
  width: ${problemSectionWidth};
  cursor: ${props => props.isActive ? 'text' : 'default'}
`

export const SectionNameTextareaDark = reactStyled.textarea`
  overflow: hidden;
  resize: none;
  white-space: pre-wrap;
  border: none;
  border-radius: 3px;
  padding: 0.5em;
  margin: 0px 0px;
  font-size: 16px;
  font-weight: 600;
  width: ${problemSectionWidth};
  min-width: 165px;
  color: #fff;
  background: ${props => props.isFocused ? '#232a30' : 'transparent'};
  &:focus {
    outline: none;
  }
  cursor: ${props => props.isActive ? 'text' : 'default'}
`

export const SectionNameTextarea = reactStyled.textarea`
  overflow: hidden;
  resize: none;
  white-space: pre-wrap;
  border: none;
  border-radius: 3px;
  padding: 0.5em;
  margin: 0px 0px;
  font-size: 14pt;
  font-weight: 600;
  width: ${problemSectionWidth};
  min-width: 165px;
  &:focus {
    outline: none;
  }
  cursor: ${props => props.isActive ? 'text' : 'default'}
`

export const SectionContent = styled.Text`
  font-family: 'Open Sans';
  font-size: 18;
  min-height: 24;
  margin: 0 50px 0 25px;
  display:block;
  color: ${props => props.isAmwellDark ? '#fff' : 'inherit'};
`

export const Blurred = styled.Text`
  filter: blur(4px);
`

export const Macro = styled.Text`
  color: #707070;
`

export const ScribeToProcess = styled.Text`
  font-weight: 600;
  font-style: italic;
`

export const Paragraph = styled.Text`
  display: block;
  margin-top: 26;
`

export const SectionIndicator = styled.View`
  position: absolute;
  left: -80;
  height: 100%;
  width: 12;
  background-color: ${charcoal};
`

export const CapturingIndicator = styled(Animated.View)`
  position: absolute;
  top: 28;
  right: 180;
  height: 27px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CapturingIndicatorDot = styled(Animated.View)`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${slateGrey};
  margin: 0px 6px;
  ${props => props.isAmwellTheme && css`
    background-color: #46aff2;
    height: 6px;
    width: 6px;
    margin: 0px 2px;
  `}
`

export const AddProblem = reactStyled.div`
  margin: 20px 0px 10px 30px;
  color: rgb(46, 95, 98);
  font-weight: 600;
`

export const SectionHeader = reactStyled.div`
  position: relative;
  display: flex;
  flex-direction: row; 
  align-items: ${props => props.isAmwellTheme ? 'center' : 'flex-start'};
  padding-bottom: 8px;
`
export const CodeSelectorButtonDark = reactStyled.div`
  background-color: ${props => props.icdCode ? 'none' : '#1774cc'};
  border: ${props => props.icdCode ? `1px solid ${amwellPrimaryButtonOutlineColor}` : '#1774cc'};
  color: ${props => props.icdCode ? amwellPrimaryButtonOutlineColor : 'inherit'};
  position: absolute;
  right: 16px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 80px;
  font-size: 12px;
  transition: 0.3s;
  opacity: ${props => props.disabled ? '0.5' : '1'};
  border-radius: 15px;
  ${props => !props.disabled && css`
    &:hover {
      cursor: pointer;
      }
    `};
 
  &:hover {
    background-color: ${props => props.icdCode ? 'rgba(70, 175, 242, 0.12)' : 'rgb(62, 137, 208)'};
    }
  }
`
export const CodeSelectorButton = reactStyled.div`
  position: absolute;
  right: 50px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${icd10BtnWidth};
  height: 30px;
  outline: 1px solid rgba(240, 244, 239, 1);
  box-shadow: 0 0 3px #ddd;
  color: gray;
  font-size: 12pt;
  background-color: ${props => props.icdCode ? 'rgba(240, 244, 239, 0.75)' : 'rgba(0,0,0,0)'};
  transition: 0.3s;
  opacity: ${props => props.disabled ? '0.5' : '1'};

  ${props => !props.disabled && css`
    &:hover {
      cursor: pointer;
      background-color: rgba(240, 244, 239, 0.75);
      outline: ${props => props.icdCode ? '1px solid rgba(0, 0, 0, .1)' : '1px solid rgba(240, 244, 239, 1)'};
      }
    `}
  }
`

export const CodeLabel = reactStyled.div`
  position: absolute;
  right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${icd10BtnWidth};
  height: 30px;
  outline: 1px solid rgba(240, 244, 239, 1);
  box-shadow: 0 0 3px #ddd;
  color: gray;
  font-size: 12pt;
  background-color: ${props => props.icdCode ? 'rgba(240, 244, 239, 0.75)' : 'rgba(0,0,0,0)'};
  transition: 0.3s;
`

export const ProblemSectionNameDark = reactStyled.div`
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 18px;
  color: #fff;

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const ProblemSectionName = reactStyled.div`
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 18px;
  color: black;

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`
