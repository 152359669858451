import React from 'react'
import { Card, Button } from 'antd'

function FeatureFlagCard ({ title, description, onClick }) {
  return (
    <Card
      title={title}
      size='small'
      style={{
        width: 320,
        height: 160,
        margin: 12
      }}
      extra={
        <Button type='link' onClick={onClick}>
          View
        </Button>
      }
    >
      {description}
    </Card>
  )
}

export default FeatureFlagCard
