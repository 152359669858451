import gql from 'graphql-tag'

// View: PREFERENCES
// Description: Get preferences given organization and user IDs
export const GetPreferences = gql`
  query GetPreferences($organizationId: ID!, $userId: ID) {
    preferences(input: { organizationId: $organizationId, userId: $userId }) {
      count
      results {
        id
        name
        stringPreference {
          value
        }
        enumPreference {
          possibleValues
          value
        }
      }
    }
  }
`

// View: PREFERENCES
// Description: Update preferences for an organization or user
export const UpdatePreferences = gql`
  mutation UpdatePreferences(
    $organizationId: ID!
    $userId: ID
    $preferences: [PreferenceInput!]!
  ) {
    updatePreferences(
      input: {
        organizationId: $organizationId
        userId: $userId
        preferences: $preferences
      }
    ) {
      ids
    }
  }
`
