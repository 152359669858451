import styled from 'styled-components'

export const Powerbar = styled.div`
    padding: 10px;
    background: #E8E8E8;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h2 {
      color: #555555;
      font-size: 16px;
      margin:0px;
    }

    div {
        display: flex;
        justify-content: space-between;
    }

`
