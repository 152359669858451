import React, { Component } from 'react'
import moment from 'moment'
import { transcriptHeader } from '../../styles/colors'
import { getFullName, selectorFormat } from '@sukiai/utils'
import get from 'lodash.get'
import { withRouter } from 'react-router-dom'
import { ROUTES } from '../../lib/constants'

const styles = {
  header: {
    width: '100%',
    boxSizing: 'border-box'
  },
  headingStyle: {
    fontSize: 18,
    fontWeight: 600,
    padding: 0,
    textAlign: 'left'
  },
  metadataInfo: {
    padding: '10px',
    overflow: 'scroll',
    maxHeight: '600px'
  },
  metadataSect: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px'
  },
  metadataField: {
    display: 'flex',
    paddingLeft: 12
  },
  metadataLabel: {
    width: 190
  },
  metadataValue: {
    marginLeft: '8px'
  },
  transcriptHistory: {
    border: '1px solid lightgray'
  },
  versionSlider: {
    width: '100%'
  },
  versionSelector: {
    width: 220,
    marginLeft: 10
  },
  sectionVersion: {
    padding: 10,
    backgroundColor: transcriptHeader
  },
  sectionMeta: {
    height: '100%',
    width: '100%',
    postiion: 'relative'
  },
  sectionMetaField: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%'
  },
  clickableField: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}

class MetadataHistorySlider extends Component {
  constructor (props) {
    super(props)

    const { versions } = props
    this.state = {
      versions: versions,
      numVersions: get(versions, 'length'),
      currentVersion: this.getCurrentVersion(versions)
    }
  }

  getCurrentVersion = versions => versions.length || 1

  setVersion = (e, v) => {
    this.setState({ currentVersion: parseInt(e.target.value, 10) })
  }

  handleSliderChange = e => {
    this.setState({ currentVersion: e.target.value })
  }

  renderVersionSelector = () => {
    const { currentVersion, versions } = this.state

    return (
      <span style={styles.sectionMetaField}>
        <span>Version: </span>
        <span style={styles.versionSelector}>
          <select
            onChange={e => this.setVersion(e)}
            value={currentVersion}
            data-cy='metadata-history-version-selector'
          >
            {versions.map((v, i) => (
              <option value={i + 1} key={`${v.createdAt}`}>{i + 1}</option>
            ))}
          </select>
          &nbsp;of {versions.length}
        </span>
      </span>
    )
  }

  renderTranscriptHistoryModal = () => {
    const { currentVersion, numVersions } = this.state

    return (
      <div style={styles.transcriptHistory}>
        <div style={styles.sectionVersion}>
          <div style={styles.sectionMeta}>
            {this.renderVersionSelector()}
          </div>
          <div className='slidecontainer' data-cy='metadata-history-version-slider'>
            <input
              type='range'
              style={styles.versionSlider}
              min={1}
              max={numVersions}
              value={currentVersion}
              onChange={e => this.handleSliderChange(e)}
            />
          </div>
        </div>
      </div>
    )
  }

  renderMetadataInfo = () => {
    const { history } = this.props
    const { versions, currentVersion } = this.state
    const m = versions[currentVersion - 1]

    const appt = get(m, 'metadata.appointment')
    const p = get(m, 'metadata.patient')
    const u = get(m, 'metadata.user')
    const status = get(m, 'metadata.status')
    const clientType = get(m, 'metadata.clientType')
    const createdWithS2 = get(m, 'metadata.createdWithS2')
    const pulledNoteFromEmr = m?.metadata?.pulledNoteFromEmr
    const author = get(m, 'author')
    const submissionStatus = get(m, 'metadata.submissionStatus')
    const submissionInformation = get(m, 'metadata.submissionInformation')

    // Display role if author type is USER, otherwise display MACHINE or UNKNOWN
    const type = get(author, 'type')
    const userType = type !== 'USER' ? type : get(author, 'role.type')
    const authorData = author && userType !== 'UNKNOWN'
      ? (
        <div>
          {this.renderMetadataField('User Type', userType)}
          {type === 'USER' &&
            this.renderMetadataField('User Name', author.userName, () => history.push(`/${author.organizationId}/${ROUTES.USERS}`))}
          {type === 'USER' && this.renderMetadataField('Organization Name', author.organizationName)}
          {type === 'USER' && this.renderMetadataField('Organization ID', author.organizationId)}
          {type === 'USER' && this.renderMetadataField('User ID', author.userId)}
        </div>
      )
      : <div style={styles.metadataField}> No Author Data </div>

    const apptData = appt
      ? (
        <div>
          {this.renderMetadataField('ID', appt.id)}
          {this.renderMetadataField('Starts at', appt && moment(appt.startsAt)?.format('MMM DD YYYY, h:mm:ss a'))}
          {this.renderMetadataField('Reason', appt.reason)}
          {this.renderMetadataField('EMR ID', appt.emrId || 'N/A')}
          {this.renderMetadataField('EMR Encounter ID', appt.emrEncounterId || 'N/A')}
          {this.renderMetadataField('EMR Department ID', appt.emrDepartmentId || 'N/A')}
          {this.renderMetadataField('EMR Encounter Status', appt.emrEncounterStatus || 'N/A')}
        </div>
      )
      : <div style={styles.metadataField}> No Appointment </div>

    const pData = p
      ? (
        <div>
          {this.renderMetadataField('Name', getFullName(p.person))}
          {this.renderMetadataField('ID', p.id)}
          {this.renderMetadataField('MRN', p.mrn || 'N/A')}
          {this.renderMetadataField('EMR ID', p.emrId || 'N/A')}
          {this.renderMetadataField('EMR ID Type', p.emrIdType || 'N/A')}
        </div>
      )
      : <div style={styles.metadataField}> No Patient </div>

    const noteName = get(m, 'metadata.name')
    const notetypeId = get(m, 'metadata.noteTypeId')
    const noteTypeData = (
      <div>
        {this.renderMetadataField('Name', noteName)}
        {this.renderMetadataField('ID', notetypeId)}
      </div>
    )

    const submissionInformationData = submissionInformation
      ? (
        <div>
          {this.renderMetadataField(
            'Destinations',
            get(submissionInformation, 'destinations', []).join(', '))}
          {this.renderMetadataField('Try all destinations', submissionInformation.tryAllDestinations ? 'true' : 'false')}
        </div>
      )
      : <div style={styles.metadataField}> No submission information </div>

    const submissionStatusData = submissionStatus
      ? (
        <div>
          {this.renderMetadataField('Final destination', submissionStatus.finalDestination || 'N/A')}
          {this.renderMetadataField('Warning type', get(submissionStatus, 'warning.type') || 'N/A')}
          {this.renderMetadataField('Warning message', get(submissionStatus, 'warning.message') || 'N/A')}
        </div>
      )
      : <div style={styles.metadataField}> No submission status </div>

    const uData = u
      ? (
        <div>
          {this.renderMetadataField('Name', getFullName(u.person))}
          {this.renderMetadataField('ID', u.id)}
        </div>
      )
      : <div style={styles.metadataField}> No User </div>

    return (
      <div style={styles.metadataInfo}>
        {this.renderMetadataField('Created', moment(m.createdAt).format('MMM DD YYYY, h:mm:ss a'))}
        {this.renderMetadataField('Status', status)}
        {this.renderMetadataField('Client Type', clientType)}
        {this.renderMetadataField('Created with S2', createdWithS2 ? 'TRUE' : 'FALSE')}
        {this.renderMetadataField('Pulled note from EMR', pulledNoteFromEmr ? 'TRUE' : 'FALSE')}
        {this.renderMetadataSection(authorData, 'Author')}
        {this.renderMetadataSection(apptData, 'Appointment')}
        {this.renderMetadataSection(pData, 'Patient')}
        {this.renderMetadataSection(noteTypeData, 'Note Type')}
        {this.renderMetadataSection(submissionInformationData, 'Submission Information', true)}
        {this.renderMetadataSection(submissionStatusData, 'Submission Status', true)}
        {this.renderMetadataSection(uData, 'User Info', true)}
      </div>
    )
  }

  renderMetadataField = (field, val, clickAction) => (
    <div style={styles.metadataField} data-cy={`metadata-field-${selectorFormat(field)}`}>
      <b style={styles.metadataLabel}>{field}:</b>
      <div onClick={clickAction} style={clickAction && styles.clickableField}>
        {typeof (val) === 'object' ? JSON.stringify(val) : val}
      </div>
    </div>
  )

  renderMetadataSection = (data, sectionName, withDropdown) => (
    <div style={styles.metadataSect}>
      {withDropdown
        ? (
          <details data-cy={`metadata-section-${selectorFormat(sectionName)}`}>
            <summary><b>{sectionName}</b></summary>
            {data}
          </details>
        )
        : (
          <div data-cy={`metadata-section-${selectorFormat(sectionName)}`}>
            <b>{sectionName}</b>
            {data}
          </div>
        )}
    </div>
  )

  render () {
    const { style } = this.props
    const { versions } = this.state
    if (!versions) return null

    return (
      <div style={{ ...styles.header, ...style }}>
        {this.renderTranscriptHistoryModal()}
        {this.renderMetadataInfo()}
      </div>
    )
  }
}

export default withRouter(MetadataHistorySlider)
