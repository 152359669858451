import styled, { css } from 'styled-components/native'
import { Animated } from 'react-native'
import { casal, charcoal, platinum, snowDrift, westSide, white } from '../../lib/colors'
import alertTriangle from '../../assets/icons/alert-triangle.svg'
import { Z_INDEX, TOAST } from '../../lib/constants'

const getToastBG = type => {
  if (type === TOAST.TYPE.ERROR) return platinum
  else if (type === TOAST.TYPE.PRIMARY) return casal
  else if (type === TOAST.TYPE.WARNING) return westSide
  else return snowDrift
}

export const ToastContainer = styled(Animated.View)`
  position: fixed;
  display: ${props => props.message
    ? 'flex'
    : 'none'
  };
  left: ${props => props.vw * 0.35};
  top: ${props => props.vh + 5};
  width: ${props => props.vw * 0.3};
  box-shadow: 0px 0px 10px rgba(0,0,0,0.7);
  padding: 30px 40px;
  z-index: ${Z_INDEX.MODAL};
  background-color: ${props => getToastBG(props.type)};
  align-items: center;
  flex-direction: row;

  ${props => props.vw < 900 &&
    css`
      left: 50%;
      /* to center fixed div */
      margin-left: -150px; 
      width: 300px;
    `
  }
`

export const AmwellToastContainer = styled(Animated.View)`
  position: fixed;
  display: ${props => props.message
    ? 'flex'
    : 'none'
  };
  left: ${props => props.vw * 0.1};
  top: ${props => props.vh + 5};
  width: ${props => props.vw * 0.8};
  box-shadow: 0px 0px 10px rgba(0,0,0,0.7);
  padding: 0px;
  z-index: ${Z_INDEX.MODAL};
  background-color: #232a30;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`

export const ToastText = styled.Text`
  font-family: "Open Sans";
  font-size: 18px;
  color:  ${props => props.type === TOAST.TYPE.PRIMARY ? white : charcoal};
  padding: 0 10px;
`
export const AmwellText = styled.Text`
  font-family: "Open Sans";
  font-size: 16px;
  color: #FFFFFF;
  padding: 16px;
`

export const ErrorIcon = styled.Image.attrs({
  source: ({
    uri: alertTriangle,
    width: 24,
    height: 24
  }),
  draggable: false
})`
`
