import React from 'react'
import styled from 'styled-components'
import { whiteSmoke, charcoal } from '../styles/colors'
import { Z_INDEX } from '../lib/constants'
import { convertSlateToPlainText } from '../lib/util'
import { isCharUnsupported } from '@sukiai/utils'

const Veil = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${Z_INDEX.VEIL};
`

const Container = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  width: 600px;
  padding: 25px;
  background: ${whiteSmoke};
  color: ${charcoal};
  border-radius: 4px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.5);
  z-index: ${Z_INDEX.MODAL};
  position: relative;
`

const Heading = styled.div`

h2 {
    font-size: 24px;
  font-weight: 600; 
  margin: 0
}

  
  p {
      margin: 5px 0 15px 0;
  }
`

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`

const Section = styled.div`
    margin: 10px 0;
    h4 {
        margin: 0;
    }
`

const Button = styled.button.attrs({
  type: 'button'
})`
  border: none;
  outline: none;
  padding: 0px;
  background: ${charcoal};
  color: white;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
  margin-left: 14px;
  align-self: flex-end;
  font-weight: 600;
`

export default class ContentErrorsDialog extends React.Component {
    handleClose = () => {
      const { closeDialog } = this.props
      closeDialog()
    }

    highlightErrors = (text) => {
      // returns JSX with styles applied to chars unsupported
      const parts = text.split('')
      return (
        <span>
          {parts.map((part, i) => (
            <span
              key={i}
              style={
                isCharUnsupported(part)
                  ? { background: '#e08683', color: '#3e0808', paddingLeft: '2px', paddingRight: '2px' }
                  : {}
              }
            >
              {part}
            </span>
          ))}
        </span>
      )
    }

    renderSections = () => {
      const { contentErrors } = this.props
      return contentErrors.map((section, i) => {
        const plainText = convertSlateToPlainText(section.content)
        return (
          <Section key={i}>
            <h4> {section.name}</h4>
            <div key={section.id}>{this.highlightErrors(plainText)}</div>
          </Section>)
      })
    }

    render () {
      const { contentErrors, open } = this.props
      return (
        open && (
          <Veil onClick={this.handleClose}>
            <Container data-cy='nt-configure-specialties-container' onClick={e => e.stopPropagation()}>
              <Body>
                <Heading>
                  <h2>Error:    Unsupported Characters</h2>       <p>We found errors unsupported chracters with <strong>{contentErrors?.length}</strong> section(s). Please fix before saving.</p>
                </Heading>
                {this.renderSections()}

                <Button data-cy='nt-set-specialties-button' onClick={this.handleClose}>
                  Close
                </Button>

              </Body>
            </Container>
          </Veil>
        )
      )
    }
}
