import React, { Component } from 'react'
import { getFullName } from '@sukiai/utils'
import {
  getAge,
  getGender,
  copyText
} from '../../lib/util'
import moment from 'moment'
import get from 'lodash.get'
import { adminHeaderGrey } from '../../styles/colors'
import { NOTE_MODE } from '../../lib/constants'

const NOT_AVAILABLE = 'N/A'
const UNKNOWN_PATIENT = 'Unknown Patient'

const styles = {
  header: {
    width: '100%',
    padding: '30px 50px',
    boxSizing: 'border-box'
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  name: {
    fontSize: 24,
    fontWeight: 800,
    padding: 0,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1
  },
  age: {
    fontSize: 14,
    fontWeight: 400
  },
  gender: {
    marginBottom: -3
  },
  patientInfo: {
    fontSize: 14,
    padding: 0,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1
  },
  noteName: {
    fontSize: 19,
    fontWeight: 600,
    marginBottom: 20,
    textAlign: 'center'
  },
  id: {
    color: adminHeaderGrey,
    fontSize: 16,
    cursor: 'pointer',
    marginTop: 6,
    width: 'fit-content',
    fontWeight: 500
  }
}

class NoteHeader extends Component {
  copy = (e, text) => {
    e.preventDefault()
    copyText(text)
  }

  render () {
    const {
      patient,
      noteName,
      createdAt,
      appt,
      noteMode
    } = this.props

    const apptEmrId = get(appt, 'emrId')
    const emrEncounterId = get(appt, 'emrEncounterId')
    const emrDepartmentId = get(appt, 'emrDepartmentId')
    const mrn = get(patient, 'mrn')
    const patientId = get(patient, 'id')
    const patientEmrId = get(patient, 'emrId')
    const p = get(patient, 'person')

    let initials = `${get(p, 'firstName[0]', '')} ${get(p, 'lastName[0]', '')}`
    if (initials === ' ') {
      initials = 'UNKNOWN PATIENT'
    }
    const patientName = getFullName(p) || UNKNOWN_PATIENT
    const age = getAge(get(p, 'dateOfBirth'))
    const gender = getGender(p)
    const dob = p && (new Date(p.dateOfBirth).getTime() !== 0) && moment(p.dateOfBirth).utc().format('MM/DD/YYYY')
    const dos = (appt && moment(appt.startsAt).format('MM/DD/YYYY')) || NOT_AVAILABLE
    const created = createdAt && moment(createdAt).format('MM/DD/YYYY h:mm A')

    return (
      <div style={{ ...styles.header, ...this.props.style }}>
        {noteName &&
          <div style={styles.noteName} data-cy='note-name'>
            {noteName}
          </div>}
        <details data-cy='patient-details'>
          <summary style={{ ...styles.name, display: 'block', outline: 'none' }}>
            {`Patient: ${initials}`}
            {noteMode === NOTE_MODE.DEBUG &&
              <div>
                {patientId &&
                  <div style={styles.id} onClick={e => this.copy(e, patientId)}>
                  Suki Patient ID: {patientId}
                  </div>}
                {patientEmrId &&
                  <div style={styles.id} onClick={e => this.copy(e, patientEmrId)}>
                  EMR Patient ID: {patientEmrId}
                  </div>}
                {apptEmrId &&
                  <div style={styles.id} onClick={e => this.copy(e, apptEmrId)}>
                  EMR Appointment ID: {apptEmrId}
                  </div>}
                {emrEncounterId &&
                  <div style={styles.id} onClick={e => this.copy(e, emrEncounterId)}>
                  EMR Encounter ID: {emrEncounterId}
                  </div>}
                {emrDepartmentId &&
                  <div style={styles.id} onClick={e => this.copy(e, emrDepartmentId)}>
                  EMR Department ID: {emrDepartmentId}
                  </div>}
              </div>}
          </summary>
          <div style={styles.info}>
            <div style={styles.name}>
              <div data-cy='patient-name'>{patientName}</div>
              {age &&
                <span style={styles.age} data-cy='patient-age-and-gender'>
                  Age {age}&nbsp;&nbsp;&bull;&nbsp;&nbsp;{gender}
                </span>}
            </div>
            <div style={styles.patientInfo}>
              {mrn && <div data-cy='patient-mrn'>MRN: {mrn}</div>}
              {dob && <div data-cy='patient-dob'>Date of Birth: {dob}</div>}
              {dos && <div data-cy='patient-dos'>Date of Service: {dos}</div>}
              {created && <div data-cy='note-created-at'>Created: {created}</div>}
            </div>
          </div>
        </details>
      </div>
    )
  }
}

export default NoteHeader
