import React, { Component } from 'react'
import styled from 'styled-components'

import EditorPreview from './EditorPreview'
import { Powerbar } from './styled'
import { s2Example } from './sample'
import { USER_PERSONA } from '../../lib/constants'
import { withRouter } from 'react-router-dom'

const Container = styled.div`
`

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const EditorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const SukiFormatBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;

  textarea {
    width: 1000px;
    min-height: 500px;
    border: 0;
  }
`

const PersonaButton = styled.button`
  margin: 0px 30px;
  height: 50px;
  width: 120px;
`

class EditorJungle extends Component {
    state = {
      content: JSON.stringify(s2Example),
      persona: USER_PERSONA.PHYSICIAN
    }

    handleTextareaChange = e => {
      this.setState({ content: e.target.value })
    }

    handleChangePersona = persona => {
      const { location } = this.props
      location.pathname = `/editorsandbox?persona=${persona}`
      this.setState({ persona })
    }

    render () {
      const { content, persona } = this.state
      return (
        <Container>
          <EditorContainer>
            <SukiFormatBox>
              <Powerbar>
                <h2>S2 JSON </h2>
              </Powerbar>
              <textarea value={content} onChange={this.handleTextareaChange} />
            </SukiFormatBox>
            <EditorPreview
              key={content}
              content={content}
              persona={persona}
            />
          </EditorContainer>
          <ButtonRow>
            <PersonaButton
              onClick={() => this.handleChangePersona(USER_PERSONA.PHYSICIAN)}
            >
              Doctor
            </PersonaButton>
            <PersonaButton
              onClick={() => this.handleChangePersona(USER_PERSONA.SCRIBE)}
            >
              Ops
            </PersonaButton>
            <PersonaButton
              onClick={() => this.handleChangePersona(USER_PERSONA.ADMIN)}
            >
              Admin
            </PersonaButton>
          </ButtonRow>
        </Container>
      )
    }
}

export default withRouter(EditorJungle)
