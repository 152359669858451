import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import RaisedButton from 'material-ui/RaisedButton'
import DatePicker from 'material-ui/DatePicker'
import { withRouter } from 'react-router'
import { compose } from 'react-apollo'
import { notifySuccess, setError } from '../../lib/util'
import moment from 'moment'
import client from '../../apollo'
import { RefreshEMRAppointments } from '@sukiai/gql/admin'
import { whiteSmoke } from '../../styles/colors'

const styles = {
  formStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    color: 'white',
    padding: '20px'
  },
  rowFormStyle: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'flex-end'
  },
  text: {
    color: 'white'
  },
  formsContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between'
  },
  btn: {
    margin: '12px'
  }
}

// Validate the Appointment Creation Form
const validate = values => {
  const errors = {}
  const requiredFields = [
    'fromDate',
    'toDate'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}

const renderDatePicker = ({
  input: { onBlur, ...inputProps },
  onChange,
  ...props
}) =>
  <DatePicker
    {...inputProps}
    onChange={(event, value) => inputProps.onChange(value)}
    value={inputProps.value !== '' ? inputProps.value : null}
    {...props}
  />

class Form extends Component {
  render () {
    const {
      pristine,
      reset,
      submitting,
      handleSubmit
    } = this.props
    return (
      <form style={styles.formStyle} onSubmit={handleSubmit}>
        <div>
          <Field
            name='fromDate'
            component={renderDatePicker}
            hintText='Start Date'
          />
        </div>
        <div>
          <Field
            name='toDate'
            component={renderDatePicker}
            hintText='End Date'
          />
        </div>
        <div>
          <RaisedButton
            id='apptSubmit'
            type='submit'
            label='Submit'
            disabled={pristine || submitting}
            primary
            style={styles.btn}
          />
          <RaisedButton
            type='button'
            label='Clear Values'
            disabled={pristine || submitting}
            onClick={reset}
            style={styles.btn}
          />
        </div>
      </form>
    )
  }
}

// Actual Appointments Refresh Form
const AppointmentsRefreshForm = reduxForm({ form: 'AppointmentsRefreshForm', validate })(Form)

const getTimerange = (fromDate, toDate) => {
  return {
    fromDate: moment(fromDate).startOf('day').toISOString(),
    toDate: moment(toDate).endOf('day').toISOString()
  }
}

// Container to hold both the form and the table
class AppointmentsRefreshRangePicker extends Component {
  handleFormSubmit = (values) => {
    const { currentOrg, currentUser } = this.props
    const { id, emr: { type } } = currentOrg

    // for organization level refresh, we will not have currentUser Prop here so set currentUserID to ""
    const currentUserID = (currentUser === undefined) ? '' : currentUser.id

    const {
      fromDate,
      toDate
    } = getTimerange(values.fromDate, values.toDate)

    // Refresh EMR appointments
    client.mutate({
      mutation: RefreshEMRAppointments,
      variables: {
        organizationId: id,
        userID: currentUserID,
        emrType: type,
        fromDate,
        toDate
      }
    }).then(({ data }) => {
      window.Logger.info(
        `Refreshed EMR appointments for ${currentOrg.name} and user ${currentUserID} from ${moment(fromDate).format('MMYYYY-MM-DD HH:mm Z')} to ${moment(toDate).format('MMYYYY-MM-DD HH:mm Z')}.`
      )
      notifySuccess(
        `Refreshed EMR appointments for ${currentOrg.name} and user ${currentUserID} from ${moment(fromDate).format('MMYYYY-MM-DD HH:mm Z')} to ${moment(toDate).format('MMYYYY-MM-DD HH:mm Z')}.`
      )
    }).catch(error => {
      console.error(`Error refreshing appointments for ${currentOrg.name} and user ${currentUserID} from ${moment(fromDate).format('MMYYYY-MM-DD HH:mm Z')} to ${moment(toDate).format('MMYYYY-MM-DD HH:mm Z')}. [Error: ${JSON.stringify(error)}]`)
      window.Logger.error(`Error refreshing appointments for ${currentOrg.name} and user ${currentUserID} from ${moment(fromDate).format('MMYYYY-MM-DD HH:mm Z')} to ${moment(toDate).format('MMYYYY-MM-DD HH:mm Z')}. [Error: ${JSON.stringify(error)}]`)
      setError(error)
    })
  }

  render () {
    return (
      <div style={styles.formsContainer}>
        <div style={{ width: '100%', backgroundColor: whiteSmoke }}>
          <AppointmentsRefreshForm
            onSubmit={values => this.handleFormSubmit(values)}
          />
        </div>
      </div>
    )
  }
}

export default compose(
  reduxForm({
    form: 'AppointmentsRefreshRangePicker' // a unique identifier for this form
  }),
  withRouter
)(AppointmentsRefreshRangePicker)
