import React from 'react'
// import { fulvous } from '../../../styles/colors'
// import FontIcon from 'material-ui/FontIcon'
// import doctorDictating from '../../../images/doc-in-section.svg'
// import scribeSuki from '../../../images/scribe-suki.svg'

export default sections => {
  const styles = {
    container: {
      left: 0,
      margin: 0,
      height: 48,
      width: 48,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }

  // [TODO] LEFT NAV LOGIC FOR SHOWING THESE STATES WILL NEED TO CHANGE
  // [TODO] These statuses are deprecated
  const processedSections = sections && sections.length > 0 && sections.map(s => {
    const newSection = { ...s, statusIcon: null }

    let icon
    // switch (newSection.status) {
    //   case 'ASR':
    //   case 'USER_EDITS':
    //     icon = (
    //       <img
    //         id='doctorDictating'
    //         src={doctorDictating}
    //         alt='Doctor dictating'
    //         style={{ height: 10, width: 10 }}
    //       />
    //     )
    //     break
    //   case 'READY_FOR_PROCESSING':
    //   case 'SCRIBE_EDITS':
    //     icon = (
    //       <img
    //         id='sectionProcessing'
    //         src={scribeSuki}
    //         alt='Section processing'
    //         style={{ height: 30, width: 30 }}
    //       />
    //     )
    //     break
    //   case 'REVIEWED':
    //     icon = (
    //       <FontIcon
    //         id='scribeProcessed'
    //         className='material-icons'
    //         color={fulvous}
    //       >
    //         check
    //       </FontIcon>
    //     )
    //     break
    //   case 'PROCESSED_BY_AI':
    //   case 'CREATED':
    //   default:
    //     break
    // }

    newSection.statusIcon = (
      <div style={styles.container}>
        {icon}
      </div>
    )

    return newSection
  })

  return processedSections
}
