import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _includes from 'lodash/includes'

const filterSelectedUsersFromOrganization = (selectedUserIds, users) =>
  _map(
    _filter(users, (user) => _includes(selectedUserIds, user?.id)),
    (user) => user?.id
  )

export { filterSelectedUsersFromOrganization }
