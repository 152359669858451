import React, { Component } from 'react'
import Frame from './Frame'
import adminBackground from '../images/adminBackground.jpg'
import request from 'superagent'
import { connect } from 'react-redux'
import { BASE_URL } from '../config'
import auth from '../okta'
import { EditorSidebar } from '../components/util'
import Paper from 'material-ui/Paper/Paper'
import { setCommandType } from '../actions/admin'

const styles = {
  main: {
    padding: '40px 40px 0px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  commandsContainer: {
    width: '100%'
  },
  paper: {
    display: 'flex',
    padding: '20px 30px',
    marginBottom: 20,
    height: 400,
    overflow: 'hidden',
    justifyContent: 'space-around',
    flexFlow: 'row'
  }
}

class CommandCentral extends Component {
  state = {
    intents: [],
    selectedCmd: ''
  }

  componentDidMount = () => {
    request.get(`${BASE_URL}/idl`)
      .set('Authorization', auth.getIdToken().idToken)
      .end((err, res) => {
        if (err) {
          console.error('Error downloading IDL config.')
          window.Logger.error('Error downloading IDL config.')
        } else {
          const idl = res.text && JSON.parse(res.text)
          this.setState({
            intents: idl.intents
          })
        }
      })
  }

  renderSlots = () => {
    const intent = this.state.intents.find(intent => intent.type === this.props.cmd)
    const slots = intent && intent.slots
    if (slots) {
      return (
        <div>
          {slots.map((s, i) => <p key={i}>{s}</p>)}
        </div>
      )
    } else {
      return <p>No slots defined for this intent</p>
    }
  }

  renderViews = () => {
    const intent = this.state.intents.find(intent => intent.type === this.props.cmd)
    const viewsAllowed = intent && intent.viewsAllowed
    if (viewsAllowed) {
      return (
        <div>
          {viewsAllowed.map((v, i) => <p key={i}>{v}</p>)}
        </div>
      )
    } else {
      return <p>No ui views defined for this intent</p>
    }
  }

  render () {
    return (
      <Frame
        background={adminBackground}
        mainStyle={styles.main}
      >
        <EditorSidebar
          noAddButton
          items={this.state.intents}
          route={window.location.pathname}
          setCommand={this.props.setCommand}
        />
        <div style={styles.commandsContainer}>
          <Paper style={Object.assign({}, styles.paper)}>
            <div>
              <h4>Selected Command</h4>
              {this.props.cmd || 'No command selected'}
            </div>
            <div>
              <h4>Slots</h4>
              {this.renderSlots()}
            </div>
            <div>
              <h4>UI Views where command works</h4>
              {this.renderViews()}
            </div>
          </Paper>
        </div>
      </Frame>
    )
  }
}

const mapStateToProps = ({ admin }) => ({
  cmd: admin.command
})

const mapDispatchToProps = dispatch => ({
  setCommand: id => dispatch(setCommandType(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(CommandCentral)
