import React from 'react'
import { BlockControls, Arrow } from './styled'
import { Form, CircleButton } from '../../components'

const BlockHeader = ({ field, sections = [], remove, upDisabled, downDisabled, move, ...props }) => {
  const sectionOptions = sections.map(s => (
    <option key={s.id} value={s.id}>
      {s.name}
    </option>
  ))

  return (
    <Form.Section margin='14px' {...props}>
      <Form.Select {...field} options={sectionOptions} font='20px' />
      <BlockControls data-cy='script-block-controls'>
        <CircleButton data-cy='script-delete-block' onClick={remove}>
          –
        </CircleButton>
        <Arrow data-cy='script-move-block-up' disabled={upDisabled} onClick={() => move(-1)}>
          ⬆
        </Arrow>
        <Arrow data-cy='script-move-block-down' disabled={downDisabled} onClick={() => move(1)}>
          ⬇
        </Arrow>
      </BlockControls>
    </Form.Section>
  )
}

export default BlockHeader
