import store from 'store2'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _reduce from 'lodash/reduce'
import _isEmpty from 'lodash/isEmpty'
import _includes from 'lodash/includes'
import { compose, graphql } from 'react-apollo'
import React, { useState, useEffect } from 'react'
import { GetUsers, GetAllOrganizations } from '@sukiai/gql/admin'
import { Skeleton, Table, Input, Button, Select, message } from 'antd'

import {
  filterUsersBySearchText,
  formatUsersDataForTable,
  filterSelectedUsersFromOrganization,
  getRowSelection,
  sortUsersByAccess,
  getColumns,
  createOrganizationOptions
} from './helpers'
import client from '../../../../apollo'
import { LOCAL_STORE } from '../../constants'
import { sortItemsByKey } from '../../../../lib/util'
import { FETCH_POLICY } from '../../../../lib/constants'

const { Search } = Input

function UsersTable ({
  organizations,
  loadingUsers,
  selectedUserIds,
  sukiAdminUserIds = [],
  isEditable,
  onSave,
  isLoading,
  showRole = false,
  disableOrgSelection = false
}) {
  const [tableData, setTableData] = useState([])
  const [unidentifiedUsers, setUnidentifiedUsers] = useState(selectedUserIds)
  const [searchText, setSearchText] = useState('')
  const [isEditView, setIsEditView] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState({})
  const [selectedOrganization, setSelectedOrganization] = useState(
    store.get(LOCAL_STORE.ORGANIZATION_ID)
  )
  const [users, setUsers] = useState([])
  const [usersLoading, setUsersLoading] = useState(true)

  useEffect(() => {
    if (isLoading || _isEmpty(users)) return

    const currentOrganizationSelectedUsers =
      filterSelectedUsersFromOrganization(selectedUserIds, users)

    const unknownUsers = unidentifiedUsers.filter(id => !_includes(currentOrganizationSelectedUsers, id))
    setUnidentifiedUsers(unknownUsers)

    if (!selectedUsers[selectedOrganization]) {
      // check if we already have for this organization selected users to avoid losing selection
      setSelectedUsers({
        ...selectedUsers,
        [selectedOrganization]: currentOrganizationSelectedUsers
      })
    }
  }, [selectedUserIds, isLoading, users])

  useEffect(() => {
    const usersSortedByAccess = sortUsersByAccess(
      users,
      selectedUserIds,
      sukiAdminUserIds
    )
    const filteredUsers = filterUsersBySearchText(
      usersSortedByAccess,
      searchText
    )
    const tableData = formatUsersDataForTable(filteredUsers)
    setTableData(tableData)
  }, [users, searchText, selectedUserIds])

  useEffect(() => {
    if (_isEmpty(selectedOrganization)) {
      return
    }
    setUsersLoading(true)
    client
      .query({
        query: GetUsers,
        variables: { organizationId: selectedOrganization },
        fetchPolicy: FETCH_POLICY.NETWORK_ONLY
      })
      .then((response) => {
        const usersResponse = _get(response, 'data.users.results', [])
        setUsers(usersResponse)
      })
      .catch((error) => {
        message.error('Failed to fetch users')
      })
      .finally(() => {
        setUsersLoading(false)
      })
  }, [selectedOrganization])

  const handleChangeOrganization = (organization) => {
    setSelectedOrganization(organization)
  }

  const handleSelect = (selectedRowKeys) => {
    const selectedUserIds = selectedUsers[selectedOrganization]
    const selectedUserIdsNotRelatedToTable = selectedUserIds.filter(id => !tableData.find(user => user.id === id))
    const selectedUserIdsNotRelatedToTableFromOrganization = filterSelectedUsersFromOrganization(selectedUserIdsNotRelatedToTable, users)

    setSelectedUsers({
      ...selectedUsers,
      [selectedOrganization]: [...selectedUserIdsNotRelatedToTableFromOrganization, ...selectedRowKeys]
    })
  }

  const handleSearch = (searchText) => {
    setSearchText(searchText)
  }

  const handleSearchTextChange = (event) => {
    handleSearch(event.target.value)
  }

  const handleSetEditView = () => {
    setIsEditView(true)
  }

  const handleSave = () => {
    const allSelectedUsers = _reduce(
      selectedUsers,
      (allusers, orgUsers) => {
        return [...allusers, ...orgUsers]
      },
      [...unidentifiedUsers]
    )
    onSave(allSelectedUsers)
    setIsEditView(false)
  }

  const handleCancel = () => {
    setSelectedUsers(selectedUserIds)
    setIsEditView(false)
  }

  const rowSelection = getRowSelection(
    selectedUsers[selectedOrganization],
    isEditView,
    handleSelect,
    sukiAdminUserIds
  )

  const organizationOptions = createOrganizationOptions(organizations)

  if (loadingUsers) {
    return <Skeleton active />
  }

  const renderActionButtons = () => {
    if (!isEditView) {
      return (
        <Button
          type='primary'
          onClick={handleSetEditView}
          className='antd-button-override'
          loading={isLoading}
          disabled={!isEditable}
        >
          Edit
        </Button>
      )
    }
    return (
      <div>
        <Button
          type='primary'
          onClick={handleSave}
          className='antd-button-override antd-button-override-success'
          loading={isLoading}
        >
          Save
        </Button>
        <Button
          type='primary'
          onClick={handleCancel}
          danger
          className='antd-button-override'
          loading={isLoading}
        >
          Cancel
        </Button>
      </div>
    )
  }

  const columns = getColumns(showRole, sukiAdminUserIds, selectedUserIds)
  return (
    <>
      <div className='table-search'>
        {renderActionButtons()}
        <div>
          <Select
            options={organizationOptions}
            style={{ width: 200, marginRight: '24px' }}
            defaultValue={store.get(LOCAL_STORE.ORGANIZATION_ID)}
            value={selectedOrganization}
            onChange={handleChangeOrganization}
            disabled={disableOrgSelection}
          />
          <Search
            placeholder='search user'
            allowClear
            onChange={handleSearchTextChange}
            onSearch={handleSearch}
            style={{
              width: 240
            }}
          />
        </div>
      </div>

      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        loading={usersLoading}
      />
    </>
  )
}

const mapUsersToProps = ({ ownProps, data }) => ({
  ...ownProps,
  errorUsers: data?.error,
  loadingUsers: data?.loading,
  users: data?.users?.results || []
})

const mapOrgsToProps = ({ data }) => ({
  orgsError: data.error,
  orgsLoading: data.loading,
  organizations: sortItemsByKey(
    _get(data, 'organizations.results', []).slice(),
    'name'
  )
})

export default compose(
  graphql(GetAllOrganizations, {
    options: {
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK
    },
    props: mapOrgsToProps
  })
)(UsersTable)
