import React, { Component } from 'react'
import { Animated } from 'react-native'
import {
  Loading,
  LoginContainer,
  LoginError,
  LoginLogo,
  FormContainer,
  Input,
  Password,
  LoginButtonContainer,
  LoginButtonText,
  ResetPasswordButtonContainer,
  ResetPasswordButtonText
} from './styled'

class Login extends Component {
  state = {
    buttonAnim: new Animated.Value(0)
  }

  componentWillMount () {
    window.addEventListener('keydown', this.handleKeyPress)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleKeyPress)
  }

  handleKeyPress = e => {
    const { handleSubmit } = this.props
    e.key === 'Enter' && handleSubmit(e)
  }

  componentWillReceiveProps (nextProps) {
    nextProps.error && Animated.sequence([
      Animated.timing(this.state.buttonAnim, {
        toValue: -1,
        duration: 100
      }),
      Animated.timing(this.state.buttonAnim, {
        toValue: 0.8,
        duration: 100
      }),
      Animated.timing(this.state.buttonAnim, {
        toValue: -0.6,
        duration: 100
      }),
      Animated.timing(this.state.buttonAnim, {
        toValue: 0.4,
        duration: 100
      }),
      Animated.timing(this.state.buttonAnim, {
        toValue: -0.2,
        duration: 100
      }),
      Animated.timing(this.state.buttonAnim, {
        toValue: 0,
        duration: 100
      })
    ]).start()
  }

  render () {
    const {
      handleUsernameChange,
      handlePasswordChange,
      handleSubmit,
      handleForgotPassword,
      loading
    } = this.props

    const vw = window.innerWidth
    const vh = window.innerHeight

    const buttonStyle = {
      transform: [
        {
          translateX: this.state.buttonAnim.interpolate({
            inputRange: [-1, 1],
            outputRange: [-5, 5]
          })
        }
      ]
    }

    const loginText = loading ? <Loading vw={vw} /> : 'Log in'

    return (
      <LoginContainer vw={vw} vh={vh}>
        <LoginLogo vw={vw} />
        <FormContainer vw={vw}>
          <Input
            vw={vw}
            onChange={handleUsernameChange}
            onKeyPress={this.handleKeyPress}
            data-cy='textfield-username'
          />
          <Password
            vw={vw}
            onChange={handlePasswordChange}
            onKeyPress={this.handleKeyPress}
            data-cy='textfield-password'
          />
          {this.props.error && <LoginError>Login failed. Please try again.</LoginError>}
          <LoginButtonContainer
            onClick={handleSubmit}
            disabled={loading}
            data-cy='btn-login'
          >
            <LoginButtonText vw={vw} style={buttonStyle}>
              {loginText}
            </LoginButtonText>
          </LoginButtonContainer>
          <ResetPasswordButtonContainer
            onPress={handleForgotPassword}
            data-cy='btn-login'
          >
            <ResetPasswordButtonText vw={vw}>
            Forgot Password
            </ResetPasswordButtonText>
          </ResetPasswordButtonContainer>
        </FormContainer>
      </LoginContainer>
    )
  }
}

export default Login
