import React from 'react'
import { FontIcon } from 'material-ui'
import styled from 'styled-components'

const StyledInput = styled.div`
  border-radius: 10px;
  border: 0;

  position: relative;
  display: inline-block;
  height: 32px;
  width: 100%;

  .search-icon {
    position: absolute;
    top: 8px;
    left: 8px;
    pointer-events: none;
    height: 15px;
    width: 15px;
  }
`

export const InputElement = styled.input`
  height: 100%;
  width: 100%;
  padding: 0px 7px 0px 28px;
  border-radius: 3px;
  border: 1px solid rgb(223, 225, 230);
  font-weight: normal;
  font-size: 15px;
`

const SearchInput = ({ icon, className, filter, onChange, ...inputProps }) => {
  return (
    <StyledInput>
      <div className='search-icon'>
        <FontIcon className='material-icons' style={{ fontSize: 18 }}>
          search
        </FontIcon>
      </div>
      <InputElement {...inputProps} onChange={onChange} hasIcon={!!icon} />
    </StyledInput>
  )
}

export default SearchInput
