import React, { Component } from 'react'
import { Animated } from 'react-native'
import {
  Wrapper,
  Container,
  Icon
} from './styled'

class Alert extends Component {
  state = {
    alertAnim: new Animated.Value(0)
  }

  componentWillMount () {
    this.props.muteAgent()
  }

  componentDidMount () {
    Animated.timing(this.state.alertAnim, {
      toValue: 1,
      duration: 200
    }).start()
  }

  // Don't let users dismiss the alert for now
  // dismissAlert = () => {
  //   Animated.timing(this.state.alertAnim, {
  //     toValue: 0,
  //     duration: 200
  //   }).start(() => this.props.clearAlert())
  // }

  render () {
    const {
      message,
      size,
      dataCy
    } = this.props

    const styles = {
      container: {
        transform: [
          { translateY: '-50%' },
          { translateX: '50%' },
          {
            scale: this.state.alertAnim.interpolate({
              inputRange: [0, 1],
              outputRange: [0.85, 1]
            })
          }
        ],
        opacity: this.state.alertAnim
      }
    }

    return (
      <Wrapper>
        <Container style={styles.container} size={size} data-cy={dataCy}>
          <Icon size={size} title={message} />
        </Container>
      </Wrapper>
    )
  }
}

export default Alert
