import React from 'react'
import { Query, Mutation } from 'react-apollo'
import get from 'lodash.get'
import { GetUsers, CopyScript, GetScriptsByUserId } from '@sukiai/gql/admin'
import { getFullName } from '@sukiai/utils'
import { ModalVeil, ModalContainer, ModalHeading, ModalBody, ModalSelect, ModalButton } from './styled'
import { COPY_BUTTON } from './constants'
import { notifySuccess, setError, sortScriptsByName, sortScriptsBySource } from '../../lib/util'

export default class CopyToUser extends React.Component {
  state = {
    copyToId: null
  }

  setCopyToId = copyToId => {
    this.setState({ copyToId })
  }

  handleClose = () => {
    const { closeDialog } = this.props
    closeDialog()
    this.setState({ copyToId: null })
  }

  onCopy = async (cache, { data: { copyMacro } }) => {
    const { copyToId } = this.state
    const { organizationId } = this.props
    const copy = get(copyMacro, 'macro')

    try {
      // Get all current scripts from the cache for copied to user
      const { macros: allScriptsRes } = cache.readQuery({
        query: GetScriptsByUserId,
        variables: { organizationId, userId: copyToId }
      })

      // Add newly copied script to list of scripts
      const allScripts = get(allScriptsRes, 'results', [])
      allScripts.push(copy)

      // Re-sort scripts by name
      sortScriptsByName(allScripts)
      sortScriptsBySource(allScripts)

      // Update the cache with the new list of scripts
      await cache.writeQuery({
        query: GetScriptsByUserId,
        variables: { organizationId, userId: copyToId },
        data: {
          macros: {
            __typename: 'QueryMacrosPayload',
            count: get(allScripts, 'length', 0),
            results: allScripts
          }
        }
      })
    } catch (err) {
      // Scripts for copied to user are not in cache yet
      console.info(`Cannot update cache with copied script for user ${copyToId}`)
    }

    this.handleClose()
  }

  render () {
    const { copyToId } = this.state
    const { open, organizationId, userId, scriptId } = this.props

    const usersVariables = { organizationId }
    const copyVariables = { organizationId, id: scriptId, userId: copyToId }
    const buttonDisabled = !organizationId || !copyToId || !scriptId

    return (
      open && (
        <ModalVeil onClick={this.handleClose}>
          <ModalContainer data-cy='scripts-copy-to-user-container' onClick={e => e.stopPropagation()}>
            <ModalHeading>Copy script to user</ModalHeading>
            <ModalBody>
              <Query query={GetUsers} variables={usersVariables} skip={!organizationId}>
                {({ loading: usersLoading, data: usersData }) => {
                  const users = get(usersData, 'users.results', [])

                  return (
                    <ModalSelect
                      data-cy='scripts-copy-to-user-select'
                      value={copyToId}
                      onChange={e => this.setCopyToId(e.target.value)}
                    >
                      <option value=''>Select user to copy to</option>
                      {usersLoading && <option disabled>Loading users...</option>}
                      {users
                        .filter(u => u.id !== userId)
                        .map(u => (
                          <option key={u.id} value={u.id}>
                            {getFullName(u.person)}
                          </option>
                        ))}
                    </ModalSelect>
                  )
                }}
              </Query>
              <Mutation
                mutation={CopyScript}
                variables={copyVariables}
                onError={setError}
                onCompleted={() => notifySuccess('Copied script')}
                update={this.onCopy}
              >
                {(copyToUser, { loading }) => (
                  <ModalButton
                    data-cy='scripts-copy-to-user-button'
                    disabled={buttonDisabled || loading}
                    onClick={copyToUser}
                  >
                    {loading ? COPY_BUTTON.LOADING : COPY_BUTTON.DEFAULT}
                  </ModalButton>
                )}
              </Mutation>
            </ModalBody>
          </ModalContainer>
        </ModalVeil>
      )
    )
  }
}
