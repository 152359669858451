import _includes from 'lodash/includes'

import { COLUMNS } from '../constants'

const getUserRole = (user, sukiAdminUserIds = [], selectedUserIds = []) => {
  if (_includes(sukiAdminUserIds, user?.id)) {
    return 'Admin'
  }
  if (_includes(selectedUserIds, user?.id)) {
    return 'Read/Write'
  }
  return 'Read'
}

const getColumns = (
  showRole = false,
  sukiAdminUserIds = [],
  selectedUserIds = []
) => {
  if (!showRole) {
    return COLUMNS
  }
  return [
    ...COLUMNS,
    {
      title: 'Role',
      render: (_, user) => getUserRole(user, sukiAdminUserIds, selectedUserIds),
      sorter: (a, b) => {
        const roleA = getUserRole(a, sukiAdminUserIds, selectedUserIds)
        const roleB = getUserRole(b, sukiAdminUserIds, selectedUserIds)
        return roleA > roleB ? 1 : -1
      }
    }
  ]
}

export { getColumns }
