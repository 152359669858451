import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Paper from 'material-ui/Paper'
import { LinkText, EditButton, InputItem, FormControls, Button } from './styled'
import editPencil from '../../images/edit-pencil.svg'
import { borderGrey } from '../../styles/colors'

const styles = {
  headerFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center'
  },

  formLayout: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    margin: '15px 0'
  },

  formLabel: {
    margin: '10px 0'
  },

  sep: {
    margin: '0 5px',
    fontWeight: 'normal',
    textShadow: 'none',
    color: borderGrey
  }
}

export default class UserNvoq extends Component {
  state = {
    isEditMode: false,
    wakewordPreference: 'Suki',
    ...this.props
  }

  getInitialValues () {
    const { nvoqUserId, nvoqOrgId, nvoqUsername, nvoqTopic } = this.props
    return {
      nvoqUserId: nvoqUserId || '',
      nvoqOrgId: nvoqOrgId || '',
      nvoqUsername: nvoqUsername || '',
      nvoqTopic: nvoqTopic || ''
    }
  }

  handleOnEditButton = e => {
    this.setState({ isEditMode: true })
  }

  handleCancel = e => {
    e.preventDefault()
    const resetValues = this.getInitialValues()
    this.setState({ ...resetValues, isEditMode: false })
  }

  handleSave = e => {
    e.preventDefault()
    this.props.updateNvoqUserAccount(
      {
        nvoqUserId: this.state.nvoqUserId,
        nvoqOrgId: this.state.nvoqOrgId,
        nvoqUsername: this.state.nvoqUsername,
        nvoqTopic: this.state.nvoqTopic,
        apiKey: this.state.apiKey
      },
      this.state.wakewordPreference
    )
  }

  handleDelete = e => {
    e.preventDefault()
    if (window.confirm('Are you sure you want to delete this nVoq user?')) {
      this.props.deleteNvoqUserAccount()
    }
  }

  handleChange = e => {
    const { name, type, value } = e.target
    const val = type === 'number' ? parseFloat(value) : value
    this.setState({ [name]: val })
  }

  render () {
    const {
      isEditMode,
      nvoqTopic,
      nvoqOrgId,
      nvoqUsername,
      nvoqUserId,
      wakewordPreference
    } = this.state
    const { fullName, nvoqTopics, organizationId, sukiUserId } = this.props
    const link = `/${organizationId}/users?=${sukiUserId}`

    return (
      <div className='container' style={{ margin: '15px' }}>
        <Paper elevation={3}>
          <div className='form-container' style={{ padding: '15px' }}>
            <form
              noValidate
              autoComplete='off'
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <header style={styles.headerFlex}>
                <h2>
                  <Link to={link} style={{ textDecoration: 'none' }}>
                    <LinkText>{fullName}</LinkText>
                  </Link>
                  <span style={styles.sep}>/</span>
                  nVoq Settings
                </h2>
                {!isEditMode && (
                  <EditButton onClick={this.handleOnEditButton}>
                    <img
                      alt='edit'
                      src={editPencil}
                      height='35%'
                      width='35%'
                    />
                    <div>Edit</div>
                  </EditButton>
                )}
              </header>
              <h4 style={styles.formLabel}>nVoq User ID</h4>
              {nvoqUserId}
              <h4 style={styles.formLabel}>nVoq Organization ID</h4>
              {nvoqOrgId}
              <h4 style={styles.formLabel}>nVoq Username</h4>
              {nvoqUsername}
              <div style={styles.formLayout}>
                <InputItem htmlFor='nvoqTopic'>
                  nVoq Topic
                  <select
                    name='nvoqTopic'
                    value={nvoqTopic}
                    onChange={this.handleChange}
                    disabled={!isEditMode}
                  >
                    {nvoqTopics &&
                      nvoqTopics.topics.map(name => (
                        <option name={name} key={name} value={name}>
                          {name}
                        </option>
                      ))}
                  </select>
                </InputItem>
                <InputItem htmlFor='wakewordPreference'>
                  nVoq Wake Word Preference
                  <select
                    name='wakewordPreference'
                    value={wakewordPreference}
                    onChange={this.handleChange}
                    disabled={!isEditMode}
                  >
                    {/* TODO: Update once we get more information  */}
                    <option name='Suki' key='Suki' value='Suki'>
                      Suki
                    </option>
                  </select>
                </InputItem>
              </div>
              <FormControls>
                <div>
                  {isEditMode && (
                    <Button
                      type='primary'
                      disabled={this.props.isProcessing}
                      onClick={this.handleSave}
                    >
                      Save Changes
                    </Button>
                  )}
                  {isEditMode && (
                    <Button
                      disabled={this.props.isProcessing}
                      onClick={this.handleCancel}
                    >
                      Cancel
                    </Button>
                  )}
                </div>
                <div />
                <Button
                  disabled={this.props.isProcessing}
                  type='danger'
                  onClick={this.handleDelete}
                >
                  Delete User nVoq
                </Button>
              </FormControls>
            </form>
          </div>
        </Paper>
      </div>
    )
  }
}
