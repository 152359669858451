import React from 'react'
import {
  FormContainer,
  Item,
  InputItem,
  Input,
  Select,
  Button,
  JSONField,
  JSONLabel,
  JSONContainer
} from './styled'
import { Formik, Form, Field } from 'formik'
import Loading from '../Loading'
import get from 'lodash.get'
import map from 'lodash.map'
import { SAVE_PREFERENCE } from './constants'
import { capitalize } from '../../lib/util'

const styles = {
  form: {
    display: 'flex',
    alignItems: 'center'
  }
}

const renderEnumPref = (pref, field) => (
  <Select {...field}>
    {get(pref, 'possibleValues', []).map(v => (
      <option key={v} value={v}>
        {capitalize(v.split(',')[0].trim())}
      </option>
    ))}
  </Select>
)

const renderStringPref = (field, setFieldValue) => {
  try {
    const parsedValues = JSON.parse(field.value)
    if (parsedValues && typeof parsedValues === 'object') {
      const updateJSON = e => {
        const newObj = { ...parsedValues, [e.target.name]: e.target.value }
        setFieldValue(field.name, JSON.stringify(newObj))
      }

      return (
        <JSONContainer>
          {map(parsedValues, (value, name) => (
            <JSONField key={name}>
              <JSONLabel>
                {capitalize(name)}:
                &nbsp;&nbsp;
              </JSONLabel>
              <Input name={name} value={value} onChange={updateJSON} />
            </JSONField>
          ))}
        </JSONContainer>
      )
    }
  } catch (e) {
    return <Input {...field} placeholder='Value' />
  }
}

const Preference = ({ updatePreference, last, ...initialValues }) => {
  const { id, enumPreference: enumPref } = initialValues

  return (
    <FormContainer last={last}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => updatePreference(values, actions)}
      >
        {({ isSubmitting, setFieldValue, values }) => {
          const isChangedEnumField = values?.enumPreference?.value !== initialValues?.enumPreference?.value
          const isChangedStringField = initialValues?.stringPreference?.value !== values?.stringPreference?.value
          const isDisabledSaveButton = isSubmitting || (!enumPref && !isChangedStringField) || (enumPref && !isChangedEnumField)

          return (
            <Form id={`pref-${id}`} style={styles.form}>
              <Item>
              ID:&nbsp;
                {initialValues.id}
              </Item>
              <Item>
              Name:&nbsp;
                {initialValues.name}
              </Item>
              <InputItem>
                <Field name={enumPref ? 'enumPreference.value' : 'stringPreference.value'}>
                  {({ field }) => enumPref
                    ? renderEnumPref(enumPref, field)
                    : renderStringPref(field, setFieldValue)}
                </Field>
              </InputItem>
              <Button disabled={isDisabledSaveButton}>
                {isSubmitting ? <Loading size={24} thickness={3} /> : SAVE_PREFERENCE}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </FormContainer>
  )
}

export default Preference
