import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { List, ListItem } from 'material-ui/List'
import { GetNoteTypes, GetOrganizationById, GetUserById } from '@sukiai/gql/admin'
import { charcoal, whiteSmoke } from '../../styles/colors'
import { ROUTES } from '../../lib/constants'

export const styles = {
  list: {
    backgroundColor: whiteSmoke,
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'auto',
    boxShadow: '2px 2px 8px 0px rgba(0,0,0,0.5)',
    marginBottom: 20
  },
  inner: {
    padding: '20px 30px',
    fontSize: 20,
    color: charcoal
  }
}

class UserLinks extends Component {
  handleToAppts = () => {
    const { history, orgId, userId } = this.props
    history.push(`/${orgId}/${ROUTES.APPTS}?userId=${userId}`)
  }

  handleToScripts = () => {
    const { history, orgId, userId } = this.props
    history.push(`/${orgId}/${userId}/${ROUTES.SCRIPTS}`)
  }

  handleToPreferences = () => {
    const { history, orgId, userId } = this.props
    history.push(`${ROUTES.PREFERENCES}/${orgId}/${userId}`)
  }

  handleToSpecialityMapping = () => {
    const { history, orgId, userId } = this.props
    history.push(`${ROUTES.SPECIALITIES}/${orgId}/${userId}`)
  }

  handleToNoteTypes = () => {
    const { history, orgId, userId } = this.props
    history.push(`/${ROUTES.NOTE_TYPES}/${orgId}/${userId}`)
  }

  handleToNvoq = () => {
    const { history, orgId, userId } = this.props
    history.push(`/${orgId}/${userId}/${ROUTES.NVOQ}`)
  }

  handlePreloadNoteTypes = () => {
    const { client, orgId: organizationId, userId } = this.props

    client.query({
      query: GetNoteTypes,
      variables: { organizationId, userId }
    })

    client.query({
      query: GetOrganizationById,
      variables: { id: organizationId, withEmrInfo: true }
    })

    client.query({
      query: GetUserById,
      variables: { organizationId, id: userId }
    })
  }

  render () {
    return (
      <List style={styles.list}>
        <ListItem id='schedule-menu-button' innerDivStyle={styles.inner} onClick={this.handleToAppts}>
          Schedule
        </ListItem>
        <ListItem id='scripts-menu-button' innerDivStyle={styles.inner} onClick={this.handleToScripts}>
          Scripts
        </ListItem>
        <ListItem id='preferences-menu-button' innerDivStyle={styles.inner} onClick={this.handleToPreferences}>
          Preferences
        </ListItem>
        <ListItem id='speciality-mapping-menu-button' innerDivStyle={styles.inner} onClick={this.handleToSpecialityMapping}>
          Speciality Mapping
        </ListItem>
        <ListItem
          id='notetypes-menu-button'
          innerDivStyle={styles.inner}
          onClick={this.handleToNoteTypes}
          onMouseEnter={this.handlePreloadNoteTypes}
        >
          Note Types
        </ListItem>
        <ListItem
          id='notetypes-menu-button'
          innerDivStyle={styles.inner}
          onClick={this.handleToNvoq}
        >
          nVoq Settings
        </ListItem>
      </List>
    )
  }
}

export default withApollo(withRouter(UserLinks))
