import React, { Component } from 'react'
import get from 'lodash/get'
import moment from 'moment'
import {
  DarkHeaderContainer,
  HeaderContainer,
  Top,
  TemplateName,
  TemplateNameDark,
  Bottom,
  Left,
  LeftWithPadding,
  RightWithPadding,
  Right,
  PatientName,
  AgeGender,
  Container,
  Meta,
  LoadingContainer,
  LoadingMeta,
  Label,
  GenderLoadingContainer,
  NoteLockStatus,
  NoteLockStatusName,
  ProcessingIcon
} from './styled'
import {
  getFullName,
  getAge,
  getGender,
  displayAge,
  isAmwellTheme
} from '../../lib/util'
import {
  NOT_AVAILABLE,
  DATE_OF_BIRTH,
  DATE_OF_SERVICE,
  MRN,
  LOADING,
  DATE_PICKER,
  APPOINT_PICKER,
  GENDER_PICKER,
  DATE_DEFAULT_VAL,
  MRN_INPUT,
  SELECT_GENDER
} from './constants'
import { NOTE_STATUS } from '../../lib/constants'
import Loading from '../Loading'
import processingClock from '../../assets/icons/processing-clock.svg'

const STANDARD_DATE_FORMAT = 'MM/DD/YYYY'

class NoteHeader extends Component {
  setPickerInEditableState = (field) => {
    if (this.props.setEditableState) {
      this.props.setEditableState(field, true)
    }
  }

  isNoteSubmittedOrImportedFromEMR = () => {
    const { status } = this.props
    return (status === NOTE_STATUS.SUBMITTED_TO_EMR || status === NOTE_STATUS.IMPORTED_FROM_EMR)
  }

  // Depending on following conditions render the required info
  // Case 1: If emr related or submitted to/imported from emr then just display the DOB. No option to edit it is available
  // Remaning cases are for a non-emr situation
  // Case 2: If no patient name yet, then just display N/A. No option to edit it yet
  // Case 3: If the date is available when loading the note. Display as is. With an option when clicked on will go
  // give the datePicker
  // Case 4: When the date is clicked on show the date picker
  // Case 5: When the date is selected from the picker and a backend call is made to set the dob, in the mean
  // time show the loader in place of the date
  renderDateOfBirthSection = (patientName, dob, isAmwell = false) => {
    const {
      DobPicker,
      editableStatesForPickers,
      isSukiOnly
    } = this.props

    const dateOfBirth = moment(dob).format('YYYY-MM-DD')
    if (!isSukiOnly || isAmwell) {
      return (
        <Meta data-cy='patient-dob'>
          {DATE_OF_BIRTH}: {dob || NOT_AVAILABLE}
        </Meta>
      )
    } else if (!patientName.length || get(editableStatesForPickers, DATE_PICKER)) {
      if (!patientName.length) {
        return (
          <Meta data-cy='patient-dob'>
            {DATE_OF_BIRTH}: {dob || NOT_AVAILABLE}
          </Meta>
        )
      }
      if (get(editableStatesForPickers, DATE_PICKER) === LOADING) {
        return (
          <LoadingMeta data-cy='patient-dob'>
            <Label>{DATE_OF_BIRTH} : </Label>
            <LoadingContainer> <Loading size='small' /></LoadingContainer>
          </LoadingMeta>
        )
      }
      return (<Meta data-cy='patient-dob'>{DATE_OF_BIRTH} : {DobPicker(dateOfBirth)}</Meta>)
    } else {
      return (
        <Meta data-cy='patient-dob' onClick={() => this.setPickerInEditableState(DATE_PICKER)}>
          {DATE_OF_BIRTH}: {dob || DATE_DEFAULT_VAL}
        </Meta>)
    }
  }

  // Depending on following conditions render the required info
  // Case 1: If emr related or submitted to/imported from emr then just display the DOs. No option to edit it is available
  // Remaning cases are for a non-emr situation
  // Case 2: If no patient name yet, then just display N/A. No option to edit it yet
  // Case 3: If the date is available when loading the note. Display as is. With an option when clicked on will go
  // give the datePicker
  // Case 4: When the date is clicked on show the appointment date picker
  // Case 5: When the date is selected from the picker and a backend call is made to set the dob, in the mean
  // time show the loader in place of the date
  renderDateOfService = (patientName, dos, isAmwell = false) => {
    const {
      SchedulePicker,
      editableStatesForPickers,
      isDosEditable
    } = this.props

    let dateOfService = moment(dos).format('YYYY-MM-DD')

    if (!dos) {
      dateOfService = moment().format('YYYY-MM-DD')
    }
    if (!isDosEditable || this.isNoteSubmittedOrImportedFromEMR() || isAmwell) {
      const dosString = dos || moment().format(STANDARD_DATE_FORMAT)
      return (
        <Meta data-cy='patient-dos'>
          {DATE_OF_SERVICE}: {dosString}
        </Meta>
      )
    } else if (!dos || get(editableStatesForPickers, APPOINT_PICKER)) {
      if (!patientName.length) {
        return (<Meta data-cy='patient-dos'>{DATE_OF_SERVICE}: {NOT_AVAILABLE}</Meta>)
      } else {
        if (get(editableStatesForPickers, APPOINT_PICKER)) {
          if (editableStatesForPickers[APPOINT_PICKER] === LOADING) {
            return (
              <LoadingMeta data-cy='patient-dob'>
                <Label>{DATE_OF_SERVICE} : </Label>
                <LoadingContainer> <Loading size='small' /></LoadingContainer>
              </LoadingMeta>)
          }
          return (<Meta data-cy='patient-dos'>{DATE_OF_SERVICE}: {SchedulePicker(dateOfService)}</Meta>)
        } else {
          return (<Meta data-cy='patient-dos'>{DATE_OF_SERVICE}: {NOT_AVAILABLE}</Meta>)
        }
      }
    } else {
      return (
        <Meta data-cy='patient-dos' onClick={() => this.setPickerInEditableState(APPOINT_PICKER)}>
          {DATE_OF_SERVICE}: {dos}
        </Meta>
      )
    }
  }

  // Depending on following conditions render the required info
  // Case 1: If emr related or submitted to/imported from emr then just display the DOs. No option to edit it is available
  // Remaning cases are for a non-emr situation
  // Case 2: If the gender is available when loading the note. Display as is. With an option when clicked on will go
  // give the genderPicker
  // Case 3: When the gender is clicked on show the gender picker
  // Case 4: When the gender is selected from the picker and a backend call is made to set the dob, in the mean
  // time show the loader in place of the date
  renderGender = (gender, isAmwell = false) => {
    const {
      GenderPicker,
      editableStatesForPickers,
      isSukiOnly
    } = this.props

    if (!isSukiOnly || isAmwell) {
      return (<div data-cy='patient-gender'>{gender}</div>)
    } else if (get(editableStatesForPickers, GENDER_PICKER)) {
      if (editableStatesForPickers[GENDER_PICKER] === LOADING) {
        return (
          <GenderLoadingContainer data-cy='patient-gender'>
            <Loading size='small' />
          </GenderLoadingContainer>
        )
      }
      return (
        <div data-cy='patient-gender'>
          {GenderPicker(gender)}
        </div>
      )
    }
    return (
      <div
        data-cy='patient-gender'
        onClick={() => this.setPickerInEditableState(GENDER_PICKER)}
      >
        {gender || SELECT_GENDER}
      </div>
    )
  }

  // Depending on following conditions render the required info
  // Case 1: If emr related or submitted to/imported from emr then just display the DOs. No option to edit it is available
  // Remaning cases are for a non-emr situation
  // Case 2: If no patient name yet, then just display N/A. No option to edit it yet
  // Case 3: If the mrn is available when loading the note. Display as is. With an option when clicked on will go
  // give the mrnInput
  // Case 4: When the mrn is clicked on show the mrn input field
  // Case 5: When the mrn is selected from the picker and a backend call is made to set the mrn, in the mean
  // time show the loader in place of the date
  renderMRN = (patientName, mrnVal, isAmwell = false) => {
    const {
      MrnInput,
      editableStatesForPickers,
      isSukiOnly
    } = this.props

    if (!isSukiOnly || isAmwell) {
      return (
        <Meta data-cy='patient-mrn'>
          {MRN}: {mrnVal || NOT_AVAILABLE}
        </Meta>
      )
    } else if (!patientName.length || get(editableStatesForPickers, MRN_INPUT)) {
      if (!patientName.length) {
        return (
          <Meta data-cy='patient-mrn'>
            {MRN}: {mrnVal || NOT_AVAILABLE}
          </Meta>
        )
      }
      if (get(editableStatesForPickers, MRN_INPUT) === LOADING) {
        return (
          <LoadingMeta data-cy='patient-mrn'>
            <Label>{MRN} : </Label>
            <LoadingContainer> <Loading size='small' /></LoadingContainer>
          </LoadingMeta>
        )
      }
      return (<Meta data-cy='patient-mrn'>{MRN} : {MrnInput(mrnVal)}</Meta>)
    } else {
      return (
        <Meta data-cy='patient-mrn' onClick={() => this.setPickerInEditableState(MRN_INPUT)}>
          {MRN}: {mrnVal || NOT_AVAILABLE}
        </Meta>)
    }
  }

  renderNoteLockStatus = () => {
    const { isProcessing } = this.props
    if (isProcessing) {
      return (
        <NoteLockStatus>
          <NoteLockStatusName data-cy='note-lock-status'>
            Being processed
          </NoteLockStatusName>
          <ProcessingIcon
            width='24px'
            height='24px'
            source={processingClock}
          />
        </NoteLockStatus>
      )
    }

    return null
  }

  render () {
    const {
      name,
      patient,
      appointment,
      SearchComponent
    } = this.props
    const isAmwellDark = isAmwellTheme()
    const patientPerson = get(patient, 'person')
    const isDefaultTime = patientPerson && new Date(patientPerson.dateOfBirth).getTime() === 0
    if (isDefaultTime) {
      patientPerson.dateOfBirth = null
    }
    const patientName = getFullName(patientPerson)
    const age = getAge(patientPerson)
    const gender = getGender(patientPerson)
    const mrn = get(patient, 'mrn')
    const dob = get(patientPerson, 'dateOfBirth') && moment(patientPerson.dateOfBirth).utc().format(STANDARD_DATE_FORMAT)
    const dos = appointment && moment(appointment.startsAt).format(STANDARD_DATE_FORMAT)

    // getFullName() returns a string with one blank space if the person object
    // passed in has empty string values for firstName,middleName and lastName
    // So, to coverup for that case when the string is actually empty trimming it
    // and then checking the length
    let trimmedPatientName = patientName.trim()
    if (!trimmedPatientName.length) {
      trimmedPatientName = ''
    } else {
      trimmedPatientName = patientName
    }

    if (isAmwellDark) {
      return (
        <DarkHeaderContainer>
          {name
            ? (
              <Top>
                <TemplateNameDark data-cy='note-type-name'>
                  {name}
                </TemplateNameDark>
              </Top>
            )
            : null}
          {this.renderNoteLockStatus()}
          <Bottom isAmwell>
            <LeftWithPadding>
              <PatientName data-cy='patient-name'>
                {SearchComponent ? SearchComponent(trimmedPatientName) : trimmedPatientName}
              </PatientName>

              <AgeGender ispatientnameunknown={(trimmedPatientName.length) ? 'true' : 'false'} isAmwell>
                <Container>
                  {displayAge(age)}
                </Container>
                {age && (
                  <Container>
                    {'  •  '}
                  </Container>
                )}
                <Container>
                  {this.renderGender(gender, isAmwellDark)}
                </Container>
              </AgeGender>
            </LeftWithPadding>
            <RightWithPadding>
              {this.renderMRN(trimmedPatientName, mrn, isAmwellDark)}
              {this.renderDateOfBirthSection(trimmedPatientName, dob, isAmwellDark)}
              {this.renderDateOfService(trimmedPatientName, dos, isAmwellDark)}
            </RightWithPadding>
          </Bottom>
        </DarkHeaderContainer>
      )
    }

    return (
      <HeaderContainer>
        {name
          ? (
            <Top>
              <TemplateName data-cy='note-type-name'>
                {name}
              </TemplateName>
            </Top>
          )
          : null}
        {this.renderNoteLockStatus()}
        <Bottom>
          <Left>
            <PatientName data-cy='patient-name'>
              {SearchComponent ? SearchComponent(trimmedPatientName) : trimmedPatientName}
            </PatientName>

            <AgeGender ispatientnameunknown={(trimmedPatientName.length) ? 'true' : 'false'}>
              <Container>
                {displayAge(age)}
              </Container>
              {age && (
                <Container>
                  {'  •  '}
                </Container>
              )}
              <Container>
                {this.renderGender(gender)}
              </Container>
            </AgeGender>
          </Left>
          <Right>
            {this.renderMRN(trimmedPatientName, mrn)}
            {this.renderDateOfBirthSection(trimmedPatientName, dob)}
            {this.renderDateOfService(trimmedPatientName, dos)}
          </Right>
        </Bottom>
      </HeaderContainer>
    )
  }
}

export default NoteHeader
