import React from 'react'
import { withRouter } from 'react-router-dom'
import { Query, Mutation } from 'react-apollo'
import get from 'lodash.get'
import {
  GetNoteTypes,
  CreateNoteType,
  GetOrganizationById,
  GetNoteTypeById,
  GetUserById,
  GetSections
} from '@sukiai/gql/admin'
import { getFullName, snakeCapsToEnglish } from '@sukiai/utils'
import TopNavBar from '../TopNavBar'
import { Sidebar } from '../../components'
import NoteTypeEditor from './NoteTypeEditor'
import { EditorContainer, Empty } from './styled'
import { EMR_DESTINATION, QUERY_PARAMS, ORG_IDS, SPECIALTY_TYPE, FETCH_POLICY } from '../../lib/constants'
import { sortItemsByKey, notifySuccess, setError } from '../../lib/util'
import queryString from 'query-string'
import Loading from '../../components/Loading'
import { NO_NOTE_TYPES } from './constants'
import client from '../../apollo'

const SPECIALTIES_ARR = Object.keys(SPECIALTY_TYPE).map(s => ({
  name: snakeCapsToEnglish(s),
  value: s
}))

class NoteTypes extends React.Component {
  state = {
    selectedId: null,
    filteredSpecialty: SPECIALTY_TYPE.NA // default filter (only applies to onboarding)
  }

  componentDidMount () {
    const params = queryString.parse(this.props.location.search)
    const ntId = params[QUERY_PARAMS.NOTETYPE_ID]

    ntId && this.setState({ selectedId: ntId })
  }

  componentDidUpdate (prevProps, prevState) {
    const selectedId = this.state.selectedId
    const prevSelectedId = prevState.selectedId

    if (selectedId && selectedId !== prevSelectedId) {
      this.props.history.replace({ search: `?${[QUERY_PARAMS.NOTETYPE_ID]}=${selectedId}` })

      document.getElementById(selectedId) &&
        document
          .getElementById(selectedId)
          .scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  resetSpecialtyFilter = () => {
    // if onboarding default, reset back to default (N/A)
    const userId = get(this.props, 'match.params.userId')
    const organizationId = get(this.props, 'match.params.orgId')
    const isOnboardingDefault = organizationId === ORG_IDS.SUKI_ONBOARDING_DEFAULTS && !userId

    if (isOnboardingDefault) {
      this.setState({ filteredSpecialty: SPECIALTY_TYPE.NA })
    }
  }

  setSelected = id => {
    this.setState({ selectedId: id })
  }

  setFilteredSpecialty = e => {
    const organizationId = get(this.props.match, 'params.orgId')
    const userId = get(this.props.match, 'params.userId')
    const specialty = e.target.value

    // need to select first from new filter list by default
    client.query({
      query: GetNoteTypes,
      variables: {
        organizationId,
        userId,
        specialties: [specialty]
      }
    }).then(({ data }) => {
      const selectedId = get(data, 'noteTypes.results[0].id')
      this.setState({ selectedId, filteredSpecialty: specialty })
    })
  }

  addToCache = async (cache, { data: { createNoteType } }) => {
    const { filteredSpecialty } = this.state
    const { match } = this.props
    const organizationId = get(match, 'params.orgId')
    const userId = get(match, 'params.userId')
    const isOnboardingDefault = organizationId === ORG_IDS.SUKI_ONBOARDING_DEFAULTS

    const newNoteType = get(createNoteType, 'noteType')

    // if onboarding default then we need to
    // update the query variables
    const queryVariables = { organizationId, userId }
    if (isOnboardingDefault) {
      queryVariables.specialties = [filteredSpecialty]
    }

    const { noteTypes: allNoteTypesRes } = cache.readQuery({
      query: GetNoteTypes,
      variables: queryVariables
    })

    // Add the new note type to the list of note types
    const allNoteTypes = [...get(allNoteTypesRes, 'results', []), newNoteType]

    // Re-sort the note types by name
    sortItemsByKey(allNoteTypes, 'name')

    // Update the cache with the new list of note types
    await cache.writeQuery({
      query: GetNoteTypes,
      variables: queryVariables,
      data: {
        noteTypes: {
          __typename: 'QueryNoteTypesPayload',
          count: get(allNoteTypes, 'length', 0),
          results: allNoteTypes
        }
      }
    })

    await cache.writeQuery({
      query: GetNoteTypeById,
      variables: { organizationId, id: newNoteType.id },
      data: {
        noteTypes: {
          __typename: 'QueryNoteTypesPayload',
          count: 1,
          results: [newNoteType]
        }
      }
    })

    this.setSelected(newNoteType.id)
    // if onbording default org, reset filter to ALL
    this.resetSpecialtyFilter()
  }

  mapSidebarItem = ({ id, name, source }) => ({ id, name, source })

  render () {
    const { filteredSpecialty } = this.state
    const { match } = this.props
    const organizationId = get(match, 'params.orgId')
    const userId = get(match, 'params.userId')

    const isOnboardingDefault = organizationId === ORG_IDS.SUKI_ONBOARDING_DEFAULTS && !userId
    const isFilteringBySpecialty = isOnboardingDefault && !!filteredSpecialty

    const noteTypeVariables = { organizationId, userId }
    if (isFilteringBySpecialty) {
      noteTypeVariables.specialties = [filteredSpecialty]
    }

    const orgVariables = { id: organizationId, withEmrInfo: true }

    const userVariables = { organizationId, id: userId }

    const sectionsVariables = { organizationId, withMapping: true }

    return (
      <>
        <TopNavBar />
        <Query query={GetNoteTypes} variables={noteTypeVariables} fetchPolicy={FETCH_POLICY.CACHE_AND_NETWORK}>
          {({ loading: noteTypesLoading, data: noteTypesData }) => (
            <Query query={GetOrganizationById} variables={orgVariables} skip={!organizationId}>
              {({ loading: orgLoading, data: orgData }) => (
                <Query query={GetUserById} variables={userVariables} skip={!userId}>
                  {({ loading: userLoading, data: userData }) => (
                    <Query query={GetSections} variables={sectionsVariables} skip={!organizationId}>
                      {({ loading: sectionsLoading, data: sectionsData }) => {
                        const org = get(orgData, 'organizations.results[0]')
                        const firstDestination = get(org, 'emr.capabilities.destinations[0]', EMR_DESTINATION.SUKI_ONLY)

                        const sections = get(sectionsData, 'sections.results', [])
                        let firstSection = []

                        if (sectionsData && !sectionsLoading) {
                          if (sections.length === 0) {
                            setError('This org has no sections! Need at least 1 section to create a notetype')
                          } else {
                            const s = sections[0]
                            firstSection = [
                              {
                                id: s.id,
                                name: s.name,
                                navigationKeywords: s.hints,
                                pbcSectionFlag: s.pbcSectionFlag
                              }
                            ]
                          }
                        }

                        const createVariables = {
                          organizationId,
                          noteType: {
                            userId,
                            name: 'New Note Type',
                            destinations: [firstDestination],
                            sections: firstSection,
                            source: 'ADMIN'
                          }
                        }

                        const queryVariables = { organizationId, userId }
                        if (isOnboardingDefault) {
                          queryVariables.specialties = [SPECIALTY_TYPE.NA]

                          // if in suki onboarding,
                          // all created notetypes will default to 'N/A' for specialties
                          createVariables.noteType.specialties = [SPECIALTY_TYPE.NA]
                        }

                        const createRefetch = [
                          {
                            query: GetNoteTypes,
                            variables: queryVariables
                          }
                        ]

                        const noteTypes = get(noteTypesData, 'noteTypes.results', [])

                        // TODO (zingerj): Eventually rethink sorting here
                        // Probably unnecessary, but good as a backup?
                        sortItemsByKey(noteTypes, 'name')

                        let sidebarTitle, sidebarItems, sidebarSections
                        if (userId) {
                          const user = get(userData, 'users.results[0].person')
                          const fullName = getFullName(user)
                          sidebarTitle = `${get(user, 'firstName', 'User')}'s Note Types`

                          const userNoteTypes = noteTypes.filter(nt => !!nt.userId)
                          const orgNoteTypes = noteTypes.filter(nt => !nt.userId)

                          sidebarSections = [
                            {
                              title: fullName || 'User',
                              items: userNoteTypes.map(this.mapSidebarItem)
                            },
                            {
                              title: get(org, 'name', 'Organization'),
                              items: orgNoteTypes.map(this.mapSidebarItem)
                            }
                          ]
                        } else {
                          sidebarTitle = `${get(org, 'name', 'Organization')}'s Note Types`
                          sidebarItems = noteTypes.map(this.mapSidebarItem)
                        }

                        const selectedId =
                          this.state.selectedId ||
                          get(sidebarItems, '[0].id') ||
                          get(sidebarSections, '[0].items[0].id') ||
                          get(sidebarSections, '[1].items[0].id')

                        if (this.state.selectedId !== selectedId) {
                          this.setState({ selectedId: selectedId })
                        }

                        const selectedNoteType = noteTypes.find(nt => nt.id === selectedId)
                        const readOnly = !!userId && !get(selectedNoteType, 'userId')

                        const loading = noteTypesLoading || orgLoading || userLoading || sectionsLoading

                        return (
                          <Mutation
                            mutation={CreateNoteType}
                            variables={createVariables}
                            onError={setError}
                            onCompleted={() => notifySuccess('Created note type')}
                            update={this.addToCache}
                            refetchQueries={createRefetch}
                          >
                            {createNoteType => (
                              <>
                                <Sidebar
                                  withNav
                                  title={sidebarTitle}
                                  dataType='note type'
                                  loading={loading}
                                  handleAdd={createNoteType}
                                  sections={sidebarSections}
                                  items={sidebarItems}
                                  selectedId={selectedId}
                                  handleSelect={this.setSelected}
                                  dropList={isOnboardingDefault && SPECIALTIES_ARR}
                                  dropListSelected={filteredSpecialty}
                                  handleDropListSelect={this.setFilteredSpecialty}
                                />
                                <EditorContainer>
                                  {noteTypesLoading ? (
                                    <Loading />
                                  ) : !noteTypes.length ? (
                                    <Empty data-cy='notetypes-empty'>{NO_NOTE_TYPES}</Empty>
                                  ) : (
                                    <NoteTypeEditor
                                      key={selectedId}
                                      organizationId={organizationId}
                                      userId={userId}
                                      id={selectedId}
                                      readOnly={readOnly}
                                      setSelected={this.setSelected}
                                      filteredSpecialty={filteredSpecialty}
                                      isOnboardingDefault={isOnboardingDefault}
                                    />
                                  )}
                                </EditorContainer>
                              </>
                            )}
                          </Mutation>
                        )
                      }}
                    </Query>
                  )}
                </Query>
              )}
            </Query>
          )}
        </Query>
      </>
    )
  }
}

export default withRouter(NoteTypes)
