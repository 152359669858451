import gql from 'graphql-tag'

export const GetCompositionsSms = gql`
  query GetCompositionsSms($organizationId: ID!, $userId: ID!, $limit: Int, $offset: Int) {
    compositionsSms(input: {
      organizationId: $organizationId,
      userId: $userId,
      limit: $limit,
      offset: $offset,
      includeMetadata: true,
      includeSections: false
    }) {
      count,
      results {
        ... on Composition {
          id,
          userId,
          organizationId,
          metadata {
            name,
            status,
            submissionInformation {
              destinations,
              tryAllDestinations
            },
            submissionStatus {
              warning {
                type,
                message
              },
              finalDestination
            },
            clientType,
            createdWithS2
            pulledNoteFromEmr
            noteTypeId,
            appointment {
              id
              startsAt
              emrId
              emrEncounterId
              emrDepartmentId
              emrEncounterStatus
              reason
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                gender,
                dateOfBirth
              },
              mrn,
              emrId,
              emrIdType
            },
            user {
              id,
              person {
                firstName,
                middleName,
                lastName
              }
            }
          },
          sections {
            id,
            name,
            content,
            status,
            cursors {
              actor,
              location
            }
          },
          sectionsS2 {
            id,
            name,
            content,
            status,
            cursors {
              actor,
              location
            }
          }
          createdAt
        }
      }
    }
  }
`

// USED IN: ADMIN_NOTE
export const GetCompositionsByIdSms = gql`
  query GetCompositionsByIdSms($organizationId: ID!, $ids: [ID!]!) {
    compositionsSms(input: {
      organizationId: $organizationId,
      ids: $ids,
      includeMetadata: true,
      includeSections: true
    }) {
      count,
      results {
        # If it's a composition
        ... on Composition {
          id,
          userId,
          metadata {
            name,
            status,
            submissionInformation {
              destinations,
              tryAllDestinations
            },
            submissionStatus {
              warning {
                type,
                message
              },
              finalDestination
            },
            clientType,
            createdWithS2
            pulledNoteFromEmr
            noteTypeId,
            appointment {
              id
              startsAt
              emrId
              emrEncounterId
              emrDepartmentId
              emrEncounterStatus
              reason
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                gender,
                dateOfBirth
              },
              mrn,
              emrId,
              emrIdType
            },
            user {
              id,
              person {
                firstName,
                middleName,
                lastName
              }
            }
          },
          sections {
            id,
            name,
            content,
            status,
            cursors {
              actor,
              location
            }
            diagnosisEntry {
              diagnosis {
                icdCode
                billable
                snomedCode
                description
                snomedDescription
                hccCode
                panelRanking
                suggestionType
              }
              detailedDisplay {
                tags {
                  text
                }
              }
              confirmStatus
            }
            pbcSectionFlag
          },
          sectionsS2 {
            id,
            name,
            content,
            status,
            diagnosisEntry {
              diagnosis {
                icdCode
                billable
                snomedCode
                description
                snomedDescription
                hccCode
                panelRanking
                suggestionType
              }
              detailedDisplay {
                tags {
                  text
                }
              }
              confirmStatus
            }
            pbcSectionFlag
            numberOfCursorEvents
          },
          createdAt
        },
        # If it's a patient note
        ... on PatientNote {
          noteId,
          compositionId,
          metadata {
            name,
            status,
            submissionInformation {
              destinations,
              tryAllDestinations
            },
            submissionStatus {
              warning {
                type,
                message
              },
              finalDestination
            },
            clientType,
            createdWithS2,
            pulledNoteFromEmr
            noteTypeId,
            appointment {
              id
              startsAt
              emrId
              emrEncounterId
              emrDepartmentId
              emrEncounterStatus
              reason
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                gender,
                dateOfBirth
              },
              mrn,
              emrId,
              emrIdType
            },
            user {
              id,
              person {
                firstName,
                middleName,
                lastName
              }
            }
          },
          sections {
            id,
            name,
            content,
            status,
            cursors {
              actor,
              location
            }
            diagnosisEntry {
              diagnosis {
                icdCode
                billable
                snomedCode
                description
                snomedDescription
                hccCode
                panelRanking
                suggestionType
              }
              detailedDisplay {
                tags {
                  text
                }
              }
              confirmStatus
            }
            pbcSectionFlag
          },
          sectionsS2 {
            id,
            name,
            content,
            status,
            diagnosisEntry {
              diagnosis {
                icdCode
                billable
                snomedCode
                description
                snomedDescription
                hccCode
                panelRanking
                suggestionType
              }
              detailedDisplay {
                tags {
                  text
                }
              }
              confirmStatus
            }
            pbcSectionFlag
            numberOfCursorEvents
          }
        }
      }
    }
  }
`

export const GetNotesSms = gql`
  query GetNotesSms($organizationId: ID!, $userId: ID, $limit: Int, $offset: Int) {
    notesSms(input: {
      organizationId: $organizationId,
      userId: $userId,
      limit: $limit,
      offset: $offset
    }) {
      count,
      results {
        # If it's a patient note
        ... on PatientNote {
          noteId,
          compositionId,
          metadata {
            status,
            user {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix
              },
              userType
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix
              },
              mrn,
              emrId,
              emrIdType
            },
            appointment {
              id,
              startsAt,
              emrId,
              emrEncounterId,
              emrDepartmentId
            }
          },
          compositionCreatedAt
        }
      }
    }
  }
`

export const GetVersionedCompositionSms = gql`
  query GetVersionedCompositionSms($organizationId: ID!, $id: ID!) {
    versionedCompositionSms(input: {
      organizationId: $organizationId,
      id: $id
    }) {
      note {
        ... on VersionedComposition {
          id
          userId
          createdAt
          metadata {
            name
            status
            noteTypeId
            appointment {
              id
              startsAt
              emrId
              emrEncounterId
              emrDepartmentId
              emrEncounterStatus
              reason
            }
            patient {
              id
              person {
                firstName
                middleName
                lastName
                dateOfBirth
                gender
                prefix
                suffix
              }
              mrn
              emrId
              emrIdType
            }
            user {
              id
              person {
                firstName
                lastName
                middleName
              }
            }
            submissionInformation {
                destinations
                tryAllDestinations
              }
            submissionStatus {
              warning {
                type
                message
              }
              finalDestination
            }
            clientType
            createdWithS2
            pulledNoteFromEmr
          }
          metadataEntries {
            author {
              organizationId
              userId
              type
              role {
                type
              }
              userName
              organizationName
            }
            createdAt
            metadata {
              name
              status
              noteTypeId
              appointment {
                id
                startsAt
                emrId
                emrEncounterId
                emrDepartmentId
                emrEncounterStatus
                reason
              }
              patient {
                id
                person {
                  firstName
                  middleName
                  lastName
                  dateOfBirth
                  gender
                  prefix
                  suffix
                }
                mrn
                emrId
                emrIdType
              }
              user {
                id
                person {
                  firstName
                  lastName
                  middleName
                }
              }
              submissionInformation {
                destinations
                tryAllDestinations
              }
              submissionStatus {
                warning {
                  type
                  message
                }
                finalDestination
              }
              clientType
              createdWithS2
              pulledNoteFromEmr
            }
          }
          sections {
            id
            sectionEntries {
              author {
                organizationId
                userId
                type
                role {
                  type
                }
                userName
                organizationName
              }
              createdAt
              section {
                name
                content
                navigationKeywords
                status
                diagnosisEntry {
                  diagnosis {
                    icdCode
                    billable
                    snomedCode
                    description
                    snomedDescription
                    hccCode
                    panelRanking
                    suggestionType
                  }
                  detailedDisplay {
                    tags {
                      text
                    }
                  }
                  confirmStatus
                }
                pbcSectionFlag
              }
            }
          },
          sectionsS2 {
            id
            sectionEntries {
              author {
                organizationId
                userId
                type
                role {
                  type
                }
                userName
                organizationName
              }
              createdAt
              section {
                name
                content
                navigationKeywords
                status
                cursorPosition
                diagnosisEntry {
                  diagnosis {
                    icdCode
                    billable
                    snomedCode
                    description
                    snomedDescription
                    hccCode
                    panelRanking
                    suggestionType
                  }
                  detailedDisplay {
                    tags {
                      text
                    }
                  }
                  confirmStatus
                }
                pbcSectionFlag
                numberOfCursorEvents
              }
            }
          }
        }
        ... on VersionedNote {
          noteId
          compositionId
          metadata {
            name
            status
            noteTypeId
            appointment {
              id
              startsAt
              emrId
              emrEncounterId
              emrDepartmentId
              emrEncounterStatus
              reason
            }
            patient {
              id
              person {
                firstName
                middleName
                lastName
                dateOfBirth
                gender
                prefix
                suffix
              }
              mrn
              emrId
              emrIdType
            }
            user {
              id
              person {
                firstName
                lastName
                middleName
              }
            }
            submissionInformation {
                destinations
                tryAllDestinations
              }
            submissionStatus {
              warning {
                type
                message
              }
              finalDestination
            }
            clientType
            createdWithS2
            pulledNoteFromEmr
          }
          metadataEntries {
            author {
              organizationId
              userId
              type
              role {
                type
              }
              userName
              organizationName
            }
            createdAt
            metadata {
              name
              status
              noteTypeId
              appointment {
                id
                startsAt
                emrId
                emrEncounterId
                emrDepartmentId
                emrEncounterStatus
                reason
              }
              patient {
                id
                person {
                  firstName
                  middleName
                  lastName
                  dateOfBirth
                  gender
                  prefix
                  suffix
                }
                mrn
                emrId
                emrIdType
              }
              user {
                id
                person {
                  firstName
                  lastName
                  middleName
                }
              }
              submissionInformation {
                destinations
                tryAllDestinations
              }
              submissionStatus {
                warning {
                  type
                  message
                }
                finalDestination
              }
              clientType
              createdWithS2
              pulledNoteFromEmr
            }
          }
          sections {
            id
            sectionEntries {
              author {
                organizationId
                userId
                type
                role {
                  type
                }
                userName
                organizationName
              }
              createdAt
              section {
                name
                content
                navigationKeywords
                status
                cursors {
                  actor
                  location
                }
                diagnosisEntry {
                  diagnosis {
                    icdCode
                    billable
                    snomedCode
                    description
                    snomedDescription
                    hccCode
                    panelRanking
                    suggestionType
                  }
                  detailedDisplay {
                    tags {
                      text
                    }
                  }
                  confirmStatus
                }
                pbcSectionFlag
              }
            }
          },
          sectionsS2 {
            id
            sectionEntries {
              author {
                organizationId
                userId
                type
                role {
                  type
                }
                userName
                organizationName
              }
              createdAt
              section {
                name
                content
                navigationKeywords
                status
                cursorPosition
                diagnosisEntry {
                  diagnosis {
                    icdCode
                    billable
                    snomedCode
                    description
                    snomedDescription
                    hccCode
                    panelRanking
                    suggestionType
                  }
                  detailedDisplay {
                    tags {
                      text
                    }
                  }
                  confirmStatus
                }
                pbcSectionFlag
                numberOfCursorEvents
              }
            }
          }
        }
      }
    }
  }
`

// USED IN: ADMIN_NOTE (STATUS SELECTOR)
export const GetCompositionMetadataByIdSms = gql`
  query GetCompositionMetadataByIdSms($organizationId: ID!, $id: ID!) {
    compositionsSms(input: {
      organizationId: $organizationId,
      ids: [$id],
      includeMetadata: true,
      includeSections: false
    }) {
      count,
      results {
        ... on Composition {
          id,
          metadata {
            name,
            noteTypeId,
            status,
            submissionInformation {
              destinations,
              tryAllDestinations
            },
            submissionStatus {
              warning {
                type,
                message
              },
              finalDestination
            },
            clientType
            createdWithS2
            pulledNoteFromEmr
            appointment {
              id,
              owner {
                id,
                roles {
                  type
                },
                person {
                  firstName,
                  middleName,
                  lastName,
                  prefix,
                  suffix,
                  gender,
                  dateOfBirth
                },
                authId,
                email,
                specialty,
                specialties,
                timezone,
                userType,
                activationSent
              },
              patient {
                id,
                person {
                  firstName,
                  middleName,
                  lastName,
                  dateOfBirth,
                  gender,
                  prefix,
                  suffix
                },
                mrn,
                emrId,
                emrIdType
              },
              startsAt,
              endsAt,
              type,
              medicalProcedure,
              reason,
              emrId,
              emrEncounterId,
              emrDepartmentId
            },
            encounterAddress {
              line1,
              line2,
              city,
              state,
              zip,
              country
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                dateOfBirth,
                gender,
                prefix,
                suffix
              },
              mrn,
              emrId,
              emrIdType
            },
            user {
              id,
              roles {
                type
              },
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                gender,
                dateOfBirth
              },
              authId,
              email,
              specialty,
              specialties,
              timezone,
              userType,
              activationSent
            }
          }
        },
        # If it's a patient note
        ... on PatientNote {
          noteId,
          compositionId,
          metadata {
            name,
            noteTypeId,
            status,
            submissionInformation {
              destinations,
              tryAllDestinations
            },
            submissionStatus {
              warning {
                type,
                message
              },
              finalDestination
            },
            clientType
            createdWithS2
            pulledNoteFromEmr
            appointment {
              id,
              owner {
                id,
                roles {
                  type
                },
                person {
                  firstName,
                  middleName,
                  lastName,
                  prefix,
                  suffix,
                  gender,
                  dateOfBirth
                },
                authId,
                email,
                specialty,
                specialties,
                timezone,
                userType,
                activationSent
              },
              patient {
                id,
                person {
                  firstName,
                  middleName,
                  lastName,
                  dateOfBirth,
                  gender,
                  prefix,
                  suffix
                },
                mrn,
                emrId,
                emrIdType
              },
              startsAt,
              endsAt,
              type,
              medicalProcedure,
              reason,
              emrId,
              emrEncounterId,
              emrDepartmentId
            },
            encounterAddress {
              line1,
              line2,
              city,
              state,
              zip,
              country
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                dateOfBirth,
                gender,
                prefix,
                suffix
              },
              mrn,
              emrId,
              emrIdType
            },
            user {
              id,
              roles {
                type
              },
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                gender,
                dateOfBirth
              },
              authId,
              email,
              specialty,
              specialties,
              timezone,
              userType,
              activationSent
            }
          }
        }
      }
    }
  }
`

// USED IN: USERS, ADMIN_NOTE, PATIENT_NOTE
export const GetAllNotesSms = gql`
  query GetAllNotesSms($organizationId: ID!, $userId: ID, $patientId: ID, $limit: Int, $offset: Int) {
    allNotesSms(input: {
      organizationId: $organizationId,
      userId: $userId,
      patientId: $patientId,
      limit: $limit,
      offset: $offset
    }) {
      count,
      results {
        # If it's a composition
        ... on Composition {
          id,
          metadata {
            status,
            user {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix
              },
              userType
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix
              },
              mrn,
              emrId,
              emrIdType
            },
            appointment {
              id,
              startsAt,
              emrId,
              emrEncounterId,
              emrDepartmentId
            }
          },
          createdAt
        },
        # If it's a patient note
        ... on PatientNote {
          noteId,
          compositionId,
          metadata {
            status,
            user {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix
              },
              userType
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix
              },
              mrn,
              emrId,
              emrIdType
            },
            appointment {
              id,
              startsAt,
              emrId,
              emrEncounterId,
              emrDepartmentId
            }
          },
          compositionCreatedAt
        }
      }
    }
  }
`

export const GetVersionedNoteSms = gql`
  query GetVersionedNoteSms($organizationId: ID, $id: ID!) {
    versionedNoteSms(input: {
      organizationId: $organizationId,
      id: $id,
    }) {
      note {
        noteId,
        compositionId,
        metadata {
          name,
          status,
          noteTypeId,
          appointment {
            id
            startsAt
            emrId
            emrEncounterId
            emrDepartmentId
            emrEncounterStatus
            reason
          },
          patient {
            id,
            person {
              firstName,
              middleName,
              lastName,
              dateOfBirth,
              gender,
              prefix,
              suffix
            },
            mrn,
            emrId,
            emrIdType
          },
          user {
            id,
            person {
              firstName,
              lastName,
              middleName
            }
          },
          submissionInformation {
              destinations,
              tryAllDestinations
            },
          submissionStatus {
            warning {
              type,
              message
            },
            finalDestination
          },
          clientType
          createdWithS2
          pulledNoteFromEmr
        },
        metadataEntries {
          author {
            organizationId
            userId
            type
            role {
              type
            }
            userName
            organizationName
          }
          createdAt
          metadata {
            name,
            status,
            noteTypeId,
            appointment {
              id
              startsAt
              emrId
              emrEncounterId
              emrDepartmentId
              emrEncounterStatus
              reason
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                dateOfBirth,
                gender,
                prefix,
                suffix
              },
              mrn,
              emrId,
              emrIdType
            },
            user {
              id,
              person {
                firstName,
                lastName,
                middleName
              }
            },
            submissionInformation {
              destinations,
              tryAllDestinations
            },
            submissionStatus {
              warning {
                type,
                message
              },
              finalDestination
            },
            clientType
            createdWithS2
            pulledNoteFromEmr
          }
        },
        sections {
          id,
          sectionEntries {
            author {
              organizationId
              userId
              type
              role {
                type
              }
              userName
              organizationName
            }
            createdAt,
            section {
              name,
              content,
              navigationKeywords,
              status,
              cursors {
                actor,
                location
              }
              diagnosisEntry {
                diagnosis {
                  icdCode
                  billable
                  snomedCode
                  description
                  snomedDescription
                  hccCode
                  panelRanking
                  suggestionType                
                }
                detailedDisplay {
                  tags {
                    text
                  }
                }
                confirmStatus
              }
            }
          }
        },
        sectionsS2 {
          id,
          sectionEntries {
            author {
              organizationId
              userId
              type
              role {
                type
              }
              userName
              organizationName
            }
            createdAt,
            section {
              name,
              content,
              navigationKeywords,
              status,
              cursorPosition
              diagnosisEntry {
                diagnosis {
                  icdCode
                  billable
                  snomedCode
                  description
                  snomedDescription
                  hccCode
                  panelRanking
                  suggestionType                
                }
                detailedDisplay {
                  tags {
                    text
                  }
                }
                confirmStatus
              }
              numberOfCursorEvents
            }
          }
        },
        compositionCreatedAt
      }
    }
  }
`
