import _map from 'lodash/map'
import _join from 'lodash/join'
import _trim from 'lodash/trim'
import _filter from 'lodash/filter'
import _compact from 'lodash/compact'
import _includes from 'lodash/includes'
import _toLower from 'lodash/toLower'

const getUserFullName = (user) =>
  _trim(
    _join(
      _compact([
        user?.person?.firstName,
        user?.person?.middleName,
        user?.person?.lastName
      ]),
      ' '
    )
  )

const sortUsersByAccess = (users, selectedUserIds, sukiAdminUserIds) => {
  const adminUsers = _filter(users, (user) => {
    return _includes(sukiAdminUserIds, user?.id)
  })
  const sortedAdminUsers = adminUsers.sort((a, b) => {
    const nameA = getUserFullName(a)
    const nameB = getUserFullName(b)
    return nameA > nameB ? 1 : -1
  })
  const activeUsers = _filter(users, (user) => {
    return (
      _includes(selectedUserIds, user?.id) &&
      !_includes(sukiAdminUserIds, user?.id)
    )
  })
  const sortedActiveUsers = activeUsers.sort((a, b) => {
    const nameA = getUserFullName(a)
    const nameB = getUserFullName(b)
    return nameA > nameB ? 1 : -1
  })
  const inActiveUsers = _filter(users, (user) => {
    return !_includes(selectedUserIds, user?.id)
  })
  const sortedInActiveUsers = inActiveUsers.sort((a, b) => {
    const nameA = getUserFullName(a)
    const nameB = getUserFullName(b)
    return _toLower(nameA) > _toLower(nameB) ? 1 : -1
  })
  return [...sortedAdminUsers, ...sortedActiveUsers, ...sortedInActiveUsers]
}

const formatUsersDataForTable = (users) =>
  _map(users, (user) => ({
    ...user,
    key: user?.id
  }))

const filterUsersBySearchText = (users, searchText) =>
  _filter(users, (user) => {
    return (
      _includes(_toLower(user?.email), _toLower(searchText)) ||
      _includes(_toLower(getUserFullName(user)), _toLower(searchText)) ||
      _includes(_toLower(user?.id), _toLower(searchText))
    )
  })

const getRowSelection = (
  selectedUserIds = [],
  isEditView,
  onSelect,
  sukiAdminUserIds
) => ({
  selectedRowKeys: [...selectedUserIds, ...sukiAdminUserIds],
  onChange: onSelect,
  getCheckboxProps: (user) => ({
    disabled: !isEditView || _includes(sukiAdminUserIds, user?.id)
  })
})

export {
  filterUsersBySearchText,
  formatUsersDataForTable,
  getRowSelection,
  getUserFullName,
  sortUsersByAccess
}
