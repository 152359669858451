import styled from 'styled-components'
import { whiteSmoke, charcoal } from '../../styles/colors'
import { Z_INDEX } from '../../lib/constants'

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 30px;
  box-sizing: border-box;
  overflow: scroll;
  z-index: ${Z_INDEX.ACTION_BAR};
`

export const FloatingButton = styled.button.attrs({
  type: 'button'
})`
  outline: none;
  border: none;
  padding: 0px;
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  background-color: ${whiteSmoke};
  ${p => p.disabled && 'opacity: 0.85;'}
  box-shadow: ${p => (p.disabled ? 'none' : '0px 0px 15px rgba(0, 0, 0, 0.4)')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${p => p.size || '60px'};
  width: ${p => p.size || '60px'};
  margin-top: 30px;
  transition: all 0.3s;
`

export const Icon = styled.i.attrs({
  className: 'material-icons'
})`
  ${p => p.disabled && 'opacity: 0.5;'}
  color: ${charcoal};
  font-size: 28px;
  transition: all 0.3s;
`
