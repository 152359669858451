export const NO_SCRIPTS = 'No scripts yet. To create one, click the + button on the sidebar.'

export const CURRENT_SECTION = [
  {
    id: '11111111-1111-1111-1111-111111111111',
    createdBy: '11111111-1111-1111-1111-111111111111',
    name: 'Any Section'
  }]

export const COPY_BUTTON = {
  DEFAULT: 'Copy',
  LOADING: 'Copying...'
}
