import styled from 'styled-components/native'
import reactStyled from 'styled-components'

import { black, casal, white } from '../../lib/colors'

export const Input = styled.TextInput.attrs({
  autoCapitalize: 'none',
  autoFocus: true,
  autoCorrect: 'false',
  placeholder: 'Email',
  placeholderTextColor: '#C0C0C0'
})`
    width: 100%;
    font-family: 'Open Sans';
    font-size: 25px;
    color: ${black};
    padding: 6px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: ${black};
    outline: none;
    margin-bottom: ${props => props.vw * 0.012};
    margin: 30px 0;
  `

export const ResetContainer = styled.View`
  height: ${props => props.vh};
  width: ${props => props.vw};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-left: ${props => props.vw * 0.1};
  padding-right: ${props => props.vw * 0.1};
`

export const FormContainer = reactStyled.div`
  width: 405px;
  display: flex;
  flex-direction:column;
  align-items: center;
`

export const ResetButtonText = reactStyled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${casal};
  border-radius: 30px;
  font-size: 22px;
  color: ${white};
  margin-top: 15px;
  width: 255px;
  border: 0;
  padding: 13px 0px;
  &:disabled {
    cursor: not-allowed;
  }
  &:active {
    opacity: 1.0 !important;
  }
  &:hover {
    opacity: 0.75;
  }
`

export const ResetHeaderText = reactStyled.h4`
  font-weight: 500;
  font-size: 25px;
  margin: 0;
`

export const ResetDescText = reactStyled.p`
  font-weight:300;
  font-size: 20px;
  text-align:center;
  margin: 20px 0;
`
