import React from 'react'
import {
  Wrapper,
  Title,
  Desc,
  Problem,
  ProblemTitle,
  DescWrapper,
  ProblemDesc,
  Code,
  Footer,
  ActionText,
  ActionTextRight,
  ItemSection,
  TitleWrapper,
  AddCode,
  ProblemHeader
} from './styled'
import get from 'lodash.get'

class ConfirmCodeModal extends React.Component {
  componentWillReceiveProps (nextProps) {
    nextProps.shouldDismiss && this.props.open && nextProps.removeModal()
  }

  handleContinueClick = () => {
    const { handleContinue } = this.props
      handleContinue()
  }

  formatCode = code => {
    if (code.length > 3) {
      return code.substring(0, 3) + '.' + code.substring(3)
    }
    return code
  }

  renderItems = () => {
    const { problemSections, handleCodeClick, dupeProblems } = this.props
    return problemSections.map((p, i) => {
      const desc = get(p, 'diagnosisEntry.diagnosis.description', '')
      const code = get(p, 'diagnosisEntry.diagnosis.icdCode', '')
      const content = get(p, 'content', '')
      const searchCode = () => handleCodeClick(p.id, p.name, true, content)
      return (
        <Problem
          first='true'
          key={i}
          onClick={searchCode}
          isDupe={dupeProblems.has(code)}
          data-cy={`confirm-problem-${i}`}
        >
          <ProblemHeader>
            <ProblemTitle>{p.name}</ProblemTitle>
            {!code && <AddCode data-cy='confirm-add-code'>Add Code</AddCode>}
          </ProblemHeader>
          {code &&
            <DescWrapper>
              <ProblemDesc>{desc}</ProblemDesc>
              <Code data-cy={`confirm-problem-code-${code}`}>
                {this.formatCode(code)}
              </Code>
            </DescWrapper>}
        </Problem>
      )
    })
  }

  render () {
    const { dismiss } = this.props

    return (
      <Wrapper vw={window.innerWidth} onClick={e => e.stopPropagation()}>
        <TitleWrapper>
          <Title>Choose Codes</Title>
          <Desc>Here you can choose or change diagnosis codes for this note.</Desc>
        </TitleWrapper>
        <ItemSection>
          {this.renderItems()}
        </ItemSection>
        <Footer>
          <ActionText onClick={dismiss}>CANCEL</ActionText>
          <ActionTextRight onClick={this.handleContinueClick}>CONTINUE</ActionTextRight>
        </Footer>
      </Wrapper>
    )
  }
}

export default ConfirmCodeModal
