import React from 'react'
import ErrorAlert from './ErrorAlert'
import { clearServerError } from '../../actions'
import { connect } from 'react-redux'

const ErrorModal = ({ error, clearError }) =>
  error ? <ErrorAlert open error={error} handler={clearError} /> : null

const mapStateToProps = ({ general: { error } }) => ({ error })

const mapDispatchToProps = dispatch => ({
  clearError: () => dispatch(clearServerError())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorModal)
