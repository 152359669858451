import React, { Component } from 'react'
import logo from '../images/suki-vertical.svg'
import adminBackground from '../images/adminBackground.jpg'

const styles = {
  container: {
    background: `url(${adminBackground})`,
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    overflow: 'hidden',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  middle: {
    marginTop: -100
  },
  text: {
    color: 'white',
    textAlign: 'center',
    fontSize: 30
  }
}

class NotFound extends Component {
  render () {
    return (
      <div style={Object.assign({}, styles.container, { background: `url(${adminBackground})` })}>
        <div style={styles.middle}>
          {/* TODO Still need to swap in new logo */}
          <img src={logo} width={600} alt='Suki Logo' />
          <div style={styles.text}>
            The page you requested
            <br />can't be found.
          </div>
        </div>
      </div>
    )
  }
}

export default NotFound
