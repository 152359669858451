import React from 'react'
import { Mutation } from 'react-apollo'
import { DeleteScript, CopyScript, GetScriptsByUserId, GetScriptById } from '@sukiai/gql/admin/Scripts'
import get from 'lodash.get'
import ReactTooltip from 'react-tooltip'
import { setError, notifySuccess, sortScriptsByName, sortScriptsBySource } from '../../lib/util'
import { ActionBar } from '../../components'

export default class ScriptActionBar extends React.Component {
  onDelete = async (cache, { data: { deleteMacros } }) => {
    const {
      organizationId,
      userId,
      setSelected,
      isOnboardingDefault,
      filteredSpecialty
    } = this.props

    // Get all current scripts from the cache
    // will need to query with selected specialty
    const queryVariables = { organizationId, userId }
    if (isOnboardingDefault) {
      queryVariables.specialties = [filteredSpecialty]
    }

    // Get all current scripts from the cache
    const { macros: allScriptsRes } = cache.readQuery({
      query: GetScriptsByUserId,
      variables: queryVariables
    })

    // Remove the deleted script from the cache
    const allScripts = get(allScriptsRes, 'results', [])
    const deletedId = get(deleteMacros, 'ids[0]')
    const deletedIdx = allScripts.findIndex(nt => nt.id === deletedId)
    allScripts.splice(deletedIdx, 1)

    // Update the cache with the new list of scripts
    await cache.writeQuery({
      query: GetScriptsByUserId,
      variables: queryVariables,
      data: {
        macros: {
          __typename: 'QueryMacrosPayload',
          count: get(allScripts, 'length', 0),
          results: allScripts
        }
      }
    })

    // Set the selected script to the one above in the list
    // If the deleted one was the first, set it to the first one
    // Will unselect all scripts if there are none left
    const newIdx = deletedIdx > 0 ? deletedIdx - 1 : 0
    const newId = get(allScripts, `[${newIdx}].id`)
    setSelected(newId)

    // Update the query result for this script in the cache
    cache.writeQuery({
      query: GetScriptById,
      variables: { organizationId, id: deletedId },
      data: { macros: null }
    })
  }

  onCopy = async (cache, { data: { copyMacro } }) => {
    const {
      organizationId,
      userId,
      setSelected,
      isOnboardingDefault,
      filteredSpecialty
    } = this.props

    const queryVariables = { organizationId, userId }
    if (isOnboardingDefault) {
      queryVariables.specialties = [filteredSpecialty]
    }

    // Get all current scripts from the cache
    const { macros: allScriptsRes } = cache.readQuery({
      query: GetScriptsByUserId,
      variables: queryVariables
    })

    // Add the copied script to the cache
    const allScripts = get(allScriptsRes, 'results', [])
    const copy = get(copyMacro, 'macro')
    allScripts.push(copy)

    // Re-sort the scripts by name
    sortScriptsByName(allScripts)
    sortScriptsBySource(allScripts)

    // Update the cache with the new list of scripts
    await cache.writeQuery({
      query: GetScriptsByUserId,
      variables: { organizationId, userId },
      data: {
        macros: {
          __typename: 'QueryMacrosPayload',
          count: get(allScripts, 'length', 0),
          results: allScripts
        }
      }
    })

    const copyId = get(copy, 'id')

    // Write the copied script data to its corresponding query
    // This saves a trip to the server since we will want to display
    // this newly copied script next
    await cache.writeQuery({
      query: GetScriptById,
      variables: { organizationId, id: copyId },
      data: {
        macros: {
          __typename: 'QueryMacrosPayload',
          count: 1,
          results: [copy]
        }
      }
    })

    // Set the selected script to the copied one
    setSelected(copyId)
  }

  render () {
    const {
      blocks,
      organizationId,
      id,
      formDirty,
      formSubmitting,
      openCopyDialog,
      openSpecialtiesDialog,
      showSpecialties,
      saveScript,
      setErrorContentDialog,
      validateErrorSections,
      userId
    } = this.props
    const deleteVariables = { organizationId, id, ownerId: userId }
    const copyVariables = { organizationId, id }

    const optimisticDelete = {
      __typename: 'Mutation',
      deleteMacros: {
        __typename: 'DeleteMacrosPayload',
        ids: [id]
      }
    }

    return (
      <Mutation
        mutation={DeleteScript}
        variables={deleteVariables}
        optimisticResponse={optimisticDelete}
        onError={setError}
        onCompleted={() => notifySuccess('Deleted script')}
        update={this.onDelete}
      >
        {(deleteScript, { loading: deleteLoading }) => (
          <Mutation
            mutation={CopyScript}
            variables={copyVariables}
            onError={setError}
            onCompleted={() => notifySuccess('Copied script')}
            update={this.onCopy}
          >
            {(copyScript, { loading: copyLoading }) => {
              const loading = deleteLoading || copyLoading || formSubmitting
              const copyScriptHandler = (...args) => {
                const contentErrors = validateErrorSections(blocks)
                if (contentErrors) {
                  setErrorContentDialog(contentErrors)
                  return
                }
                copyScript(args)
              }
              return (
                <ActionBar>
                  <ReactTooltip place='left' type='dark' effect='solid' />
                  <ActionBar.Button
                    data-cy='delete-script'
                    onClick={deleteScript}
                    disabled={loading}
                    icon='delete'
                    data-tip='Delete script'
                  />
                  {showSpecialties && (
                    <ActionBar.Button
                      data-cy='configure-script-specialties'
                      onClick={openSpecialtiesDialog}
                      disabled={loading}
                      icon='school'
                      data-tip='Configure Specialties'
                    />
                  )}
                  <ActionBar.Button
                    data-cy='copy-script-to-user'
                    onClick={openCopyDialog}
                    disabled={loading || formDirty}
                    icon='person_add'
                    data-tip='Copy to another user'
                  />
                  <ActionBar.Button
                    data-cy='copy-script'
                    onClick={copyScriptHandler}
                    disabled={loading || formDirty}
                    icon='filter_none'
                    data-tip='Copy to this user'
                  />
                  <ActionBar.Button
                    data-cy='copy-script'
                    onClick={saveScript}
                    disabled={loading || !formDirty}
                    icon='check'
                    data-tip='Save script'
                  />
                </ActionBar>
              )
            }}
          </Mutation>
        )}
      </Mutation>
    )
  }
}
