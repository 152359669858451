import React, { Component } from 'react'
import { Animated, Easing } from 'react-native'
import {
  Wrapper,
  SectionContainer,
  SectionName,
  SectionContent,
  Blurred,
  Macro,
  ScribeToProcess,
  Paragraph,
  SectionIndicator,
  CapturingIndicator,
  CapturingIndicatorDot
} from './styled'
import { SECTION_STATE } from '../../lib/constants'
import { isAmwellTheme } from '../../lib/util'

class NoteSection extends Component {
  state = {
    textAnim: new Animated.Value(0),
    dotAnim1: new Animated.Value(0),
    dotAnim2: new Animated.Value(0),
    dotAnim3: new Animated.Value(0),
    animating: false,
    isFocused: false,
    isAmwellDark: isAmwellTheme()
  }

  forceFocusOn () {
    if (this.state.isAmwellDark) {
      this.setState({ isFocused: true })
    }
  }

  forceFocusOff () {
    if (this.state.isAmwellDark) {
      this.setState({ isFocused: false })
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.currentSectionId === this.props.id &&
      nextProps.currentSectionState === SECTION_STATE.DICTATION &&
      !this.state.animating) {
      this.startAnimation()
    } else if (nextProps.currentSectionId !== this.props.id && this.state.animating) {
      this.setState({
        animating: false,
        textAnim: new Animated.Value(0),
        dotAnim1: new Animated.Value(0),
        dotAnim2: new Animated.Value(0),
        dotAnim3: new Animated.Value(0)
      })
    }
  }

  startAnimation = () => {
    this.setState({ animating: true })

    Animated.timing(this.state.textAnim, {
      toValue: 1,
      duration: 300,
      easing: Easing.linear
    }).start()

    Animated.loop(
      Animated.stagger(300,
        [
          Animated.sequence([
            Animated.timing(this.state.dotAnim1, {
              toValue: 1,
              duration: 500,
              easing: Easing.linear
            }),
            Animated.delay(100),
            Animated.timing(this.state.dotAnim1, {
              toValue: 0,
              duration: 500,
              easing: Easing.linear
            }),
            Animated.delay(500)
          ]),
          Animated.sequence([
            Animated.timing(this.state.dotAnim2, {
              toValue: 1,
              duration: 500,
              easing: Easing.linear
            }),
            Animated.delay(100),
            Animated.timing(this.state.dotAnim2, {
              toValue: 0,
              duration: 500,
              easing: Easing.linear
            }),
            Animated.delay(500)
          ]),
          Animated.sequence([
            Animated.timing(this.state.dotAnim3, {
              toValue: 1,
              duration: 500,
              easing: Easing.linear
            }),
            Animated.delay(100),
            Animated.timing(this.state.dotAnim3, {
              toValue: 0,
              duration: 500,
              easing: Easing.linear
            }),
            Animated.delay(500)
          ])
        ]
      )
    ).start()
  }

  handleWrapperClick = e => {
    const { id, setSection, handleSectionClick, dictation } = this.props
    handleSectionClick && handleSectionClick(e)
    setSection && setSection(id, dictation ? SECTION_STATE.DICTATION : SECTION_STATE.TYPING)
  }

  handleContentClick = e => {
    const { dictation } = this.props

    // Treat click on actual content different than click in section wrapper
    if (dictation) {
      // stopDictation()
      // setSection && setSection(id, SECTION_STATE.TYPING)
    }
  }

  render () {
    const {
      id,
      name,
      editor,
      currentSectionId,
      currentSectionState,
      keyboardMode
    } = this.props

    const styles = {
      capturing: {
        opacity: this.state.textAnim
      },
      dot1: {
        transform: [
          {
            scale: this.state.dotAnim1.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 1.3]
            })
          }
        ],
        opacity: this.state.dotAnim1
      },
      dot2: {
        transform: [
          {
            scale: this.state.dotAnim2.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 1.3]
            })
          }
        ],
        opacity: this.state.dotAnim2
      },
      dot3: {
        transform: [
          {
            scale: this.state.dotAnim3.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 1.3]
            })
          }
        ],
        opacity: this.state.dotAnim3
      }
    }

    /* change to onPress and touchable opacity eventually */
    const { isFocused, isAmwellDark } = this.state
    const isActive = currentSectionId === id
    return (
      <Wrapper
        isFocused={isFocused}
        onClick={this.handleWrapperClick}
        id={`section-wrapper-${id}`}
        onBlur={() => this.forceFocusOff()}
        onFocus={() => this.forceFocusOn()}
        onMouseLeave={() => this.forceFocusOff()}
        onMouseOver={() => this.forceFocusOn()}
        isAmwellTheme={isAmwellDark}
        isActive={isActive}
        isDictationMode={!keyboardMode}
      >
        <SectionContainer isAmwellDark={isAmwellDark} id={id} isActive={isActive} isDictationMode={!keyboardMode}>
          {isActive && currentSectionState === SECTION_STATE.DICTATION && !keyboardMode &&
            <CapturingIndicator style={styles.capturing} isAmwellTheme={isAmwellDark}>
              <CapturingIndicatorDot style={styles.dot1} isAmwellTheme={isAmwellDark} />
              <CapturingIndicatorDot style={styles.dot2} isAmwellTheme={isAmwellDark} />
              <CapturingIndicatorDot style={styles.dot3} isAmwellTheme={isAmwellDark} />
            </CapturingIndicator>}
          {isActive && !isAmwellDark && <SectionIndicator data-cy={`section-indicator-${name.replace(/\W/g, '-').toLowerCase()}`} />}
          <SectionName>
            {name}
          </SectionName>
          <SectionContent
            data-cy={`section-content-${name.replace(/\W/g, '-').toLowerCase()}`}
            onClick={this.handleContentClick}
            id='section-content'
            isAmwellTheme={isAmwellDark}
          >
            {editor}
          </SectionContent>
        </SectionContainer>
      </Wrapper>
    )
  }
}

export default NoteSection

export {
  SectionContent,
  Blurred,
  Macro,
  ScribeToProcess,
  Paragraph
}
