import gql from 'graphql-tag'

// View: NVOQ
// Description: Get nVoq information
export const GetNvoqInformation = gql`
  query GetNvoqInformation($organizationId: ID!, $sukiUserId: ID!) {
    preferences(input: { organizationId: $organizationId, sukiUserId: $sukiUserId }) {
      information {
        nvoqOrgId,
        nvoqUserId,
        nvoqUsername,
        nvoqTopic
      },
      nvoqEndpoint
    }
  }
`

// View: NVOQ
// Description: Get User nVoq information
export const GetUserNvoqInformation = gql`
  query GetUserNvoqInformation($organizationId: ID!, $sukiUserId: ID!) {
    nvoqInformation(input: { organizationId: $organizationId, sukiUserId: $sukiUserId }) {
      information {
        nvoqOrgId,
        nvoqUserId,
        nvoqUsername,
        nvoqTopic,
        apiKey
      },
      nvoqEndpoint
    }
  }
`

// View: NVOQ
// Description: Update nvoq data for a user
export const UpdateNvoqUserAccount = gql`
  mutation UpdateNvoqUserAccount(
    $organizationId: ID!,
    $sukiUserId: ID!,
    $information: NvoqInformationInput!,
    $wakewordPreference: String!,
  ) {
    updateNvoqUserAccount(
      input: {
        organizationId: $organizationId
        sukiUserId: $sukiUserId
        information: $information
        wakewordPreference: $wakewordPreference
      }
    ) {
      success
    }
  }
`

export const GetAllAvailableTopics = gql`
  query GetAllAvailableTopics {
    availableTopics {
      topics
    }
  }
`

export const DeleteNvoqUserAccount = gql`
  mutation DeleteNvoqUserAccount(
    $sukiUserIds: [ID!]!
  ) {
    deleteNvoqUserAccount(input: {
      sukiUserIds: $sukiUserIds
    }) {
      success
    }
  }
`

// View: Admin Orgs
// Show all nVoq linked Suki organizations
export const GetLinkedNvoqOrganizations = gql`
query LinkedNvoqOrganizations($organizationId: ID!) {
  linkedNvoqOrganizations(input: {
    organizationId: $organizationId
  }) {
    organizations {
      nvoqOrganizationId,
      nvoqOrganizationName,
      sukiOrganizationId
    }
  }
}
`

// View: Admin Orgs
// Get all nVoq organizations for the selector on admin UI
export const GetNvoqOrganizations = gql`
query NvoqOrganizations($organizationId: ID!) {
  nvoqOrganizations(input: {
    organizationId: $organizationId
  }) {
    organizations {
      nvoqOrganizationId,
      nvoqOrganizationName
    }
  }
}
`

// View: Admin Orgs
// Link a Suki Organization to a nVoq Organization
export const LinkNvoqOrganization = gql`
  mutation LinkNvoqOrganization(
    $organizationId: ID!,
    $nvoqOrganizationId: ID!
  ) {
    linkSukiOrganizationToNvoq(input: {
      organizationId: $organizationId,
      nvoqOrganizationId: $nvoqOrganizationId
    }) {
      success
    }
  }
`
