export const CLEAR_DOCTOR_OPENED_NOTE = 'CLEAR_DOCTOR_OPENED_NOTE'
export const CLEAR_DOCTOR_OPENED_SECTION = 'CLEAR_DOCTOR_OPENED_SECTION'
export const SET_CURRENT_SECTION = 'SET_CURRENT_SECTION'
export const SET_DOCTOR_OPENED_NOTE = 'SET_DOCTOR_OPENED_NOTE'
export const SET_DOCTOR_OPENED_SECTION = 'SET_DOCTOR_OPENED_SECTION'
export const SET_NOTE_ID = 'SET_NOTE_ID'
export const SET_NOTE_MODE = 'SET_NOTE_MODE'

export const clearDoctorOpenedNote = noteId => ({
  type: CLEAR_DOCTOR_OPENED_NOTE,
  noteId
})

export const clearDoctorOpenedSection = ({ noteId, sectionId }) => ({
  type: CLEAR_DOCTOR_OPENED_SECTION,
  noteId,
  sectionId
})

export const setDoctorOpenedNote = noteId => ({
  type: SET_DOCTOR_OPENED_NOTE,
  noteId
})

export const setDoctorOpenedSection = ({ noteId, sectionId }) => ({
  type: SET_DOCTOR_OPENED_SECTION,
  noteId,
  sectionId
})

export const setCurrentSection = id => ({
  type: SET_CURRENT_SECTION,
  id
})

export const setNoteId = id => ({
  type: SET_NOTE_ID,
  id
})

export const setNoteMode = mode => ({
  type: SET_NOTE_MODE,
  mode
})
