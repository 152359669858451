import store from 'store2'
import _get from 'lodash/get'
import _includes from 'lodash/includes'

import { FEATURE_FLAG_WRITE, LOCAL_STORE } from '../constants'

const isWriteAccessEnabled = (featureFlags) => {
  const userId = store.get(LOCAL_STORE.USER_ID)
  const organizationId = store.get(LOCAL_STORE.ORGANIZATION_ID)

  const featureFlagWriteParameter = featureFlags[FEATURE_FLAG_WRITE]
  const sukiUserIds = _get(
    featureFlagWriteParameter,
    'value.access.sukiUserIds',
    []
  )
  const sukiOrganizationIds = _get(
    featureFlagWriteParameter,
    'value.access.sukiOrganizationIds',
    []
  )

  const sukiAdminUserIds = _get(
    featureFlagWriteParameter,
    'value.access.sukiAdminUserIds',
    []
  )

  if (_includes(sukiAdminUserIds, userId)) return true

  return (_includes(sukiUserIds, userId) && _includes(sukiOrganizationIds, organizationId))
}

const isOrganizationEnabledForFeatureFlags = (featureFlags) => {
  const organizationId = store.get(LOCAL_STORE.ORGANIZATION_ID)
  const featureFlagWriteParameter = featureFlags[FEATURE_FLAG_WRITE]
  const sukiOrganizationIds = _get(
    featureFlagWriteParameter,
    'value.access.sukiOrganizationIds',
    []
  )
  return _includes(sukiOrganizationIds, organizationId)
}

export { isOrganizationEnabledForFeatureFlags, isWriteAccessEnabled }
