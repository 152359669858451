import React from 'react'
import { FlagOutlined, HistoryOutlined, TeamOutlined } from '@ant-design/icons'

const FEATURE_FLAGS_MENU_ITEM = {
  key: 'FEATURE_FLAGS',
  icon: <FlagOutlined />,
  children: '',
  label: 'Feature flags'
}

const USERS_MENU_ITEM = {
  key: 'USERS',
  icon: <TeamOutlined />,
  children: '',
  label: 'Access management'
}

const ACTIVITY_HISTORY_MENU_ITEM = {
  key: 'ACTIVITY_HISTORY',
  icon: <HistoryOutlined />,
  children: '',
  label: 'Activity history'
}

const MENU_ITEMS = [
  FEATURE_FLAGS_MENU_ITEM,
  USERS_MENU_ITEM
  // ACTIVITY_HISTORY_MENU_ITEM,
]

export {
  MENU_ITEMS,
  FEATURE_FLAGS_MENU_ITEM,
  USERS_MENU_ITEM,
  ACTIVITY_HISTORY_MENU_ITEM
}
