import React, { Component } from 'react'
import { Formik, Form, Field } from 'formik'
import client from '../../apollo'
import {
  slateGrey,
  charcoal,
  whiteSmoke,
  scribeGreen,
  demoGrey
} from '../../styles/colors'
import Radium from 'radium'
import { SearchEMRUsers } from '@sukiai/gql/admin'
import get from 'lodash/get'

const styles = {
  outerContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    background: 'rgba(0,0,0,0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 50
  },
  formContainer: {
    width: '50vw',
    minWidth: 800,
    background: whiteSmoke
  },
  header: {
    padding: '20px 40px',
    backgroundColor: slateGrey,
    color: 'white',
    fontSize: 24,
    fontWeight: 600,
    boxSizing: 'border-box'
  },
  body: {
    padding: '10px 40px',
    maxHeight: '75vh',
    overflow: 'auto',
    boxSizing: 'border-box'
  },
  input: {
    outline: 'none',
    borderBottom: `1px solid ${slateGrey}`,
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    backgroundColor: 'transparent',
    padding: 10,
    marginBottom: 20,
    fontSize: 20,
    width: '80%',
    boxSizing: 'border-box',
    color: charcoal,
    display: 'block'
  },
  select: {
    color: charcoal,
    fontSize: 20,
    outline: 'none',
    width: '100%',
    marginBottom: 20
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 40px 20px'
  },
  button: {
    fontSize: 22,
    fontWeight: 600,
    color: scribeGreen,
    padding: '10px 20px',
    cursor: 'pointer',
    transition: '0.2s',
    borderRadius: 2,
    marginLeft: 6,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none'
  },
  disabled: {
    color: demoGrey,
    cursor: 'not-allowed'
  },
  searchBtnDiv: {
    display: 'flex',
    flexDirection: 'row'
  },
  searchBtn: {
    fontSize: 18,
    fontWeight: 600,
    color: scribeGreen,
    padding: '10px 20px',
    cursor: 'pointer',
    transition: '0.2s',
    borderRadius: 2,
    marginLeft: 6,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    width: '20%'
  }
}

class LinkEMRForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchedUsers: [],
      searchQuery: '',
      ...props.initialValues
    }
  }

  handleSearch () {
    const { searchQuery } = this.state
    const { emrOrgId, currentOrgId } = this.props

    this.setState({
      isSearching: true
    })

    if (searchQuery.length > 0) {
      client
        .query({
          query: SearchEMRUsers,
          variables: {
            organizationId: currentOrgId,
            query: searchQuery,
            emrOrgId: emrOrgId
          }
        })
        .then(({ data }) => {
          const emrUsers = get(data, 'searchEMRUsers.users')
          let firstUserValue = ''
          let fhirUserId = ''
          if (emrUsers.length > 0) {
            const { emrUserId, emrDepartmentId, emrUsername, emrUserIdList } = emrUsers[0]
            fhirUserId = emrUsers[0].fhirUserId
            firstUserValue = `${emrUserId}:${emrDepartmentId}:${emrUsername}:${emrUserIdList}`
          }
          this.setState({
            searchedUsers: emrUsers,
            isSearching: false,
            fhirUserId,
            emrIds: firstUserValue
          })
        })
        .catch(e => {
          this.setState({
            searchedUsers: [],
            isSearching: false
          })
          console.error(e)
        })
    } else {
      this.setState({
        searchedUsers: [],
        isSearching: false
      })
    }
  }

  handleSearchChange (e) {
    this.setState({
      searchQuery: e.target.value
    })
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  render () {
    const { toggleForm, handleSubmit, TIMEZONES } = this.props
    const initialValues = {
      emrIds: this.state.emrIds,
      fhirUserId: this.state.fhirUserId,
      timezone: this.state.timezone
    }

    const { isSearching } = this.state

    return (
      <div style={styles.outerContainer} onClick={toggleForm}>
        <div style={styles.formContainer} onClick={e => e.stopPropagation()}>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            enableReinitialize
          >
            {({ values, isSubmitting }) => (
              <Form>
                <div style={styles.header}>Link EMR User</div>
                <div style={styles.body}>
                  <div style={styles.searchBtnDiv}>
                    <Field
                      name='search'
                      type='text'
                      placeholder='Type to Search ...'
                      style={styles.input}
                      onChange={this.handleSearchChange.bind(this)}
                    />
                    <Field
                      name='searchbtn'
                      type='button'
                      value={isSearching ? 'Searching ...' : 'Search'}
                      style={styles.searchBtn}
                      onClick={this.handleSearch.bind(this)}
                    />
                  </div>

                  {this.state.searchedUsers.length > 0 && (
                    <div>
                      Pick EMR User
                      <Field
                        name='emrIds'
                        component='select'
                        placeholder='EMR User'
                        style={styles.select}
                      >
                        {this.state.searchedUsers.map(searchedUser => (
                          <option
                            key={searchedUser.emrUserId}
                            value={`${searchedUser.emrUserId}:${searchedUser.emrDepartmentId}:${searchedUser.emrUsername}:${searchedUser.emrUserIdList}`}
                          >
                            {searchedUser.emrLastName},{' '}
                            {searchedUser.emrFirstName} (Username:{' '}
                            {searchedUser.emrUsername}, Dept Name:{' '}
                            {searchedUser.emrDepartmentName} Dept ID:{' '}
                            {searchedUser.emrDepartmentId}, User ID:{' '}
                            {searchedUser.emrUserId})
                          </option>
                        ))}
                      </Field>
                    </div>
                  )}
                  {this.state.searchedUsers.length > 0 && (
                    <div>
                      Timezone of the user
                      <Field
                        name='timezone'
                        component='select'
                        placeholder='America/Los Angeles'
                        style={styles.select}
                        onChange={this.handleChange}
                      >
                        {TIMEZONES.map(tz => (
                          <option key={tz.value} value={tz.value}>
                            {tz.value}
                          </option>
                        ))}
                      </Field>
                    </div>
                  )}
                </div>

                <div style={styles.footer}>
                  <div style={{ display: 'flex' }}>
                    <div
                      key='cancel'
                      style={
                        isSubmitting
                          ? { ...styles.button, ...styles.disabled }
                          : styles.button
                      }
                      onClick={toggleForm}
                      disabled={isSubmitting}
                    >
                      CANCEL
                    </div>
                    <button
                      key='submit'
                      type='submit'
                      style={
                        isSubmitting
                          ? { ...styles.button, ...styles.disabled }
                          : styles.button
                      }
                      disabled={isSubmitting}
                    >
                      LINK
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

export default Radium(LinkEMRForm)
