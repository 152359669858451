import styled from 'styled-components'
import { charcoal, whiteSmoke, slateGrey, accentColor, modalOverlay } from '../../styles/colors'

const handleButtonType = type => {
  switch (type) {
    case 'primary':
      return 'color: #fff; background-color: #428bca;'
    case 'danger':
      return 'color: #fff; background-color: #d9534f'
    default:
      return 'color: #333, background-color: #fff'
  }
}

export const FormContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background: ${whiteSmoke};
  border-radius: 4px;
  box-shadow: 0px 2px 8px ${modalOverlay};
  margin-bottom: ${props => !props.last && '15px'};
`

export const InputItem = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  font-weight: bold;

  input {
    padding: 10px;
    font-size: 12px;
    margin-top: 5px;
  }
`

export const Button = styled.button`
  margin: 0.2em 0.1em;
  font-weight: 700;
  font-size: 12px;
  border: none;
  border-radius: 3px;
  padding: 0.65em 1.3em;
  ${({ type }) => handleButtonType(type)};
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.5;
  }
`

export const FormControls = styled.div`
  display: flex;
  justify-content: space-between;
`

export const EditButton = styled.div`
  color: ${whiteSmoke};
  padding: 10px;
  border-radius: 20px;
  background-color: ${accentColor};
  box-shadow: 0px 0px 5px ${modalOverlay};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 12;
  cursor: pointer;
  opacity: 1;
  transition: 0.2s;
  fontSize: 12px;

  &:hover {
    opacity: 0.85;
  }

  &:active: {
    transform: scale(0.95);
  }

  & * > {
    padding: 0px 5px;
  }
`
/* Potentialy refactor this to util if used more */
export const LinkText = styled.span`
  color: ${slateGrey};

  &:hover {
    color: ${charcoal};
  }

`
