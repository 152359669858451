import React from 'react'
import { compose, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import Radium from 'radium'
import get from 'lodash.get'
import AppointmentsRangePicker from './AppointmentsRangePicker'
import ReprocessEncounters from './ReprocessEncounters'
import {
  GetEMRSectionsForOrg,
  GetSections,
  GetNoteTypes,
  GetPendingUsers
} from '@sukiai/gql/admin'
import { whiteSmoke, charcoal, platinum } from '../../styles/colors'
import { ROUTES } from '../../lib/constants'
import { copyText, showReleaseAllNotes, releaseAllNotesBackdoor } from '../../lib/util'

const styles = {
  name: {
    fontSize: 26,
    color: whiteSmoke,
    fontWeight: 600,
    marginBottom: 24,
    height: 36,
    display: 'flex',
    alignItems: 'center'
  },
  editButton: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    backgroundColor: 'rgba(245, 247, 250, 0.30)',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12,
    cursor: 'pointer',
    opacity: 1,
    transition: '0.2s',
    ':hover': {
      opacity: 0.85
    },
    ':active': {
      transform: 'scale(0.95)'
    }
  },
  linkContainer: {
    width: '35%',
    backgroundColor: whiteSmoke,
    padding: '10px 0',
    boxShadow: '0 0 10px rgba(0,0,0,0.5)',
    overflow: 'hidden'
  },
  link: {
    fontSize: 20,
    color: charcoal,
    padding: '16px 28px',
    cursor: 'pointer',
    marginLeft: '-1%',
    marginRight: '-1%',
    transition: '0.2s',
    ':hover': {
      backgroundColor: '#C0C0C0'
    },
    ':active': {
      transform: 'scale(0.98)'
    }
  },
  reprocessButton: {
    height: 50,
    width: 140,
    backgroundColor: platinum,
    color: charcoal,
    fontSize: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 15px rgba(0,0,0,0.5)',
    borderRadius: 2,
    cursor: 'pointer',
    transition: '0.2s',
    ':active': {
      transform: 'scale(0.98)',
      boxShadow: '0px 0px 5px rgba(0,0,0,0.4)'
    }
  },
  subheader: {
    color: whiteSmoke,
    width: 'fit-content'
  },
  clickable: {
    cursor: 'pointer'
  }
}

const preloadSections = (client, organizationId) => {
  client.query({
    query: GetEMRSectionsForOrg,
    variables: { organizationId }
  })

  client.query({
    query: GetSections,
    variables: { organizationId, withMapping: true }
  })
}

const preloadNoteTypes = (client, organizationId) => {
  client.query({
    query: GetNoteTypes,
    variables: { organizationId }
  })
}

const preloadPendingUsers = (client, organizationId) => {
  client.query({
    query: GetPendingUsers,
    variables: { organizationId }
  })
}

const handleReleaseAllNotes = () => {
  if (window.confirm('Are you sure you want to release all notes from ops queue?')) {
    releaseAllNotesBackdoor()
  }
}

const OrgLinks = props => {
  const handleInviteNewUser = () => {
    props.toggleNewInviteForm(true)
  }

  const { currentOrg, history, client } = props
  const hasEMR = !!get(currentOrg, 'emr.id')

  return currentOrg ? (
    <div>
      <div style={styles.linkContainer}>
        <div
          key='users'
          data-cy='users'
          style={styles.link}
          onClick={() => history.push(`/${currentOrg.id}/${ROUTES.USERS}`)}
        >
          Users
        </div>
        <div
          key='notetypes'
          data-cy='notetypes'
          style={styles.link}
          onClick={() => history.push(`/${ROUTES.NOTE_TYPES}/${currentOrg.id}`)}
          onMouseEnter={() => preloadNoteTypes(client, currentOrg.id)}
        >
          Note Types
        </div>
        <div
          key='preferences'
          data-cy='preferences'
          style={styles.link}
          onClick={() => history.push(`${ROUTES.PREFERENCES}/${currentOrg.id}`)}
        >
          Preferences
        </div>
        <div
          key='sections'
          data-cy='sections'
          style={styles.link}
          onClick={() => history.push(`${currentOrg.id}/${ROUTES.SECTIONS}`)}
          onMouseEnter={() => preloadSections(client, currentOrg.id)}
        >
          Sections
        </div>
        {hasEMR && (
          <div
            key='emr'
            data-cy='emr'
            style={styles.link}
            onClick={() => history.push(`${currentOrg.id}/${ROUTES.EMR}`)}
          >
            EMR
          </div>
        )}
        {showReleaseAllNotes(currentOrg.id) &&
          <div
            key='releaseAllNotes'
            data-cy='release-all-notes'
            style={styles.link}
            onClick={handleReleaseAllNotes}
          >
              Release All Locked Notes
          </div>}
      </div>

      <div style={{ ...styles.linkContainer, ...{ marginTop: '25px' } }}>
        <div
          key='inviteNewUser'
          data-cy='invite-new-user'
          style={styles.link}
          onClick={handleInviteNewUser}
        >
              Invite New User
        </div>
        <div
          key='pendingUser'
          data-cy='pending-user'
          style={styles.link}
          onClick={() => history.push(`/${currentOrg.id}/${ROUTES.PENDING_USERS}`)}
          onMouseEnter={() => preloadPendingUsers(client, currentOrg.id)}
        >
              Pending Users
        </div>
      </div>

      {hasEMR && (
        <div>
          <h3
            style={{ ...styles.subheader, ...styles.clickable }}
            onClick={() => copyText(get(currentOrg, 'emr.id', ''))}
          >
            EMR ID: {currentOrg?.emr?.id || 'Unknown'} (
            {currentOrg?.emr?.type || 'Unknown'} / {currentOrg?.emr?.secondaryType || 'Unknown'})
          </h3>
          <div>
            <h3 style={styles.subheader}>Refresh EMR Appointments</h3>
            <AppointmentsRangePicker currentOrg={currentOrg} />
          </div>
          <div>
            <h3 style={styles.subheader}>Reprocess Existing EMR Encounters</h3>
            <ReprocessEncounters currentOrg={currentOrg} />
          </div>
        </div>
      )}
    </div>
  ) : null
}

export default compose(withRouter, withApollo, Radium)(OrgLinks)
