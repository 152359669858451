import gql from 'graphql-tag'

// USED IN: APPT_MANAGER
export const CreatePatient = gql`
  mutation CreateOrUpdatePatient($organizationId: ID!, $dateOfBirth: String, $gender: Gender, $firstName: String!, $middleName: String, $lastName: String!, $prefix: String, $suffix: String, $mrn: String) {
    createOrUpdatePatient(input: {
      organizationId: $organizationId,
      person: {
        dateOfBirth: $dateOfBirth,
        gender: $gender,
        firstName: $firstName,
        middleName: $middleName,
        lastName: $lastName,
        prefix: $prefix,
        suffix: $suffix
      },
      mrn: $mrn
    }) {
      patient {
        id
      }
    }
  }
`

// USED IN: APPT_MANAGER
export const GetPatients = gql`
  query GetPatients($organizationId: ID!, $ids: [ID!]) {
    patients(input: {
      organizationId: $organizationId,
      ids: $ids
    }) {
      results {
        id,
        person {
          dateOfBirth,
          gender,
          firstName,
          middleName,
          lastName,
          prefix,
          suffix
        }
      }
    }
  }
`
