import {
  SET_USER_ID,
  SET_USER_NOTES_PANEL_FILTER,
  SET_ORG_ID,
  SET_COMMAND_TYPE,
  SET_PREF_ID
} from '../actions/admin'

const initialState = {
  userId: null,
  userNotesPanelFilter: 'COMPOSITIONS',
  orgId: null,
  command: null,
  prefId: null
}

const admin = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ID:
      return { ...state, userId: action.userId }
    case SET_USER_NOTES_PANEL_FILTER:
      return { ...state, userNotesPanelFilter: action.filter }
    case SET_ORG_ID:
      return { ...state, orgId: action.orgId }
    case SET_COMMAND_TYPE:
      return { ...state, command: action.command }
    case SET_PREF_ID:
      return { ...state, prefId: action.prefId }
    default:
      return state
  }
}

export default admin
