import React from 'react'
import { USER_PERSONA, EDITOR_TYPE } from '../lib/constants'
import Editor, { V2_DEFAULT_CONTENT } from '@sukiai/quill-editor'

// Content editor for Slate.js
// Compatible with Formik
export default class ContentEditor extends React.Component {
  state = {
    initialContent: this.props.field.value
  }

  shouldComponentUpdate (nextProps) {
    if (nextProps.content !== this.props.content) return false
    return true
  }

  render () {
    const {
      readOnly,
      field: { name },
      form: { setFieldValue },
      editorType,
      enableChips
    } = this.props
    const { initialContent } = this.state

    return (
      <Editor
        defaultValue={initialContent || V2_DEFAULT_CONTENT}
        onChange={newValue => setFieldValue(name, newValue)}
        persona={USER_PERSONA.ADMIN}
        editorType={editorType || EDITOR_TYPE.NOTE}
        readOnly={readOnly}
        enableChips={enableChips || false}
      />
    )
  }
}
