import jwtDecode from 'jwt-decode'

export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'

export const userLogin = jwtToken => {
  const decoded = jwtDecode(jwtToken)

  // TODO: this could just be a single object with the user payload
  return {
    type: USER_LOGIN,
    email: decoded.email
  }
}

export const userLogout = () => ({
  type: USER_LOGOUT
})
