export const MODAL_BUTTONS = {
  CONTINUE: 'Continue Editing',
  DONE: 'I\'m Done',
  DELETE: 'Delete',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  START_NOTE: 'Start Note',
  UNIFINISHED_NOTES: 'Save to Unfinished Notes',
  SUBMIT_PDF: 'Submit as PDF',
  INSERT: 'Insert',
  EDIT: 'Edit',
  BACK: 'Back',
  CHANGE_CODE: 'Change Code',
  REMOVE_CODE: 'Remove Code',
  PRINT: 'Print',
  OK: 'OK'
}
