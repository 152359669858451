const COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: (a, b) => {
      return a > b ? 1 : -1
    }
  },
  {
    title: 'ID',
    dataIndex: 'id'
  }
]

export { COLUMNS }
