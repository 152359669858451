import gql from 'graphql-tag'

export const DeleteUserObliviate = gql`
  mutation DeleteUserObliviate($organizationId: String, $userIds: [String!]!, $purge: Boolean) {
    deleteUserObliviate(input: { 
      organizationId: $organizationId,
      userIds: $userIds,
      purge: $purge
    }) {
      serviceDataDeletionStatus{
        serviceName
        serviceDataDeletionStatus
      }
    }
  }
`

export const DeleteOrganizationObliviate = gql`
  mutation DeleteUserObliviate($orgIds: [String!]!, $purge: Boolean)  {
    deleteOrganizationObliviate(input: { 
      orgIds: $orgIds,
      purge: $purge
    }) {
      serviceDataDeletionStatus{
        serviceName
        serviceDataDeletionStatus
      }
    }
  }
`
