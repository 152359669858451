import React, { Component } from 'react'
import { Animated, Easing } from 'react-native'
import {
  SidebarContainer,
  PatientName,
  SectionsContainer,
  SectionContainer,
  SectionName,
  EMRBadge
} from './styled'
import { getFullName } from '../../lib/util'

class NoteSidebar extends Component {
  constructor (props) {
    super(props)

    this.state = {
      sidebarAnim: new Animated.Value(0),
      sections: props.initialSections
    }
  }

  componentDidMount () {
    Animated.timing(this.state.sidebarAnim, {
      toValue: 1,
      duration: 200,
      easing: Easing.linear
    }).start()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.sectionsToUpdate) {
      const newSections = this.state.sections
      nextProps.sectionsToUpdate.forEach(stu => {
        const idx = newSections.findIndex(s => s.id === stu.id)
        if (idx > -1) newSections[idx].status = stu.status
      })
      this.setState({ sections: newSections })
    }
  }

  render () {
    const {
      modalType,
      currentSectionId,
      setCurrentSection,
      patient
    } = this.props

    const vw = window.innerWidth
    const vh = window.innerHeight

    const { sections } = this.state

    const sectionsArray = sections && sections.map(s => (
      <div id = {`section-container-${s.id}`}>
      <SectionContainer
        key={s.id}
        current={(s.id === currentSectionId).toString()}
        onPress={() => setCurrentSection(s.id)}
        data-cy={`note-sidebar-section-${s.name.replace(/\W/g, '-').toLowerCase()}`}
      >
        <SectionName>
          {s.name}
        </SectionName>
        {s.source === 'EMR' && <EMRBadge>EMR</EMRBadge>}
      </SectionContainer>
      </div>
    ))

    const styles = {
      container: {
        transform: [
          {
            translateX: this.state.sidebarAnim.interpolate({
              inputRange: [0, 1],
              outputRange: ['-100%', '0%']
            })
          }
        ]
      }
    }

    return (
      <SidebarContainer style={styles.container} className='sidebar-container' vw={vw} modal={modalType}>
        <PatientName data-cy='note-sidebar-name'>
          {patient && patient.person && getFullName(patient.person)}
        </PatientName>
        <SectionsContainer vh={vh}>
          {sectionsArray}
        </SectionsContainer>
      </SidebarContainer>
    )
  }
}

export default NoteSidebar
