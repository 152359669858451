import styled from 'styled-components/native'
import reactStyled, { css } from 'styled-components'
import { Animated } from 'react-native'
import {
  black,
  casal,
  charcoal,
  scribeGreen,
  snowyDrift,
  platinum,
  fulvous,
  westSide,
  white
} from '../../lib/colors'
import { LISTS, DIMENSIONS, Z_INDEX } from '../../lib/constants'
import newNoteButton from '../../assets/icons/new-note-button.svg'

const {
  LIST_HEADER,
  LIST_CELL_ONE,
  LIST_DELIMITER
} = DIMENSIONS

export const ListContainer = styled(Animated.View)`
  position: ${props => props.isSmallWindow ? 'static' : 'fixed'};
  ${props => !props.isSmallWindow &&
    css`
      top: ${props => ((props.calcheight + LIST_HEADER) <= props.vh - (props.marginTop * 2)) && props.type !== LISTS.SCHEDULE
        ? (props.vh - (props.calcheight + LIST_HEADER)) / 2
        : props.marginTop
      };
      left: ${props => props.isCrampedWindow ? '40vw' : '50vw'};
      min-width: 475;
      width: 35vw;
    `
  }
  ${props => props.isSmallWindow &&
    css`
      margin-top: ${props => ((props.calcheight + LIST_HEADER) <= props.vh - (props.marginTop * 2)) && props.type !== LISTS.SCHEDULE
        ? (props.vh - (props.calcheight + LIST_HEADER)) / 2
        : props.marginTop
      };

      /*  center with marginLeft: (100vw / 2) - (container / 2) */
      margin-left: 10vw;
      width: 80vw;
    `
  }
  box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
  transition-duration: 0.2s;
  z-index: ${Z_INDEX.LIST};
`

export const ListHeader = styled.View`
  width: 100%;
  background-color: ${snowyDrift};
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.type === LISTS.SCHEDULE
    ? 'space-around'
    : 'center'
  };
`

export const TitleWrapper = styled.TouchableOpacity.attrs({
  activeOpacity: 0.7
})`

`

export const Title = styled.Text`
  text-align: center;
  color: ${casal};
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 22;
  height: 90;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.type === LISTS.SCHEDULE
    ? props.vw * 0.30 - 180
    : props.vw * 0.35 - 140
  };
  min-width: ${props => props.type === LISTS.SCHEDULE
    ? 475 - 180
    : 475 - 140
  };
`

export const ButtonWrapper = styled.TouchableOpacity.attrs({
  activeOpacity: 0.7
})`
  position: absolute;
  right: 15;
`

export const NewNoteButton = styled.Image.attrs({
  source: newNoteButton
})`
  height: 40;
  width: 40;
  box-shadow: 0px 0px 6px rgba(0,0,0,0.7);
  border-radius: 50%;
`

export const CellWrapper = styled.TouchableOpacity.attrs({
  activeOpacity: 0.7
})`
  width: 100%;
`

export const ListCell = styled.View`
  width: 100%;
  background-color: ${white};
  padding: 30px;
  padding-left: ${props => props.type === LISTS.UNFINISHED_NOTES
    ? 60
    : 30
  };
  padding-right: 8px;
  cursor: pointer;
`

export const CellRow = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30;
`

export const CellText = styled.Text`
  font-family: 'Open Sans';
  color: ${charcoal};
  font-size: 18;
`

export const RightText = styled(CellText)`
  margin-right: 70px;
`

export const NameText = styled(CellText)`
  font-weight: 600;
  font-size: 20;
  color: ${props => props.isnameunknown === 'true' ? fulvous : charcoal};
`

export const NewText = styled(NameText)`
  color: ${scribeGreen};
`

export const StatusIcon = styled.Image`
  height: ${props => props.height ? props.height : 30};
  width: ${props => props.width ? props.width : 30};
`

export const IconSection = styled.View`
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  right: 0;
  width: 60px;
`

export const IconContainer = styled.View`
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-right: ${props => props.secondary === 'true'
    ? -4
    : 0
  };
`

export const ExclamationPoint = styled.Text`
  color: ${fulvous};
  font-size: 28px;
  font-weight: bold;
`

export const Divider = styled.View`
  background-color: ${platinum};
  height: 1;
`

export const ListBody = styled.View`
  width: 100%;
  min-height: ${props => props.type === LISTS.UNFINISHED_NOTES
    ? LIST_DELIMITER + (LIST_CELL_ONE * 3) + 2
    : props.type === LISTS.PATIENT_NOTES
      // PRIOR
      ? (LIST_CELL_ONE * 3) + 2
      // SCHEDULE
      : props.vh - props.marginTop - 90
  };
  background-color: ${white};
  display: flex;
  align-items: center;
  justify-content: ${props => props.center === 'true' && !props.fullHeight
    ? 'center'
    : 'flex-start'
  };
  max-height: ${props => props.vh - props.marginTop - 90};
  overflow: auto;
  transition-duration: 0.2s;
  height: ${props => props.calcheight};
`

export const EmptyText = styled(NameText)`
  text-align: center;
  font-size: 26;
`

export const Loading = styled.ActivityIndicator.attrs({
  size: 'large',
  color: fulvous
})`

`

export const ArrowTouchable = styled.TouchableOpacity.attrs(props => ({
  activeOpacity: props.active === 'true' ? 0.6 : 0.2
}))`
  height: 90;
  width: 90;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.active === 'true'
    ? 1
    : 0.5
  };
  transition-duration: 0.5s;
`

export const ArrowIcon = styled.Image`
  height: 35.25;
  width: 21;
`

export const DownCaret = styled.Image`
  margin-top: 1;
  height: 8;
  width: 14;
  color: ${casal};
`

export const DayText = styled.Text`
  position: absolute;
  bottom: 7;
  color: ${casal};
  font-family: 'Open Sans';
  opacity: 0.8;
  font-size: 16;
`

export const PDFBadge = styled.Text`
  background-color: ${westSide};
  width: 40px;
  padding: 4px 10px;
  border-radius: 20;
  color: ${black};
  font-family: 'Open Sans';
  font-size: 10;
  font-weight: 600;
`

export const Delimiter = styled.Text`
  font-family: 'Open Sans';
  font-size: 22;
  padding: 20px 30px 0px;
  color: ${charcoal};
`

export const UnfinishedWrapper = reactStyled.div`
  width: 100%;
`

export const PatientInfo = reactStyled.div`
  flex-grow: 1;
`

export const EmrPill = styled.Text`
  background-color: ${westSide};
  padding: 4px 10px;
  border-radius: 20;
  color: ${black};
  font-family: 'Open Sans';
  font-size: 10;
  font-weight: 600;
  margin-right: -3;
`
