import React, { Component } from 'react'
import Radium from 'radium'
import { MetadataHistorySlider } from './index'
import { navBarHeight } from '../../styles/dimensions'
import { listHeaderBrown, whiteSmoke } from '../../styles/colors'
import { withRouter } from 'react-router-dom'

const styles = {
  button: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: 'white',
    padding: '10px 30px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: 'color 0.2s',
    ':hover': {
      color: 'rgba(255,255,255,0.75)'
    }
  },
  modalContainer: {
    position: 'fixed',
    top: `calc(${navBarHeight} + 42)`,
    right: 100,
    zIndex: 100,
    width: 600,
    boxShadow: '0px 0px 6px rgba(0,0,0,0.5)',
    overflow: 'scroll',
    backgroundColor: whiteSmoke
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: listHeaderBrown,
    fontSize: 22,
    height: 75
  }
}

class MetadataHistoryPanel extends Component {
  state = {
    open: false,
    modalText: '',
    currentHintId: null
  }

  handleToggleModal = () => {
    this.setState({ open: !this.state.open })
  }

  render () {
    const { versions } = this.props
    const { open } = this.state
    if (!versions) return null

    const buttonStyle = open ? { ...styles.button, backgroundColor: listHeaderBrown } : styles.button

    return (
      <div>
        <div
          onClick={this.handleToggleModal}
          style={buttonStyle}
          data-cy='metadata-history-panel-header'
        >
          Metadata History
        </div>
        {open && (
          <div style={styles.modalContainer}>
            <div style={styles.modalHeader}>
              Metadata
            </div>
            <MetadataHistorySlider versions={versions} />
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(Radium(MetadataHistoryPanel))
