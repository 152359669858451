import React, { Component } from 'react'
import AudioControl from './AudioControl'
import PlayArrowIcon from 'material-ui/svg-icons/av/play-arrow'
import DropDownIcon from 'material-ui/svg-icons/image/details'
import { scribeGreen, adminHeaderGrey } from '../../styles/colors'
import { MIN_AUDIO_DURATION } from '../../lib/constants'
import PropTypes from 'prop-types'

const FILTERED_AUDIO_LABEL = `Filtered audio (< ${MIN_AUDIO_DURATION}ms):`

const styles = {
  playbackList: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%'
  },
  playbackItems: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '40px',
    width: '100%',
    marginBottom: '10px'
  },
  playbackItem: {
    border: `2px solid ${scribeGreen}`,
    width: '40px',
    height: '40px',
    marginRight: '4px'
  },
  audioPlayer: {
    border: `2px solid ${scribeGreen}`,
    width: '100%'
  },
  icon: {
    padding: '8px',
    color: scribeGreen
  },
  filteredAudioLabel: {
    color: adminHeaderGrey
  }
}

class PlaybackList extends Component {
  state = {
    shownIndex: -1
  }

  onItemSelected (idx) {
    if (idx > -1) {
      this.setState(oldState => ({
        shownIndex: oldState.shownIndex !== idx ? idx : -1
      }))
    }
  }

  render () {
    const { shownIndex } = this.state
    const {
      audioTranscripts,
      sectionId,
      isCurrentSection,
      enableAudioDownload
    } = this.props

    const filteredAudioTranscripts = audioTranscripts.filter(t => t.duration > MIN_AUDIO_DURATION)
    const numFilteredAudio = audioTranscripts.length - filteredAudioTranscripts.length

    return (
      <div style={styles.playbackList}>
        <div style={styles.playbackItems}>
          {filteredAudioTranscripts.map((a, idx) => (
            <div
              className='audio-dropdown'
              key={`transcript-${idx}`}
              style={styles.playbackItem}
              onClick={this.onItemSelected.bind(this, idx)}
            >
              {shownIndex === idx
                ? <DropDownIcon style={styles.icon} />
                : <PlayArrowIcon style={styles.icon} />}
            </div>
          ))}
        </div>
        {numFilteredAudio > 0 &&
          <div style={styles.filteredAudioLabel}>{FILTERED_AUDIO_LABEL} {numFilteredAudio}</div>}
        {filteredAudioTranscripts.map((a, idx) => idx === shownIndex ? (
          <AudioControl
            key={`audio-control-${sectionId}-${idx}`}
            wavesurferId={`wavesurfer-${sectionId}-${idx}`}
            src={a.audioUri}
            isCurrentSection={isCurrentSection}
            enableAudioDownload={enableAudioDownload}
          />
        ) : null)}
      </div>
    )
  }
}

PlaybackList.defaultProps = {
  audioTranscripts: []
}

PlaybackList.propTypes = {
  audioTranscripts: PropTypes.array
}

export default PlaybackList
