import gql from 'graphql-tag'

// USED IN: APPT_MANAGER
export const CreateAppointment = gql`
  mutation CreateOrUpdateAppointment($organizationId: ID!, $ownerId: String!, $patientId: String!, $startsAt: String!, $endsAt: String!, $type: AppointmentType, $medicalProcedure: String, $reason: String) {
    createOrUpdateAppointment(input: {
      organizationId: $organizationId,
      appointment: {
        ownerId: $ownerId,
        patientId: $patientId,
        startsAt: $startsAt,
        endsAt: $endsAt,
        type: $type,
        medicalProcedure: $medicalProcedure,
        reason: $reason
      }
    }) {
      appointment {
        id
      }
    }
  }
`

// USED IN: APPT_MANAGER
export const GetAppointments = gql`
  query GetAppointments($organizationId: ID!, $ownerId: ID!, $startsAtRangeBeginning: String!, $startsAtRangeEnd: String!) {
    appointments(input: {
      organizationId: $organizationId,
      ownerId: $ownerId,
      startsAtRangeBeginning: $startsAtRangeBeginning,
      startsAtRangeEnd: $startsAtRangeEnd
    }) {
      results {
        id,
        startsAt,
        endsAt,
        type,
        patient {
          id,
          person {
            firstName,
            middleName,
            lastName,
            dateOfBirth,
            gender
          }
        }
      }
    }
  }
`
