import React from 'react'
import CircularProgress from 'material-ui/CircularProgress'
import { fulvous } from '../styles/colors'

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100%',
    width: '100%'
  }
}

const Loading = ({ size, thickness, color }) => (
  <div style={styles.container}>
    <CircularProgress
      size={size || 48}
      thickness={thickness || 4}
      color={color || fulvous}
    />
  </div>
)

export default Loading
