import React, { Component } from 'react'
import { whiteSmoke, platinum, charcoal } from '../../styles/colors'
import Radium from 'radium'
import { ActivateUser, GetUsers, ResetPassword } from '@sukiai/gql/admin'
import { graphql, compose } from 'react-apollo'
import { notifySuccess, setError } from '../../lib/util'

const styles = {
  container: {
    width: '100%',
    marginBottom: 20,
    backgroundColor: platinum,
    boxShadow: '2px 2px 8px rgba(0,0,0,0.5)',
    padding: '20px 30px',
    boxSizing: 'border-box'
  },
  activateButton: {
    width: '100%',
    height: 60,
    backgroundColor: charcoal,
    boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
    cursor: 'pointer',
    color: whiteSmoke,
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.2s',
    ':active': {
      transform: 'scale(0.98)'
    },
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    boxSizing: 'border-box'
  },
  resetPasswordButton: {
    width: '100%',
    height: 60,
    backgroundColor: charcoal,
    boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
    cursor: 'pointer',
    color: whiteSmoke,
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.2s',
    ':active': {
      transform: 'scale(0.98)'
    },
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    boxSizing: 'border-box'
  }
}

class ActivatePanel extends Component {
  state = {
    loading: false
  }

  handleActivate = () => {
    const {
      activateUser,
      orgId,
      userId
    } = this.props

    this.setState({ loading: true })

    activateUser({
      variables: {
        organizationId: orgId,
        id: userId
      },
      refetchQueries: [{
        query: GetUsers,
        variables: { organizationId: orgId }
      }]
    }).then(({ data: { id } }) => {
      this.setState({ loading: false })
      console.info('Activation email sent for ID:', id)
      notifySuccess('Activation email sent')
    }).catch(error => {
      this.setState({ loading: false })
      console.error('Error sending activation email:', error)
      setError(error)
    })
  }

  // resetPassword = () => {
  //   const {
  //     resetPassword,
  //     userId,
  //     orgId
  //   } = this.props
  //
  //   this.setState({ loading: true })
  //
  //   resetPassword({
  //     variables: {
  //       organizationId: orgId,
  //       userId
  //     },
  //     refetchQueries: [{
  //       query: GetUsers,
  //       variables: { organizationId: orgId }
  //     }]
  //   }).then(({ data: { userId } }) => {
  //     this.setState({ loading: false })
  //     console.info('Reset password email sent for ID:', userId)
  //     notifySuccess('Reset password email sent')
  //   }).catch(error => {
  //     this.setState({ loading: false })
  //     console.error('Error sending reset password email:', error)
  //     setError(error)
  //   })
  // }

  render () {
    return (
      <div style={styles.container}>
        <div key='activate' style={styles.activateButton} onClick={this.handleActivate}>
          {this.state.loading ? <i>Activating...</i> : 'Activate in Okta'}
        </div>
        {/* TODO Implement this once `activationSent` isn't always null */}
        {/* <div key='reset' style={styles.resetPasswordButton} onClick={this.resetPassword}>
          {this.state.loading ? <i>Resetting...</i> : `Reset Password`}
        </div> */}
      </div>
    )
  }
}

export default compose(
  graphql(ActivateUser, { name: 'activateUser' }),
  graphql(ResetPassword, { name: 'resetPassword' }),
  Radium
)(ActivatePanel)
