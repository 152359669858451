import React, { Component } from 'react'
import { Animated, Easing } from 'react-native'
import { StyledVeil } from './styled'
import { isAmwellTheme } from '../../lib/util'

class Veil extends Component {
  state = {
    veilAnim: new Animated.Value(0),
    isAmwellDark: isAmwellTheme()
  }

  componentWillReceiveProps (nextProps) {
    Animated.timing(this.state.veilAnim, {
      toValue: nextProps.active ? 1 : 0,
      duration: 200,
      easing: Easing.linear
    }).start()
  }

  render () {
    const {
      dismiss,
      active,
      children,
      dark,
      disableClickToDismiss
    } = this.props

    const vw = window.innerWidth
    const vh = window.innerHeight

    const styles = active ? {
      veil: {
        backgroundColor: this.state.veilAnim.interpolate({
          inputRange: [0, 1],
          outputRange: ['rgba(255,255,255,0.1)', 'rgba(255,255,255,0.2)']
        }),
        cursor: 'default'
      },
      darkVeil: {
        backgroundColor: this.state.veilAnim.interpolate({
          inputRange: [0, 1],
          outputRange: ['rgba(1,1,1,0.5)', 'rgba(1,1,1,0.5)']
        }),
        cursor: 'default'
      }
    } : {}

    return (
      <div onClick={this.state.isAmwellDark || disableClickToDismiss ? () => {} : dismiss}>
        <StyledVeil active={active.toString()} style={dark ? styles.darkVeil : styles.veil} vw={vw} vh={vh}>
          {children}
        </StyledVeil>
      </div>
    )
  }
}

export default Veil
