import styled from 'styled-components'

import syncWhiteIcon from '../../images/sync-white.svg'
import warningIcon from '../../images/warning-icon.svg'
import checkIcon from '../../images/check-icon.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Button = styled.button`
  height: 30px;
  color: white;
  border-radius: 5px;
  border: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12;
  cursor: pointer;
  opacity: 1;
  transition: all 0.15s ease;

  &:hover {
    opacity: 0.85;
  }
`

export const RefreshText = styled.span`
  margin-left: 10px;
  font-size: 11px;
  opacity: .8;
`

export const ResultText = styled.span`
  margin-right: 5px;
  color: white;
`

export const getStatusStyling = status => {
  switch (status) {
    case true:
      return {
        bg: '#60CB9D',
        icon: checkIcon
      }
    case false:
      return {
        bg: '#DC5050',
        icon: warningIcon
      }
    default:
      return {
        bg: 'rgba(245, 247, 250, 0.3)',
        icon: syncWhiteIcon
      }
  }
}
