import React from 'react'
import ReactDOM from 'react-dom'
import Routes from './routes'
import store from './store'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import sukiMuiTheme from './styles/sukiMuiTheme'
import { Provider } from 'react-redux'
import { StyleRoot } from 'radium'
import * as serviceWorker from './serviceWorker'
import setup from './setup'
import 'regenerator-runtime/runtime.js'
import './styles.css'

setup()

ReactDOM.render(
  <StyleRoot>
    <MuiThemeProvider muiTheme={sukiMuiTheme}>
      <Provider store={store}>
        <React.StrictMode>
          <Routes />
        </React.StrictMode>
      </Provider>
    </MuiThemeProvider>
  </StyleRoot>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
