import React, { Component } from 'react'
import { connect } from 'react-redux'

import Notifications from 'react-notification-system-redux'
import { startRefreshTimer } from '../okta'

const style = {
  // The keys in the object below need to be in sync with any changes in
  // https://github.com/gor181/react-notification-system-redux
  // Do not change the names, unless there is a change in the parent library itself
  NotificationItem: {
    DefaultStyle: {
      margin: '10px 5px 20px 5px',
      color: '#fff',
      border: 'none',
      backgroundColor: '#888',
      fontSize: '14px',

      borderRadius: 0
    }
  }
}

class NotificationsOverlay extends Component {
  componentDidMount () {
    // Start refresh token timer on the notifications overlay, which is always on
    startRefreshTimer()
  }

  render () {
    const { notifications } = this.props

    return (
      <Notifications
        notifications={notifications}
        style={style}
      />
    )
  }
}

export default connect(({ notifications }) => ({ notifications }))(NotificationsOverlay)
