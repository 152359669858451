import React, { Component } from 'react'
import Radium from 'radium'
import { OrgLinks } from './index'
import { copyText, notifySuccess, setError } from '../../lib/util'
import { whiteSmoke, fulvous } from '../../styles/colors'
import editPencil from '../../images/edit-pencil.svg'
import { OpsAccessControl, EnhancedReviewBox } from '../../containers'

import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import RaisedButton from 'material-ui/RaisedButton'
import client from '../../apollo'
import {
  GetLinkedNvoqOrganizations,
  LinkNvoqOrganization,
  createAndLinkAthenaOrganization,
  GetOrganizationById
} from '@sukiai/gql/admin'

import Loading from '../Loading'
import get from 'lodash.get'
import { EMR_TYPE } from '../../lib/constants'

const styles = {
  name: {
    fontSize: 26,
    color: whiteSmoke,
    fontWeight: 600,
    marginBottom: 24,
    height: 36,
    display: 'flex',
    alignItems: 'center'
  },
  editButton: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    backgroundColor: 'rgba(245, 247, 250, 0.30)',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12,
    cursor: 'pointer',
    opacity: 1,
    transition: '0.2s',
    ':hover': {
      opacity: 0.85
    },
    ':active': {
      transform: 'scale(0.95)'
    }
  },
  subheader: {
    color: whiteSmoke,
    width: 'fit-content'
  },
  clickable: {
    cursor: 'pointer'
  },
  selectField: {
    marginTop: 6,
    marginRight: 20
  },
  selected: {
    color: fulvous,
    fontWeight: 600
  },
  linkButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  input: {
    height: '28px',
    width: '40%',
    maxWidth: '235px',
    minWidth: '100px',
    padding: '3px 10px',
    margin: '20px 15px 20px 0',
    ':focus': {
      outline: 'none'
    }
  }
}

class OrgMainContent extends Component {
  state = {
    nvoqOrgToLink: null,
    isLinking: false,
    isLinkingAthena: false,
    athenaEmrOrganizationId: ''
  }

  handleAthenaEmrOrganizationIdChange = e => this.setState({ athenaEmrOrganizationId: e.target.value })

  handleUpdateNvoqOrgToLink = (event, idx, value) => {
    console.info(`Selected ${value}`)
    this.setState({
      nvoqOrgToLink: value
    })
  }

  handleNvoqOrgLink = () => {
    const { currentOrg } = this.props
    const { nvoqOrgToLink } = this.state

    this.setState({
      isLinking: true
    })
    if (nvoqOrgToLink && nvoqOrgToLink.length > 0) {
      client.mutate({
        mutation: LinkNvoqOrganization,
        variables: {
          organizationId: currentOrg.id,
          nvoqOrganizationId: nvoqOrgToLink
        },
        refetchQueries: [{
          query: GetLinkedNvoqOrganizations,
          variables: {
            organizationId: currentOrg.id
          }
        }]
      }).then(() => {
        notifySuccess('Successfully linked organization')
      }).catch((error) => {
        setError('Error linking Nvoq organization ' + JSON.stringify(error))
      }).finally(() => {
        this.setState({
          isLinking: false
        })
      })
    }
  }

  handleAthenaOrgLink = () => {
    const { currentOrg } = this.props
    const { athenaEmrOrganizationId } = this.state

    this.setState({
      isLinkingAthena: true
    })
    if (athenaEmrOrganizationId && athenaEmrOrganizationId.length > 0) {
      client.mutate({
        mutation: createAndLinkAthenaOrganization,
        variables: {
          sukiOrganizationId: currentOrg.id,
          emrOrganizationId: athenaEmrOrganizationId
        },
        refetchQueries: [{
          query: GetOrganizationById,
          variables: {
            id: currentOrg.id,
            withEmrInfo: true
          }
        }]
      }).then(({ data }) => {
        console.info('Successfully linked organization', data)
        notifySuccess('Successfully linked Athena organization')
        window.setTimeout(() => {
          this.setState({
            isLinkingAthena: false
          })
        }, 5000)
      }).catch(e => {
        this.setState({
          isLinkingAthena: false
        })
        setError(`Could not link Athena organization: ${e}`)
      })
    }
  }

  renderOrgHeader = (currentOrg, linkedNvoqOrgs, currentNvoqOrg, allNvoqOrgs, toggleForm, FORMS) => {
    const hasAthenaEMR = !!get(currentOrg, 'emr.id') && currentOrg?.emr?.type === EMR_TYPE.ATHENA
    const { athenaEmrOrganizationId, nvoqOrgToLink } = this.state

    return (
      <div>
        <div style={styles.name}>
          {currentOrg.name}
          <div
            style={styles.editButton}
            onClick={toggleForm.bind(this, FORMS.UPDATE_ORG, currentOrg.name)}
          >
            <img alt='edit' src={editPencil} height='50%' width='50%' />
          </div>
        </div>
        <h3
          style={{ ...styles.subheader, ...styles.clickable }}
          onClick={() => copyText(currentOrg.id)}
        >
        Suki ID: {currentOrg.id}
        </h3>
        {currentNvoqOrg
          ? (
            <h3
              title={currentNvoqOrg.nvoqOrganizationId}
              style={{ ...styles.subheader, ...styles.clickable }}
            >
              nVoq Organization: {currentNvoqOrg.nvoqOrganizationName}
            </h3>
          )
          : (allNvoqOrgs && allNvoqOrgs.length > 0
            ? (
              <div style={styles.linkButton}>
                <SelectField
                  value={nvoqOrgToLink}
                  onChange={this.handleUpdateNvoqOrgToLink}
                  disabled={!allNvoqOrgs}
                  style={styles.selectField}
                  selectedMenuItemStyle={styles.selected}
                  data-cy='nvoqlink-select-field'
                >
                  {allNvoqOrgs && allNvoqOrgs.map(o =>
                    <MenuItem key={o.nvoqOrganizationId} value={o.nvoqOrganizationId} primaryText={o.nvoqOrganizationName} />
                  )}
                </SelectField>
                <RaisedButton
                  key='link'
                  label={this.state.isLinking ? 'Linking ...' : 'Link nVoq Organization'}
                  primary
                  disabled={!nvoqOrgToLink}
                  onClick={() => {
                    window.confirm('Please confirm') && this.handleNvoqOrgLink()
                  }}
                />
              </div>
            )
            : undefined
          )}

        {hasAthenaEMR ? (
          <div>
            <h3
              style={{ ...styles.subheader, ...styles.clickable }}
              onClick={() => copyText(get(currentOrg, 'emr.id', ''))}
            >
                Linked to Athena tablespace: {currentOrg?.emr?.id || 'Unknown'}
            </h3>
          </div>
        ) : (
          <div style={styles.linkButton}>
            <input
              key='athenaInput'
              type='text'
              value={athenaEmrOrganizationId}
              onChange={this.handleAthenaEmrOrganizationIdChange}
              placeholder='Athena tablespace'
              style={styles.input}
            />
            <RaisedButton
              key='athenaLink'
              label={this.state.isLinkingAthena ? 'Linking ...' : 'Link to Athena organization'}
              primary
              disabled={!athenaEmrOrganizationId}
              onClick={() => {
                window.confirm(`Are you sure you want to link to this Athena org tablespace ${athenaEmrOrganizationId}?`) && this.handleAthenaOrgLink()
              }}
            />
          </div>
        )}
      </div>
    )
  }

  render () {
    const {
      currentOrg,
      loading,
      FORMS,
      toggleForm,
      toggleNewInviteForm,
      orgs,
      linkedNvoqOrgs,
      allNvoqOrgs
    } = this.props

    if (!currentOrg) return null
    if (loading) return <Loading />

    const currentNvoqOrg = linkedNvoqOrgs?.[0]

    return (
      <div>
        {currentOrg && this.renderOrgHeader(currentOrg, linkedNvoqOrgs, currentNvoqOrg, allNvoqOrgs, toggleForm, FORMS)}
        <OrgLinks
          FORMS={FORMS}
          currentOrg={currentOrg}
          toggleForm={toggleForm}
          toggleNewInviteForm={toggleNewInviteForm}
        />
        <OpsAccessControl
          orgs={orgs}
          currentOrg={currentOrg}
        />
        <EnhancedReviewBox
          reviewLevel='org'
          currentOrg={currentOrg}
        />
      </div>
    )
  }
}

export default Radium(OrgMainContent)
