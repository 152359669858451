import React from 'react'
import { OutOfBoxWrapperInvisit, CloseModalButtonDark } from './styled'
import infoIcon from '../../assets/icons/info-icon.svg'
import {
  destroyOobeToast,
  getShouldHideToast
} from '../../lib/util'

const InVisitToast = ({ toggleVisible }) => {
  const shouldHideToast = getShouldHideToast('hideInVisitToast')
  const destroy = () => {
    toggleVisible()
    destroyOobeToast('hideInVisitToast')
  }

  if (shouldHideToast) return null

  return (
    <OutOfBoxWrapperInvisit>
      <div className='icon-item'>
        <img src={infoIcon} />
      </div>
      <div className='article-item'>
        <article>To insert a script, type “+” and part of
        a script name.
        </article>
      </div>
      <div className='button-item'>
        <CloseModalButtonDark onClick={destroy}>
          <div id='mdiv'>
            <div className='mdiv'>
              <div className='md' />
            </div>
          </div>
        </CloseModalButtonDark>
      </div>
    </OutOfBoxWrapperInvisit>
  )
}

export default InVisitToast
