import React, { Component } from 'react'
import {
  ResetContainer,
  Input,
  FormContainer,
  ResetButtonText,
  ResetHeaderText,
  ResetDescText
} from './styled'
import { Loading } from '../Login/styled'
import { KEYCODES } from '../../lib/constants'
import { isValidEmail } from '@sukiai/utils'

class ResetPassword extends Component {
  state = {
    username: this.props.username || '',
    isValidEmail: isValidEmail(this.props.username) || false
  }

  handleChange = e => {
    const username = e.target.value
    this.setState({ isValidEmail: isValidEmail(username), username: username })
  }

  handleSubmit = () => {
    const { username } = this.state
    const { handleSubmit } = this.props

    handleSubmit(username)
  }

  handleKeyPress = e => {
    e.key === KEYCODES.ENTER && this.handleSubmit()
  }

  render () {
    const { username, isValidEmail } = this.state
    const { isLoading } = this.props
    const vw = window.innerWidth
    const vh = window.innerHeight

    return (
      <ResetContainer vw={vw} vh={vh}>
        <FormContainer vw={vw}>
          <ResetHeaderText>Forgot Password</ResetHeaderText>
          <ResetDescText>Please enter your Suki account email address, then check your email to finish resetting your password.</ResetDescText>
          <Input
            onKeyPress={this.handleKeyPress}
            value={username}
            vw={vw}
            onChange={this.handleChange} type='email'
          />
          <ResetButtonText onClick={this.handleSubmit} disabled={!isValidEmail || isLoading}>
            {isLoading
              ? <Loading vw={vw} />
              : 'Reset Password'}
          </ResetButtonText>
        </FormContainer>

      </ResetContainer>)
  }
}

export default ResetPassword
