import React, { Component } from 'react'
import Radium from 'radium'
import { Link, withRouter } from 'react-router-dom'
import IdleTimer from 'react-idle-timer'
import logo from '../images/suki-logo-navbar.svg'
import Paper from 'material-ui/Paper'
import { UserMenu, Clock } from '../components/Nav'
import { titleBarItems, whiteSmoke, scribeGreen } from '../styles/colors'
import { menuFontSize } from '../styles/typography'
import { navBarHeight } from '../styles/dimensions'
import { getUserName, logout } from '../lib/util'
import {
  ROUTES,
  NOTE_MODE,
  NOTE_MODE_STRING,
  INACTIVITY_TIMEOUT
} from '../lib/constants'
import { compose } from 'react-apollo'
import { connect } from 'react-redux'
import { setNoteMode } from '../actions/note'

const styles = {
  paper: {
    zIndex: 1000,
    position: 'fixed',
    top: 0,
    left: 0,
    overflow: 'hidden',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: whiteSmoke,
    height: navBarHeight,
    display: 'flex',
    justifyContent: 'space-between'
  },
  clock: {
    color: titleBarItems,
    fontSize: menuFontSize,
    margin: '0 10px',
    fontWeight: 500,
    width: 80,
    textAlign: 'center'
  },
  language: {
    color: titleBarItems
  },
  link: {
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
      color: scribeGreen,
      cursor: 'pointer'
    }
  },
  logo: {
    height: '100%',
    padding: '8px 20px',
    boxSizing: 'border-box'
  },
  name: {
    color: titleBarItems,
    fontWeight: 600,
    fontSize: menuFontSize,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  navContainer: {
    height: '100%',
    flex: 1
  },
  noteSelect: {
    display: 'flex',
    alignItems: 'center'
  },
  rightSide: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  unfinishedMargin: {
    marginRight: 26
  },
  userMenu: {
    margin: '0 20px 0 10px'
  },
  dropdownStyle: {
    marginLeft: 20,
    outline: 'none',
    backgroundColor: 'none',
    padding: '12px 20px'
  }
}

class TopNavBar extends Component {
  constructor (props) {
    super(props)
    this.idleTimer = null
  }

  componentDidMount () {
    // Not sure what is setting the margin to 8, but eliminate it
    document.body.style.margin = 0
  }

  setMode = (value) => {
    this.props.setNoteMode(value)

    const modifiedPath = `${window.location.pathname}?mode=${value}`
    window.history.replaceState({}, '', modifiedPath)
  };

  handleOnAction = () => {
    this.idleTimer.reset()
  };

  handleOnIdle = () => {
    window.alert(
      'You haven’t used Suki for an hour, so to help with security we’ll sign you out.'
    )
    logout()
  };

  render () {
    // TODO Fix name in nav bar not persisting on refresh
    const { history, location, orgName, mode } = this.props

    let middle = ''
    let switchPath = location.pathname

    if (switchPath.includes(ROUTES.NOTE_TYPES)) {
      switchPath = ROUTES.NOTE_TYPES
    } else if (switchPath.includes(ROUTES.SCRIPTS)) {
      switchPath = ROUTES.SCRIPTS
    } else if (switchPath.includes(ROUTES.USERS)) {
      switchPath = ROUTES.USERS
    } else if (switchPath.includes(ROUTES.ORGS)) {
      switchPath = ROUTES.ORGS
    } else if (switchPath.includes(ROUTES.NOTE)) {
      switchPath = ROUTES.NOTE
    } else if (switchPath.includes(ROUTES.SPECIALITIES)) {
      switchPath = ROUTES.SPECIALITIES
    }

    switch (switchPath) {
      case ROUTES.NOTE_TYPES:
        middle = 'Note Types Editor'
        break
      case ROUTES.USERS:
        middle = orgName ? `${orgName} - Users` : 'Users'
        break
      case ROUTES.ORGS:
        middle = 'Organizations'
        break
      case ROUTES.FEATURE_FLAGS:
        middle = 'Feature Flags'
        break
      case ROUTES.SPECIALITIES:
        middle = 'Speciality Mapping'
        break
      case ROUTES.NOTE:
        middle = (
          <div style={styles.noteSelect}>
            Admin Note
            <select
              style={styles.dropdownStyle}
              onChange={(e) => this.setMode(e.target.value)}
              defaultValue={mode}
              data-cy='note-view-select'
            >
              <option value={NOTE_MODE.QA}>{NOTE_MODE_STRING.QA}</option>
              <option value={NOTE_MODE.DEBUG}>{NOTE_MODE_STRING.DEBUG}</option>
              <option value={NOTE_MODE.PLAIN}>{NOTE_MODE_STRING.PLAIN}</option>
            </select>
          </div>
        )
        break
      default:
        middle = ''
        break
    }

    const link = ROUTES.ORGS

    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref
          }}
          timeout={INACTIVITY_TIMEOUT}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <Paper style={styles.paper} rounded={false}>
          <div style={styles.navContainer}>
            <Link to={link}>
              <img src={logo} style={styles.logo} alt='Suki Logo' />
            </Link>
          </div>
          <div style={Object.assign({}, styles.navContainer, styles.name)}>
            {middle}
          </div>
          <div style={Object.assign({}, styles.navContainer, styles.rightSide)}>
            <Clock style={styles.clock} />
            <UserMenu
              style={styles.userMenu}
              history={history}
              user={getUserName()}
            />
          </div>
        </Paper>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  setNoteMode: (mode) => dispatch(setNoteMode(mode))
})

const mapStateToProps = ({ note }) => ({
  mode: note.noteMode
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  Radium
)(TopNavBar)
