import _get from 'lodash/get'
import { Menu, message } from 'antd'
import { EyeInvisibleOutlined } from '@ant-design/icons'
import React, { useState, useEffect, useCallback } from 'react'

import {
  FEATURE_FLAGS_MENU_ITEM,
  MENU_ITEMS,
  USERS_MENU_ITEM
} from './constants'
import { featureFlagService } from './api'
import TopNavBar from '../../containers/TopNavBar'
import { FeatureFlagList, UserManagement } from './components'
import { isOrganizationEnabledForFeatureFlags } from './helpers'

import './featureFlags.css'

function FeatureFlags () {
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    FEATURE_FLAGS_MENU_ITEM.key
  )
  const [featureFlags, setFeatureFlags] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [featureFlagCreationInProgress, setFeatureFlagCreationInProgress] = useState(false)

  useEffect(() => {
    featureFlagService
      .fetchFeatureFlags()
      .then((response) => {
        const flags = _get(response, 'data.flags', {})
        setFeatureFlags(flags)
      })
      .catch((error) => {
        setFeatureFlags({})
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const handleMenuItemChange = ({ key }) => {
    setSelectedMenuItem(key)
  }

  const handleUpdateUserAccess = useCallback(
    ({ key, sukiUserIds, sukiOrganizationIds }) => {
      setIsLoading(true)
      const loaderMessage = message.loading(
        'Access rules update in progress',
        0
      )
      featureFlagService
        .updateUserAccess({
          sukiUserIds,
          sukiOrganizationIds
        })
        .then((response) => {
          const updatedFeatureFlags = {
            ...featureFlags,
            ...response?.data
          }

          setFeatureFlags(updatedFeatureFlags)
          message.success('Access rules successfully updated', 5)
        })
        .catch((error) => {
          console.log(error)
          message.error('Access rules update failed', 5)
        })
        .finally(() => {
          setIsLoading(false)
          setTimeout(loaderMessage, 0)
        })
    },
    [featureFlags]
  )

  const handleUpdateFeatureFlag = ({
    key,
    sukiUserIds,
    sukiOrganizationIds
  }) => {
    setIsLoading(true)
    const loaderMessage = message.loading(`${key} update in progress`, 0)
    featureFlagService
      .updateFeatureFlag({
        key,
        sukiUserIds,
        sukiOrganizationIds
      })
      .then((response) => {
        const updatedFeatureFlags = {
          ...featureFlags,
          ...response?.data
        }

        setFeatureFlags(updatedFeatureFlags)
        message.success(`${key} successfully updated`, 5)
      })
      .catch((error) => {
        console.log(error)
        message.error(`${key} update failed`, 5)
      })
      .finally(() => {
        setIsLoading(false)
        setTimeout(loaderMessage, 0)
      })
  }

  const handleCreareFeatureFlag = ({ key, description }) => {
    setFeatureFlagCreationInProgress(true)
    const loaderMessage = message.loading(
      'Feature flag creation in progress',
      0
    )
    featureFlagService
      .createFeatureFlag({ key, description })
      .then((response) => {
        const updatedFeatureFlags = {
          ...featureFlags,
          ...response?.data
        }

        setFeatureFlags(updatedFeatureFlags)
        message.success(`${key} successfully created`, 5)
      })
      .catch(() => {
        console.log(error)
        message.error('Creating new feature flag failed', 5)
      })
      .finally(() => {
        setFeatureFlagCreationInProgress(false)
        setTimeout(loaderMessage, 0)
      })
  }

  if (!isLoading && !isOrganizationEnabledForFeatureFlags(featureFlags)) {
    return (
      <div className='flags-page'>
        <TopNavBar />
        <div className='no-access-content'>
          <EyeInvisibleOutlined className='hidden-icon' /> Your organization
          does not have access
        </div>
      </div>
    )
  }

  const renderUserManagement = () => {
    return (
      <UserManagement
        featureFlags={featureFlags}
        isLoading={isLoading}
        onSave={handleUpdateUserAccess}
      />
    )
  }

  const renderFlagsList = () => {
    return (
      <FeatureFlagList
        featureFlags={featureFlags}
        isLoading={isLoading}
        onSave={handleUpdateFeatureFlag}
        onCreate={handleCreareFeatureFlag}
        featureFlagCreationInProgress={featureFlagCreationInProgress}
      />
    )
  }

  return (
    <div className='flags-page'>
      <TopNavBar />
      <div className='flags-container'>
        <div className='menu-container'>
          <Menu
            style={{ width: 200, border: '1px solid #f0f0f0', borderRadius: 2 }}
            defaultSelectedKeys={[FEATURE_FLAGS_MENU_ITEM.key]}
            items={MENU_ITEMS}
            mode='inline'
            onClick={handleMenuItemChange}
          />
        </div>
        <div className='content-container'>
          {selectedMenuItem === USERS_MENU_ITEM.key
            ? renderUserManagement()
            : renderFlagsList()}
        </div>
      </div>
    </div>
  )
}

export default FeatureFlags
