import styled from 'styled-components/native'
import { Animated } from 'react-native'
import { Z_INDEX } from '../../lib/constants'
import alertTriangle from '../../assets/icons/alert-triangle.svg'

export const Wrapper = styled.TouchableWithoutFeedback`

`

export const Container = styled(Animated.View)`
  position: absolute;
  top: ${props => props.size * 0.5};
  right: 0;
  z-index: ${Z_INDEX.AGENT};
`

export const Icon = styled.Image.attrs({
  source: props => ({
    uri: alertTriangle,
    width: props.size * 0.15,
    height: props.size * 0.15
  }),
  draggable: false
})`

`
