import gql from 'graphql-tag'

// USED IN: USERS
export const GetNotificationHistory = gql`
  query GetNotificationHistory($organizationId: ID!, $userId: ID!, $startsAtRangeBeginning: String!, $startsAtRangeEnd: String!) {
    notificationHistory(input: {
      organizationId: $organizationId,
      userId: $userId,
      startsAtRangeBeginning: $startsAtRangeBeginning,
      startsAtRangeEnd: $startsAtRangeEnd
    }) {
      events {
        sentAt,
        content {
          header,
          body
        },
        entry {
          userId,
          notificationKey,
          deviceType,
          deviceInfo
        }
      }
    }
  }
`
