import styled, { css } from 'styled-components'
import { black } from '../../lib/colors'
import circleCheck from '../../assets/images/circle-check.png'
import signNote from '../../assets/icons/sign-note.svg'

export const ActionContainer = styled.div`
  width: 68px;
  position: fixed;
  bottom: 0;
  right: 21px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  opacity: 1;

  ${props => props.isSmallWindow && css`right: 60px;`}
`

export const ImDoneSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

export const ImDoneButton = styled.img.attrs({
  src: circleCheck
})`
  height: 40px;
  width: 40px;
  margin: 20px 20px 6px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
  border: 0;
  z-index: 2;
  border-radius: 50%;
`

export const SignOffButton = styled.img.attrs({
  src: signNote
})`
  height: 30px;
  width: 30px;
  padding: 5px;
  margin: 20px 0 6px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
  border: 0;
  z-index: 2;
  border-radius: 50%;
`

export const ImDoneText = styled.div`
  color: ${black};
  font-size: 14px;
`
