import axios from './config'

const fetchFeatureFlags = () => {
  return axios.get('/preferences/feature-flags')
}

const createFeatureFlag = ({ key, description }) => {
  return axios.post('/preferences/feature-flags', {
    key,
    description,
    sukiUserIds: [],
    sukiOrganizationIds: []
  })
}

const updateFeatureFlag = ({ key, sukiUserIds, sukiOrganizationIds }) => {
  return axios.post(`/preferences/feature-flags/${key}`, {
    sukiUserIds,
    sukiOrganizationIds
  })
}

const updateUserAccess = ({ sukiUserIds, sukiOrganizationIds }) => {
  return axios.post('/preferences/feature-flags/access', {
    sukiUserIds,
    sukiOrganizationIds
  })
}

export default {
  fetchFeatureFlags,
  createFeatureFlag,
  updateFeatureFlag,
  updateUserAccess
}
