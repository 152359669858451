import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  GetPendingUsers,
  GetOrganizationById,
  SendDemoEmail,
  RetractDemoInvitation
} from '@sukiai/gql/admin'
import { snakeCapsToEnglish, getFullName } from '@sukiai/utils'
import { List, ListItem } from 'material-ui/List'

import styled from 'styled-components'
import Frame from './Frame'
import adminBackground from '../images/adminBackground.jpg'
import client from '../apollo'
import moment from 'moment'
import omitDeep from 'omit-deep-lodash'

import { getLastFirstName, notifySuccess, setError } from '../lib/util'
import { FETCH_POLICY } from '../lib/constants'
import { whiteSmoke, charcoal } from '../styles/colors'
import { styles as UserLinkStyle } from '../components/Users/UserLinks'
import { Sidebar } from '../components'
import { InviteUserForm } from '../components/Orgs'

const SecondaryText = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin: 3px 0;
`

const styles = {
  main: {
    padding: '40px 20px 0px'
  },
  userNameContainer: {
    width: '100%',
    fontSize: 26,
    color: whiteSmoke,
    fontWeight: 600,
    minHeight: 83,
    marginBottom: 16
  },
  userName: {
    display: 'flex',
    alignItems: 'center'
  },
  editButton: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    backgroundColor: 'rgba(245, 247, 250, 0.30)',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12,
    cursor: 'pointer',
    opacity: 1,
    transition: '0.2s',
    ':hover': {
      opacity: 0.85
    },
    ':active': {
      transform: 'scale(0.95)'
    }
  },
  columns: {
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 99px)'
  },
  left: {
    width: '25%',
    marginRight: 20,
    height: '100%'
  },
  middle: {
    width: '35%'
  },
  right: {
    marginLeft: 20,
    width: '35%'
  },
  subheader: {
    width: 'fit-content',
    color: whiteSmoke,
    cursor: 'pointer',
    fontSize: 20,
    marginTop: 16,
    marginRight: 15
  },
  panelSelector: {
    background: 'none',
    border: 'none',
    fontSize: '16pt',
    color: charcoal
  }
}

class PendingUsers extends Component {
  state = {
    currentUser: null,
    formOpen: null,
    isLoadingMore: false,
    newInviteFormOpen: null
  }

  toggleNewInviteForm = (open) => {
    const { refetchUsers } = this.props
    this.setState({
      newInviteFormOpen: open
    }, () => {
      if (!open) {
        refetchUsers()
      }
    })
  }

  onHandleSelect = userId => {
    const { users } = this.props

    // find user and set
    const user = users.find(u => u.user.id === userId)
    this.setState({ currentUser: user })
  }

  handleResendInvitation = () => {
    const { refetchUsers, users } = this.props
    const { currentUser } = this.state
    const selectedUser = currentUser || users[0]

    // sanitize for mutation
    const newUserObj = { ...omitDeep(selectedUser?.user, '__typename') }
    newUserObj.roles = [{ type: 'USER' }] // workaround for schema requirement
    delete newUserObj.id

    client.mutate({
      mutation: SendDemoEmail,
      variables: {
        user: newUserObj
      }
    }).then(({ data }) => {
      refetchUsers()
      notifySuccess(`Invitation re-sent to ${data?.sendDemoEmail?.email}`)
    })
      .catch(error => {
        console.error('Error with resending pending Invitation:', error)
        setError(error)
        return false
      })
  }

  handleRetractInvitation = () => {
    const { refetchUsers, users } = this.props
    const { currentUser } = this.state

    // get selected
    const selectedUser = currentUser || users[0]

    // confirmation window
    const confirmed = window.confirm(`Are you sure you want to retract invitation from ${selectedUser?.user?.email}?`)
    if (!confirmed) {
      return
    }

    client.mutate({
      mutation: RetractDemoInvitation,
      variables: {
        id: selectedUser?.user.id
      }
    })
      .then(({ data }) => {
        refetchUsers()
        notifySuccess(`Pending Invitation Retracted for ${selectedUser?.user?.email}`)
      })
      .catch(error => {
        console.error('Error with retracting pending invatation:', error)
        setError(error)
        return false
      })
  }

  render () {
    const {
      users,
      orgData,
      loadingUsers
    } = this.props

    const { currentUser } = this.state
    const selectedUser = currentUser || users[0]
    const pendingUserItems = users.map(u => ({ name: getLastFirstName(u.user.person), id: u.user.id }))
    const sentDate = selectedUser?.invitedAt && moment(selectedUser?.invitedAt).format('MM/DD/YYYY h:mm A')

    return (
      <Frame
        background={adminBackground}
        mainStyle={styles.main}
        orgName={orgData?.name}
      >
        <Sidebar
          items={pendingUserItems}
          selectedId={selectedUser?.user.id}
          title='Pending Users'
          handleSelect={this.onHandleSelect}
          loading={loadingUsers}
          handleAdd={() => this.toggleNewInviteForm(true)}
        />

        {pendingUserItems?.length
          ? (
            <>
              <div style={styles.userNameContainer}>
                {getFullName(selectedUser?.user?.person)}
                <SecondaryText><strong> Sent: </strong> {sentDate}</SecondaryText>
                <SecondaryText> <strong> Register Stage: </strong> {snakeCapsToEnglish(selectedUser?.registerStage)} </SecondaryText>
              </div>

              <List style={{ ...UserLinkStyle.list, ...{ width: '445px' } }}>
                <ListItem id='schedule-menu-button' innerDivStyle={UserLinkStyle.inner} onClick={this.handleResendInvitation}>
          Re-send Invitation
                </ListItem>
                <ListItem id='scripts-menu-button' innerDivStyle={UserLinkStyle.inner} onClick={this.handleRetractInvitation}>
          Retract Pending Invitation
                </ListItem>
              </List>
            </>
          ) : null}

        {this.state.newInviteFormOpen && (
          <InviteUserForm
            orgId={orgData?.id}
            toggleNewInviteForm={this.toggleNewInviteForm}
          />
        )}
      </Frame>
    )
  }
}

const mapStateToProps = ({ admin }) => ({
  currentUserId: admin.userId
})

const mapPendingUsersToProps = ({ ownProps, data }) => ({
  errorUsers: data.error,
  loadingUsers: data.loading,
  users: data?.pendingUsers?.results || [],
  refetchUsers: data.refetch
})

const mapOrgToProps = ({ data }) => ({
  orgError: data.error,
  orgLoading: data.loading,
  orgData: data?.organizations?.results?.[0]
})

export default compose(
  connect(mapStateToProps),
  withRouter,
  graphql(GetPendingUsers, {
    skip: ({ match }) => !(match?.params?.orgId),
    options: ({ match }) => ({
      variables: { organizationId: match?.params?.orgId },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK
    }),
    props: mapPendingUsersToProps
  }),
  graphql(GetOrganizationById, {
    skip: ({ match }) => !(match?.params?.orgId),
    options: ({ match }) => ({
      variables: {
        id: match?.params?.orgId,
        withEmrInfo: true
      }
    }),
    props: mapOrgToProps
  })

)(PendingUsers)
