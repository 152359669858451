import React, { Component } from 'react'
import { Formik, Form, Field, FieldArray } from 'formik'
import {
  slateGrey,
  charcoal,
  whiteSmoke,
  scribeGreen,
  demoGrey
} from '../../styles/colors'
import Radium from 'radium'
import { ROLES, GENDERS, SPECIALTY_TYPE } from '../../lib/constants'
import map from 'lodash.map'
import { Multiselect } from 'multiselect-react-dropdown'

const SPECIALTIES_ARR = Object.keys(SPECIALTY_TYPE)
const styles = {
  outerContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    background: 'rgba(0,0,0,0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 50
  },
  formContainer: {
    width: '30vw',
    minWidth: 600,
    background: whiteSmoke
  },
  header: {
    padding: '20px 40px',
    backgroundColor: slateGrey,
    color: 'white',
    fontSize: 24,
    fontWeight: 600,
    boxSizing: 'border-box'
  },
  body: {
    padding: '10px 40px',
    maxHeight: '75vh',
    overflow: 'auto',
    boxSizing: 'border-box'
  },
  input: {
    outline: 'none',
    borderBottom: `1px solid ${slateGrey}`,
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    backgroundColor: 'transparent',
    padding: 10,
    marginBottom: 20,
    fontSize: 20,
    width: '50%',
    boxSizing: 'border-box',
    color: charcoal,
    display: 'block'
  },
  select: {
    color: charcoal,
    fontSize: 20,
    outline: 'none',
    width: '50%',
    marginBottom: 20,
    display: 'block'
  },
  roles: {
    marginBottom: 20,
    fontSize: 18,
    color: charcoal,
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  roleItem: {
    display: 'flex',
    alignItems: 'center'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 40px 20px'
  },
  button: {
    fontSize: 22,
    fontWeight: 600,
    color: scribeGreen,
    padding: '10px 20px',
    cursor: 'pointer',
    transition: '0.2s',
    borderRadius: 2,
    marginLeft: 6,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none'
    // ':hover': {
    //   backgroundColor: '#D8D8D8'
    // },
    // ':active': {
    //   transform: 'scale(0.98)'
    // }
  },
  delete: {
    marginLeft: 0
  },
  disabled: {
    color: demoGrey,
    cursor: 'not-allowed'
    // ':hover': {
    //   backgroundColor: 'transparent'
    // },
    // ':active': {
    //   transform: 'scale(1)'
    // }
  },
  multiSelect: {
    multiselectContainer: {
      // To change css for multiselect (Width,height,etc..)
      width: 'fit-content',
      minWidth: '50%',
      margin: '25px 0'
    },
    searchBox: {
      borderWidth: '0 0 1px 0',
      borderRadius: '0px'
    }
  },
  checkboxInput: {
    marginRight: '7px'
  },
  checkboxLabel: {
    fontSize: 18,
    color: charcoal
  }
}

class UserForm extends Component {
  renderRole = (r, values, arrayHelpers) => (
    <div key={r} style={styles.roleItem}>
      <input
        name={`${r}-role`}
        type='checkbox'
        value={r}
        checked={values.roles && values.roles.some(role => role.type === r)}
        onChange={e => this.updateRoles(e, r, values, arrayHelpers)}
      />
      <label>&nbsp;{r}</label>
    </div>
  )

  updateRoles = (e, role, values, { push, remove }) => {
    if (e.target.checked) push({ type: role })
    else {
      const idx = values.roles.findIndex(r => r.type === role)
      remove(idx)
    }
  }

  handleDelete = () => {
    const confirm = window.confirm('Are you sure you want to delete this user?')
    if (confirm) {
      this.props.handleDelete()
    }
  }

  render () {
    const {
      disableSharedUserInput,
      FORMS,
      TIMEZONES,
      toggleForm,
      initialValues,
      handleSubmit,
      type
    } = this.props
    return (
      <div style={styles.outerContainer} onClick={toggleForm}>
        <div style={styles.formContainer} onClick={e => e.stopPropagation()}>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <div style={styles.header}>{`${type} User`}</div>
                <div style={styles.body}>
                  <Field
                    name='person.prefix'
                    type='text'
                    placeholder='Prefix (Dr., Mr.)'
                    style={styles.input}
                  />
                  <Field
                    name='person.firstName'
                    type='text'
                    placeholder='First Name'
                    style={styles.input}
                  />
                  <Field
                    name='person.middleName'
                    type='text'
                    placeholder='Middle Name'
                    style={styles.input}
                  />
                  <Field
                    name='person.lastName'
                    type='text'
                    placeholder='Last Name'
                    style={styles.input}
                  />
                  <Field
                    name='person.suffix'
                    type='text'
                    placeholder='Suffix (Jr., III)'
                    style={styles.input}
                  />
                  <Field
                    name='userType'
                    type='text'
                    placeholder='User Type (MD, PA)'
                    style={styles.input}
                  />
                  <Field
                    name='person.gender'
                    component='select'
                    placeholder='Gender'
                    style={styles.select}
                  >
                    {map(GENDERS, g => (
                      <option key={g} value={g}>
                        {g}
                      </option>
                    ))}
                  </Field>
                  <FieldArray
                    name='specialties'
                    render={({ form }) => (
                      <Multiselect
                        name='specialties'
                        style={styles.multiSelect}
                        placeholder='Add Specialities'
                        options={SPECIALTIES_ARR}
                        isObject={false}
                        singleSelect={true}
                        selectedValues={values.specialties}
                        onSelect={(list, item) => {
                          if (item === SPECIALTY_TYPE.NA) {
                            list = [SPECIALTY_TYPE.NA]
                          } else {
                            list = list.filter(
                              item => item !== SPECIALTY_TYPE.NA
                            )
                          }

                          form.setFieldValue('specialties', list)
                        }}
                        onRemove={(list) => {
                          form.setFieldValue('specialties', list)
                        }}
                      />
                    )}
                  />
                  <FieldArray name='roles'>
                    {arrayHelpers => (
                      <div style={styles.roles}>
                        {map(ROLES, r =>
                          this.renderRole(r, values, arrayHelpers)
                        )}
                      </div>
                    )}
                  </FieldArray>
                  <Field
                    name='email'
                    type='email'
                    placeholder='Email'
                    style={styles.input}
                  />
                  <Field
                    name='phoneNumber'
                    type='phone'
                    placeholder='Phone #'
                    autoComplete='none'
                    style={styles.input}
                  />
                  <Field
                    name='timezone'
                    component='select'
                    placeholder='Timezone'
                    style={styles.select}
                  >
                    {TIMEZONES.map(tz => (
                      <option key={tz.value} value={tz.value}>
                        {tz.value}
                      </option>
                    ))}
                  </Field>
                  <div style={styles.roleItem}>
                    <Field
                      name='sharedUser'
                      type='checkbox'
                      disabled={disableSharedUserInput}
                      style={styles.checkboxInput}
                    />

                    <label style={{ ...styles.checkboxLabel, opacity: disableSharedUserInput ? 0.6 : 1 }}>Shared User</label>
                  </div>
                </div>
                <div style={styles.footer}>
                  <div>
                    {type === FORMS.UPDATE && (
                      <div
                        key='delete'
                        style={isSubmitting
                          ? { ...styles.button, ...styles.delete, ...styles.disabled }
                          : { ...styles.button, ...styles.delete }}
                        onClick={this.handleDelete}
                        disabled={isSubmitting}
                      >
                        DELETE
                      </div>
                    )}
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div
                      key='cancel'
                      style={
                        isSubmitting
                          ? { ...styles.button, ...styles.disabled }
                          : styles.button
                      }
                      onClick={toggleForm}
                      disabled={isSubmitting}
                    >
                      CANCEL
                    </div>
                    <button
                      key='submit'
                      type='submit'
                      style={
                        isSubmitting
                          ? { ...styles.button, ...styles.disabled }
                          : styles.button
                      }
                      disabled={isSubmitting}
                    >
                      {type.toUpperCase()}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

export default Radium(UserForm)
