import React, { Component } from 'react'
import { CheckScheduleHealth } from '@sukiai/gql/admin'
import moment from 'moment'

import { refreshEMRAppointments } from '../../lib/util'
import client from '../../apollo'
import {
  Button,
  Container,
  getStatusStyling,
  RefreshText,
  ResultText
} from './styles'

const convertToEMRUserInput = (currentUser) => {
  const { emrDepartmentId, emrUserIdList, fhirUserId, id, timezone, emrUserIdType } = currentUser
  return emrUserIdList.map((emrUserId) => ({
    sukiUserId: id,
    emrUserId: emrUserId,
    emrDepartmentId,
    fhirUserId,
    timezone,
    emrUserIdType,
    emrUserIdList: [emrUserId]
  }))
}

class EMRStatusCheck extends Component {
  state = {
    isLoading: false,
    isRefresingEMR: false,
    hasAppointment: null, // true/false/null
    hasError: false // keeps track of graphql error on refreshEMRAppointments graphQL call
  };

  handleStatusCheck = async () => {
    const { organizationId, emrType, currentUser, orgName } = this.props
    const users = convertToEMRUserInput(currentUser)

    this.setState({ isLoading: true })
    // get appointment status
    const hasAppointment = await client.mutate({
      mutation: CheckScheduleHealth,
      variables: {
        organizationId,
        emrType,
        users,
        daysBeforeNow: 14,
        daysAfterNow: 28
      }
    }).then(({ data }) => data.checkScheduleHealth.hasAppointment)
      .catch(error => {
        this.setState({ hasError: true })
        console.log(error)
        return false
      })

    // save to state, set loading state
    this.setState({ hasAppointment, isRefresingEMR: !!hasAppointment, isLoading: !!hasAppointment })

    // refresh emr appointments, if has appointment
    if (hasAppointment) {
      await refreshEMRAppointments({
        organizationId,
        userID: currentUser.id,
        emrType,
        fromDate: moment().subtract(14, 'd').startOf('day').toISOString(),
        toDate: moment().add(28, 'd').endOf('day').toISOString(),
        orgName
      })

      this.setState({ isRefresingEMR: false, isLoading: true })
    }
  }

  render () {
    const { hasAppointment, isLoading, isRefresingEMR } = this.state
    const { timestamp } = this.props
    return (
      <Container>
        <Button
          onClick={this.handleStatusCheck}
          disabled={hasAppointment !== null || !!isLoading}
          style={{ background: getStatusStyling(hasAppointment).bg }}
          status={hasAppointment != null && hasAppointment.toString()}
        >
          <img src={getStatusStyling(hasAppointment).icon} width={20} alt='status-icon' />
          {hasAppointment != null && <ResultText>{hasAppointment ? 'Success' : this.state.hasError ? 'Failed' : 'No appointments found'}</ResultText>}
        </Button>
        {timestamp && !isRefresingEMR && <RefreshText>Last checked:{moment(timestamp).format('MM/DD/YYYY')}</RefreshText>}
        {isRefresingEMR && <RefreshText>Refreshing EMR appointments...</RefreshText>}
      </Container>
    )
  }
}

export default EMRStatusCheck
