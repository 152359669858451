import React, { Component } from 'react'
import {
  slateGrey,
  whiteSmoke,
  scribeGreen,
  charcoal,
  demoGrey
} from '../../styles/colors'
import { notifySuccess, setError } from '../../lib/util'
import client from '../../apollo'
import {
  isValidEmail
} from '@sukiai/utils'
import { SendDemoEmail } from '@sukiai/gql/admin'
import styled from 'styled-components'
import { Loading } from '../index'

const styles = {
  container: {
    boxSizing: 'border-box',
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(0,0,0,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formContainer: {
    backgroundColor: whiteSmoke,
    width: '50vw'
  },
  header: {
    padding: '20px 40px',
    backgroundColor: slateGrey,
    color: 'white',
    fontSize: 24,
    fontWeight: 600
  },
  body: {
    padding: '20px 40px',
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    outline: 'none',
    borderBottom: `1px solid ${slateGrey}`,
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    backgroundColor: 'transparent',
    padding: 10,
    marginBottom: 20,
    fontSize: 20,
    width: '100%',
    boxSizing: 'border-box',
    color: charcoal
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 20px 20px'
  },
  button: {
    fontSize: 22,
    fontWeight: 800,
    color: scribeGreen,
    padding: '10px 20px',
    cursor: 'pointer',
    transition: '0.2s',
    borderRadius: 2,
    marginLeft: 6,
    ':hover': {
      backgroundColor: '#D8D8D8'
    },
    ':active': {
      transform: 'scale(0.95)'
    }
  },
  delete: {
    marginLeft: 0
  },
  disabled: {
    color: demoGrey,
    cursor: 'not-allowed',
    ':hover': {
      backgroundColor: 'transparent'
    },
    ':active': {
      transform: 'scale(1)'
    }
  }
}

const Field = styled.div`
  margin-bottom: 10px;

  label {
    color: rgb(66, 82, 110);
    font-size: 12px;
    font-weight: 600;

    &::after {
      color: rgb(222, 53, 11);
      content: "*";
      display: inline-block;
      padding-left: 4px;
      display: ${props => props.required ? 'inline' : 'none'};
    }
  }

  input {
    color: #071E44;
    font-size: 14px;
    height: 18px;
    border-radius: 3px;
    width: 100%;
    padding: 8px 6px;
    border: 2px solid #B3BAC5;
    margin-top: 3px;
  }
`

const Button = styled.button`
    &:disabled {
      opacity: .6;
      cursor: not-allowed;
    }

    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    cursor: pointer;
    user-select: none;
    font-size: 14;
    color: rgb(255, 255, 255);
    border: 0;
    padding: 0px 12px;
    border-radius: 3px;
    transition: all 0.1s ease 0s;
    background: rgb(85 127 158);
    font-weight: 600;
    margin-left: 20px;
    
    &.secondary {
      background: transparent;
      color: rgb(66, 82, 110);
      font-weight: 500;
    }

    .loader {
      margin-right: 8px;
    }
`

class InviteUserForm extends Component {
  state = {
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    email: '',
    isLoading: false
  }

  handleInviteUser = () => {
    const {
      firstName,
      middleName,
      lastName,
      suffix,
      email
    } = this.state

    const { orgId, toggleNewInviteForm } = this.props

    this.setState({ isLoading: true })

    client.mutate({
      mutation: SendDemoEmail,
      variables: {
        user: {
          email,
          organizationId: orgId,
          person: {
            firstName,
            middleName,
            lastName,
            suffix
          },
          roles: [{ type: 'USER' }], // default
          timezone: 'America/Los_Angeles' // default
        }
      }
    })
      .then(({ data }) => {
        notifySuccess(`Invitation sent to ${email}`)
        toggleNewInviteForm(false)
        this.setState({ isLoading: false })
      })
      .catch(error => {
        console.error('Error with resending invatation:', error)
        this.setState({ isLoading: false })
        setError(error)
        return false
      })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

   checkFieldErrors = () => {
     const {
       firstName,
       lastName,
       email
     } = this.state

     // true means invalid
     return {
       firstName: firstName.trim().length === 0,
       lastName: lastName.trim().length === 0,
       email: !isValidEmail(email)
     }
   }

   render () {
     const {
       firstName,
       middleName,
       lastName,
       suffix,
       email,
       isLoading
     } = this.state
     const { toggleNewInviteForm } = this.props
     const errors = this.checkFieldErrors()
     const isInvalid = Object.keys(errors).some(x => errors[x])

     return (
       <div
         style={styles.container}
       >
         <div
           style={styles.formContainer}
           onClick={e => e.stopPropagation()}
         >
           <div style={styles.header}>
           Send Email to New Potential User
           </div>
           <div style={styles.body}>
             <Field required>
               <label>First Name</label>
               <input
                 autoFocus
                 name='firstName'
                 value={firstName}
                 onChange={this.handleChange}
               />
             </Field>
             <Field>
               <label> Middle Name</label>
               <input
                 name='middleName'
                 value={middleName}
                 onChange={this.handleChange}
               />
             </Field>
             <Field required>
               <label> Last Name</label>
               <input
                 name='lastName'
                 value={lastName}
                 onChange={this.handleChange}
               />
             </Field>
             <Field>
               <label>Suffix</label>
               <input
                 name='suffix'
                 value={suffix}
                 onChange={this.handleChange}
               />
             </Field>
             <Field required>
               <label>Email</label>
               <input
                 name='email'
                 value={email}
                 onChange={this.handleChange}
               />
             </Field>
           </div>
           <div style={styles.footer}>
             <div />
             <div style={{ display: 'flex', justifyContent: 'space-between' }}>
               <Button
                 className='secondary'
                 onClick={() => toggleNewInviteForm(false)}
               >
                Cancel
               </Button>

               <Button
                 disabled={isInvalid || isLoading}
                 isLoading={false}
                 onClick={this.handleInviteUser}
               >
                 {isLoading && (
                   <div className='loader'>
                     <Loading color='white' size='16' thickness='2' />
                   </div>
                 )}
                Send Invitation
               </Button>
             </div>
           </div>
         </div>
       </div>
     )
   }
}

export default InviteUserForm
