import React, { useState, useEffect, useRef } from 'react'
import get from 'lodash.get'
import client from '../../apollo'
import { GetCompositionsSms } from '@sukiai/gql/admin'
import { UserNoteItem } from '../Users'
import { filterNotes } from '../../lib/util'
import { GetNotesSms } from '@sukiai/gql/admin/Sms'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'

const NotesContainer = styled.div`
  padding: 0px;
  background-color: white;
  width: 100%;
  height: calc(100% - 74px);
  min-height: calc(100% - 74px);
  overflow: auto;
`
const FILTER_TYPES = {
  COMPOSITIONS: 'COMPOSITIONS',
  PATIENT_NOTES: 'PATIENT_NOTES'
}

const blankArr = Array.from(Array(10))
const LIMIT = 20

const UserNotes = ({ userId, organizationId, noteFilterType, filterVal }) => {
  const [notes, setNotes] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [offset, setOffset] = useState(0)
  const [isInitLoading, setIsInitLoading] = useState(true)

  const containerRef = useRef()

  const queryResults = (nextOffset) => {
    const query = noteFilterType === FILTER_TYPES.COMPOSITIONS ? GetCompositionsSms : GetNotesSms
    return client
      .query({
        query: query,
        variables: {
          userId,
          organizationId,
          offset: nextOffset,
          limit: LIMIT
        }
      }, {
        fetchPolicy: 'network_only'
      })
      .then(({ data }) => {
        const pathkey =
        noteFilterType === FILTER_TYPES.COMPOSITIONS
          ? 'compositionsSms'
          : 'notesSms'
        const results = get(data, `${pathkey}.results`, [])

        return results
      })
  }

  useEffect(() => {
    setIsInitLoading(true)
    setHasMore(false)
    setNotes([])
    containerRef.current.querySelector('.infinite-scroll-component').scrollTop = 0
    containerRef.current.scrollTop = 0
    fetchMoreNotes([], 0)
  }, [userId, organizationId, noteFilterType])

  const fetchMoreNotes = async (prevNotes = notes, nextOffset = offset) => {
    const newNotes = await queryResults(nextOffset).then((results) => results)
    setNotes([...prevNotes, ...newNotes])
    setOffset(nextOffset + LIMIT)
    setHasMore(newNotes.length >= LIMIT)
    setIsInitLoading(false)
  }

  const filteredNotes = filterNotes(notes, filterVal)

  const renderNotes = () => {
    if (isInitLoading) {
      return <>{blankArr.map((ele, i) => <UserNoteItem key={i} loading={isInitLoading} />)}</>
    }
    return filteredNotes.map(n => {
      return (
        <UserNoteItem
          key={n.id || n.noteId}
          note={n}
          userId={userId}
          orgId={organizationId}
        />
      )
    })
  }

  return (
    <NotesContainer id='notes-container' ref={containerRef}>
      <InfiniteScroll
        dataLength={filteredNotes.length}
        next={fetchMoreNotes}
        hasMore={hasMore}
        scrollableTarget='notes-container'
      >
        {renderNotes()}
      </InfiniteScroll>
    </NotesContainer>
  )
}

export default UserNotes
