import React from 'react'
import { Query } from 'react-apollo'
import { Multiselect } from 'multiselect-react-dropdown'
import { Formik, Form as FormikForm, Field, FieldArray } from 'formik'
import get from 'lodash.get'
import omitDeep from 'omit-deep-lodash'
import { GetSections, GetScriptById, UpdateScript, GetScriptsByUserId } from '@sukiai/gql/admin'
import { V2_DEFAULT_CONTENT } from '@sukiai/quill-editor'
import ActionBar from './ActionBar'
import BlockHeader from './BlockHeader'
import CopyToUser from './CopyToUser'
import ScriptSearch from './ScriptSearch'
import { AddBlockButton, ContentContainer, NameContainer } from './styled'
import { Paper, Loading, Form, CircleButton, ContentEditor, SpecialtiesList } from '../../components'
import { scribeGreen } from '../../styles/colors'
import { SCRIPT_TAG, EDITOR_TYPE, ORG_IDS, LO_STO, SPECIALTIES_LIST } from '../../lib/constants'
import loSto from '../../config/loSto'
import { CURRENT_SECTION } from '../Scripts/constants'
import { isContentError, setError, notifySuccess, sortScriptsByName, sortScriptsBySource } from '../../lib/util'
import ActiveField from './ActiveField'
import SpecialtiesDialog from '../../components/SpecialtiesDialog'
import styled from 'styled-components'
import ContentErrorsDialog from '../../components/ContentErrorsDialog'

const SpecialtiesContainer = styled.div`
  display:flex;
  justify-content: flex-end;
  margin-top: -60px;
  margin-right: -20px;
  margin-bottom: 30px;
`

class ScriptEditor extends React.Component {
  state = {
    copyDialogOpen: false,
    specialtiesDialogOpen: false,
    contentErrors: false
  }

  setSpecialtiesDialog = specialtiesDialogOpen => {
    this.setState({ specialtiesDialogOpen })
  }

  setCopyDialogOpen = open => {
    this.setState({ copyDialogOpen: open })
  }

  setContentErrors = contentErrors => {
    this.setState({ contentErrors })
  }

  validateErrorSections = (sections) => {
    const errorSections = sections.reduce((array, section) => {
      if (isContentError(section.content)) {
        array.push(section)
      }
      return array
    }, [])

    return errorSections.length > 0 ? errorSections : false
  }

  handleOpeningCopyDialog = (blocks) => {
    // make sure sections doesn't have unsupported chars
    const contentErrors = this.validateErrorSections(blocks)
    if (contentErrors) {
      this.setState({ contentErrors })
      return
    }
    // proceed as usual
    this.setCopyDialogOpen(true)
  }

  updateScript = (values, { setSubmitting, resetForm }, client) => {
    const { organizationId, userId, id } = this.props
    try {
      values = this.validateAndTransform(values)

      const optimisticResponse = {
        __typename: 'Mutation',
        updateMacro: {
          __typename: 'UpdateMacroPayload',
          macro: {
            __typename: 'Macro',
            id,
            userId,
            ...values
          }
        }
      }

      // make sure sections dont have unsupported chars
      const contentErrors = this.validateErrorSections(values.blocks)
      if (contentErrors) {
        this.setState({ contentErrors })
        setSubmitting(false)
        return
      }

      this.addTypenames(optimisticResponse)

      const variables = { organizationId, userId, id, ...values }

      client
        .mutate({
          mutation: UpdateScript,
          variables,
          optimisticResponse,
          update: this.onUpdate
        })
        .then(res => {
          notifySuccess('Updated script')
          console.info(`Successfully updated script ${id}`)
        })
        .catch(err => {
          resetForm()
          console.error(`Error updating script. ${err}`)
          setError(`Could not update script. ${err}`)
        })
    } catch (e) {
      console.error(`Could not update script ${id}. ${e}`)
      setError(`Could not update script. ${e}`)
    } finally {
      setSubmitting(false)
    }
  }

  onUpdate = async (cache, { data: { updateMacro } }) => {
    const { organizationId, userId, filteredSpecialty, isOnboardingDefault } = this.props

    // if onboarding default then we need to
    // update the query variables
    const queryVariables = { organizationId, userId }
    if (isOnboardingDefault) {
      queryVariables.specialties = [filteredSpecialty]
    }

    // Get all current scripts from the cache
    const { macros: allScriptsRes } = cache.readQuery({
      query: GetScriptsByUserId,
      variables: queryVariables
    })

    // Update the script in the cache
    const allScripts = get(allScriptsRes, 'results', [])
    const updatedScript = get(updateMacro, 'macro')
    const updatedId = get(updatedScript, 'id')
    const updatedIdx = allScripts.findIndex(s => s.id === updatedId)
    allScripts.splice(updatedIdx, 1, updatedScript)

    // Re-sort the scripts by name
    sortScriptsByName(allScripts)
    sortScriptsBySource(allScripts)

    // Update the cache with the new list of scripts
    await cache.writeQuery({
      query: GetScriptsByUserId,
      variables: queryVariables,
      data: {
        macros: {
          __typename: 'QueryMacrosPayload',
          count: get(allScripts, 'length', 0),
          results: allScripts
        }
      }
    })
  }

  addTypenames = optimisticResponse => {
    optimisticResponse.updateMacro.macro.tags = get(optimisticResponse, 'updateMacro.macro.tags', []).map(t => ({
      ...t,
      name: {
        ...t.name,
        __typename: 'SlotName'
      },
      __typename: 'Tag'
    }))

    optimisticResponse.updateMacro.macro.blocks = get(optimisticResponse, 'updateMacro.macro.blocks', []).map(b => ({
      ...b,
      __typename: 'Block'
    }))
  }

  validateAndTransform = values => {
    const name = get(values, 'name')
    if (!name) throw new Error('Please enter a script name')
    const keywords = get(values, 'keywords', '')
    const aliases = (get(keywords, 'length', 0) > 0 && keywords.split(', ').map(k => k.trim())) || null
    const specialties = get(values, 'specialties', '[]')

    const nameTag = {
      type: SCRIPT_TAG.NAME,
      name: {
        value: name,
        aliases
      }
    }

    const sectionMap = new Map()
    const blocks = get(values, 'blocks', [])
    if (!blocks.length) throw new Error('Please add at least one block')
    const transformedBlocks = blocks.map(b => {
      const { id, content } = b

      if (!id) throw new Error('Please specify sections for all blocks')
      if (!content || content === V2_DEFAULT_CONTENT) throw new Error('Please remove empty blocks')
      if (sectionMap.get(id)) throw new Error('Please remove duplicate sections')

      sectionMap.set(id, true)

      return { sectionId: id, content }
    })

    return { tags: [nameTag], blocks: transformedBlocks, specialties, source: 'ADMIN' }
  }

  getInitialValues = script => {
    script = omitDeep(script, '__typename')
    const nameTag = get(script, 'tags', []).find(t => t.type === SCRIPT_TAG.NAME)
    const name = get(nameTag, 'name.value')
    const aliases = get(nameTag, 'name.aliases', [])
    const keywords = aliases ? aliases.join(', ') : ''
    const blocks = get(script, 'blocks', []).map(b => ({ id: b.sectionId, content: b.content }))
    const specialties = get(script, 'specialties', [])

    return { name, keywords, blocks, specialties }
  }

  render () {
    const { contentErrors, copyDialogOpen, specialtiesDialogOpen } = this.state
    const { organizationId, userId, id, setSelected, filteredSpecialty, isOnboardingDefault } = this.props
    const skip = !organizationId || !id
    const scriptVariables = { organizationId, id }
    const sectionVariables = { organizationId, withMapping: false }
    const showSpecialties = organizationId === ORG_IDS.SUKI_ONBOARDING_DEFAULTS

    // allow dynamic chips for e2e test org
    const enableChips = loSto.get(LO_STO.CURRENT_ORG)?.emr?.id || organizationId === '22222222-2222-2222-2222-222222222222'

    return (
      <Paper>
        <Query query={GetScriptById} variables={scriptVariables} skip={skip}>
          {({ loading: scriptLoading, data: scriptData }) => (
            <Query query={GetSections} variables={sectionVariables} skip={skip}>
              {({ loading: sectionsLoading, data: sectionsData, client }) => {
                if (scriptLoading || sectionsLoading) return <Loading />

                const script = get(scriptData, 'macros.results[0]')
                if (!script) return null

                const initialValues = this.getInitialValues(script)
                const sections = CURRENT_SECTION.concat(get(sectionsData, 'sections.results', []))

                const newBlock = {
                  id: get(sections, '[0].id', ''),
                  content: V2_DEFAULT_CONTENT
                }

                return (
                  <Formik
                    key={id}
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={(values, helpers) => this.updateScript(values, helpers, client)}
                  >
                    {({ values, handleSubmit, dirty, isSubmitting, setFieldValue: formSetFieldValue }) => (
                      <>
                        <FormikForm data-cy='script-editor'>
                          {showSpecialties && (
                            <>
                              <SpecialtiesContainer>
                                <SpecialtiesList list={values.specialties} handleClick={() => this.setSpecialtiesDialog(true)} />
                              </SpecialtiesContainer>

                              <SpecialtiesDialog
                                open={specialtiesDialogOpen}
                                closeDialog={() => this.setSpecialtiesDialog(false)}
                                setSpecialties={(list) => formSetFieldValue('specialties', list)}
                                selected={values.specialties}
                              />
                            </>
                          )}

                          <Form.Section flex direction='column'>
                            <ActiveField data-cy='script-name' name='name'>
                              {({ field, form, active }) => {
                                const { setFieldValue } = form
                                const name = get(field, 'value')
                                const dirty = initialValues.name !== name
                                const onSearchSelect = (name, keywords) => {
                                  setFieldValue('name', name)
                                  setFieldValue('keywords', keywords)
                                }

                                return (
                                  <NameContainer>
                                    <Form.Input
                                      field={field}
                                      placeholder='Script Name'
                                      isTitle
                                      margin='0px'
                                      style={{ width: '100%' }}
                                    />
                                    <ScriptSearch
                                      key={id}
                                      organizationId={organizationId}
                                      active={active}
                                      dirty={dirty}
                                      name={name}
                                      onSelect={onSearchSelect}
                                    />
                                  </NameContainer>
                                )
                              }}
                            </ActiveField>
                            <Field
                              data-cy='script-keywords'
                              name='keywords'
                              placeholder='Keywords'
                              component={Form.Input}
                              margin='0px'
                            />
                          </Form.Section>
                          <Form.Section flex direction='column'>
                            <FieldArray
                              name='specialties'
                              render={({ form }) => (
                                <Multiselect
                                  name='specialties'
                                  placeholder='Add Specialities'
                                  options={SPECIALTIES_LIST}
                                  isObject={false}
                                  selectedValues={values?.specialties}
                                  onSelect={(list) => {
                                    form.setFieldValue('specialties', list)
                                  }}
                                  onRemove={(list) => {
                                    form.setFieldValue('specialties', list)
                                  }}
                                />
                              )}
                            />
                          </Form.Section>
                          <FieldArray name='blocks'>
                            {({ remove, swap, push }) => (
                              <>
                                {get(values, 'blocks', []).map((b, idx, bs) => (
                                  <Form.Section
                                    key={`${id}-${b.id}-${idx + 1}-block`}
                                    data-cy='script-block'
                                    id={`${b.id}-${idx + 1}-block`}
                                  >
                                    <Field
                                      data-cy='script-block-section'
                                      id={`${b.id}-${idx + 1}-section`}
                                      name={`blocks.${idx}.id`}
                                      sectionId={b.id}
                                      component={BlockHeader}
                                      sections={sections}
                                      remove={() => remove(idx)}
                                      upDisabled={idx === 0}
                                      downDisabled={idx === bs.length - 1}
                                      move={dir => swap(idx, idx + dir)}
                                    />
                                    <Field
                                      data-cy='script-block-content'
                                      id={`${b.id}-${idx + 1}-content`}
                                      name={`blocks.${idx}.content`}
                                    >
                                      {field => (
                                        <ContentContainer>
                                          <ContentEditor
                                            key={`${id}-${b.id}-${idx + 1}`}
                                            id={`${id}-${b.id}-${idx + 1}`}
                                            editorType={EDITOR_TYPE.SCRIPT}
                                            enableChips={enableChips}
                                            {...field}
                                          />
                                        </ContentContainer>
                                      )}
                                    </Field>
                                  </Form.Section>
                                ))}
                                <AddBlockButton data-cy='script-add-section' onClick={() => push(newBlock)}>
                                  <CircleButton color={scribeGreen}>+</CircleButton>
                                  Add block
                                </AddBlockButton>
                              </>
                            )}
                          </FieldArray>
                        </FormikForm>
                        <ActionBar
                          organizationId={organizationId}
                          userId={userId}
                          id={id}
                          formDirty={dirty}
                          formSubmitting={isSubmitting}
                          openSpecialtiesDialog={() => this.setSpecialtiesDialog(true)}
                          openCopyDialog={() => this.handleOpeningCopyDialog(values.blocks)}
                          saveScript={handleSubmit}
                          setSelected={setSelected}
                          showSpecialties={showSpecialties}
                          filteredSpecialty={filteredSpecialty}
                          isOnboardingDefault={isOnboardingDefault}
                          validateErrorSections={this.validateErrorSections}
                          blocks={values.blocks}
                          setErrorContentDialog={this.setContentErrors}
                        />
                        <CopyToUser
                          open={copyDialogOpen}
                          organizationId={organizationId}
                          userId={userId}
                          scriptId={id}
                          closeDialog={() => this.setCopyDialogOpen(false)}
                        />

                        {!!contentErrors &&
                          <ContentErrorsDialog
                            open={!!contentErrors}
                            contentErrors={contentErrors}
                            closeDialog={() => this.setContentErrors(false)}
                          />}
                      </>
                    )}
                  </Formik>
                )
              }}
            </Query>
          )}
        </Query>
      </Paper>
    )
  }
}

export default ScriptEditor
