import React from 'react'
import Editor from '@sukiai/quill-editor'
import { ROLES } from '../../lib/constants'
import { isHTMLString, sanitizeHTML } from '../../lib/util'

export default props => {
  const { s2Mode, section: { content } } = props
  const text = content && JSON.parse(content).total_string
  const isHTML = isHTMLString(text)

  return isHTML ? (
    <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(text) }} style={{ 'overflow-x': 'hidden' }} />
  ) : (
    <div style={props.style}>
      <Editor
        value={content}
        persona={ROLES.ADMIN}
        readOnly
        s2Mode={s2Mode}
      />
    </div>
  )
}
