import React from 'react'
import styled from 'styled-components'
import { whiteSmoke, charcoal } from '../styles/colors'
import { Multiselect } from 'multiselect-react-dropdown'
import { SPECIALTY_TYPE, Z_INDEX } from '../lib/constants'
import { snakeCapsToEnglish } from '@sukiai/utils'

const SPECIALTIES_ARR = Object.keys(SPECIALTY_TYPE).map(s => ({
  name: snakeCapsToEnglish(s),
  value: s
}))

const Veil = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${Z_INDEX.VEIL};
`

const Container = styled.div`
  width: 600px;
  padding: 25px;
  background: ${whiteSmoke};
  color: ${charcoal};
  border-radius: 4px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.5);
  z-index: ${Z_INDEX.MODAL};
`

const Heading = styled.div`
  font-size: 24px;
  font-weight: 600;
`

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`

const Button = styled.button.attrs({
  type: 'button'
})`
  border: none;
  outline: none;
  padding: 0px;
  background: ${charcoal};
  color: white;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 14px;
  margin-left: 14px;
  align-self: flex-end;
  font-weight: 600;
`

const MultiSelectStyle = {
  multiselectContainer: {
    width: '100%',
    margin: '25px 0'
  },
  searchBox: {
    borderWidth: '0 0 1px 0',
    borderRadius: '0px',
    marginLeft: '5px'
  }
}

export default class SpecialtiesDialog extends React.Component {
  /*
    Speciatlies strucutre in this components
    needs to be an array of object {name: 'N/A', value: 'Na'}
    Will need to convert back when submiting though
    Ex: 'NA' -> {name: 'N/A', value: 'Na'} -> 'NA'
  */

  state = {
    selected: this.props.selected.map(s => ({
      name: snakeCapsToEnglish(s),
      value: s
    }))
  }

  setCopyToId = copyToId => {
    this.setState({ copyToId })
  }

  handleClose = () => {
    const { closeDialog } = this.props
    closeDialog()
    this.setState({ copyToId: null })
  }

  setSpecialties = (specialties) => {
    this.setState({ selected: specialties })
  }

  handleSetSpecialties = () => {
    const { selected } = this.state
    const { setSpecialties } = this.props

    const selectedCopy = [...selected]
    selectedCopy.sort((a, b) => a.value.localeCompare(b.value))

    this.setState({ selected: selectedCopy })

    const convertBack = selectedCopy.map(s => s.value)
    setSpecialties(convertBack)

    this.handleClose()
  }

  render () {
    const { selected } = this.state
    const { open } = this.props

    return (
      open && (
        <Veil onClick={this.handleClose}>
          <Container data-cy='nt-configure-specialties-container' onClick={e => e.stopPropagation()}>
            <Body>
              <Heading>Configure Specialties</Heading>

              <Multiselect
                name='specialties'
                style={MultiSelectStyle}
                placeholder='Add Specialties'
                options={SPECIALTIES_ARR}
                displayValue='name'

                selectedValues={selected}
                onSelect={(list, item) => {
                  const { value } = item
                  if (value === SPECIALTY_TYPE.NA) {
                    list = [item]
                  } else {
                    list = list.filter(
                      i => i.value !== SPECIALTY_TYPE.NA
                    )
                  }
                  this.setSpecialties(list)
                }}
                onRemove={(list) => {
                  this.setSpecialties(list)
                }}
              />

              <Button data-cy='nt-set-specialties-button' onClick={this.handleSetSpecialties}>
                  Set Specialties
              </Button>

            </Body>
          </Container>
        </Veil>
      )
    )
  }
}
