import React from 'react'
import { Form, CircleButton } from '../../components'
import { Mapping, Arrow, SectionControls } from './styled'
import { FONT } from './constants'

const SectionHeader = ({
  field,
  sections = [],
  mapping,
  onChange,
  removeSection,
  upDisabled,
  downDisabled,
  moveSection,
  readOnly,
  ...props
}) => {
  const sectionOptions = sections.map(s => (
    <option key={s.id} value={s.id}>
      {`${s.name}${s.pbcSectionFlag ? ' (PBN)' : ''}`}
    </option>
  ))

  return (
    <Form.Section margin='14px' {...props}>
      <Form.Select {...field} onChange={onChange} options={sectionOptions} font={FONT.L} disabled={readOnly} />
      {!readOnly && (
        <SectionControls data-cy='nt-section-controls'>
          <CircleButton data-cy='nt-delete-section' onClick={removeSection}>
            –
          </CircleButton>
          <Arrow data-cy='nt-move-section-up' disabled={upDisabled} onClick={() => moveSection(-1)}>
            ⬆
          </Arrow>
          <Arrow data-cy='nt-move-section-down' disabled={downDisabled} onClick={() => moveSection(1)}>
            ⬇
          </Arrow>
        </SectionControls>
      )}
      {mapping && <Mapping data-cy='nt-section-mapping'>maps to {mapping}</Mapping>}
    </Form.Section>
  )
}

export default SectionHeader
