import React, { Component } from 'react'
import { fulvous } from '../../styles/colors'
import { GetCompositionMetadataByIdSms } from '@sukiai/gql/admin'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import omitDeep from 'omit-deep-lodash'
import { setError } from '../../lib/util'
import client from '../../apollo'
import { NOTE_STATUS } from '../../lib/constants'
import get from 'lodash.get'

const styles = {
  summary: {
    outline: 'none',
    cursor: 'pointer',
    fontSize: 18
  },
  status: {
    fontWeight: 600
  },
  selectField: {
    marginTop: 6
  },
  selected: {
    color: fulvous,
    fontWeight: 600
  }
}

class StatusSelector extends Component {
  state = {
    loading: false
  }

  componentDidUpdate (prevProps, prevState) {
    if (get(prevProps, 'composition.metadata.status') && prevState.loading) {
      this.setState({ loading: false })
    }
  }

  handleUpdateStatus = (event, idx, value) => {
    const { orgId, composition, updateComposition, refetchNote } = this.props
    const compId = get(composition, 'id')

    this.setState({ loading: true })
    client.query({
      query: GetCompositionMetadataByIdSms,
      variables: {
        organizationId: orgId,
        id: compId
      }
    }).then(({ data }) => {
      const old = get(data, 'compositionsSms.results[0]')
      const newMetadata = old && {
        ...old.metadata,
        status: value
      }
      return omitDeep(newMetadata, '__typename')
    }).then(newMeta => {
      return updateComposition({
        variables: {
          organizationId: orgId,
          id: compId,
          metadata: newMeta
        }
      })
    }).then(updatedData => {
      refetchNote()
      console.info(`Updated note status to: ${value}`)
    }).catch(error => {
      this.setState({ loading: false })
      setError(error)
      console.error('Error updating note status:', error)
    })
  }

  getEMRWarning = () => {
    const { composition } = this.props
    const warningType = get(composition, 'metadata.submissionStatus.warning.type', '')
    const warningMessage = get(composition, 'metadata.submissionStatus.warning.message', '')
    return `EMR Warning: ${warningType}${warningMessage && ` (${warningMessage[0].toUpperCase()}${warningMessage.slice(1)})`}`
  }

  render () {
    const { composition, style } = this.props
    const { loading } = this.state

    let noteStatus, submissionWarning
    if (!loading) {
      noteStatus = get(composition, 'metadata.status')
      submissionWarning = get(composition, 'metadata.submissionStatus.warning')
    }

    return (
      <div style={style} data-cy='status-selector-container'>
        <details>
          <summary style={styles.summary}>
            Note Status: {
              loading
                ? <i>Loading...</i>
                : <span style={styles.status}>{noteStatus}</span>
            }
            {
              noteStatus === NOTE_STATUS.SUBMITTED_TO_EMR && submissionWarning &&
                <div style={{ fontStyle: 'italic' }}>
                  {this.getEMRWarning()}
                </div>
            }
          </summary>
          <SelectField
            value={noteStatus}
            onChange={this.handleUpdateStatus}
            disabled={loading}
            style={styles.selectField}
            selectedMenuItemStyle={styles.selected}
            data-cy='status-selector-select-field'
          >
            {Object.values(NOTE_STATUS).map(s => <MenuItem key={s} value={s} primaryText={s} />)}
          </SelectField>
        </details>
      </div>
    )
  }
}

export default StatusSelector
