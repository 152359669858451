const RADIO_OPTIONS = [
  {
    label: 'Organizations',
    value: 'Organizations'
  },
  {
    label: 'Users',
    value: 'Users'
  }
]

export { RADIO_OPTIONS }
