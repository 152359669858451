import React from 'react'
import { OutOfBoxWrapper, CloseModalButtonDark, OobeGridContainer } from './styled'
import {
  destroyOobeToast,
  getShouldHideToast
} from '../../lib/util'

const carouselData = [
  { id: 0, body: 'Say “Suki, go to <section>” or tap a section and dictate.', title: 'Hello, I’m Suki.' },
  { id: 1, body: 'Say “Suki, insert <script>” to insert a script. Or type “+” and part of the script name.' },
  { id: 2, body: 'To learn more about Suki, click “Help.”' }
]

const initialState = { slideState: 0, showScript: true, showCarousel: false }

const Dot = ({ slideState, id, onSlideJump }) => (
  <div
    onClick={() => onSlideJump(id)}
    className={slideState === id ? 'dot active' : 'dot'}
  />
)

const CarouselSlide = (props) => {
  if (props.slideState !== props.item.id) return null

  return (
    <div className='slide-wrap'>
      {props.item.title && (
        <div style={{ fontWeight: 'bold' }}>{props.item.title}</div>
      )}
      <div>{props.item.body}</div>
    </div>
  )
}

const WrapupToast = ({ toggleVisible }) => {
  const [state, setState] = React.useState({ ...initialState })
  const shouldHideToast = getShouldHideToast('hideWrapupToast')
  const isLastSlide = state.slideState === carouselData.length - 1

  const goToCarousel = () => setState(prevState => ({ ...prevState, showScript: false, showCarousel: true }))

  const handleJumpSlide = slideId => setState(prevState => ({ ...prevState, slideState: slideId }))

  const handleNext = () => {
    if (state.slideState === carouselData.length - 1) {
      setState(prevState => ({ ...prevState, slideState: 0 }))
    } else {
      setState(prevState => ({ ...prevState, slideState: prevState.slideState + 1 }))
    }
  }

  const handlePrev = () => {
    if (state.slideState === 0) {
      setState(prevState => ({ ...prevState, slideState: carouselData.length - 1 }))
    } else {
      setState(prevState => ({ ...prevState, slideState: prevState.slideState - 1 }))
    }
  }

  const destroy = () => {
    toggleVisible()
    destroyOobeToast('hideWrapupToast')
  }

  const nextOrDestroy = () => {
    if (isLastSlide) {
      destroy()
    } else {
      handleNext()
    }
  }

  if (shouldHideToast) return null

  if (!state.showScript && !state.showCarousel) return null

  if (state.showScript) {
    return (
      <OobeGridContainer>
        <div className='article-text'>
          <article>
            Click the badge to wake Suki.
          </article>
          <article>
            Suki only listens when awake.
          </article>
        </div>
        <div className='close-wrap'>
          <CloseModalButtonDark onClick={goToCarousel}>
            <div id='mdiv'>
              <div className='mdiv'>
                <div className='md' />
              </div>
            </div>
          </CloseModalButtonDark>
        </div>
      </OobeGridContainer>
    )
  }

  if (state.showCarousel) {
    return (
      <OutOfBoxWrapper>
        <div className='carousel-wrapper'>
          <div>
            <article>
              {carouselData.map((item, index) => (
                <CarouselSlide slideState={state.slideState} id={index} key={item.id} item={item} />
              ))}
            </article>
          </div>
          <div className='carousel-footer'>
            <div className='footer-item'>
              <button className='carousel-btn' onClick={handlePrev}>Previous</button>
            </div>
            <div className='footer-item'>
              <div class='divider' />
            </div>
            <div className='footer-item'>
              <button className='carousel-btn' onClick={nextOrDestroy}>{isLastSlide ? 'Done' : 'Next'}</button>
            </div>
            <div className='footer-item-close'>
              <CloseModalButtonDark onClick={destroy}>
                <div id='mdiv'>
                  <div className='mdiv'>
                    <div className='md' />
                  </div>
                </div>
              </CloseModalButtonDark>
            </div>
          </div>
        </div>
        <div className='dot-wrapper'>
          {carouselData.map((item, index) => (
            <Dot onSlideJump={handleJumpSlide} slideState={state.slideState} id={index} key={item.id} item={item} />
          ))}
        </div>
      </OutOfBoxWrapper>
    )
  }
}

export default WrapupToast
