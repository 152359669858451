import gql from 'graphql-tag'

// USED IN: ADMIN_NOTE, PATIENT_NOTE
export const AudioAndTranscriptsBySection = gql`
  query AudioAndTranscriptsBySection($organizationId: ID!, $compositionId: ID!, $sectionId: ID!) {
    transcripts(input: {
      organizationId: $organizationId,
      compositionId: $compositionId,
      sectionId: $sectionId,
      withTranscripts: true
    }) {
      results {
        audioUri,
        duration,
        transcripts {
          uri,
          rawContentType,
          rawContent,
          textContent
        }
      }
    }
  }
`

// USED IN: ADMIN_NOTE
export const GetScriptChangeAudio = gql`
  query GetScriptChangeAudio($organizationId: ID!, $compositionId: ID!) {
    transcripts(input: {
      organizationId: $organizationId,
      compositionId: $compositionId,
      isScriptChange: true,
      withTranscripts: true
    }) {
      results {
        audioUri,
        duration,
        transcripts {
          uri,
          rawContentType,
          rawContent,
          textContent
        }
      }
    }
  }
`
