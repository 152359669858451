import React from 'react'
import { Container, FloatingButton, Icon } from './styled'

export default class ActionBar extends React.Component {
  static Button = ({ onClick, disabled, icon, ...props }) => (
    <FloatingButton onClick={onClick} disabled={disabled} {...props}>
      <Icon disabled={disabled}>{icon}</Icon>
    </FloatingButton>
  )

  render () {
    const { children } = this.props
    return <Container data-cy='action-bar'>{children}</Container>
  }
}
