import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { uuid } from 'uuidv4'
import Switch from 'react-switch'
import { compose, graphql } from 'react-apollo'
import { GetUsers, UpdateOrganization, UpdateUser } from '@sukiai/gql/admin'
import { whiteSmoke, slateGrey } from '../styles/colors'
import omitDeep from 'omit-deep-lodash'
import { GetAllOrganizations, GetOrganizationById } from '@sukiai/gql/admin/Organizations'

const disclosureTextOptions = {
  OFF: 'Enhanced Review will not be available for users in this organization.',
  DEFAULT_OFF: 'Enhanced Review will be off by default for each new user in this organization.',
  DEFAULT_ON: 'Enhanced Review will be on by default for each new user in this organization.'
}

const orgOptions = [
  { id: uuid(), val: 'NA', text: 'Not Available', code: 'OFF' },
  { id: uuid(), val: 'OFF', text: 'Off by default', code: 'DEFAULT_OFF' },
  { id: uuid(), val: 'ON', text: 'On by default', code: 'DEFAULT_ON' }
]

const orgMap = {
  OFF: 'Not Available',
  DEFAULT_ON: 'On by default',
  DEFAULT_OFF: 'Off by default'
}

const EnhancedReviewBoxUser = styled.div`
  background-color: ${whiteSmoke};
  width: 100%;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  margin-top: 30px;
  padding: 10px 0;
  .switch-bar {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 0 18px;
  }
  .switch-wrap {
    display: flex;
    align-items: center;
  }
  span {
    color: ${slateGrey};
    margin: 18px;
    font-size: 12px;
  }
  .loader {
    display: flex;
    justify-content: center;
  }

  .not-available {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const EnhancedReviewBoxOrg = styled.div`
  background-color: ${whiteSmoke};
  min-width: 320px;
  width: 35%;
  min-height: 9rem;
  display: flex;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  margin-top: 30px;
  .box-inner {
    margin: 20px;
  }

  label {
    font-size: 18px;
  }

  span {
    color: ${slateGrey};
    font-size: 14px;
  }
  .select-drop {
    background-color: transparent;
    padding: 0 1em 0 0;
    margin: 10px 0;
    width: calc(100% - 55px);
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    border-radius: 4px;
  }
  .select-drop:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }
`

const isUserEnhancedReviewEnabled = (orgEnhanced, userEnhanced) => {
  if (orgEnhanced === 'OFF') return 'NOT_AVAILABLE'

  if (userEnhanced === 'UNKNOWN') {
    if (orgEnhanced === 'DEFAULT_ON') {
      return 'ON'
    } else if (orgEnhanced === 'DEFAULT_OFF') {
      return 'OFF'
    }
  }
  return userEnhanced
}

const UserEnhancedOption = (props) => { // eslint-disable-next-line
  const [userEnhancedOptionState, setUserEnhancedOptionState] = React.useState({
    isChecked: false,
    isLocked: false,
    error: null
  })

  const toggleChecked = () => {
    const cleanValues = omitDeep(props.currentUser, '__typename')
    // const map = { ON: true, OFF: false }
    const isEnhancable = userEnhancedOptionState.isChecked ? 'OFF' : 'ON'
    cleanValues.enhancedReview = isEnhancable
    const organizationId = props.currentOrgData.id
    const currentUser = props.currentUser
    props.updateUser({
      variables: {
        organizationId,
        ...currentUser,
        ...cleanValues
      },
      refetchQueries: [
        {
          query: GetUsers,
          variables: { organizationId }
        }
      ]
    })
      .then(() => {
        setUserEnhancedOptionState(prevState => ({
          ...prevState,
          isChecked: !userEnhancedOptionState.isChecked
        }))
      })
      .catch((error) => {
        setUserEnhancedOptionState(prevState => ({
          ...prevState,
          error
        }))
      })
  }

  React.useEffect(() => {
    if (props.currentUser && props.currentOrgData) {
      const { currentOrgData, currentUser } = props
      const _isUserEnhancedReviewEnabled = isUserEnhancedReviewEnabled(currentOrgData.enhancedReview, currentUser.enhancedReview)
      if (_isUserEnhancedReviewEnabled === 'ON') {
        setUserEnhancedOptionState(prevState => ({ ...prevState, isChecked: true }))
      } else if (_isUserEnhancedReviewEnabled === 'OFF') {
        setUserEnhancedOptionState(prevState => ({ ...prevState, isChecked: false }))
      } else if (_isUserEnhancedReviewEnabled === 'NOT_AVAILABLE') {
        setUserEnhancedOptionState(prevState => ({ ...prevState, isLocked: true }))
      }
    }
  }, [props.currentOrgData, props.currentUser])

  if (!props.currentUser || !props.currentOrgData) {
    return (
      <EnhancedReviewBoxUser>
        <div className='loader'>
          Loading...
        </div>
      </EnhancedReviewBoxUser>
    )
  }

  if (userEnhancedOptionState.isLocked) {
    return (
      <EnhancedReviewBoxUser>
        <div className='switch-bar'>
          Enhanced Review
        </div>
        <span>Not available for this user's organization</span>
      </EnhancedReviewBoxUser>
    )
  }

  return (
    <EnhancedReviewBoxUser>
      <div className='switch-bar'>
        <div>
          Enhanced Review
        </div>
        <div className='switch-wrap'>
          <Switch
            onHandleColor='#fff'
            onColor='#3498db'
            height={20}
            width={45}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={toggleChecked}
            checked={userEnhancedOptionState.isChecked}
          />
        </div>
      </div>
      <span>Feature {userEnhancedOptionState.isChecked ? 'ON' : 'OFF'}</span>
    </EnhancedReviewBoxUser>
  )
}

const OrgEnhancedOption = (props) => {
  const [orgEnhancedOptionState, setOrgEnhancedOptionState] = React.useState({
    currentOrgId: props.currentOrg.id,
    optionText: props.currentOrg.enhancedReview,
    error: null
  })

  const [disclosureText, setDisclosureText] = useState(disclosureTextOptions[orgEnhancedOptionState.optionText])

  useEffect(() => {
    setOrgEnhancedOptionState(prevState => ({
      ...prevState,
      currentOrgId: props.currentOrg.id,
      optionText: props.currentOrg.enhancedReview
    }))
    if (orgEnhancedOptionState.currentOrgId !== props.currentOrg.id) {
      setDisclosureText(disclosureTextOptions[props.currentOrg.enhancedReview])
    }
  }, [props.currentOrg])

  const updateOrg = (_enhancedReview) => {
    const { updateOrganization, currentOrg } = props
    updateOrganization({
      variables: {
        id: currentOrg.id,
        name: currentOrg.name,
        enhancedReview: _enhancedReview.enhancedReview
      },
      refetchQueries: [
        {
          query: GetAllOrganizations
        },
        {
          query: GetOrganizationById,
          variables: {
            id: currentOrg.id,
            withEmrInfo: true
          }
        }
      ]
    })
      .then(
        ({
          data: {
            updateOrganization: { organization }
          }
        }) => {
          setOrgEnhancedOptionState(prevState => {
            if (prevState.currentOrgId === orgEnhancedOptionState.currentOrgId) {
              return {
                ...prevState,
                optionText: orgMap[_enhancedReview.enhancedReview]
              }
            } else {
              return { ...prevState }
            }
          })
        }
      )
      .catch(error => {
        setOrgEnhancedOptionState(prevState => ({
          ...prevState,
          error
        }))
      })
  }

  const handleChange = (e) => {
    const res = orgOptions.filter(x => x.val === e.target.value)
    if (res.length > 0) {
      const currentEnhancedReviewState = res[0].code
      const [obj] = res
      const { code } = obj
      updateOrg({ enhancedReview: code })
      setDisclosureText(disclosureTextOptions[currentEnhancedReviewState])
    }
  }

  return (
    <EnhancedReviewBoxOrg>
      <div className='box-inner'>
        <div>
          <label for='enhanced-review'>Enhanced Review</label>
        </div>
        <select className='select-drop' onChange={handleChange}>
          {orgOptions.map((x) => (
            <option selected={x.text === orgMap[orgEnhancedOptionState.optionText]} key={x.id} value={x.val}>{x.text}</option>
          ))}
        </select>
        <div>
          <span>{disclosureText}</span>
        </div>
      </div>
    </EnhancedReviewBoxOrg>
  )
}

const EnhancedReviewBox = (props) => {
  const componentMap = {
    org: <OrgEnhancedOption {...props} />,
    user: <UserEnhancedOption {...props} />
  }

  return (
    <>
      {componentMap[props.reviewLevel]}
    </>
  )
}

export default compose(
  graphql(UpdateOrganization, { name: 'updateOrganization' }),
  graphql(UpdateUser, { name: 'updateUser' })
)(EnhancedReviewBox)
