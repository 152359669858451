import {
  CLEAR_DOCTOR_OPENED_NOTE,
  CLEAR_DOCTOR_OPENED_SECTION,
  SET_CURRENT_SECTION,
  SET_DOCTOR_OPENED_NOTE,
  SET_DOCTOR_OPENED_SECTION,
  SET_NOTE_ID,
  SET_NOTE_MODE
} from '../actions/note'
import { NOTE_MODE } from '../lib/constants'
import queryString from 'query-string'

const getNoteModeFromUrl = () => {
  const mode = queryString.parse(window.location.search).mode
  if (!mode) return NOTE_MODE.DEFAULT
  return mode
}

const initialState = {
  /** note id is tracked as `id`: & doesn't need an initial state */
  currentSectionId: '',
  // Is the current note, open in scribe/admin view, open by doctor
  isCurrentNoteOpenByDoctor: false,
  currentSectionOpenByDoctor: '',
  noteMode: getNoteModeFromUrl()
}

const note = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_SECTION:
      return { ...state, currentSectionId: action.id }
    case SET_NOTE_ID:
      return { ...state, id: action.id }
    case SET_DOCTOR_OPENED_NOTE:
      if (action.noteId === state.id) {
        return { ...state, isCurrentNoteOpenByDoctor: true }
      }
      return state
    case CLEAR_DOCTOR_OPENED_NOTE:
      if (action.noteId === state.id) {
        return { ...state, isCurrentNoteOpenByDoctor: false }
      }
      return state
    case SET_DOCTOR_OPENED_SECTION:
      if (action.noteId === state.id) {
        return { ...state, currentSectionOpenByDoctor: action.sectionId }
      }
      return state
    case CLEAR_DOCTOR_OPENED_SECTION:
      if (action.noteId === state.id) {
        return { ...state, currentSectionOpenByDoctor: '' }
      }
      return state
    case SET_NOTE_MODE:
      return { ...state, noteMode: action.mode }
    default:
      return state
  }
}

export default note
