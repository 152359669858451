// Sidebar
export const sideBarWidth = '320px'

// Note
export const noteMarginTopAndBottom = '50px'
export const noteWidth = '60vw'
export const noteSectionPadding = 24
export const sectionNameSize = 22
export const sectionContentSize = 20

// Title bar
export const navBarHeight = '40px'
