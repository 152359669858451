import React, { Component } from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'
import TopNavBar from './TopNavBar'
import { navBarHeight, noteMarginTopAndBottom, sideBarWidth } from '../styles/dimensions'
import adminBackground from '../images/adminBackground.jpg'

const styles = {
  container: {
    '@media print': {
      display: 'none'
    }
  },
  body: {
    position: 'fixed',
    width: '100vw',
    top: 0,
    left: 0,
    display: 'flex',
    paddingTop: navBarHeight,
    backgroundSize: 'cover'
  },
  side: {
    height: `calc(100vh - ${navBarHeight})`,
    width: '20vw',
    minWidth: 320
  },
  main: {
    height: `calc(100vh - ${navBarHeight})`,
    width: '80vw',
    boxSizing: 'border-box',
    padding: `${noteMarginTopAndBottom} 8vw`,
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto'
  },
  paper: {
    height: `calc(100vh - ${navBarHeight} - ${noteMarginTopAndBottom})`,
    width: '100%',
    minWidth: 500
  },
  noPaperMain: {
    position: 'fixed',
    top: navBarHeight,
    left: sideBarWidth,
    width: `calc(100vw - ${sideBarWidth})`,
    '@media (min-width: 1600px)': {
      left: '20vw',
      width: '80vw'
    },
    height: `calc(100vh - ${navBarHeight})`,
    boxSizing: 'border-box',
    overflow: 'scroll'
  }
}

class Frame extends Component {
  static propTypes = {
    background: PropTypes.string.isRequired
  }

  static defaultProps = {
    background: adminBackground
  }

  render () {
    return (
      <div style={styles.container}>
        <TopNavBar orgName={this.props.orgName} />
        <div style={Object.assign({}, styles.body, { backgroundImage: `url(${this.props.background})` })}>
          <div style={styles.side}>
            {this.props.children && this.props.children[0]}
          </div>
          {this.props.withNote
            ? (
              <div style={styles.main} id='frame'>
                <div style={styles.paper} id='frame-paper'>
                  {this.props.children && this.props.children[1]}
                </div>
              </div>
            )
            : React.Children.count(this.props.children) > 1 && (
              <div style={Object.assign({}, styles.noPaperMain, this.props.mainStyle)}>
                {this.props.children.slice(1)}
              </div>
            )}
        </div>
      </div>
    )
  }
}

export default Radium(Frame)
