import React, { Component } from 'react'
import {
  slateGrey,
  whiteSmoke,
  scribeGreen,
  charcoal,
  demoGrey
} from '../../styles/colors'
import Radium from 'radium'

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(0,0,0,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formContainer: {
    backgroundColor: whiteSmoke,
    width: '50vw'
  },
  header: {
    padding: '30px 40px',
    backgroundColor: slateGrey,
    color: 'white',
    fontSize: 24,
    fontWeight: 600
  },
  body: {
    padding: '30px 40px',
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    outline: 'none',
    borderBottom: `1px solid ${slateGrey}`,
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    backgroundColor: 'transparent',
    padding: 10,
    marginBottom: 20,
    fontSize: 20,
    width: '50%',
    boxSizing: 'border-box',
    color: charcoal
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 40px 20px'
  },
  button: {
    fontSize: 22,
    fontWeight: 800,
    color: scribeGreen,
    padding: '10px 20px',
    cursor: 'pointer',
    transition: '0.2s',
    borderRadius: 2,
    marginLeft: 6,
    ':hover': {
      backgroundColor: '#D8D8D8'
    },
    ':active': {
      transform: 'scale(0.95)'
    }
  },
  delete: {
    marginLeft: 0
  },
  disabled: {
    color: demoGrey,
    cursor: 'not-allowed',
    ':hover': {
      backgroundColor: 'transparent'
    },
    ':active': {
      transform: 'scale(1)'
    }
  }
}

class OrgForm extends Component {
  handleDelete = () => {
    const confirmed = window.confirm('Are you sure want to delete this organization?')
    if (confirmed) {
      this.props.toggleForm()
      this.props.deleteOrg()
    }
  }

  submit = () => {
    const {
      FORMS,
      type,
      addOrg,
      updateOrg,
      toggleForm
    } = this.props

    if (type === FORMS.ADD_ORG) {
      addOrg()
    } else {
      updateOrg()
    }

    toggleForm()
  }

  render () {
    const {
      FORMS,
      type,
      orgName,
      updateName,
      toggleForm
    } = this.props

    return (
      <div
        style={styles.container}
        onClick={toggleForm}
      >
        <div
          style={styles.formContainer}
          onClick={e => e.stopPropagation()}
        >
          <div style={styles.header}>
            {type === FORMS.ADD_ORG
              ? 'Add Organization'
              : 'Update Organization'}
          </div>
          <div style={styles.body}>
            <input
              autoFocus
              value={orgName}
              onChange={e => updateName(e)}
              style={styles.input}
              placeholder='Organization Name'
            />
          </div>
          <div style={styles.footer}>
            <div>

              {type === FORMS.UPDATE_ORG &&
                <div
                  key='delete'
                  style={Object.assign({}, styles.button, styles.delete)}
                  onClick={this.handleDelete}
                >
                  DELETE
                </div>}
            </div>
            <div style={{ display: 'flex' }}>
              <div
                key='cancel'
                style={styles.button}
                onClick={toggleForm}
              >
                CANCEL
              </div>
              <div
                key='submit'
                style={Object.assign({}, styles.button, !orgName && styles.disabled)}
                onClick={orgName ? this.submit : () => null}
              >
                {type === FORMS.ADD_ORG
                  ? 'ADD'
                  : 'UPDATE'}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Radium(OrgForm)
