import styled from 'styled-components'

export const Section = styled.section`
  margin-bottom: ${p => p.margin || '30px'};
  ${p =>
    p.flex &&
    `
    display: flex;
    flex-direction: ${p.direction || 'row'};
    align-items: center;
  `}
`

export const Input = styled.input`
  font-size: ${p => (p.isTitle ? '24px' : '16px')};
  padding: 5px;
  text-align: ${p => (p.isTitle ? 'center' : 'left')};
  display: block;
  font-weight: ${p => (p.isTitle ? 600 : 'inherit')};
  width: 50%;
  box-sizing: border-box;
  margin-bottom: ${p => p.margin || '10px'};
`

// TODO: Standardize font sizes
export const Select = styled.select`
  font-size: ${p => p.font || '16px'};
  flex: 1;
`
