import React, { Component } from 'react'
import { Animated } from 'react-native'
import { get } from 'lodash'
import {
  ResultsContainer,
  ResultOuterWrapper,
  ResultWrapper,
  SearchResult,
  Divider
} from './styled'
import { KEY_CODES } from './constants'
import { isAmwellTheme } from '../../lib/util'

// This component is the dropdown list - holds n number of list items
class DropDown extends Component {
  state = {
    resultsAnim: new Animated.Value(0),
    currentHighlightedIndex: null,
    currentHoveredIndex: null
  }

  componentWillMount () {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  componentDidMount () {
    const { resultsAnim } = this.state
    Animated.timing(resultsAnim, {
      toValue: 1,
      duration: 200
    }).start()
  }

  componentWillReceiveProps (nextProps) {
    const { resultsAnim } = this.state
    const { resultsData } = nextProps

    if (get(resultsData, 'length', 0) !== get(this.props.resultsData, 'length', 0)) {
      this.setState({ currentHighlightedIndex: null, currentHoveredIndex: null })
      Animated.timing(resultsAnim, {
        toValue: 1,
        duration: 200
      }).start()
    }
  }

  handleKeyDown = (e) => {
    const keyCode = e.keyCode
    const { currentHighlightedIndex } = this.state
    const { resultsData, onListItemClickHandler } = this.props

    switch (keyCode) {
      case KEY_CODES.DOWN_ARROW_KEY:
        if (currentHighlightedIndex === null) {
          this.setState({ currentHighlightedIndex: 0 })
        } else if (currentHighlightedIndex < resultsData.length - 1) {
          this.setState({ currentHighlightedIndex: currentHighlightedIndex + 1 })
        }
        break
      case KEY_CODES.UP_ARROW_KEY:
        if (currentHighlightedIndex > 0) {
          this.setState({ currentHighlightedIndex: currentHighlightedIndex - 1 })
        }
        break
      case KEY_CODES.ENTER:
        onListItemClickHandler(get(resultsData[currentHighlightedIndex], 'id'))
        break
    }
  }

  setHoverState = index => {
    this.setState({ currentHoveredIndex: index })
  }

  getResultsDropdownListItems () {
    return (this.props.resultsData.map((result, i, data) => {
      return (
        <ResultOuterWrapper key={result.id}>
          <ResultWrapper onClick={() => this.props.onListItemClickHandler(result.id)} data-cy={`search-result-container-${i}`}>
            <SearchResult
              onMouseOver={() => this.setHoverState(i)}
              iscurrentlyhovered={i === this.state.currentHoveredIndex ? 'true' : 'false'}
              isselected={i === this.state.currentHighlightedIndex ? 'true' : 'false'}
              data-cy={`result-item${i}`}
              isAmwellTheme={isAmwellTheme()}
            >
              {result.content}
            </SearchResult>
          </ResultWrapper>
          {i !== data.length - 1 && <Divider isAmwellTheme={isAmwellTheme()} />}
        </ResultOuterWrapper>
      )
    }))
  }

  render () {
    const {
      resultsAnim
    } = this.state
    const { resultsData, emptyListViewHandler } = this.props
    const styles = {
      results: {
        opacity: resultsAnim
      }
    }
    return (
      <ResultsContainer
        style={styles.results}
        data-cy='dropdown-container-component'
        isAmwellTheme={isAmwellTheme()}
      >
        {get(resultsData, 'length', 0) > 0
          ? this.getResultsDropdownListItems()
          : emptyListViewHandler()}
      </ResultsContainer>
    )
  }
}

export default DropDown
