import React, { Component } from 'react'
import get from 'lodash.get'
import {
  charcoal,
  fulvous,
  scribeGreen,
  selectedGrey,
  slateGrey,
  whiteSmoke
} from '../../styles/colors'
import { ListItem } from 'material-ui/List'
import FontIcon from 'material-ui/FontIcon'
import Divider from 'material-ui/Divider'
import { getFullName } from '@sukiai/utils'
import { getOrgIdFromPath } from '../../lib/util'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import loSto from '../../config/loSto'
import { ROUTES, LO_STO, NOTE_STATUS } from '../../lib/constants'
import processingClock from '../../images/processing-clock.svg'
import signNoteIcon from '../../images/sign-note.svg'
import Skeleton from '@material-ui/lab/Skeleton'

const styles = {
  itemContainer: {
    color: charcoal,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    backgroundColor: whiteSmoke,
    borderBottom: '1px solid #ececec'
  },
  inner: {
    padding: 28,
    width: 'calc(28vw - 35px)',
    boxSizing: 'border-box'
  },
  emrPill: {
    backgroundColor: slateGrey,
    color: whiteSmoke,
    fontWeight: 600,
    borderRadius: 45,
    padding: '5px 8px',
    fontSize: 14
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 20,
    marginBottom: 10,
    fontSize: 20
  },
  name: {
    fontWeight: 600
  },
  info: {
    fontSize: 16
  },
  icon: {
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

const UNKNOWN_PATIENT = 'Unknown Patient'

class UserNoteItem extends Component {
  toNote = note => {
    // TODO [@arunan] We are doing a full refresh of the note page for now.
    const orgId = getOrgIdFromPath()
    const userId = loSto.get(LO_STO.CURRENT_USER_ID)
    if (note.noteId) {
      window.location.href = `/${orgId}/${userId}/${ROUTES.PATIENT_NOTE}/${note.noteId}`
    } else {
      window.location.href = `/${orgId}/${userId}/${ROUTES.NOTE}/${note.id}`
    }
  }

  getStatusIcon = note => {
    const status = note?.metadata?.status
    switch (status) {
      case NOTE_STATUS.INCOMPLETE:
        return (
          <FontIcon
            className='material-icons'
            color={fulvous}
            style={{ fontSize: 30 }}
          >
            priority_high
          </FontIcon>
        )
      case NOTE_STATUS.USER_IS_DONE:
        return (
          <div style={styles.icon}>
            <img
              width={30}
              height={30}
              src={processingClock}
              alt='User Is Done'
            />
          </div>
        )
      case NOTE_STATUS.SUBMITTED_TO_EMR:
      case NOTE_STATUS.IMPORTED_FROM_EMR:
        return note.compositionId
          ? (
            <FontIcon
              className='material-icons'
              color={scribeGreen}
              style={{ fontSize: 30 }}
            >
              done
            </FontIcon>
          ) : <div style={styles.emrPill}>EMR</div>
      case NOTE_STATUS.NEED_DOCTOR_SIGN_OFF:
        return (
          <div style={styles.icon}>
            <img
              width={30}
              height={30}
              src={signNoteIcon}
              alt='Sign Note'
            />
          </div>
        )
      default:
    }
  }

  getPatientName = (note) => {
    const person = get(note, 'metadata.patient.person')
    if (person) {
      return getFullName(person)
    }
    return UNKNOWN_PATIENT
  }

  render () {
    const { note, selected, loading } = this.props

    if (loading) {
      return (
        <div>
          <ListItem
            style={Object.assign({}, styles.itemContainer, selected && { backgroundColor: selectedGrey })}
            innerDivStyle={styles.inner}
          >
            <div style={styles.heading}>
              <div style={styles.name}>
                <Skeleton width={150} height={18} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }} />
            </div>
            <div style={styles.info}>
              <Skeleton width={250} />

            </div>
          </ListItem>
          <Divider />
        </div>
      )
    }

    if (!get(note, 'metadata')) return null

    let date, time
    const appointment = get(note, 'metadata.appointment')
    const createdAt = get(note, 'createdAt')
    const compositionCreatedAt = get(note, 'compositionCreatedAt')

    if (appointment) {
      date = moment(appointment.startsAt).format('MM/DD/YYYY')
      time = moment(appointment.startsAt).format('h:mm A')
    } else if (createdAt) {
      date = moment(createdAt).format('MM/DD/YYYY')
      time = moment(createdAt).format('h:mm A')
    } else if (compositionCreatedAt) {
      date = moment(compositionCreatedAt).format('MM/DD/YYYY')
      time = moment(compositionCreatedAt).format('h:mm A')
    }

    return (
      <div>
        <ListItem
          onClick={() => this.toNote(note)}
          style={Object.assign({}, styles.itemContainer, selected && { backgroundColor: selectedGrey })}
          innerDivStyle={styles.inner}
          data-cy={`user-note-item-${note.id}`}
        >
          <div style={styles.heading}>
            <div style={styles.name}>
              {this.getPatientName(note)}

            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {this.getStatusIcon(note)}
            </div>
          </div>
          <div style={styles.info}>
            {date}&nbsp;&bull;&nbsp;{time}
          </div>
        </ListItem>
        <Divider />
      </div>
    )
  }
}

export default withRouter(UserNoteItem)
