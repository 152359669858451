import styled from 'styled-components/native'
import { Animated } from 'react-native'
import { black, westSide } from '../../lib/colors'
import check from '../../assets/icons/check-whiteSmoke.svg'
import { Z_INDEX, MODALS } from '../../lib/constants'

export const SidebarContainer = styled(Animated.View)`
  background-color: rgba(255,255,255,0.20);
  max-height: 480px;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
  width: 24vw;
  z-index: ${props => props.modal === MODALS.READ_NOTE
    ? Z_INDEX.VEIL_ACTIVE - 1
    : Z_INDEX.SIDEBAR
  };
  overflow-y: scroll;
  overscroll-behavior: contain;
`

export const PatientName = styled.Text`
  font-family: 'Open Sans';
  font-size: 22;
  color: ${black};
  margin-bottom: 20;
  padding: 20px;
  padding-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const SectionsContainer = styled.View`
  max-height: 100vh - 340 - 70;
  overflow: auto;
  padding-bottom: 20;
`

export const SectionContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 0.5
})`
  padding-left: 64;
  padding-right: 64;
  height: 60;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${props => props.current === 'true'
    ? 'rgba(80,80,80,0.2)'
    : 'transparent'
  };
`

export const SectionName = styled(PatientName)`
  font-size: 20;
  margin: 0px;
  padding: 0px;
`

export const Check = styled.Image.attrs({
  source: check
})`
  height: 20;
  width: 25;
  position: absolute;
  left: 20;
`

export const EMRBadge = styled.Text`
  background-color: ${westSide};
  padding-left: 8;
  padding-right: 8;
  padding-top: 4;
  padding-bottom: 4;
  border-radius: 20;
  position: absolute;
  right: 15;
  color: ${black};
  font-family: 'Open Sans';
  font-size: 12;
`
