import React from 'react'
import {
  ActionContainer,
  ImDoneButton,
  ImDoneText,
  ImDoneSection,
  SignOffButton
} from './styled'
import { useMediaQuery } from 'react-responsive'
import { isAmwellTheme } from '../../lib/util'

const ActionPanel = ({ handleImDone, isSignOff, title }) => {
  const isSmallWindow = useMediaQuery({
    query: '(max-width: 900px)'
  })
  const isAmwellDark = isAmwellTheme()

  if (isAmwellDark) {
    return null
  }

  return (
    <ActionContainer isSmallWindow={isSmallWindow}>
      <ImDoneSection onClick={handleImDone} data-cy='note-btn-im-done'>
        {isSignOff ? <SignOffButton /> : <ImDoneButton />}
        <ImDoneText>{title}</ImDoneText>
      </ImDoneSection>
    </ActionContainer>
  )
}

export default ActionPanel
