import React, { Component } from 'react'
import { Animated, Easing } from 'react-native'
import styled from 'styled-components'
import {
  ModalContainer,
  Heading,
  HeadingDark,
  SecondaryHeading,
  Body,
  BodyText,
  BodyTextDark,
  BodyImage,
  ItemWrapper,
  RadioButton,
  SelectedRadio,
  Footer,
  ActionButton,
  ActionText,
  Divider,
  AmwellItemWrapper
} from './styled'
import { getModalCyTag } from './util'
import Loading from '../Loading'
import ModalItem from '../ModalItem'
import { MODALS } from '../../lib/constants'
import { MODAL_BUTTONS } from './constants'
import { isAmwellTheme } from '../../lib/util'
import closeIcon from '../../assets/icons/close-icon.svg'

// [TODO: MARK] Bad style. Eventually get a debounce to only allow you to click buttons once.
let disableAction = false

class ModalDialog extends Component {
  state = {
    modalAnim: new Animated.Value(0),
    imgDims: null,
    isAmwellDark: isAmwellTheme()
  }

  componentDidMount () {
    Animated.timing(this.state.modalAnim, {
      toValue: 1,
      duration: 200,
      easing: Easing.linear
    }).start()

    if (this.props.type === MODALS.INACTIVITY_TIMEOUT) {
      const timeout = setTimeout(this.props.handleInactiveTimeout, 30 * 1000)
      this.setState({ timeout })
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.shouldDismiss && this.props.open) {
      Animated.timing(this.state.modalAnim, {
        toValue: 0,
        duration: 200,
        easing: Easing.linear
      }).start(nextProps.removeModal)

      if (this.props.type === MODALS.INACTIVITY_TIMEOUT) {
        clearTimeout(this.state.timeout)
      }
    }

    if (nextProps.type === MODALS.IMAGE) {
      const i = new Image() // eslint-disable-line

      i.src = nextProps.imageSource
      i.onload = () => {
        this.setState({
          imgDims: {
            height: i.height,
            width: i.width
          }
        })
      }
    } else {
      this.setState({ imgDims: null })
    }
  }

  renderBackButton = () => {
    const { goBack } = this.props
    return (
      <div style={{ position: 'absolute', marginTop: '-30px' }} onClick={goBack}>
        <ActionText>Back</ActionText>
      </div>
    )
  }

  render () {
    const {
      type,
      heading,
      body,
      items = [],
      dismiss,
      deleteNote,
      deleteProblemSection,
      submitNote,
      unfinishedNote,
      imageSource,
      loading,
      onSelect,
      insertScript,
      editMode,
      enterEditMode,
      updateScript,
      content,
      secondaryHeader,
      deleteScript,
      undoEdits,
      zeroStateText,
      data,
      icd10Search,
      deleteDiagnosis,
      bulkPrintNotes,
      bulkSaveNotes,
      styles: propStyles,
      selectType,
      redirectToSchedule
    } = this.props

    const vw = window.innerWidth
    const vh = window.innerHeight

    const styles = {
      modal: {
        opacity: this.state.modalAnim,
        transform: [
          {
            scale: this.state.modalAnim.interpolate({
              inputRange: [0, 1],
              outputRange: [0.6, 1]
            })
          }
        ]
      }
    }

    let bodyContent
    let actions = []
    let containerProps = {}

    const CloseIcon = styled.img.attrs({
      src: closeIcon
    })`
      width: 20px;
      height: 20px;
      position: absolute;
      right: 24px;
      top: 24px;
      cursor: pointer
    `

    switch (type) {
      case MODALS.LIST:
        bodyContent = items.map((i, idx) => {
          const { name, unstructuredSig } = i
          // eventually make more generic
          if (!name || !unstructuredSig) return

          // Necessary right now, because of lack of structured data
          const medication = name.substr(0, name.indexOf(' '))
          const restOfName = name.substr(name.indexOf(' '))
          const mainContent = (
            <div>
              <BodyText
                data-cy='modal-body'
                color='black'
                semibold='true'
              >
                {medication}
              </BodyText>
              <BodyText>{restOfName}</BodyText>
            </div>
          )

          const expandedContent = unstructuredSig
            ? (
              <BodyText color='black' fontSize='16px'>
                {i.unstructuredSig}
              </BodyText>
            )
            : null

          const expandButton = (
            <div style={{ color: '#6ca9ce' }}>
              more
            </div>
          )

          return (
            <ModalItem
              mainContent={mainContent}
              expandButton={expandButton}
              expandedContent={expandedContent}
              key={idx}
              data-cy={`list-item-${idx}`}
            />
          )
        })

        // If there are no items to display, display zero state
        if (items.length === 0) {
          bodyContent = (
            <div>{zeroStateText}</div>
          )
        }

        break
      case MODALS.IM_DONE:
        bodyContent = <BodyText data-cy='modal-body'>{body}</BodyText>
        actions = [
          {
            title: MODAL_BUTTONS.CONTINUE,
            action: dismiss,
            dataCy: 'modal-btn-continue'
          },
          {
            title: MODAL_BUTTONS.DONE,
            action: submitNote,
            dataCy: 'modal-btn-im-done'
          }
        ]
        break
      case MODALS.EMPTY_NOTE_IM_DONE:
        bodyContent = <BodyText data-cy='modal-body'>{body}</BodyText>
        actions = [
          {
            title: MODAL_BUTTONS.CONTINUE,
            action: dismiss,
            dataCy: 'modal-btn-continue'
          },
          {
            title: MODAL_BUTTONS.DELETE,
            action: deleteNote,
            dataCy: 'modal-btn-delete'
          }
        ]
        break
      case MODALS.UNKNOWN_PATIENT_SAVE:
        bodyContent = <BodyText data-cy='modal-body'>{body}</BodyText>
        actions = [
          {
            title: MODAL_BUTTONS.CONTINUE,
            action: dismiss,
            dataCy: 'modal-btn-continue'
          },
          {
            title: MODAL_BUTTONS.SAVE,
            action: unfinishedNote,
            dataCy: 'modal-btn-unfinished'
          }
        ]
        break
      case MODALS.NO_ENCOUNTER:
        bodyContent = <BodyText data-cy='modal-body'>{body}</BodyText>
        actions = [
          {
            title: MODAL_BUTTONS.CANCEL,
            action: dismiss,
            dataCy: 'modal-btn-cancel'
          },
          {
            title: MODAL_BUTTONS.UNIFINISHED_NOTES,
            action: unfinishedNote,
            dataCy: 'modal-btn-unfinished'
          }
        ]
        break
      case MODALS.NOTE_SUBMISSION_WITHOUT_EMR_CONNECTION:
        bodyContent = <BodyText data-cy='modal-body'>{body}</BodyText>
        actions = [
          {
            title: MODAL_BUTTONS.OK,
            action: redirectToSchedule,
            dataCy: 'modal-btn-unfinished'
          }
        ]
        break
      case MODALS.PDF_SUBMIT:
        bodyContent = <BodyText data-cy='modal-body'>{body}</BodyText>
        actions = [
          {
            title: MODAL_BUTTONS.CANCEL,
            action: dismiss,
            dataCy: 'modal-btn-cancel'
          },
          {
            title: MODAL_BUTTONS.UNIFINISHED_NOTES,
            action: unfinishedNote,
            dataCy: 'modal-btn-unfinished'
          },
          {
            title: MODAL_BUTTONS.SUBMIT_PDF,
            action: () => submitNote(true, true),
            dataCy: 'modal-btn-submit-pdf'
          }
        ]
        break
      case MODALS.IM_DONE_PDF:
        bodyContent = <BodyText data-cy='modal-body'>{body}</BodyText>
        actions = [
          {
            title: MODAL_BUTTONS.CANCEL,
            action: dismiss,
            dataCy: 'modal-btn-cancel'
          },
          {
            title: MODAL_BUTTONS.UNIFINISHED_NOTES,
            action: unfinishedNote,
            dataCy: 'modal-btn-unfinished'
          },
          {
            title: MODAL_BUTTONS.SUBMIT_PDF,
            action: submitNote,
            dataCy: 'modal-btn-submit-pdf'
          }
        ]
        break
      case MODALS.START_NOTE:
        bodyContent = <BodyText data-cy='modal-body'>{body}</BodyText>
        actions = [
          {
            title: MODAL_BUTTONS.CANCEL,
            action: dismiss,
            dataCy: 'modal-btn-cancel'
          },
          {
            title: MODAL_BUTTONS.START_NOTE,
            action: () => console.info('start'),
            dataCy: 'modal-btn-start-note'
          }
        ]
        break
      case MODALS.RADIO:
        bodyContent = items.map(i => (
          <ItemWrapper key={i.id} onPress={() => console.info('Set selected')}>
            <RadioButton active='false'>{false && <SelectedRadio />}</RadioButton>
            <BodyText data-cy='modal-body'>{i.name}</BodyText>
          </ItemWrapper>
        ))

        actions = [
          {
            title: MODAL_BUTTONS.CANCEL,
            action: dismiss,
            dataCy: 'modal-btn-cancel'
          },
          {
            title: MODAL_BUTTONS.START_NOTE,
            action: () => console.info('Add function here'),
            dataCy: 'modal-btn-start-note'
          }
        ]
        break
      case MODALS.SCRIPT_DETAILS:
      {
        bodyContent = content
        // Insert should be disabled if currently editing script or not on note screen
        const insertDisabled = window.location.pathname.split('/')[1] !== 'note' || editMode
        actions = [
          {
            title: editMode ? MODAL_BUTTONS.CANCEL : MODAL_BUTTONS.DELETE,
            action: editMode ? undoEdits : deleteScript,
            alignLeft: true,
            dataCy: editMode ? 'modal-btn-cancel' : 'modal-btn-delete'
          },
          {
            title: editMode ? MODAL_BUTTONS.SAVE : MODAL_BUTTONS.EDIT,
            action: editMode ? updateScript : enterEditMode,
            dataCy: editMode ? 'modal-btn-save' : 'modal-btn-edit'
          }
        ]

        if (!editMode) {
          actions.push({
            title: MODAL_BUTTONS.INSERT,
            action: insertScript,
            dataCy: 'modal-btn-insert',
            disabled: insertDisabled
          })
        }
        break
      }
      case MODALS.DELETE_NOTE:
        bodyContent = <BodyText data-cy='modal-body'>{body}</BodyText>
        actions = [
          {
            title: MODAL_BUTTONS.CANCEL,
            action: dismiss,
            dataCy: 'modal-btn-cancel'
          },
          {
            title: MODAL_BUTTONS.DELETE,
            action: deleteNote,
            dataCy: 'modal-btn-delete'
          }
        ]
        break
      case MODALS.INACTIVITY_TIMEOUT:
        bodyContent = <BodyText data-cy='modal-body'>{body}</BodyText>
        actions = [
          {
            title: 'Stay signed in',
            action: dismiss,
            dataCy: 'modal-btn-stay-signed-in'
          }
        ]
        break
      case MODALS.DELETE_SECTION:
        bodyContent = this.state.isAmwellDark ? <BodyTextDark data-cy='modal-body'>This will also delete the problem content. Delete anyway?</BodyTextDark> : <BodyText data-cy='modal-body'>'This will also delete the problem content. Delete anyway?'</BodyText>
        actions = [
          {
            title: MODAL_BUTTONS.CANCEL,
            action: dismiss,
            dataCy: 'modal-btn-cancel'
          },
          {
            title: MODAL_BUTTONS.DELETE,
            action: () => deleteProblemSection(data.organizationId, data.compositionId, data.sectionId),
            dataCy: 'modal-btn-delete'
          }
        ]
        break
      case MODALS.SELECT:
        bodyContent = items.map((item, i) => (
          <div>
            {this.state.isAmwellDark
              ? (
                <AmwellItemWrapper
                  key={item.id}
                  data-cy={`modal-select-item-${i}`}
                  onClick={() => {
                    if (!disableAction) {
                      disableAction = true
                      setTimeout(() => {
                        disableAction = false
                      }, 500)
                      onSelect(item.id)
                    }
                  }}
                >
                  <BodyTextDark dataCy='modal-body'>{item.name}</BodyTextDark>
                </AmwellItemWrapper>
              )
              : (
                <ItemWrapper
                  key={item.id}
                  data-cy={`modal-select-item-${i}`}
                  onClick={() => {
                    if (!disableAction) {
                      disableAction = true
                      setTimeout(() => {
                        disableAction = false
                      }, 500)
                      onSelect(item.id)
                    }
                  }}
                >
                  <BodyText dataCy='modal-body'>{item.name}</BodyText>
                </ItemWrapper>
              )}
          </div>
        ))
        break
      case MODALS.CONFIRM_CODES:
        bodyContent = content
        break
      case MODALS.IMAGE:
      {
        // TODO [JON] I'm convinced there's a better
        // way to do all of this to make it more universal.
        // Not all cases are currently covered here, but
        // it works for the demo stuff for now.
        const { imgDims } = this.state
        const H_TO_W = imgDims && imgDims.height / imgDims.width
        const W_TO_H = imgDims && imgDims.width / imgDims.height
        let h = H_TO_W * (vw * 0.6)
        let maxh = H_TO_W * 1140
        let minh, w, minw, maxw
        const MAX_HEIGHT = vh * 0.8
        // Portrait or height is too big
        if (H_TO_W > 1 || maxh > MAX_HEIGHT) {
          h = MAX_HEIGHT
          minh = MAX_HEIGHT
          maxh = MAX_HEIGHT
          w = W_TO_H * MAX_HEIGHT - 60
          minw = W_TO_H * MAX_HEIGHT - 60
          maxw = W_TO_H * MAX_HEIGHT - 60
          containerProps = {
            w: w + 60,
            minw: minw + 60,
            maxw: maxw + 60
          }
          // Landscape (or square)
        } else {
          h = H_TO_W * (vw * 0.6)
          minh = H_TO_W * 720
          maxh = H_TO_W * 1140
          if (maxh > MAX_HEIGHT) {
            h = MAX_HEIGHT
            minh = MAX_HEIGHT
            maxh = MAX_HEIGHT
            w = W_TO_H * MAX_HEIGHT - 60
            minw = W_TO_H * MAX_HEIGHT - 60
            maxw = W_TO_H * MAX_HEIGHT - 60
            containerProps = {
              w: w + 60,
              minw: minw + 60,
              maxw: maxw + 60
            }
          }
        }

        bodyContent = (
          <BodyImage
            source={imageSource}
            h={h}
            minh={minh}
            maxh={maxh}
            w={w}
            minw={minw}
            maxw={maxw}
            data-cy='modal-image'
          />
        )
        break
      }
      case MODALS.ICD10_PREVIEW:
        bodyContent = this.state.isAmwellDark ? <BodyTextDark data-cy='modal-body'>{body}</BodyTextDark> : <BodyText data-cy='modal-body'>{body}</BodyText>
        actions = [
          {
            title: MODAL_BUTTONS.CANCEL,
            action: dismiss,
            alignLeft: true,
            dataCy: 'modal-btn-cancel'
          },
          {
            title: MODAL_BUTTONS.REMOVE_CODE,
            action: deleteDiagnosis,
            dataCy: 'modal-btn-remove'
          },
          {
            title: MODAL_BUTTONS.CHANGE_CODE,
            action: icd10Search,
            dataCy: 'modal-btn-change'
          }
        ]
        break
      case MODALS.PRINT_NOTES:
        bodyContent = <BodyText data-cy='modal-body'>{body}</BodyText>
        actions = [
          {
            title: MODAL_BUTTONS.CANCEL,
            action: dismiss,
            alignLeft: true,
            dataCy: 'modal-btn-cancel'
          },
          {
            title: MODAL_BUTTONS.SAVE,
            action: bulkSaveNotes,
            dataCy: 'modal-btn-save'
          },
          {
            title: MODAL_BUTTONS.PRINT,
            action: bulkPrintNotes,
            dataCy: 'modal-btn-print'
          }
        ]
        break
      case MODALS.LOADING:
        bodyContent = <BodyText data-cy='modal-body'><Loading position='relative' /></BodyText>
        break
      case MODALS.ERROR:
      default:
        bodyContent = <BodyText data-cy='modal-body'>{body}</BodyText>
        actions = [
          {
            title: 'OK',
            action: dismiss,
            dataCy: 'modal-btn-ok'
          }
        ]
        break
    }

    let footer

    if (loading) {
      footer = null
    } else {
      footer = actions.map((a, idx) => (
        <ActionButton
          key={idx}
          onPress={() => {
            if (!disableAction) {
              disableAction = true
              setTimeout(() => {
                disableAction = false
              }, 500)
              a.action()
            }
          }}
          data-cy={a.dataCy}
          alignleft={a.alignLeft ? 'true' : 'false'}
          disabled={a.disabled}
          padding='12px 0px'
        >
          <ActionText
            isAmwellDark={this.state.isAmwellDark}
            type={type}
            disabled={a.disabled ? 'true' : 'false'}
            allcaps={type === this.state.isAmwellDark || MODALS.IM_DONE_BYPASS_QA || type === MODALS.SCRIPT_DETAILS ? 'false' : 'true'}
          >
            <span data-cy={a.dataCy}>{a.title}</span>
          </ActionText>
        </ActionButton>
      ))
    }

    let divider = null
    if (type === MODALS.SELECT || type === MODALS.SCRIPT_DETAILS) {
      divider = <Divider />
    }

    const isScriptEdit = type === MODALS.SCRIPT_DETAILS
    const cyTag = getModalCyTag({
      modalType: type,
      selectType: selectType
    })

    const isScriptModal = heading === 'Scripts'

    return (
      <div
        onClick={e => e.stopPropagation()} data-cy={cyTag}
      >
        <ModalContainer
          style={{ ...styles.modal, ...propStyles }}
          vw={vw}
          type={type}
          {...containerProps}
          data-cy={cyTag}
          isAmwellDark={this.state.isAmwellDark}
        >
          {isScriptEdit && this.renderBackButton()}
          {this.state.isAmwellDark ? (
            <HeadingDark type={type}>
              {heading}
              {isScriptModal && <CloseIcon onClick={this.props.removeModal} />}
            </HeadingDark>
          ) : (
            <Heading type={type}>
              {heading}
            </Heading>
          )}

          {secondaryHeader &&
            <SecondaryHeading>
              {secondaryHeader}
            </SecondaryHeading>}
          {!this.state.isAmwellDark && divider}
          <Body type={type} vh={vh} isAmwellDark={this.state.isAmwellDark}>
            {loading
              ? <Loading position='relative' />
              : bodyContent}
          </Body>
          <Footer
            alignment={type === MODALS.IM_DONE_BYPASS_QA ? 'column' : 'row'}
          >
            {footer}
          </Footer>
        </ModalContainer>
      </div>
    )
  }
}

export default ModalDialog
