import React, { Component } from 'react'
import { LoginModal } from '../components/Login'
import adminBackground from '../images/adminBackground.jpg'
import logo from '../images/suki-login.svg'

const styles = {
  wallpaper: {
    backgroundImage: `url(${adminBackground})`,
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    overflow: 'hidden',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  half: {
    width: '50vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-10vh'
  },
  logo: {
    height: 360,
    width: 280,
    marginBottom: -35,
    marginLeft: 150
  },
  login: {
    width: 350,
    marginRight: 200,
    marginTop: 50
  }
}

const getWallPaper = () => {
  return Object.assign(styles.wallpaper, { backgroundImage: `url(${adminBackground})` })
}

class Login extends Component {
  render () {
    return (
      <div style={getWallPaper()}>
        <div style={styles.half}>
          <img src={logo} alt='Suki Logo' style={styles.logo} />
        </div>
        <div style={styles.half}>
          <LoginModal style={styles.login} />
        </div>
      </div>
    )
  }
}

export default Login
