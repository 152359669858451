import React from 'react'
import get from 'lodash.get'
import debounce from 'lodash.debounce'
import { Query, withApollo } from 'react-apollo'
import { GetScriptsByName } from '@sukiai/gql/admin'
import { SearchContainer, SearchEmpty, Divider, SearchResult, ResultHints } from './styled'
import { Loading } from '../../components'
import { SCRIPT_TAG } from '../../lib/constants'

class ScriptSearch extends React.Component {
  state = {
    open: false,
    typing: false,
    searchString: null,
    hovered: false
  }

  componentDidUpdate (prevProps, prevState) {
    // condition to avoid infinite loop
    if (prevProps.active === this.props.active &&
      prevProps.dirty === this.props.dirty &&
      prevProps.name === this.props.name &&
      prevProps.client === this.props.client &&
      prevProps.organizationId === this.props.organizationId) return

    const { active, dirty, name, client, organizationId } = this.props
    if (active && dirty && name) {
      try {
        // Check if results for this name are already in the cache
        // If so, skip the debounce and setState immediately since
        // it won't cause a network call to be made
        client.readQuery({
          query: GetScriptsByName,
          variables: { organizationId, name }
        })
        this.setSearchString.cancel()
        this.setState({ open: true, typing: false, searchString: name })
      } catch (e) {
        // If the results aren't already in the cache, use the
        // debounced function to update state to avoid sending
        // a network call on each keystroke
        !this.state.typing && this.setState({ open: true, typing: true })
        this.setSearchString(name)
      }
    } else {
      this.setSearchString.cancel()
      this.setState(state => ({
        open: state.hovered,
        typing: false,
        searchString: dirty ? state.searchString : null
      }))
    }
  }

  setSearchString = debounce(searchString => this.setState({ typing: false, searchString }), 500)

  handleSelect = (name, keywords) => {
    const { onSelect } = this.props
    onSelect(name, keywords)
    this.setState({ searchString: null, open: false, hovered: false })
  }

  handleSetHover = () => {
    this.setState({ hovered: true })
  }

  handleUnsetHover = () => {
    this.setState({ hovered: false })
  }

  render () {
    const { organizationId } = this.props
    const { open, typing, searchString } = this.state

    if (!open) return null

    const variables = { organizationId, name: searchString }
    const skip = !organizationId || !searchString

    return (
      <Query query={GetScriptsByName} variables={variables} skip={skip}>
        {({ loading, error, data }) => {
          if (typing || loading) {
            return (
              <SearchContainer data-cy='script-search-loading'>
                <SearchEmpty>
                  <Loading />
                </SearchEmpty>
              </SearchContainer>
            )
          }

          const scripts = get(data, 'macros.results', [])
          if (error || !get(scripts, 'length', 0)) return null

          return (
            <SearchContainer
              data-cy='script-search-results'
              onMouseEnter={this.handleSetHover}
              onMouseLeave={this.handleUnsetHover}
            >
              {scripts.map((s, idx) => {
                const nameTag = get(s, 'tags', []).find(t => t.type === SCRIPT_TAG.NAME)
                const name = get(nameTag, 'name.value', '')
                const keywords = get(nameTag, 'name.aliases', []).join(', ')

                return (
                  <React.Fragment key={s.id}>
                    {idx !== 0 && <Divider />}
                    <SearchResult
                      data-cy='script-search-result'
                      id={s.id}
                      onClick={() => this.handleSelect(name, keywords)}
                    >
                      {name}
                      {!!keywords.length && <ResultHints>{` (${keywords})`}</ResultHints>}
                    </SearchResult>
                  </React.Fragment>
                )
              })}
            </SearchContainer>
          )
        }}
      </Query>
    )
  }
}

export default withApollo(ScriptSearch)
