import React, { Component } from 'react'
import Radium from 'radium'
import FontIcon from 'material-ui/FontIcon'
import { fulvous } from '../../styles/colors'

const styles = {
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 30px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#D8D8D8'
    }
  },
  name: {
    position: 'absolute',
    left: 60,
    fontSize: 16
  },
  check: {
    position: 'absolute',
    left: 20
  }
}

class CheckableListItem extends Component {
  handleCheckItem = () => {
    this.props.checkAction(this.props.org.id)
  }

  render () {
    const { org, checked } = this.props

    return (
      <div
        onClick={this.handleCheckItem}
        style={styles.listItem}
        data-cy={`checklist-item-${org.id}`}
      >
        {checked &&
          <FontIcon
            id='scribeProcessed'
            className='material-icons'
            style={styles.check}
            color={fulvous}
          >
            check
          </FontIcon>}
        <div style={styles.name}>
          {org.name}
        </div>
      </div>
    )
  }
}

export default Radium(CheckableListItem)
