import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import RaisedButton from 'material-ui/RaisedButton'
import DatePicker from 'material-ui/DatePicker'
import { withRouter } from 'react-router'
import { compose } from 'react-apollo'
import { notifySuccess, setError } from '../../lib/util'
import moment from 'moment'
import client from '../../apollo'
import { TriggerRawNotesRefresh } from '@sukiai/gql/admin'
import { whiteSmoke } from '../../styles/colors'

const styles = {
  formStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    color: 'white',
    padding: '20px'
  },
  rowFormStyle: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'flex-end'
  },
  text: {
    color: 'white'
  },
  formsContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between'
  },
  btn: {
    margin: '12px'
  }
}

const renderDatePicker = ({
  input: { onBlur, ...inputProps },
  onChange,
  ...props
}) =>
  <DatePicker
    {...inputProps}
    onChange={(event, value) => inputProps.onChange(value)}
    value={inputProps.value !== '' ? inputProps.value : null}
    {...props}
  />

class Form extends Component {
  render () {
    const {
      pristine,
      reset,
      submitting,
      handleSubmit
    } = this.props

    return (
      <form style={styles.formStyle} onSubmit={handleSubmit}>
        <div>
          <Field
            name='startsAt'
            component={renderDatePicker}
            hintText='Start Date'
          />
        </div>
        <div>
          <Field
            name='endsAt'
            component={renderDatePicker}
            hintText='End Date'
          />
        </div>
        <div>
          <RaisedButton
            id='apptSubmit'
            type='submit'
            label='Submit'
            disabled={submitting}
            primary
            style={styles.btn}
          />
          <RaisedButton
            type='button'
            label='Clear Values'
            disabled={pristine || submitting}
            onClick={reset}
            style={styles.btn}
          />
        </div>
      </form>
    )
  }
}

// Actual Appointments Refresh Form
const ReprocessEncountersForm = reduxForm({ form: 'ReprocessEncountersForm' })(Form)

const getTimerange = (startsAt, endsAt) => ({
  startsAt: moment(startsAt).startOf('day').toISOString(),
  endsAt: moment(endsAt).endOf('day').toISOString()
})

// Container to hold both the form and the table
class ReprocessEncounters extends Component {
  handleFormSubmit = values => {
    const { currentOrg } = this.props

    const {
      startsAt,
      endsAt
    } = getTimerange(values.startsAt, values.endsAt)

    // Refresh EMR appointments
    client.mutate({
      mutation: TriggerRawNotesRefresh,
      variables: {
        organizationId: currentOrg.id,
        startsAt,
        endsAt
      }
    }).then(({ data }) => {
      window.Logger.info(
        `Reprocessed EMR encounters for ${currentOrg.name} from ${moment(startsAt).format('YYYY-MM-DD')} to ${moment(endsAt).format('YYYY-MM-DD')}.`
      )
      notifySuccess(
        `Reprocessed EMR encounters for ${currentOrg.name} from ${moment(startsAt).format('YYYY-MM-DD')} to ${moment(endsAt).format('YYYY-MM-DD')}.`
      )
    }).catch(error => {
      console.error(`Error reprocessing encounters for ${currentOrg.name} from ${moment(startsAt).format('YYYY-MM-DD')} to ${moment(endsAt).format('YYYY-MM-DD')}. [Error: ${JSON.stringify(error)}]`)
      window.Logger.error(`Error reprocessing encounters for ${currentOrg.name} from ${moment(startsAt).format('YYYY-MM-DD')} to ${moment(endsAt).format('YYYY-MM-DD')}. [Error: ${JSON.stringify(error)}]`)
      setError(error)
    })
  }

  render () {
    return (
      <div style={styles.formsContainer}>
        <div style={{ width: '100%', backgroundColor: whiteSmoke }}>
          <ReprocessEncountersForm
            onSubmit={values => this.handleFormSubmit(values)}
          />
        </div>
      </div>
    )
  }
}

export default compose(
  reduxForm({ form: 'ReprocessEncounters' }),
  withRouter
)(ReprocessEncounters)
