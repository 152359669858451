import React from 'react'
import styled from 'styled-components/native'
import { fulvous } from '../../lib/colors'

const Loader = styled.ActivityIndicator.attrs(props => ({
  color: fulvous,
  size: props.size || 'large'
}))`
  position: ${props => props.position || 'absolute'};
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
`

export default props => <Loader {...props} />
