import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _includes from 'lodash/includes'
import _toLower from 'lodash/toLower'

const sortOrganizationsByAccess = (organizations, selectedOrganizationIds) => {
  const activeOrganizations = _filter(organizations, (organization) => {
    return _includes(selectedOrganizationIds, organization?.id)
  })
  const sortedActiveOrganizations = activeOrganizations.sort((a, b) => {
    return a?.name > b?.name ? 1 : -1
  })

  const inActiveOrganizations = _filter(organizations, (organization) => {
    return !_includes(selectedOrganizationIds, organization?.id)
  })
  const sortedInActiveOrganizationss = inActiveOrganizations.sort((a, b) => {
    return _toLower(a?.name) > _toLower(b?.name) ? 1 : -1
  })
  return [...sortedActiveOrganizations, ...sortedInActiveOrganizationss]
}

const formatOrganizationsDataForTable = (users) =>
  _map(users, (user) => ({
    ...user,
    key: user?.id
  }))

const filterOrganizationsBySearchText = (organizations, searchText) =>
  _filter(organizations, (organization) => {
    return (
      _includes(_toLower(organization?.id), _toLower(searchText)) ||
      _includes(_toLower(organization?.name), _toLower(searchText))
    )
  })

const getRowSelection = (selectedOrganizationIds, isEditView, onSelect) => ({
  selectedRowKeys: selectedOrganizationIds,
  onChange: onSelect,
  getCheckboxProps: () => ({
    disabled: !isEditView
  })
})

export {
  filterOrganizationsBySearchText,
  formatOrganizationsDataForTable,
  getRowSelection,
  sortOrganizationsByAccess
}
