import React, { useState } from 'react'
import styled from 'styled-components'
import { LO_STO } from '../../lib/constants'
import { useLocalStorage } from '../../lib/util/hooks/storage'
import SearchInput from './SearchInput'
import UserNotes from './UserNotes'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  
  * {
    box-sizing: border-box;
  }

  .filter-container {
    background: #eaecef;
    width: 100%;
    
    display: flex;
    align-items: center;
    padding: 20px;

    @media (max-width: 1300px) {
      flex-direction: column;

      * {
        width: 100% !important;
      }

      select {
        margin-right: 0;
        margin-bottom: 5px;
      }
    }
  }
`

export const Select = styled.select`
  outline: 0;
  display: block;
  border: 1px solid rgb(223, 225, 230);
  margin-top: 0;
  margin-right: 5px;
  padding: 0 5px;
  font-size: 16px;
  width: 180px;
  border-radius: 4px;
  box-sizing: border-box;
  height: 32px;
`

const FILTER_TYPES = {
  COMPOSITIONS: 'COMPOSITIONS',
  PATIENT_NOTES: 'PATIENT_NOTES'
}

const UserNotesContainer = ({ userId, organizationId }) => {
  const [noteFilterType, setNoteFilterType] = useLocalStorage(
    LO_STO.USER_NOTES_PANEL_FILTER,
    FILTER_TYPES.COMPOSITIONS
  )
  const [filterVal, setFilterVal] = useState('')
  return (
    <Container>
      <div className='filter-container'>
        <Select
          name='noteFilterType'
          value={noteFilterType}
          onChange={e => setNoteFilterType(e.target.value)}
        >
          <option value={FILTER_TYPES.COMPOSITIONS}>Unfinished Notes</option>
          <option value={FILTER_TYPES.PATIENT_NOTES}>Finished Notes</option>
        </Select>
        <SearchInput
          placeholder='Filter by name or id'
          onChange={e => setFilterVal(e.target.value)}
        />
      </div>
      <UserNotes
        userId={userId}
        organizationId={organizationId}
        noteFilterType={noteFilterType}
        filterVal={filterVal}
      />
    </Container>
  )
}

export default UserNotesContainer
