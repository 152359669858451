import React, { Component } from 'react'
import get from 'lodash.get'
import {
  charcoal,
  whiteSmoke
} from '../../styles/colors'
import { ListItem } from 'material-ui/List'
import Divider from 'material-ui/Divider'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { DEVICE_NAMES } from '../../lib/constants'

const styles = {
  itemContainer: {
    color: charcoal,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    backgroundColor: whiteSmoke
  },
  inner: {
    padding: 28,
    width: 'calc(28vw - 35px)',
    boxSizing: 'border-box'
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    height: 20,
    marginBottom: 10,
    fontSize: 20,
    fontWeight: 600
  },
  body: {
    lineHeight: '20px',
    marginBottom: '10px'
  },
  info: {
    fontSize: 16,
    lineHeight: '30px'
  }
}

class NotificationEventItem extends Component {
  render () {
    const { notifEvent } = this.props

    const date = moment(notifEvent.sentAt).format('MM/DD/YYYY')
    const time = moment(notifEvent.sentAt).format('h:mm A')
    const entry = notifEvent.entry

    return (
      <div>
        <ListItem
          innerDivStyle={styles.inner}
          data-cy={`user-notif-event-${time}`}
        >
          <div style={styles.heading}>
            {get(notifEvent, 'content.header')}
          </div>
          <div style={styles.body}>
            {get(notifEvent, 'content.body')}
          </div>
          <div style={styles.info}>
            {date}&nbsp;&bull;&nbsp;{time}
          </div>
          <div style={styles.info}>
            <b>Device type:</b> {DEVICE_NAMES[entry.deviceType]}
          </div>
          <div style={styles.info}>
            <b>Device info:</b> {entry.deviceInfo}
          </div>
          <div style={styles.info}>
            <b>Notification key:</b> {entry.notificationKey.substr(0, 5)}...{entry.notificationKey.substr(entry.notificationKey.length - 3)}
          </div>
        </ListItem>
        <Divider />
      </div>
    )
  }
}

export default withRouter(NotificationEventItem)
