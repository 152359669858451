import React from 'react'
import styled from 'styled-components'
import get from 'lodash.get'
import { Mutation, Query } from 'react-apollo'
import { getFullName } from '@sukiai/utils'
import {
  NoteLockStatus,
  ReleaseNoteBackdoor,
  RemoveNoteFromQueue
} from '@sukiai/gql/admin'
import { successLight, dangerLight, dangerText } from '../styles/colors'
import { isBackDoorWhiteListed, notifySuccess, setError } from '../lib/util'
import { NOTE_QUEUE_STATUS } from '../lib/constants'

import processingClockIcon from '../images/processing-clock.svg'
import unlockIcon from '../images/lock-open.svg'
import trashIcon from '../images/trash.svg'

const {
  NOTE_IS_BEING_EDITED,
  NOTE_DOESNT_EXIST,
  NOTE_IS_AVAILABLE,
  NOTE_RELEASED_SUCCESSFULLY,
  NOTE_REMOVED_SUCCESSFULLY,
  NOTE_REMOVED_SUCCESSFULLY_WHILE_BEING_PROCESSED
} = NOTE_QUEUE_STATUS

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: white;
  padding: 10px;
  align-items: center;
  margin: 20px 0;
`

const FlexItem = styled.div`
  display: flex;
  align-items: center;
`

const ProcessingIcon = styled.img.attrs({
  src: processingClockIcon,
  alt: 'processing'
})`
  background: ${successLight};
  width: 20px;
  border-radius: 50%;
  padding: 5px;
  margin-right: 10px;
`

const UnlockIcon = styled.img.attrs({
  src: unlockIcon,
  alt: 'processing'
})`
  width: 15px;
  border-radius: 50%;
  margin-right: 5px;
`

const TrashIcon = styled.img.attrs({
  src: trashIcon,
  alt: 'delete'
})`
  width: 15px;
  border-radius: 50%;
  margin-right: 5px;
  filter: invert(0.3) sepia(1) saturate(6) hue-rotate(320deg);
`

const Button = styled.button`
  font-weight: 500;
  border: 0;
  border-radius: 5px;
  padding: 8px;
  margin: 0 5px;
  cursor: pointer;

  display: flex;
  align-items: center;

  background: ${(props) => (props.type === 'danger' ? dangerLight : '#ebecf0')};
  color: ${(props) => (props.type === 'danger' ? dangerText : '#172b4d')};
`

const NoteQueueStatusBar = ({ noteId }) => {
  const onReleaseComplete = (res) => {
    const status = get(res, 'releaseNoteBackdoor.status')
    if (status === NOTE_RELEASED_SUCCESSFULLY) {
      notifySuccess('Successfully released note from ops queue')
    } else {
      setError('Something went wrong')
    }
  }

  const onRemoveComplete = (res) => {
    const status = get(res, 'removeNoteFromQueue.status')
    if (
      status === NOTE_REMOVED_SUCCESSFULLY ||
      status === NOTE_REMOVED_SUCCESSFULLY_WHILE_BEING_PROCESSED
    ) {
      notifySuccess('Successfully removed note from ops queue')
    } else {
      setError('Something went wrong')
    }
  }

  return (
    <Container>
      <Query query={NoteLockStatus} variables={{ noteId }}>
        {({ data, error, loading }) => {
          const status = get(data, 'noteLockStatus.status')
          const scribe = get(data, 'noteLockStatus.scribe')

          if (error || !status) {
            return <p>Error fetching note status.</p>
          }
          if (loading) {
            return <p>Loading...</p>
          }

          return (
            <>
              <FlexItem>
                {status === NOTE_IS_BEING_EDITED && <ProcessingIcon />}

                {status === NOTE_DOESNT_EXIST && (
                  <div>Note is not in queue</div>
                )}
                {status === NOTE_IS_AVAILABLE && (
                  <div>Note is in queue but is not being processed</div>
                )}

                {status === NOTE_IS_BEING_EDITED && (
                  <div>
                    Processing Note:&nbsp;
                    <strong> {getFullName(scribe)} </strong>
                  </div>
                )}
              </FlexItem>

              <FlexItem>
                {status === NOTE_IS_BEING_EDITED && (
                  <Mutation
                    mutation={ReleaseNoteBackdoor}
                    onCompleted={onReleaseComplete}
                    variables={{ noteId }}
                    refetchQueries={[
                      { query: NoteLockStatus, variables: { noteId } }
                    ]}
                  >
                    {(release, { loading: releaseLoading }) => (
                      <Button
                        type='normal'
                        onClick={release}
                        disabled={releaseLoading}
                      >
                        <UnlockIcon />
                        Release Lock
                      </Button>
                    )}
                  </Mutation>
                )}
                {status !== NOTE_DOESNT_EXIST && isBackDoorWhiteListed() && (
                  <Mutation
                    mutation={RemoveNoteFromQueue}
                    onCompleted={onRemoveComplete}
                    variables={{ noteId }}
                    refetchQueries={[
                      { query: NoteLockStatus, variables: { noteId } }
                    ]}
                  >
                    {(remove, { loading: removeLoading }) => (
                      <Button
                        type='danger' onClick={(e) => {
                          const confirm = window.confirm('Are you sure you want to delete?')
                          if (confirm) {
                            remove()
                          }
                        }} disabled={removeLoading}
                      >
                        <TrashIcon />
                        Remove Note From Queue
                      </Button>
                    )}
                  </Mutation>
                )}
              </FlexItem>
            </>
          )
        }}
      </Query>
    </Container>
  )
}

export default NoteQueueStatusBar
